import React, { useState, useEffect } from 'react'
import Loader from '../../../../Utils/Loader';
import { Table } from 'react-bootstrap';
import api from '../../../../Utils/Interceptors';
import { AiFillDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { successNotify } from '../../../../Utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Redux/Store';
import { adminDeleteOperator } from '../../../../Redux/Action/Admin';

const OpeartorList = () => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [operatorList, setOperatorList] = useState([])
    const navigate = useNavigate();
    const { adminCreate } = useSelector((state: any) => state.adminOperatorCreate)
    const { adminDelete } = useSelector((state: any) => state.adminOperatorDelete)

    useEffect(() => {
        setLoading(true)
        api.get(`/admin/user/operator/list`).then((res: any) => {
            setLoading(false)
            setOperatorList(res?.data?.data)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [adminCreate?.status === 1, adminDelete?.status === 1])

    useEffect(() => {
        if (adminDelete?.status === 1) {
            successNotify("Deleted Successfully!")
            dispatch({ type: 'ADMIN_DELETE_OPERATOR_RESET' })
        }
    }, [adminDelete?.status === 1])

    const userDetail = (id: number) => {
        navigate(`/admin/opeartor-details/${id}`);
    };

    const deleteHandler = (id: number) => {
        dispatch(adminDeleteOperator(id))
    }

    return (
        <div className="table_div my-3">
            <h4>All Operators</h4>

            {
                loading ? <div
                    style={{ height: '50vh' }}
                    className={"d-flex justify-content-center align-items-center"}
                >
                    <Loader />
                </div> :
                    <Table responsive className="mb-0">
                        <thead>
                            <tr style={{ backgroundColor: "#8080800f" }}>
                                <th className="">ID</th>
                                <th className="">Name</th>
                                <th className="">Email</th>
                                <th className="">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {operatorList?.map((user: any, index: number) => {
                                return (
                                    <tr key={user._id}>
                                        <td className="">{index + 1}</td>
                                        <td className="">{user.first_name}</td>
                                        <td className="">{user.email}</td>
                                        <td className="">
                                            <div className="table_icons">
                                                <FaEye onClick={() => userDetail(user._id)} />
                                                <AiFillDelete onClick={() => deleteHandler(user._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
            }
        </div>
    )
}

export default OpeartorList