import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import OrderPayment from './OrderPayment/OrderPayment';
import { useLocation } from "react-router-dom";

const Confirmation = () => {

  const { state } = useLocation()
  const stripePromise = loadStripe('pk_test_sQRyFOwBtUzkyRV3OfD7MoRy005PT3uIBO'!);

  return (
    <div className="Order_details">
      <Container>
        <Row className="justify-content-center py-5">
          <Col md={10}>

            {/* <div className="order_id">
              <h6> Order ID: <span>#12345342</span> </h6>

            </div>

            <Row className="my-3 justify-content-end">
              <Col md={6}>
                <div className="order_detail_left">
                  <h6>Billing Address</h6>

                  <div className="p-3">
                    <p><span>Name:</span> lorem ipsum</p>
                    <p><span>Adress:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <p><span>Phone:</span> 02933321134</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="order_detail_left">
                  <h6>Shipping Address</h6>

                  <div className="p-3">
                    <p><span>Name:</span> lorem ipsum</p>
                    <p><span>Adress:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <p><span>Phone:</span> 02933321134</p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="order_product_detail mb-3">
              <h6>Product Details</h6>
              <div className="p-4">

                <Row className="product_row">
                  <Col md={6} className={'product_img'}>
                    <img src="/images/product-4.png" alt="" />
                    <p>Hookah Flavour Special</p>
                  </Col>
                  <Col md={3}>
                    <p>PRICE: $25</p>
                  </Col>

                  <Col md={3}>
                    <p>QTY: 1</p>
                  </Col>
                </Row>

                <Row className="product_row">
                  <Col md={6} className={'product_img'}>
                    <img src="/images/product-5.png" alt="" />
                    <p>Meduza-hookah special</p>
                  </Col>
                  <Col md={3}>
                    <p>PRICE: $29</p>
                  </Col>

                  <Col md={3}>
                    <p>QTY: 5</p>
                  </Col>
                </Row>

                <div className="order_id2">
                  <h5> Total: <span>Rs. 1,505</span> </h5>
                </div>
              </div>
            </div> */}

            <div className='order_product_detail mb-3'>
              <h6>Payment</h6>

              <div className='p-3'>
                <Elements stripe={stripePromise}>
                  <OrderPayment orderId={state} />
                </Elements>
              </div>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Confirmation