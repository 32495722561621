import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FindShop from "./Container/Pages/FindShop/FindShop";
import Home from "./Container/Pages/Home/Home";
import Login from "./Container/Pages/Auth/Login/Login";
import ForgetPassword from "./Container/Pages/Auth/ForgetPassword/ForgetPassword";
import Register from "./Container/Pages/Auth/Register/Register";
import MainLayout from "./Layout/MainLayout/MainLayout";
import ShopView from "./Container/Pages/FindShop/ShopView/ShopView";
import OurProducts from "./Container/Pages/OurProducts/OurProducts";
import ProductDetail from "./Container/Pages/OurProducts/ProductDetails/ProductDetail";
import Checkout from "./Container/Pages/OurProducts/ProductDetails/Checkout/Checkout";
import Confirmation from "./Container/Pages/OurProducts/ProductDetails/Confirmation/Confirmation";

// Admin Layout
import AdminLayout from "./Layout/AdminLayout/AdminLayout";
import { adminRoutes } from "./Container/Admin/Navbar/Routes";

// Customer Layout
import CustomerLayout from "./Layout/CustomerLayout/CustomerLayout";
import { customerRoutes } from "./Container/Customer/Routes/Routes";

// Owner Layout
import OwnerLayout from "./Layout/OwnerLayout/OwnerLayout";
import { OwnerRoutes } from "./Container/Owner/Routes/Routes";
import NotFound from "./Container/Pages/NotFound/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./Redux/Store";
import { getShopTypes } from "./Redux/Action/Auth";
// import { errorNotify } from "./Utils/toast";
import SiteModal from "./Components/Modal/Modal";
import OrderPlaced from "./Container/Pages/OurProducts/ProductDetails/Confirmation/OrderPayment/OrderPlaced/OrderPlaced";
import { getCurrentBPListings, getCurrentCity } from "./Redux/Action/BusinessPlaces";
// import Map from "./Components/Map/Map";
// import { Form, Spinner } from "react-bootstrap";
// import { ICoordinates } from "./Utils/Interfaces";
// import CurrentMap from "./Components/CurrentMap/CurrentMap";
import VerifyEmail from "./Container/Pages/Auth/VerifyEmail/VerifyEmail";
import Verification from "./Container/Pages/Auth/Verification/Verification";
import ChangePassword from "./Container/Pages/Auth/ChangePassword/ChangePassword";
import Events from "./Container/Pages/Events/Events";
import Advertise from "./Components/Advertise/Advertise";
import TermService from "./Components/TermService/TermService";
import AboutUs from "./Components/AboutUs/AboutUs";
import Faqs from "./Container/Pages/Home/Faqs/Faqs";
import OperatorLayout from "./Layout/OperatorLayout/OperatorLayout";
import { operatorRoutes } from "./Container/Operator/Navbar/Routes";
import UnSubscribe from "./Container/Admin/Pages/UnSubscribe/UnSubscribe";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const dispatch: AppDispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [locationShow, setLocationShow] = useState(false)
  // const [selectedCoordinates, setSelectedCoordinates] = useState<ICoordinates | null>(null);
  // const [getCountryName, setGetCountryName] = useState<any>('')
  // const { bpCurrent, loading } = useSelector((state: any) => state.BPCurrentListing);
  // const [address, setAddress] = useState('');

  // console.log(bpCurrent?.status)

  // useEffect(() => {
  //   if (bpCurrent?.status === 1) {
  //     setLocationShow(false)

  //   }
  // }, [bpCurrent])

  useEffect(() => {
    dispatch(getShopTypes())

    // let locationSet = localStorage.getItem('locationSet') ? JSON.parse(localStorage.getItem("locationSet") || "") : null;
    let countrySet = localStorage.getItem('getCity') ? JSON.parse(localStorage.getItem("getCity") || "") : null;
    // setSelectedCoordinates(locationSet)

    // dispatch(getCurrentBPListings(locationSet?.lat, locationSet?.lng))
    dispatch(getCurrentCity(countrySet))

    // if (!locationSet) {
    //   setLocationShow(true)
    // }

  }, [])

  // const currentLocationHandler = (e: any) => {
  //   e.preventDefault();

  //   if (selectedCoordinates) {
  //     localStorage.setItem('locationSet', JSON.stringify(selectedCoordinates));
  //     localStorage.setItem('getCity', JSON.stringify(getCountryName));
  //     dispatch(getCurrentBPListings(selectedCoordinates?.lat, selectedCoordinates?.lng))
  //     dispatch(getCurrentCity(getCountryName))
  //   }
  // }

  const adminLayout = (
    <Route path={"/admin"} element={<AdminLayout />}>
      {adminRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const operatorLayout = (
    <Route path={"/operator"} element={<OperatorLayout />}>
      {operatorRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const customerLayout = (
    <Route path={"/customer"} element={<CustomerLayout setLocationShow={setLocationShow} />}>
      {customerRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const ownerLayout = (
    <Route path={"/owner"} element={<OwnerLayout setLocationShow={setLocationShow} />}>
      {OwnerRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  // let hasLocation = localStorage.getItem('locationSet') ? JSON.parse(localStorage.getItem("locationSet") || "") : null;

  return (
    <React.Fragment>
      {/* <SiteModal show={locationShow} size={'lg'} onCloseModal={() => setLocationShow(!locationShow)} title="Current Location" showIcon={hasLocation ? true : false} >
        <div className="p-5">
          <Form onSubmit={currentLocationHandler}>
            <CurrentMap
              map={true}
              search={true}
              setAddress={setAddress}
              setGetCountryName={setGetCountryName}
              selectedCoordinate={selectedCoordinates}
              setSelectedCoordinate={setSelectedCoordinates}
            />
            <button type="submit">{loading ? <Spinner animation="border" size="sm" /> : "Submit"}</button>
          </Form>
        </div>
      </SiteModal> */}

      <SiteModal show={show} size={'sm'} onCloseModal={() => setShow(!show)} title="No Internet Connection" showIcon={false} >
        <div>
          <h3>Oops :( No Internet Connection</h3>
          <button className="back_btn">Refresh Page</button>
        </div>
      </SiteModal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        {/* <button onClick={() => setLocationShow(!locationShow)}>Change Current Location</button> */}
        <ScrollToTop />
        <Routes>

          <Route path={"/"} element={<MainLayout setLocationShow={setLocationShow} />}>
            <Route path={"/"} element={<Home />} />
            <Route path={"/find-shop"} element={<FindShop />} />
            <Route path={"/events"} element={<Events />} />
            <Route path={"/shop-view/:id"} element={<ShopView />} />
            <Route path={"/register"} element={<Register />} />
            <Route path={"/owner/register"} element={<Register />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/owner/login"} element={<Login />} />
            <Route path={"/admin/login"} element={<Login />} />
            <Route path={"/forget-password"} element={<ForgetPassword />} />
            <Route path={"/product-shop"} element={<OurProducts />} />
            <Route path={"/product/details/:id"} element={<ProductDetail />} />
            <Route path={"/checkout"} element={<Checkout />} />
            <Route path={"/order-confirmation"} element={<Confirmation />} />
            <Route path={"/order-placed"} element={<OrderPlaced />} />
            <Route path={"/account/verify/:id"} element={<VerifyEmail />} />
            <Route path={"/verification"} element={<Verification />} />
            <Route path={"/account/password_change/:id"} element={<ChangePassword />} />
            <Route path={"/advertise"} element={<Advertise />} />
            <Route path={"/terms-service"} element={<TermService />} />
            <Route path={"/about-us"} element={<AboutUs />} />
            <Route path={"/faqs"} element={<Faqs />} />
            <Route path={"/unsubscribe"} element={<UnSubscribe />} />
            <Route path={"*"} element={<NotFound />} />
          </Route>

          {adminLayout}
          {customerLayout}
          {ownerLayout}
          {operatorLayout}
        </Routes>
      </Router>
    </React.Fragment>
  );
}
export default App;