import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { adminOwnersProfile, adminOwnersEdit, adminEditUserPasswordChange } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from "../../../../../Utils/Loader";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { changePasswordHandler } from "../../../../../Utils/Interfaces";
import { authValidation } from "../../../../../Utils/Validations";

const OwnerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [previewImg, setPreviewImg] = useState<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);

  const dispatch: AppDispatch = useDispatch();
  const { loading, ownerProfile } = useSelector((state: any) => state.adminOwnerProfile);
  const { loading: editLoading, ownerEdit } = useSelector((state: any) => state.adminOwnerEdit);
  const { loading: passwordLoading, adminUserPassword } = useSelector((state: any) => state.adminUserPassword);
  const { register, handleSubmit, setValue } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset } = useForm<changePasswordHandler>();

  useEffect(() => {
    if (adminUserPassword?.status === 1) {
      successNotify("Password Updated Successfully!!")
      dispatch({ type: "ADMIN_USER_PASSWORD_RESET" })
      reset();
    }
  }, [adminUserPassword?.status === 1])

  useEffect(() => {
    if (ownerEdit?.status === 1) {
      successNotify("Owner Updated Successfully!!")
      navigate('/admin/all-owners')
      dispatch({ type: "ADMIN_OWNER_EDIT_RESET" })
    }
  }, [ownerEdit?.status === 1])

  useEffect(() => {
    dispatch(adminOwnersProfile(id))
  }, [dispatch, id])

  setValue("first_name", ownerProfile?.first_name);
  setValue("last_name", ownerProfile?.last_name);
  setValue("email", ownerProfile?.email);
  setValue("phone", ownerProfile?.phone);

  const imageHandler = (e: any) => {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
    setPreviewImg(URL.createObjectURL(e.target.files[0]))
  }

  const updateOwnerHandler: SubmitHandler<any> = (data) => {

    const fileData: any = new FormData();
    fileData.append("avatar", fileSelected);
    fileData.append("first_name", data.first_name);
    fileData.append("last_name", data.last_name);
    fileData.append("phone", data.phone);

    dispatch(adminOwnersEdit(id, fileData))

  };

  const updatePasswordHandler: SubmitHandler<any> = (data) => {
    if (data.password !== data.conf_password) {
      errorNotify("Passowrd not same")
      return;
    }

    dispatch(adminEditUserPasswordChange(id, data))
  }

  return (
    <div className="page_responsive">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="main_heading">Owners Details</h1>
        <button
          className="back_btn"
          onClick={() => navigate("/admin/all-owners")}
        >
          Back
        </button>
      </div>
      {
        loading ? <div
          style={{ height: '50vh' }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Loader />
        </div> :
          <div className="profile_settings">
            <h4>User Details</h4>
            <Form onSubmit={handleSubmit(updateOwnerHandler)}>
              <div className="profile_info">
                <Row className="profile_fields">
                  <Col md={12}>
                    <p>Avatar</p>
                    {
                      ownerProfile?.avatar ?
                        <img
                          src={previewImg ? previewImg : `${cloudURL}${MediaPath?.avatar}${ownerProfile?.avatar}`}
                          alt=""
                          className="profile_picture"
                        />
                        : <img
                          src={previewImg ? previewImg : "/images/dummy_img.png"}
                          alt=""
                          className="profile_picture"
                        />
                    }
                    <div>
                      <input
                        type="file"
                        className="profile_pic_input"
                        id="profile"
                        name="profile"
                        onChange={imageHandler}
                      />
                    </div>
                  </Col>

                  <Col md={6} className="py-2">
                    <label>First Name</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input
                        placeholder="Enter your First name"
                        {...register("first_name")}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Last Name</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input
                        placeholder="Enter your Last name"
                        {...register("last_name")}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Email</label>
                    <div className="signup_input">
                      <img src="/images/MailIcon.png" alt="" />

                      <input
                        placeholder="Enter email address"
                        disabled
                        value={ownerProfile?.email}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Phone</label>
                    <div className="signup_input">
                      <img src="/images/PhoneIcon.png" alt="" />

                      <input
                        placeholder="Enter phone number"
                        {...register("phone")}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="text-end">
                      <button type="submit" className="update_btn">
                        {editLoading ? <Spinner animation="border" size="sm" /> : 'Update Owner'}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>

            <div className="profile_info">
              <h6>CHANGE PASSWORD</h6>

              <Form onSubmit={handleSubmit2(updatePasswordHandler)}>
                <Row className="profile_fields">
                  <Col md={6} className="py-2">
                    <label>New Password</label>
                    <div className="signup_input">
                      <img src="/images/PasswordIcon.png" alt="" />
                      <input type="password" placeholder="Enter New Password"
                        {...register2("password", authValidation.newPassword)}
                      />
                    </div>
                    {errors2.password && (
                      <span className="error_msg">
                        {errors2?.password?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} className="py-2">
                    <label>Confirm Password</label>
                    <div className="signup_input">
                      <img src="/images/PasswordIcon.png" alt="" />
                      <input type="password" placeholder="Enter Confirm Password"
                        {...register2("conf_password", authValidation.confirmPassword)}
                      />
                    </div>
                    {errors2.conf_password && (
                      <span className="error_msg">
                        {errors2?.conf_password?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={12}>
                    <div className="text-end">
                      <button type="submit" className="update_btn">{passwordLoading ? <Spinner animation="border" size="sm" /> : 'Update Password'} </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
      }
    </div>
  );
};
export default OwnerDetails;