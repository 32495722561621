import React, { useState, useEffect } from 'react'
import { Col, Row, Container, Form, Spinner } from 'react-bootstrap';
import Modal from "../../../../Components/Modal/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { BsCalendar2Date } from "react-icons/bs";
import { EventInput } from "../../../../Utils/Interfaces";
import { eventValidation } from "../../../../Utils/Validations";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import EventList from './EventDetail/EventList';
import { errorNotify, successNotify } from '../../../../Utils/toast';
import { AppDispatch } from "../../../../Redux/Store";
import { ownerEventCreate, ownerGetAllEvents } from '../../../../Redux/Action/Owner';

const Events = () => {
    const dispatch: AppDispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [previewImg, setPreviewImg] = useState<any>(null);
    const [profileError, setProfileError] = useState<String | null>(null);
    const [fileSelected, setFileSelected] = useState<any>(null);
    const [selectedPlaces, setSelectedPlaces] = useState<any>([]);
    const [timings, setTimings] = useState<any>('')
    const { ownerPlacesListings } = useSelector((state: any) => state.ownerPlaceListing);
    const { loading, eventsCreate, error } = useSelector((state: any) => state.ownerEventsSave);

    const { register, handleSubmit, formState: { errors }, reset } = useForm<EventInput>();

    const handleChangeImage = (e: any) => {
        const fileList = e.target.files;
        if (!fileList) return;
        setFileSelected(fileList[0]);
        setPreviewImg(URL.createObjectURL(e.target.files[0]))
        setProfileError(null)
    }

    useEffect(() => {
        if (eventsCreate?.status === 1) {
            successNotify("Event created successfully!");
            reset();
            setFileSelected('')
            setPreviewImg('')
            setShowModal(false)
            dispatch({ type: "OWNER_EVENT_CREATE_RESET" });
            dispatch(ownerGetAllEvents('', ''))
        }
        if (error) {
            errorNotify(error)
            dispatch({ type: "CLEAR_ERRORS" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsCreate, error])

    const handlePlaces = (selectedOption: any) => {
        setSelectedPlaces(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : []);
    }

    const eventHandler: SubmitHandler<EventInput> = (data) => {

        // console.log(data.eventStartDate)
        // console.log(data.eventEndDate)

        if (data.eventEndDate) {
            if (fileSelected && data.eventEndDate >= data.eventStartDate) {

                const eventsData: any = new FormData();
                eventsData.append("eventTitle", data.eventTitle);
                eventsData.append("eventStartDate", data.eventStartDate);
                eventsData.append("eventEndDate", data.eventEndDate);
                eventsData.append("eventTime", timings);
                eventsData.append("eventDescription", data.eventDescription);
                eventsData.append("places", JSON.stringify(selectedPlaces));
                eventsData.append("eventImage", fileSelected);

                for(let value of eventsData){
                    console.log(value[1])
                }

                dispatch(ownerEventCreate(eventsData))
                setSelectedPlaces([])

            }
            else if (!fileSelected) {
                setProfileError("Please select Image");
            }
            else {
                errorNotify("End date is not valid!")
            }
        }

        else {
            if (!fileSelected) {
                setProfileError("Please select Image");
            }
            else {
                const eventsData: any = new FormData();
                eventsData.append("eventTitle", data.eventTitle);
                eventsData.append("eventStartDate", data.eventStartDate);
                eventsData.append("eventEndDate", data.eventEndDate);
                eventsData.append("eventTime", timings);
                eventsData.append("eventDescription", data.eventDescription);
                eventsData.append("places", JSON.stringify(selectedPlaces));
                eventsData.append("eventImage", fileSelected);

                for(let value of eventsData){
                    console.log(value[1])
                }

                dispatch(ownerEventCreate(eventsData))
                setSelectedPlaces([])

            }
        }
    };

    const options = ownerPlacesListings?.data?.map((d: any) => {
        return { value: d._id, label: d.name }
    })

    return (
        <div className='py-4'>
            <Modal
                title="Create Events"
                show={showModal}
                size="lg"
                onCloseModal={() => setShowModal(!showModal)}
                showIcon={true}
            >
                <Form onSubmit={handleSubmit(eventHandler)}>
                    <Row className="justify-content-center">
                        <Col md={11}>
                            <div className="profile_info">
                                <Row className="profile_fields">

                                    <Col md={6} className="thumb_image_container text-start">
                                        <label>Event Image</label>
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            id="image1"
                                            name="image1"
                                            onChange={handleChangeImage}
                                        />
                                        <div className="thumb_image">
                                            <label htmlFor="image1">
                                                <img
                                                    src={
                                                        previewImg ?
                                                            previewImg
                                                            : '/images/add_image.png'
                                                    }
                                                    alt=""
                                                />
                                            </label>
                                        </div>
                                        {profileError && <span className="error_msg">{profileError}</span>}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Event Title</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Event Title"
                                                {...register(
                                                    "eventTitle",
                                                    eventValidation.eventTitle
                                                )}
                                            />
                                        </div>
                                        {errors.eventTitle && (
                                            <span className="error_msg">
                                                {errors?.eventTitle?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event Start Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "eventStartDate",
                                                    eventValidation.eventStartDate
                                                )}
                                            />
                                        </div>

                                        {errors.eventStartDate && (
                                            <span className="error_msg">
                                                {errors?.eventStartDate?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event End Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "eventEndDate",
                                                    // eventValidation.eventEndDate
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event Time</label>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={timings}
                                                    onChange={(e) => setTimings(e)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className={'time_picker'}
                                                            fullWidth
                                                            placeholder="Enter OpenTime"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Event Details</label>
                                        <textarea
                                            rows={4}
                                            className="form-control"
                                            placeholder="Enter Event Description"
                                            {...register(
                                                "eventDescription",
                                                eventValidation.eventDescription
                                            )}
                                        />
                                        {errors.eventDescription && (
                                            <span className="error_msg">
                                                {errors?.eventDescription?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Places</label>
                                        <Select
                                            isMulti
                                            isClearable
                                            name="places"
                                            placeholder="Select Places"
                                            options={options}
                                            value={options?.filter((obj: any) => selectedPlaces.includes(obj.value))}
                                            onChange={handlePlaces}
                                            className="basic-multi-select form-control"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">
                                                {loading ? <Spinner animation="border" size="sm" /> : "CREATE"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Container>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <div className="text-end">
                            <button
                                className="back_btn"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Create Events
                            </button>
                        </div>
                        <EventList />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Events