import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import ReactStar from "../../../../Components/ReactStar/ReactStar";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit, FaShare } from "react-icons/fa";
import { MdVisibility } from "react-icons/md"
import "./Places.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { ownerPlaceDelete, getOwnerPlaceListings } from "../../../../Redux/Action/Owner";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import DeleteModal from "../../../../Components/Modal/Modal";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const Places = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [getPlaceId, setGetPlaceId] = useState()
  const [placeUrl, setPlaceUrl] = useState<any>({
    name: "",
    url: ""
  })
  const { ownerPlacesListings, loading } = useSelector((state: any) => state.ownerPlaceListing);
  const { ownerDeletePlaces, loading: deleteLoading } = useSelector((state: any) => state.OwnerDeletePlace);
  const { subscriptionGet, loading: subLoading } = useSelector((state: any) => state.ownerSubscriptions);

  console.log(subscriptionGet)

  const editHandler = (id: any) => {
    if (subscriptionGet?.features?.allowEdit) {
      navigate(`/owner/edit-place/${id}`, { state: { plan: subscriptionGet } })
    }
    else {
      errorNotify("Please Upgrade your Membership to Edit Your Shop")
    }
  }

  useEffect(() => {
    if (ownerDeletePlaces?.status === 1) {
      successNotify("Place Deleted Successfully")
      setShow(!show)

      dispatch({ type: 'OWNER_PLACE_DELETE_RESET' })
    }
  }, [dispatch, ownerDeletePlaces, show])

  useEffect(() => {
    dispatch(getOwnerPlaceListings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(ownerDeletePlaces?.status === 1)])

  const deleteHandler = (id: any) => {
    if (subscriptionGet?.features?.allowEdit) {
      setShow(!show)
      setGetPlaceId(id)
    }
    else {
      errorNotify("Please Upgrade your Membership to Delete Your Shop")
    }
  }

  const deletePlace = () => {
    dispatch(ownerPlaceDelete(getPlaceId))
  }

  const createPlan = () => {
    navigate('/owner/create-shop', { state: { plan: subscriptionGet } })
  }

  const shareHandler = (place: any) => {
    setPlaceUrl({
      name: place?.name,
      url: `https://hookahbars.com/shop-view/${place?.slug}`
    })
    setShow2(!show2)
  }

  return (
    <div className="py-3">
      <DeleteModal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Delete"
      >

        <div>
          <h5>Are you sure you want to delete this Place??</h5>
          <div>
            <button className="mx-3 back_btn" onClick={deletePlace}>{deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
            <button className="back_btn" onClick={() => setShow(!show)}>No</button>
          </div>
        </div>
      </DeleteModal>

      <DeleteModal
        show={show2}
        size="lg"
        onCloseModal={() => setShow2(!show2)}
        showIcon={true}
        title="Share"
      >
        <div>
          <TwitterShareButton
            title={placeUrl?.name}
            url={placeUrl?.url}
          >
            <button className="update_btn mx-3">Share to Twitter</button>
          </TwitterShareButton>

          <FacebookShareButton
            title={placeUrl?.name}
            url={placeUrl?.url}
          >
            <button className="update_btn">Share to Facebook</button>
          </FacebookShareButton>
        </div>
      </DeleteModal>

      <Container>
        <Row className="justify-content-center">
          {subscriptionGet?.features.allowMultiplePlaces && (
            <div className="business_list">
              <h5>Do you want to add more Places?? <span onClick={createPlan}>Create Now</span></h5>
            </div>
          )}
          {
            loading ? <Loader /> :
              <Col md={10}>
                <div className="table_div">
                  <h4>Places</h4>
                  {
                    ownerPlacesListings?.data?.length > 0 ?
                      <Table responsive>
                        <thead>
                          <tr style={{ backgroundColor: "#8080800f" }}>
                            <th className="text-center">PLACE NAME</th>
                            <th className="text-center">CATEGORY</th>
                            <th className="text-center">REVIEWS</th>
                            <th className="text-center">STATUS</th>
                            <th className="text-center">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ownerPlacesListings?.data?.map((placesData: any, index: number) => {

                            return (
                              <tr key={index}>
                                <td className="text-center">{placesData?.name}</td>
                                <td className="text-center">{placesData?.shop_type?.name}</td>
                                <td className="text-center">
                                  <div className="d-flex justify-content-center">
                                    <ReactStar
                                      edit={false}
                                      count={5}
                                      size={20}
                                      color2={"#ffb21e"}
                                      value={placesData?.avgRating === null ? 0 : placesData?.avgRating}
                                    />
                                  </div>
                                </td>
                                <td className="text-center">{parseInt(placesData?.active) === 0 ? <span className="pending"> Pending </span> : <span className="completed"> Active </span>}</td>
                                <td className="text-center">
                                  <div className="table_icons">
                                    <MdVisibility onClick={() => navigate(`/shop-view/${placesData?._id}`)} />
                                    <FaEdit onClick={() => editHandler(placesData?._id)} />
                                    <AiFillDelete onClick={() => deleteHandler(placesData?._id)} />
                                    {
                                      <FaShare onClick={() => shareHandler(placesData)} />
                                    }
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      :
                      <p
                        className="text-center"
                        style={{ fontSize: "20px", fontWeight: "500", padding: '15px' }}
                      >
                        No Places Found
                      </p>
                  }
                </div>
              </Col>
          }
        </Row>
      </Container>
    </div>
  );
};

export default Places;
