import React, { useEffect, useState } from "react";
import "./Register.css";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { authValidation } from "../../../../Utils/Validations";
import { RegistrationInput } from "../../../../Utils/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { registerUser, login } from "../../../../Redux/Action/Auth";
import { successNotify } from "../../../../Utils/toast";
import { CurrentUserInfo } from "../../../../Utils/helper";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const { register, handleSubmit, formState: { errors }, reset } = useForm<RegistrationInput>();
  const { error, loading, isRegistered } = useSelector((state: any) => state.register);

  console.log(CurrentUserInfo())
  console.log(isRegistered)

  // const [ownerDetails, setOwnerDetails] = useState<any>({
  //   username: '',
  //   password: ''
  // })

  useEffect(() => {
    if (isRegistered?.status === 1) {

      successNotify(isRegistered?.message)
      reset();
      dispatch({ type: "REGISTER_RESET" })

      navigate('/verification')

      // if (pathname === '/register') {

      // console.log(CurrentUserInfo())

      // navigate('/verification')
      // }
      // if (pathname === '/owner/register') {

      // navigate('/verification')

      // dispatch(login(ownerDetails))

      // setTimeout(() => {
      //   navigate("/owner/payment-plans", { state: { plan: "free" }, replace: true });
      // }, 2000)

      // }
    }
    if (error) {
      setErrorMsg(error)
      dispatch({ type: "CLEAR_ERRORS" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegistered, error])



  const registerHandler: SubmitHandler<RegistrationInput> = (data) => {

    if (data.password === data.conf_password) {
      if (pathname === "/register") {
        localStorage.removeItem("customerTab");

        let registerData = {
          ...data,
          roles: "customer"
        }
        dispatch(registerUser(registerData));
      } else {

        let registerData = {
          ...data,
          roles: "owner"
        }

        dispatch(registerUser(registerData));

        // setOwnerDetails({
        //   username: data.email,
        //   password: data.password
        // })
      }
    }
    else {
      setErrorMsg("Password must be same!!")
    }
  };

  function onChange(value: any) {
    console.log("Captcha value:", value);
  }


  return (
    <div className="main_login">
      <Container>
        <Row className="justify-content-center">
          <Col md={11} className="login_container">
            <Row className="py-5 px-3">
              <Col md={6}>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img
                    src="/images/signUp.png"
                    className="login_img"
                    alt="cl"
                  />
                </div>
              </Col>
              <Col md={6} className="px-1">
                <h3>
                  <span style={{ fontWeight: "800", color: "#ba2271" }}>
                    Create
                  </span>
                  Account
                </h3>
                {
                  errorMsg && <h5 className="main_error_msg">{errorMsg}</h5>
                }
                <Form onSubmit={handleSubmit(registerHandler)}>
                  <Row className="login_right_section">
                    <Col md={6} className="py-2">
                      <label>First Name</label>
                      <div className="signup_input">
                        <img src="/images/AccountIcon.png" alt="" />
                        <input
                          placeholder="Enter your First name"
                          {...register("first_name", authValidation.firstName)}
                        />
                      </div>
                      {errors.first_name && (
                        <span className="error_msg">
                          {errors?.first_name?.message}
                        </span>
                      )}
                    </Col>
                    <Col md={6} className="py-2">
                      <label>Last Name</label>
                      <div className="signup_input">
                        <img src="/images/AccountIcon.png" alt="" />
                        <input
                          placeholder="Enter your Last name"
                          {...register("last_name", authValidation.lastName)}
                        />
                      </div>
                      {errors.last_name && (
                        <span className="error_msg">
                          {errors?.last_name?.message}
                        </span>
                      )}
                    </Col>

                    <Col md={6} className="py-2">
                      <label>Email</label>
                      <div className="signup_input">
                        <img src="/images/MailIcon.png" alt="" />
                        <input
                          placeholder="Enter email address"
                          {...register("email", authValidation.email)}
                        />
                      </div>
                      {errors.email && (
                        <span className="error_msg">
                          {errors?.email?.message}
                        </span>
                      )}
                    </Col>
                    <Col md={6} className="py-2">
                      <label>Phone</label>
                      <div className="signup_input">
                        <img src="/images/PhoneIcon.png" alt="" />
                        <input
                          placeholder="Enter phone number"
                          {...register(
                            "phone",
                            authValidation.phoneNumber
                          )}
                        />
                      </div>
                      {errors.phone && (
                        <span className="error_msg">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </Col>

                    <Col md={6} className="py-2">
                      <label>Password</label>
                      <div className="signup_input">
                        <img src="/images/PasswordIcon.png" alt="" />
                        <input
                          type="password"
                          autoComplete={'off'}
                          placeholder="Enter Password"
                          {...register("password", authValidation.password)}
                        />
                        {errors.password && (
                          <span className="error_msg">
                            {errors?.password?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6} className="py-2">
                      <label>Confirm Password</label>
                      <div className="signup_input">
                        <img src="/images/PasswordIcon.png" alt="" />
                        <input
                          type="password"
                          autoComplete={'off'}
                          placeholder="Confirm Password"
                          {...register(
                            "conf_password",
                            authValidation.password
                          )}
                        />
                      </div>
                      {errors.conf_password && (
                        <span className="error_msg">
                          {errors?.conf_password?.message}
                        </span>
                      )}
                    </Col>
                    <Col>
                      <ReCAPTCHA
                        sitekey="AIzaSyAFsEP9H8HabaWXDeZzpZZ7-7JuVnXv2yk"
                        onChange={onChange}
                      />
                    </Col>

                    <Col md={12} className="py-2 mt-4">
                      <Button
                        style={{ width: "100%", height: "40px" }}
                        type="submit"
                      >
                        {loading ? <Spinner animation="border" size="sm" /> : 'SIGN UP'}
                      </Button>
                    </Col>

                    <Col md={12}>
                      <h6 className="text-center">
                        Already have an account?{" "}
                        <Link
                          to={
                            pathname === "/register" ? "/login" : "/owner/login"
                          }
                          style={{ fontWeight: "800", color: "#ba2271" }}
                        >
                          Sign In
                        </Link>
                      </h6>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
