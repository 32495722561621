import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { successNotify } from "../../../../Utils/toast";
import { authValidation } from "../../../../Utils/Validations";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyEmail = () => {

    const { id }: any = useParams();
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        setValue("code", id)
    }, [])

    let query = useQuery();

    let emailGet: any = query.get("email")

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<{ code: string }>();

    const forgetHandler: SubmitHandler<{ code: string }> = (data) => {
        setLoader(true)

        let d = {
            token: data.code,
            email: emailGet
        }

        axios.put('/auth/verify', d).then((res: any) => {
            setLoader(false)
            successNotify("email verify successfully")
            if (res?.data?.status === 1) {
                navigate('/login')
            }
        }).catch((e: any) => {
            setLoader(false)
            console.log(e)
        })

    };

    return (
        <div className="main_login">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="login_container">
                        <Row className="py-5 px-3">
                            <Col md={12} className=" px-1 pt-4">
                                <h3>
                                    <span style={{ fontWeight: "800", color: "#ba2271" }}>
                                        Verify your Email
                                    </span>
                                </h3>
                                <Row className="login_right_section">
                                    <Form onSubmit={handleSubmit(forgetHandler)}>
                                        <Col md={12} className="py-2">
                                            <label>Code</label>
                                            <div className="signup_input">
                                                <img src="/images/MailIcon.png" alt="" />
                                                <input
                                                    placeholder="Enter Verification Code"
                                                    {...register("code", authValidation.firstName)}
                                                />
                                            </div>
                                            {errors.code && (
                                                <span className="error_msg">
                                                    {errors?.code?.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={12} className="py-2 mt-4">
                                            <Button type="submit" style={{ width: "100%", height: "40px" }}>
                                                {loader ? <Spinner animation="border" size="sm" /> : 'Verify Email'}
                                            </Button>
                                        </Col>
                                    </Form>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default VerifyEmail;
