import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { customerOrder } from "../../../../Redux/Action/Customer";
import { AppDispatch } from "../../../../Redux/Store";
import moment from 'moment';
import Loader from "../../../../Utils/Loader";

const Orders = () => {
  const navigate = useNavigate();

  const [orderTab, setOrderTab] = useState("ALL");

  const dispatch: AppDispatch = useDispatch();
  const { loading, customerOrders } = useSelector((state: any) => state.customerGetOrders);

  console.log(customerOrders)

  useEffect(() => {
    dispatch(customerOrder())
  }, [])

  const orderStatus = (status: string) => {
    if (status === "0") {
      return <td className="text-center"> <span className="pending"> Pending </span></td>
    }
    else if (status === "1") {
      return <td className="text-center"> <span className="completed"> Completed </span></td>
    }
    else if (status === "2") {
      return <td className="text-center "> <span className="approved"> Approved </span> </td>
    }
    else if (status === "3") {
      return <td className="text-center"> <span className="rejected"> Failed </span> </td>
    }
    else if (status === "4") {
      return <td className="text-center "> <span className="rejected"> Rejected </span></td>
    }
    else if (status === "5") {
      return <td className="text-center"> <span className="rejected"> Expired </span></td>
    }
  }


  const showOrder = (order: any) => {
    if (customerOrders?.filter((list: any) => list?.active === order).length > 0) {
      return (
        <Table responsive>
          <thead>
            <tr style={{ backgroundColor: "#8080800f" }}>
              <th className="text-center">ORDER NO.</th>
              <th className="text-center">ORDER DATE</th>
              <th className="text-center">TOTAL PRICE</th>
              <th className="text-center">STATUS</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          {customerOrders
            .filter((list: any) => list?.active === order)
            .map((list: any, index: number) => (
              <tbody>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{moment(list?.order_date).format('ll')}</td>
                  <td className="text-center">{list.amount}</td>
                  {orderStatus(list?.active)}
                  <td className="text-center">
                    <div className="table_icons">
                      <FaEye
                        onClick={() =>
                          navigate(`/customer/order-details/${list._id}`)
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
        </Table>
      );
    } else return <p className="text-center py-3 fw-bold">No Data Found</p>;
  };

  const tabs = ["ALL", "PENDING", "COMPLETED", "REJECTED"];

  return (
    <div className="py-5">

      {
        loading ? <Loader /> :
          <>
            <div className="text-center">
              {tabs.map((tab) => (<button className="sign_btn m-2" onClick={() => setOrderTab(tab)}> {tab}</button>))}
            </div>

            <div className="table_div">
              <h4>All Orders</h4>
              {orderTab === "ALL" && (
                <Table responsive>
                  <thead>
                    <tr style={{ backgroundColor: "#8080800f" }}>
                      <th className="text-center">ORDER NO.</th>
                      <th className="text-center">ORDER DATE</th>
                      <th className="text-center">TOTAL PRICE</th>
                      <th className="text-center">STATUS</th>
                      <th className="text-center">ACTION</th>
                    </tr>
                  </thead>
                  {customerOrders?.map((list: any, index: number) => (
                    <tbody>
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{moment(list?.order_date).format('ll')}</td>
                        <td className="text-center">{list.amount}</td>
                        {orderStatus(list?.active)}
                        <td className="text-center">
                          <div className="table_icons">
                            <FaEye
                              onClick={() =>
                                navigate(`/customer/order-details/${list._id}`)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              )}

              {orderTab === "PENDING" && <>{showOrder("0")}</>}
              {orderTab === "COMPLETED" && <>{showOrder("1")}</>}
              {orderTab === "REJECTED" && <>{showOrder("4")}</>}
            </div>
          </>
      }

    </div>
  );
};

export default Orders;
