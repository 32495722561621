import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { getAdminSuggestedPlaceDetails } from "../../../../../Redux/Action/Admin";
import { BiWorld } from "react-icons/bi";
import Loader from "../../../../../Utils/Loader";

const PlacesDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const { loading, adminSuggestPlaceDetails } = useSelector((state: any) => state.adminGetSuggestPlaceDetails);

  useEffect(() => {
    if (id) {
      dispatch(getAdminSuggestedPlaceDetails(id))
    }
  }, [id, dispatch])

  return (
    <div className="page_responsive">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="main_heading">Suggested Place Detail</h1>
        <button
          className="back_btn"
          onClick={() => navigate("/admin/suggested-places")}
        >
          Back
        </button>
      </div>

      {
        loading ? <Loader /> :
          <div className="suggestFormHandler">
            <Row>
              <Col md={12} className="py-2">
                <label>Hookah Bar Name</label>
                <div className="signup_input">
                  <img src="/images/AccountIcon.png" alt="" />
                  <input
                    placeholder="Enter Business Name"
                    name="businessName"
                    disabled={true}
                    value={adminSuggestPlaceDetails?.name}
                  />
                </div>
              </Col>
              <Col md={6} className="py-2">
                <label>Email</label>
                <div className="signup_input">
                  <img src="/images/MailIcon.png" alt="" />
                  <input
                    placeholder="Enter email address"
                    disabled={true}
                    name="email"
                    value={adminSuggestPlaceDetails?.email}
                  />
                </div>
              </Col>
              <Col md={6} className="py-2">
                <label>Phone</label>
                <div className="signup_input">
                  <img src="/images/PhoneIcon.png" alt="" />
                  <input
                    disabled={true}
                    placeholder="Enter phone number"
                    name="phone"
                    value={adminSuggestPlaceDetails?.phone}
                  />
                </div>
              </Col>
              <Col md={12} className="py-2">
                <label>Address</label>
                <div className="signup_input">
                  <textarea
                    rows={4}
                    disabled={true}
                    name="address"
                    value={adminSuggestPlaceDetails?.address}
                    className="form-control"
                    placeholder="Enter description"
                  />
                </div>
              </Col>
              <Col md={6} className="py-2">
                <label>Country</label>
                <div className="signup_input">
                  <BiWorld />
                  <input
                    disabled={true}
                    placeholder="Enter Country Name"
                    name="country"
                    value={adminSuggestPlaceDetails?.country}
                  />
                </div>
              </Col>
              <Col md={6} className="py-2">
                <label>City</label>
                <div className="signup_input">
                  <BiWorld />
                  <input
                    disabled={true}
                    placeholder="Enter City Name"
                    name="city"
                    value={adminSuggestPlaceDetails?.city}
                  />
                </div>
              </Col>
              <Col md={12} className="py-2">
                <label>Description</label>
                <textarea
                  disabled={true}
                  rows={4}
                  name="description"
                  value={adminSuggestPlaceDetails?.description}
                  className="form-control"
                  placeholder="Enter description"
                />
              </Col>
            </Row>
          </div>
      }
    </div>
  );
};

export default PlacesDetail;
