import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import CustomerMain from "../CustomerMain/CustomerMain";
import Profile from "../Profile/Profile";
import Orders from "../Orders/Order";
// import Enquiry from "../Enquiry/Enquiry";

const CustomerDashboard = () => {
  const [tab, setTab] = useState<any>("1");

  const switchPage = {
    main: <CustomerMain />,
    profile: <Profile />,
    orders: <Orders />,
    // enquiry: <Enquiry />
  };

  useEffect(() => {
    setTab(
      localStorage.getItem("customerTab")
        ? JSON.parse(localStorage.getItem("customerTab") || "")
        : "1"
    );
  }, []);

  const changeTab = (value: string) => {
    setTab(value);
    localStorage.setItem("customerTab", JSON.stringify(value));
  };

  let showPage;
  if (tab === "1") {
    showPage = <div>{switchPage.main}</div>;
  } 
  else if (tab === "2") {
    showPage = <div>{switchPage.profile}</div>;
  } 
  else if (tab === "3") {
    showPage = <div>{switchPage.orders}</div>;
  }
  // else if (tab === "4") {
  //   showPage = <div>{switchPage.enquiry}</div>;
  // }  
  else {
    showPage = <div>{switchPage.main}</div>;
  }

  return (
    <div style={{ backgroundColor: "#f4faff" }}>
      <div className="owners_tab">
        <Container>
          <ul>
            <li
              className={tab === "1" ? "active" : ""}
              onClick={() => changeTab("1")}
            >
              Dashboard
            </li>
            <li
              className={tab === "2" ? "active" : ""}
              onClick={() => changeTab("2")}
            >
              Profile
            </li>
            <li
              className={tab === "3" ? "active" : ""}
              onClick={() => changeTab("3")}
            >
              My Orders
            </li>
            {/* <li
              className={tab === "4" ? "active" : ""}
              onClick={() => changeTab("4")}
            >
              Enquiry
            </li> */}
          </ul>
        </Container>
      </div>
      <Container>{showPage}</Container>
    </div>
  );
};

export default CustomerDashboard;
