import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { authValidation } from "../../../../Utils/Validations";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<{ email: string }>();

  const forgetHandler: SubmitHandler<{ email: string }> = (data) => {
    setLoader(true)

    axios.post('/auth/forgot_password', data)
      .then((res: any) => {
        setLoader(false)
        successNotify("Email Sent Successfully")
        navigate('/')
      }).catch((e: any) => {
        console.log(e.response.data.message)
        setLoader(false)
        errorNotify(e.response.data.message)
      })

  };

  return (
    <div className="main_login">
      <Container>
        <Row className="justify-content-center">
          <Col md={11} className="login_container">
            <Row className="py-5 px-3">
              <Col md={6}>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img
                    src="images/signUp.png"
                    className="login_img px-2"
                    alt=""
                  />
                </div>
              </Col>
              <Col
                md={6}
                className=" px-1 pt-4"
              >
                <h3>
                  <span style={{ fontWeight: "800", color: "#ba2271" }}>
                    Forget Password
                  </span>
                </h3>
                <Row className="login_right_section">
                  <Form onSubmit={handleSubmit(forgetHandler)}>
                    <Col md={12} className="py-2">
                      <label>Email</label>
                      <div className="signup_input">
                        <img src="images/MailIcon.png" alt="" />
                        <input
                          placeholder="Enter email address"
                          {...register("email", authValidation.email)}
                        />
                      </div>
                      {errors.email && (
                        <span className="error_msg">
                          {errors?.email?.message}
                        </span>
                      )}
                    </Col>
                    <Col md={12} className="py-2 mt-4">
                      <Button type="submit" style={{ width: "100%", height: "40px" }}>
                        {loader ? <Spinner animation="border" size="sm" /> : 'Send link'}
                      </Button>
                    </Col>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
