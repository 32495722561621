import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { authValidation } from "../../../../Utils/Validations";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChangePassword = () => {

    const { id }: any = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<{ password: string, confirm_password: string, code: string }>();

    useEffect(() => {
        setValue("code", id)
    }, [])

    let query = useQuery();
    let getEmail: any = query.get("email")

    const forgetHandler: SubmitHandler<any> = (d) => {
        setLoader(true)

        const data = {
            email: getEmail,
            token: id,
            password: d.password,
            confirm_password: d.confirm_password
        }

        axios.post('/auth/update_password', data)
            .then((res: any) => {
                setLoader(false)
                successNotify("Password Update Successfully")
                navigate('/login')
            }).catch((e: any) => {
                setLoader(false)
                errorNotify(e.response.data.message)
            })
    };

    return (
        <div className="main_login">
            <Container>
                <Row className="justify-content-center">
                    <Col md={11} className="login_container">
                        <Row className="py-5 px-3">
                            <Col md={6}>
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <img
                                        src="/images/signUp.png"
                                        className="login_img px-2"
                                        alt=""
                                    />
                                </div>
                            </Col>
                            <Col
                                md={6}
                                className=" px-1 pt-4"
                            >
                                <h3>
                                    <span style={{ fontWeight: "800", color: "#ba2271" }}>
                                        Change Password
                                    </span>
                                </h3>
                                <Row className="login_right_section">
                                    <Form onSubmit={handleSubmit(forgetHandler)}>
                                        <Col md={12} className="py-2">
                                            <label>New Password</label>
                                            <div className="signup_input">
                                                <img src="/images/PasswordIcon.png" alt="" />
                                                <input
                                                    placeholder="Enter New Password" type='password'
                                                    {...register("password", authValidation.password)}
                                                />
                                            </div>
                                            {errors.password && (
                                                <span className="error_msg">
                                                    {errors?.password?.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={12} className="py-2">
                                            <label>Confirm Password</label>
                                            <div className="signup_input">
                                                <img src="/images/PasswordIcon.png" alt="" />
                                                <input
                                                    placeholder="Enter Confirm Password" type='password'
                                                    {...register("confirm_password", authValidation.password)}
                                                />
                                            </div>
                                            {errors.confirm_password && (
                                                <span className="error_msg">
                                                    {errors?.confirm_password?.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={12} className="py-2 mt-4">
                                            <Button type="submit" style={{ width: "100%", height: "40px" }}>
                                                {loader ? <Spinner animation="border" size="sm" /> : 'Change Password'}
                                            </Button>
                                        </Col>
                                    </Form>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChangePassword;
