import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminExpiredSubscription, adminSendNotice } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from '../../../../../Utils/Loader';
import { Spinner, Table } from 'react-bootstrap';
import moment from 'moment';
import { successNotify } from '../../../../../Utils/toast';

const ExpiredSubscription = () => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const { loading, adminGetExpiredSubscription } = useSelector((state: any) => state.adminGetExpiredData);
    const { loading: loading2, adminSendRemind } = useSelector((state: any) => state.sendReminder);

    useEffect(() => {
        if (adminSendRemind?.status === 1) {
            successNotify("Reminder Sent Successfully!")
            dispatch({ type: 'ADMIN_SEND_NOTICE_RESET' })
        }
    }, [adminSendRemind])

    useEffect(() => {
        dispatch(adminExpiredSubscription())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isExpired = (date: any) => {
        if (Date.parse(date) > Date.now()) {
            return <td className="">{moment(date).format('ll')}</td>
        }
        else {
            return <td className=""> <span className='unsubs'> {moment(date).format('ll')} </span></td>
        }
    }

    const reminderHandler = (id: any) => {
        dispatch(adminSendNotice(id))
    }

    return (
        <div className='page_responsive'>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">Expired Subscription</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/dashboard")}
                >
                    Back
                </button>
            </div>

            <div className="table_div">
                <h4>Subscription</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            {
                                adminGetExpiredSubscription?.data?.length > 0 ?
                                    <>
                                        <thead>
                                            <tr style={{ backgroundColor: "#8080800f" }}>
                                                <th>OWNER NAME</th>
                                                <th>LISTINGS</th>
                                                <th> START</th>
                                                <th> RENEWAL</th>
                                                <th> EXPIRE</th>
                                                <th>AMOUNT</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminGetExpiredSubscription?.data?.map((list: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{list?.owner?.first_name}</td>
                                                        <td> <span className="view_orders_customer" onClick={() => navigate(`/admin/all-listings?owner=${list?.owner?._id}`)}>View Listings</span> </td>
                                                        <td>{moment(list?.subscription_date).format('ll')}</td>
                                                        <td>{moment(list?.subscription_expire).add(1, 'day').format('ll')}</td>
                                                        {
                                                            isExpired(list?.subscription_expire)
                                                        }
                                                        <td>{list?.amount}</td>
                                                        <td><button
                                                            style={{ marginTop: "0px", fontSize: "12px", padding: "6px 8px" }}
                                                            className='update_btn' onClick={() => reminderHandler(list?.owner?._id)}>
                                                            {loading2 ? <Spinner animation='border' size='sm' /> : 'Send Reminder'} </button></td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </>
                                    : <p className='text-center mb-0 mt-2'>No Data Found</p>
                            }
                        </Table>
                }
            </div>
        </div>
    )
}
export default ExpiredSubscription