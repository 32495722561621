import React, { useState, useEffect } from 'react'
import { Table, Col, Form, Row, Spinner } from "react-bootstrap";
import { BsCalendar2Date } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import Modal from "../../../../../Components/Modal/Modal";
import { CouponsInput } from "../../../../../Utils/Interfaces";
import { couponsValidation } from "../../../../../Utils/Validations";
import { FaEdit } from "react-icons/fa";
import { useForm, SubmitHandler } from "react-hook-form";
import { ownerGetAllCoupons, ownerUpdateCoupons, ownerCouponsDeleted } from '../../../../../Redux/Action/Owner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Redux/Store';
import { cloudURL, MediaPath } from '../../../../../Utils/helper';
import Loader from '../../../../../Utils/Loader';
import Select from 'react-select';
import { errorNotify, successNotify } from '../../../../../Utils/toast';

const CouponsList = () => {
    const dispatch: AppDispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [previewImg, setPreviewImg] = useState<any>(null);
    const [fileSelected, setFileSelected] = useState<any>(null);
    const [profileError, setProfileError] = useState<String | null>(null);
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<CouponsInput>();
    const { loading, getAllCoupons } = useSelector((state: any) => state.ownerGetCoupons);
    const { loading: updateLoading, updateCoupons } = useSelector((state: any) => state.ownerUpdateCoupons);
    const { deleteCoupons } = useSelector((state: any) => state.ownerDeleteCoupons);
    const { ownerPlacesListings } = useSelector((state: any) => state.ownerPlaceListing);
    const [couponData, setCouponData] = useState<any>({});
    const [selectedPlaces, setSelectedPlaces] = useState<any>([]);

    console.log(getAllCoupons)

    // console.log(selectedPlaces)

    const handlePlaces = (selectedOption: any) => {
        setSelectedPlaces(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : []);
    }

    setValue('couponCode', couponData?.code)
    setValue('couponStartDate', couponData?.start_date)
    setValue('couponEndDate', couponData?.end_date)
    setValue('couponDescription', couponData?.description)

    useEffect(() => {
        dispatch(ownerGetAllCoupons())
    }, [])

    // Update Coupons 

    useEffect(() => {
        if (updateCoupons?.status === 1) {
            successNotify("Coupon updated successfully")
            setShowModal(false)
            setPreviewImg(null)
            setFileSelected(null)
            setCouponData(null)
            dispatch({ type: "OWNER_UPDATE_COUPONS_RESET" })
            dispatch(ownerGetAllCoupons())
            reset()
        }
    }, [updateCoupons, setValue, dispatch, reset])

    // Delete Coupons

    useEffect(() => {
        if (deleteCoupons?.status === 1) {
            successNotify("Coupons deleted successfully");
            dispatch({ type: "OWNER_COUPONS_DELETE_RESET" });
            dispatch(ownerGetAllCoupons())
        }
    }, [deleteCoupons]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDeleteHandler = (id: any) => {
        dispatch(ownerCouponsDeleted(id))
    }

    const handleChangeImage = (e: any) => {
        const fileList = e.target.files;
        if (!fileList) return;
        setFileSelected(fileList[0]);
        setPreviewImg(URL.createObjectURL(e.target.files[0]))
        setProfileError(null)
    };

    const modalHandler = (indivisualCoupon: any) => {
        setShowModal(!showModal)
        setCouponData(indivisualCoupon)
    }

    const couponHandler: SubmitHandler<CouponsInput> = (data) => {

        if (data.couponEndDate > data.couponStartDate) {
            const couponsData: any = new FormData();
            couponsData.append("code", data.couponCode);
            couponsData.append("start_date", data.couponStartDate);
            couponsData.append("end_date", data.couponEndDate);
            couponsData.append("description", data.couponDescription);
            couponsData.append("places", JSON.stringify(selectedPlaces));
            couponsData.append("image", fileSelected);

            for (let value of couponsData) {
                console.log(value)
            }

            dispatch(ownerUpdateCoupons(couponData?._id, couponsData))
        }
        else {
            errorNotify("End date is not valid")
        }
    };

    const options = ownerPlacesListings?.data?.map((d: any) => {
        return { value: d._id, label: d.name }
    })

    const arr: any = couponData?.place;
    for (let j = 0; j < options?.length; j++) {
        for (let i = 0; i < arr?.length; i++) {
            if (arr[i] === options[j].value) {
                arr[i] = options[j]
            }
        }
    }

    console.log(couponData?.place)

    useEffect(() => {

        if (showModal) {
            couponData?.place?.map((p: any) => setSelectedPlaces(p.value))
        }

    }, [showModal])

    const closeModalHandler = () => {
        setShowModal(!showModal)
        setSelectedPlaces([])
    }

    return (
        <React.Fragment>
            <Modal
                title="Edit Coupon"
                show={showModal}
                size="lg"
                onCloseModal={closeModalHandler}
                showIcon={true}
            >
                <Form onSubmit={handleSubmit(couponHandler)}>
                    <Row className="justify-content-center">
                        <Col md={11}>
                            <div className="profile_info">
                                <Row className="profile_fields">
                                    <Col md={12} className={"text-start"}>
                                        <p style={{ padding: "0" }}>Coupon Image</p>
                                        {
                                            couponData?.image ?
                                                <img
                                                    src={previewImg ? previewImg : `${cloudURL}${MediaPath?.image}${couponData?.image}`}
                                                    alt=""
                                                    className="profile_picture"
                                                />
                                                : <img
                                                    src={previewImg ? previewImg : "/images/dummy_img.png"}
                                                    alt=""
                                                    className="profile_picture"
                                                />
                                        }
                                        <div>
                                            <input
                                                type="file"
                                                className="profile_pic_input"
                                                id="profile"
                                                name="profile"
                                                onChange={handleChangeImage}
                                            />
                                        </div>
                                        {profileError && <span className="error_msg">{profileError}</span>}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Coupon Code</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Coupon Code"
                                                {...register(
                                                    "couponCode",
                                                    couponsValidation.couponCode
                                                )}
                                            />
                                        </div>
                                        {errors.couponCode && (
                                            <span className="error_msg">
                                                {errors?.couponCode?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Coupon Start Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "couponStartDate",
                                                    couponsValidation.couponStartDate
                                                )}
                                            />
                                        </div>

                                        {errors.couponStartDate && (
                                            <span className="error_msg">
                                                {errors?.couponStartDate?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Coupon End Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "couponEndDate",
                                                    couponsValidation.couponEndDate
                                                )}
                                            />
                                        </div>
                                        {errors.couponEndDate && (
                                            <span className="error_msg">
                                                {errors?.couponEndDate?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Coupon Description</label>
                                        <textarea
                                            rows={4}
                                            className="form-control"
                                            placeholder="Enter Coupon Description"
                                            {...register(
                                                "couponDescription",
                                                couponsValidation.couponDescription
                                            )}
                                        />
                                        {errors.couponDescription && (
                                            <span className="error_msg">
                                                {errors?.couponDescription?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Places</label>
                                        <Select
                                            defaultValue={arr}
                                            isMulti
                                            isClearable
                                            name="places"
                                            placeholder="Select Places"
                                            options={options}
                                            onChange={handlePlaces}
                                            className="basic-multi-select form-control"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">
                                                {updateLoading ? <Spinner animation='border' size='sm' /> : "EDIT"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {
                loading ? <Loader /> :
                    <div className="table_div">
                        <h4>Coupons</h4>
                        {
                            getAllCoupons?.length > 0 ?

                                <Table responsive >
                                    <thead>
                                        <tr style={{ backgroundColor: "#8080800f" }}>
                                            <th className="text-center">IMAGE</th>
                                            <th className="text-center">COUPON CODE</th>
                                            <th className="text-center">START DATE</th>
                                            <th className="text-center">END DATE</th>
                                            <th className="text-center">DESCRIPTION</th>
                                            <th className="text-center">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getAllCoupons?.map((data: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            <img className='coupons_img' src={`${cloudURL}${MediaPath?.image}${data?.image}`} alt="service1" />
                                                        </td>
                                                        <td className="text-center">{data?.code}</td>
                                                        <td className="text-center">{data?.start_date}</td>
                                                        <td className="text-center">{data?.end_date}</td>
                                                        <td className="text-truncate text-center" style={{ maxWidth: "200px" }}>{data?.description}</td>
                                                        <td className="text-center">
                                                            <div className="table_icons">
                                                                <FaEdit onClick={() => modalHandler(data)} />
                                                                <AiFillDelete onClick={() => onDeleteHandler(data?._id)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <p
                                    className="text-center"
                                    style={{ fontSize: "20px", fontWeight: "500", padding: '15px' }}
                                >
                                    No Coupons Found
                                </p>
                        }
                    </div>
            }
        </React.Fragment>
    )
}
export default CouponsList
