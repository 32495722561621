import React, { useEffect, useState } from "react";
import { Col, Row, Table, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAmenitesList, adminDeleteAmenity } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import Loader from "../../../../Utils/Loader";
import { FaEdit } from "react-icons/fa";
import { cloudURL, MediaPath } from "../../../../Utils/helper";
import { MdDelete } from "react-icons/md"
import { successNotify } from "../../../../Utils/toast";
import { useForm, SubmitHandler } from "react-hook-form";

const Amenities = () => {
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false)

    const dispatch: AppDispatch = useDispatch();
    const { loading, amenitiesList } = useSelector((state: any) => state.adminAmenitesList);
    const { loading: deleteLoading, deleteAmenityData } = useSelector((state: any) => state.adminDeleteAmenity);

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<any>();

    useEffect(() => {
        if (amenitiesList) {
          setLoaderShow(false)
        }
      }, [amenitiesList])

    useEffect(() => {

        if (deleteAmenityData?.status === 1) {
            successNotify("Amenity Deleted Successfully")
            dispatch({ type: "ADMIN_DELETE_AMENITY_RESET" })
        }

    }, [deleteAmenityData?.status, dispatch])

    useEffect(() => {
        dispatch(adminGetAmenitesList(''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deleteAmenityData?.status])

    const deleteHandler = (id: string) => {
        dispatch(adminDeleteAmenity(id))
    }

    const productSearchHandler: SubmitHandler<any> = (data: any) => {
        setLoaderShow(true)
        dispatch(adminGetAmenitesList(data.search))
        reset();
    }

    const ResetHandler = () => {
        dispatch(adminGetAmenitesList(''))
    }

    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">Amenities</h1>
                <button className="back_btn" onClick={() => navigate("/admin/create-amenities")}>
                    Create Amenities
                </button>
            </div>

            <Form onSubmit={handleSubmit(productSearchHandler)}>
                <Row>
                    <Col md={3} className="py-2">
                        <div className="signup_input">
                            <img src="/images/AccountIcon.png" alt="" />
                            <input
                                placeholder="Search...."
                                {...register("search")}
                            />
                        </div>
                    </Col>
                    <Col md={3} className="py-2">
                        <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
                        <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
                    </Col>
                </Row>
            </Form>


            <div className="table_div">
                <h4>All Amenities</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            <thead>
                                <tr style={{ backgroundColor: "#8080800f" }}>
                                    <th className="">S.No</th>
                                    <th className="">Image</th>
                                    <th className="">Name</th>
                                    <th className="">Category</th>
                                    <th className="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {amenitiesList?.map((amenity: any, index: number) => {
                                    return (
                                        <tr key={amenity._id}>
                                            <td className="">{index + 1}</td>
                                            <td className=" amneity_table_image"><img src={`${cloudURL}${MediaPath.amenity}${amenity.picture}`} alt="" /></td>
                                            <td className="">{amenity.name}</td>
                                            <td className="">{amenity.category.name}</td>
                                            <td className="">
                                                <div className="table_icons">
                                                    {
                                                        deleteLoading ?
                                                            <>
                                                                <FaEdit />
                                                                <MdDelete />
                                                            </>
                                                            :
                                                            <>
                                                                <FaEdit onClick={() => navigate(`/admin/edit-amenities/${amenity._id}`)} />
                                                                <MdDelete onClick={() => deleteHandler(amenity._id)} />
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                }
            </div>
        </div>
    )
}

export default Amenities