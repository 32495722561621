import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

const AddBusiness = () => {
  const navigate = useNavigate();

  return (
    <div className="add-business-container mt-5">
      <Container>
        <div className="business-content text-center text-white">
          <p>ARE YOU SHOP OWNER</p>
          <h2>OWN A HOOKAH BUSINESS? LIST IT FREE..</h2>
          <button onClick={() => navigate("/owner/register")}>SIGN UP</button>
        </div>
      </Container>
    </div>
  );
};

export default AddBusiness;
