
export const cloudURL = 'https://hookahbars.com/assets/uploads/'

// export const cloudURL = 'https://hookahbar.azurewebsites.net/assets/uploads/'

export const MediaPath = { avatar: "avatar/", events: "events/", image: "bar_gallery/", 
video: "video/", gallery: "bar_gallery/", menu_image: "menus/", product_image: "products/", 
amenity: "amenity/", thumnail: "thumbnail/", banner: "banner/", main_gallery: "gallery/", advertisment_banner: "advertisment_banner/" }

export function getToken() {
    let userAuth: any = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth?.accessToken;
}

export function getRefreshToken() {
    let userAuth: any = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth?.refreshToken
};

export function updateLocalAccessToken(token: any) {
    let userAuth: any = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    userAuth.accessToken = token;
    localStorage.setItem("User", JSON.stringify(userAuth));
}

export function CurrentUserInfo() {
    let userAuth: any = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth
}