import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { productInput } from "../../../../../Utils/Interfaces";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { productValidation } from "../../../../../Utils/Validations";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { AiOutlineClose } from "react-icons/ai";
import { adminGetProductDetail, adminUpdateProduct } from "../../../../../Redux/Action/Admin";
import { cloudURL, getToken, MediaPath } from "../../../../../Utils/helper";
import Loader from "../../../../../Utils/Loader";
import axios from "axios";
import api from "../../../../../Utils/Interceptors";

const EditProduct = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const [images, setImages] = useState<any>([]);
    // const [preview, setPreview] = useState<any>([]);
    const [file, setFile] = useState<any>(null);
    const [preview1, setPreview1] = useState<any>(null)
    const [hasDiscount, setHasDiscount] = useState('0')
    const { id } = useParams();

    const [selectedImages, setSelectedImages] = useState([])

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<productInput>();
    const { loading: updateLoading, updateProductData } = useSelector((state: any) => state.adminUpdateProduct);
    const { loading, productDetail } = useSelector((state: any) => state.adminProductDetail);

    useEffect(() => {
        if (id) {

            setValue("title", productDetail?.title)
            setValue("price", productDetail?.price)
            setValue("description", productDetail?.description)
            setValue("sku", productDetail?.sku)
            if (productDetail?.active === "0") {
                setValue("active", false)
            }
            else if (productDetail?.active === "1") {
                setValue("active", true)
            }
            setHasDiscount(productDetail?.hasDiscount)
            setValue("discountValue", productDetail?.discountValue?.toString())
            setValue("discountType", productDetail?.discountType)

            let arr: any = []

            productDetail?.images?.map((gallery: any, index: number) => {
                return arr.push(`${cloudURL}${MediaPath?.product_image}${gallery}`)
            })

            setSelectedImages(arr)
            setPreview1(`${cloudURL}${MediaPath?.product_image}${productDetail?.feature_image}`)

        }
    }, [id, productDetail, setValue])


    useEffect(() => {
        if (id) {
            dispatch(adminGetProductDetail(id))
        }
    }, [id, dispatch])

    useEffect(() => {
        if (updateProductData?.status === 1) {
            successNotify("Product Updated Successfully!")
            dispatch({ type: "ADMIN_UPDATE_PRODUCT_RESET" })
            navigate("/admin/products")
        }
    }, [dispatch, navigate, updateProductData])



    const handleGalleryImagesChange = (e: any) => {

        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles)
        setImages((prev: any) => prev.concat(selectedFilesArray))
        const imagesArray: any = selectedFilesArray.map((file: any) => {
            return URL.createObjectURL(file)
        })
        setSelectedImages((previousImage) => previousImage.concat(imagesArray))
    };



    const productHandler: SubmitHandler<productInput> = (data) => {

        if (id) {

            if (data?.discountType === '0') {
                if (parseInt(data?.price) > parseInt(data?.discountValue)) {

                    const fileData: any = new FormData();
                    fileData.append('title', data?.title);
                    fileData.append('price', data?.price);
                    fileData.append('description', data?.description);
                    fileData.append('hasDiscount', hasDiscount);
                    fileData.append('discountValue', data?.discountValue);
                    fileData.append('discountType', data?.discountType);
                    fileData.append('featuredImage', file);
                    fileData.append('sku', data?.sku);
                    if (data?.active) {
                        fileData.append('active', 1);
                    }
                    else if (!data?.active) {
                        fileData.append('active', 0);
                    }
                    [...images].forEach(image => {
                        fileData.append("product_images", image);
                    });

                    for (let value of fileData) {
                        console.log(value[1])
                    }

                    dispatch(adminUpdateProduct(id, fileData))

                }

                else {
                    errorNotify("Discounted Price must be smaller than actual price")
                }
            }

            else if (data?.discountType === '1') {
                if (parseInt(data?.discountValue) < 100) {
                    const fileData: any = new FormData();
                    fileData.append('title', data?.title);
                    fileData.append('price', data?.price);
                    fileData.append('description', data?.description);
                    fileData.append('hasDiscount', hasDiscount);
                    fileData.append('discountValue', data?.discountValue);
                    fileData.append('discountType', data?.discountType);
                    fileData.append('featuredImage', file);
                    fileData.append('sku', data?.sku);
                    if (data?.active) {
                        fileData.append('active', 1);
                    }
                    else if (!data?.active) {
                        fileData.append('active', 0);
                    }
                    [...images].forEach(image => {
                        fileData.append("product_images", image);
                    });

                    for (let value of fileData) {
                        console.log(value[1])
                    }

                    dispatch(adminUpdateProduct(id, fileData))
                }

                else {
                    errorNotify("Discounted Value must be less than 100")
                }
            }

            else {
                const fileData: any = new FormData();
                fileData.append('title', data?.title);
                fileData.append('price', data?.price);
                fileData.append('description', data?.description);
                fileData.append('hasDiscount', hasDiscount);
                fileData.append('featuredImage', file);
                fileData.append('discountValue', data?.discountValue);
                fileData.append('discountType', data?.discountType);
                fileData.append('sku', data?.sku);
                if (data?.active) {
                    fileData.append('active', 1);
                }
                else if (!data?.active) {
                    fileData.append('active', 0);
                }
                [...images].forEach(image => {
                    fileData.append("product_images", image);
                });

                // dispatch(adminUpdateProduct(id, fileData))

            }

        }
    };

    const discountHandler = (e: any) => {

        if (e.target.value === 'no') {
            setHasDiscount('0')
        }

        else if (e.target.value === 'yes') {
            setHasDiscount('1')
        }
    }

    const backHandler = () => {

        navigate("/admin/products")
        dispatch({ type: "ADMIN_GET_PRODUCT_DETAIL_RESET" })
    }

    const handleChangeImage = (e: any) => {

        setFile(e.target.files[0]);
        setPreview1(URL.createObjectURL(e.target.files[0]))

    }

    async function deleteFile(event: any, index: any) {

        const splitData = event.split('/')

        const { data } = await api.delete(`/admin/product/${id}/media?image=${splitData[6]}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        console.log(data)

        images.splice(index, 1)

        console.log(images)
        setImages(images)
        setSelectedImages(selectedImages.filter((e: any) => e !== event))
    }


    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id ? 'Edit Product' : 'Create Product'}</h1>
                <button
                    className="back_btn"
                    onClick={backHandler}
                >
                    Back
                </button>
            </div>

            {
                loading ? <div>
                    <Loader />
                </div>
                    :
                    <Form onSubmit={handleSubmit(productHandler)}>
                        <Row className="login_right_section align-items-center">
                            <Col md={6} className="py-2">
                                <label>Product Name</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Product Name"
                                        {...register("title", productValidation.productName)}
                                    />
                                </div>
                                {errors.title && (
                                    <span className="error_msg">{errors?.title?.message}</span>
                                )}
                            </Col>
                            <Col md={6} className="py-2">
                                <label>Product Price</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Product Price"
                                        {...register("price", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.price && (
                                    <span className="error_msg">{errors?.price?.message}</span>
                                )}
                            </Col>
                            <Col md={6} className="py-2">
                                <label>SKU#</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter SKU#"
                                        {...register("sku", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.sku && (
                                    <span className="error_msg">{errors?.sku?.message}</span>
                                )}
                            </Col>
                            <Col md={6} className='py-2'>
                                <label>Product Activity</label>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("active", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Product Active</label>
                                </div>
                            </Col>

                            <Col md={12} className='py-2'>
                                <label>Product on Discount?</label>
                                <div className="d-flex">
                                    <div className="mt-2">
                                        <input type="radio" className='active_checkbox' checked={hasDiscount === '1' ? true : false} value={"yes"} name="hasDiscount" onClick={discountHandler}
                                            style={{ top: '5px' }}
                                        />
                                        <label>Yes</label>
                                    </div>

                                    <div className=" mx-2 mt-2">
                                        <input type="radio" className='active_checkbox' checked={hasDiscount === '0' ? true : false} value={"no"} name="hasDiscount" onClick={discountHandler}
                                            style={{ top: '5px' }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                            </Col>

                            {
                                hasDiscount === '1' && <>
                                    <Col md={6} className="py-2">
                                        <label>Discounted Value</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Discounted Value"
                                                {...register("discountValue", productValidation.productPrice)}
                                            />
                                        </div>
                                        {errors.discountValue && (
                                            <span className="error_msg">{errors?.discountValue?.message}</span>
                                        )}
                                    </Col>

                                    <Col md={6} className={'mt-2'}>
                                        <label>Discount Type*</label>
                                        <select
                                            style={{ color: "#000" }}
                                            {...register("discountType", productValidation.productPrice)}
                                        >
                                            <option value="">Select Discount Type</option>
                                            <option value="0">Fixed</option>
                                            <option value="1">Percentage %</option>
                                        </select>

                                        {errors.discountType && (
                                            <span className="error_msg">
                                                {errors?.discountType?.message}
                                            </span>
                                        )}
                                    </Col>
                                </>
                            }

                            <Col md={12} className="py-2">
                                <label>Description</label>
                                <textarea
                                    rows={4}
                                    className="form-control"
                                    placeholder="Enter description"
                                    {...register(
                                        "description",
                                        productValidation.productDescription
                                    )}
                                />
                                {errors.description && (
                                    <span className="error_msg">
                                        {errors?.description?.message}
                                    </span>
                                )}
                            </Col>

                            <Col md={6} className="thumb_image_container mb-3">
                                <label style={{ fontWeight: "600", marginBottom: "4px" }}>Featured Image*</label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="image1"
                                    name="image1"
                                    onChange={handleChangeImage}
                                />
                                <div className="thumb_image">
                                    <label htmlFor="image1">
                                        <img
                                            src={
                                                preview1 ?
                                                    preview1
                                                    : '/images/add_image.png'
                                            }
                                            alt=""
                                        />
                                    </label>
                                </div>
                            </Col>

                            <Col md={12} className="thumb_image_container">
                                <label style={{ fontWeight: "600", marginBottom: "4px" }}>Multiple Product Images*</label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="gallery"
                                    name="gallery"
                                    onChange={handleGalleryImagesChange}
                                    multiple
                                />
                                <div className="thumb_image_gallery">
                                    <label>
                                        <Row className="align-items-center justify-content-center">
                                            {selectedImages.length > 0 && selectedImages?.map((previewImg: string, index: number) => {
                                                return (
                                                    <Col md={3} key={index} className='pre_div'>
                                                        <img
                                                            src={previewImg}
                                                            alt=""
                                                            className="preview_gallery_img2"
                                                        />
                                                        <AiOutlineClose onClick={() => deleteFile(previewImg, index)} />
                                                    </Col>
                                                );
                                            })}
                                            <Col md={3}>
                                                <label htmlFor="gallery">
                                                    <img
                                                        src={'/images/add_image.png'}
                                                        alt=""
                                                        className={selectedImages.length > 0 ? "preview_gallery_img_view" : ''}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </label>
                                </div>
                                <p className="text-muted">File Format must be .png or jpg or .jpeg</p>
                            </Col>
                            <Col md={12} className="pb-5">
                                <div className="text-end">
                                    <button type="submit" className="back_btn">
                                        {
                                            updateLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : "Edit"
                                        }
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    );
};

export default EditProduct;
