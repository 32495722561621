import React from "react";
import AdminNavbar from "../../Container/Admin/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const AdminLayout = () => {
  let userAuth: any = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

  return userAuth?.roles[0] === "admin" ? (
    <React.Fragment>
      <AdminNavbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <NotFound />
  );
};
export default AdminLayout;
