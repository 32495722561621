import React, { useState, useEffect } from "react";
import { Col, Container, Row, Accordion, Spinner } from "react-bootstrap";
import ReactStars from "react-stars";
import Map from "../../../../Components/Map/Map";
import AddBusiness from "../../../../Components/AddBusiness/AddBusiness";
import { MdLocationOn } from "react-icons/md";
import { BsTelephone, BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { ImEarth } from "react-icons/im"
import { Document, Page, pdfjs } from "react-pdf";
import "./ShopView.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import Reviews from "./Reviews/Reviews";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { getBPListingsDetails, getPlaceDiscount } from '../../../../Redux/Action/BusinessPlaces'
import { MediaPath, cloudURL, getToken } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";
import EnquiryPage from "./EnquiryPage/EnquiryPage";
import DiscountedCoupons from "./DiscountedCoupons/DiscountedCoupons";
import ViewListings from "../../../Admin/Pages/AllListings/ViewListings/ViewListings";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import api from "../../../../Utils/Interceptors";
import { successNotify } from "../../../../Utils/toast";

const ShopView = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname.split('/')

  let userAuth = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

  const { bpListingDetails, loading } = useSelector((state: any) => state.BPListingDetails);
  const { placeDiscount, loading: placeLoading } = useSelector((state: any) => state.BPDiscount);

  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showDiscountedDetail, setShowDiscountedDetail] = useState({})
  const [isOwnOwner, setIsOwnOwner] = useState(false)
  const [showCropper, setShowCropper] = useState(false)
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getBPListingsDetails(id))

    return () => {
      setIsOwnOwner(false)
    }
  }, [])

  useEffect(() => {
    if (bpListingDetails && bpListingDetails[0]?._id) {
      dispatch(getPlaceDiscount(bpListingDetails[0]?._id))
    }
  }, [bpListingDetails, dispatch])

  function onDocumentLoadSuccess({ numPages }: any) { setNumPages(numPages) }

  const previousIcon = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const forwardIcon = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const showDiscountDetail = (discount: any) => {
    setShowDiscountedDetail(discount)
    setShowModal(!showModal)
  }

  let showDiscounts;
  if (placeLoading) {
    showDiscounts = <div className="text-center">
      <Loader />
    </div>
  }
  else if (placeDiscount && placeDiscount?.length === 0) {
    showDiscounts = <div>
      <p className="text-center">No Discount Found</p>
    </div>
  }
  else if (placeDiscount && placeDiscount?.length > 0) {
    showDiscounts = <div className="coupon_btns">
      {
        placeDiscount?.map((discount: any) => {
          return (
            <button onClick={() => showDiscountDetail(discount)}>{discount.code}</button>
          )
        })
      }
    </div>
  }

  const findExtention = () => {
    let filename = bpListingDetails[0]?.menu_image
    let extension = filename.split(".").pop();
    return extension
  }

  const showTimings = (data: any) => {

    if (data?.openingTime?.split(":")[0] > 12 && data?.closingTime?.split(":")[0] > 12) {
      if (data?.openingTime == 'OFF' || data?.closingTime == 'OFF') {
        return <p>Closed</p>
      }
      return <p> <span> {data?.openingTime} PM <span>to</span> {data?.closingTime} PM </span> </p>
    }
    else if (data?.openingTime?.split(":")[0] > 12 && data?.closingTime?.split(":")[0] <= 12) {
      if (data?.openingTime == 'OFF' || data?.closingTime == 'OFF') {
        return <p>Closed</p>
      }
      return <p> <span> {data?.openingTime} PM <span>to</span> {data?.closingTime} AM </span> </p>
    }
    else if (data?.openingTime?.split(":")[0] <= 12 && data?.closingTime?.split(":")[0] > 12) {
      if (data?.openingTime == 'OFF' || data?.closingTime == 'OFF') {
        return <p>Closed</p>
      }
      return <p> <span> {data?.openingTime} AM <span>to</span> {data?.closingTime} PM </span> </p>
    }
    else if (data?.openingTime?.split(":")[0] <= 12 && data?.closingTime?.split(":")[0] <= 12) {
      if (data?.openingTime == 'OFF' || data?.closingTime == 'OFF') {
        return <p>Closed</p>
      }
      return <p> <span> {data?.openingTime} AM <span>to</span> {data?.closingTime} AM </span> </p>
    }
    else {
      if (data?.openingTime == 'OFF' || data?.closingTime == 'OFF') {
        return <p>Closed</p>
      }
      return <p> <span> {data?.openingTime} <span>to</span> {data?.closingTime} </span> </p>
    }
  }

  useEffect(() => {
    if (bpListingDetails[0]?.owner?._id === userAuth?.id) {
      setIsOwnOwner(true)
    }
  }, [bpListingDetails])

  const [image, setImage] = useState();
  const [cropLoader, setCropLoader] = useState(false);
  const [cropper, setCropper] = useState<any>();

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  function dataURLtoFile(dataurl: any, filename: any) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  let file;
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropLoader(true)

      file = dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), bpListingDetails[0]?.banner_image)

      const data = {
        banner_image: file
      }

      // console.log(cropper.getCroppedCanvas().toDataURL())
      // console.log(data)
      // console.log(bpListingDetails[0]?.banner_image)

      api.post(`/${userAuth?.roles[0]}/place/${id}/media/banner`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getToken()
        }
      }).then((res: any) => {
        successNotify("Picture Updated Successfully")
        setCropLoader(false)
        window.location.reload();

      }).catch((err: any) => {
        console.log(err)
        setCropLoader(false)
      })
    }
  };

  const editImageHandler = () => {
    setShowCropper(!showCropper)
  }

  let showBtn

  if (bpListingDetails[0]?.owner?._id === userAuth?.id || userAuth?.roles[0] === 'admin') {
    showBtn = <div className="edit_resize">
      <button className="back_btn" style={{ padding: "15px 50px" }} onClick={editImageHandler}>Edit Image Resize</button>
    </div>

  }

  const showEnquirySection = () => {
    if (pathname[1] !== "admin" && bpListingDetails[0]?.shop_type?.name !== 'Smoke Shop') {
      return <EnquiryPage id={bpListingDetails[0]?._id} />
    }
  }

  return (
    <div className="shop_view mt-3">
      {
        pathname[1] === "admin" && <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="main_heading">Listing Details</h1>
          <button
            className="back_btn"
            onClick={() => navigate("/admin/all-listings")}
          >
            Back
          </button>
        </div>
      }
      <DiscountedCoupons showModal={showModal} setShowModal={setShowModal} data={showDiscountedDetail} />
      <Container>
        {loading ? <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}><Loader /></div> :
          bpListingDetails &&
          <React.Fragment>
            {
              showCropper &&
              <div className="banner_container">
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={`${cloudURL}${MediaPath?.image}${bpListingDetails[0]?.banner_image}`}
                  viewMode={1}
                  minCropBoxHeight={300}
                  minCropBoxWidth={1100}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  movable={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />

                <div className="d-flex justify-content-end" style={{ position: "relative", zIndex: "1", marginTop: "10px" }}>
                  <button onClick={() => setShowCropper(!showCropper)} className="back_btn mx-2">Cancel</button>
                  <button className="back_btn" style={{ float: "right" }} onClick={getCropData}>
                    {cropLoader ? <Spinner animation="border" size="sm" /> : 'Crop Image'}
                  </button>
                </div>
              </div>

            }

            {

              !showCropper && <div className="banner_container">
                {bpListingDetails &&
                  bpListingDetails[0]?.banner_image ?
                  <>
                    <img src={`${cloudURL}${MediaPath?.image}${bpListingDetails[0]?.banner_image}`} alt="shop_view" />
                    {showBtn}
                  </>
                  :
                  <>
                    <img src="/images/show_view.png" alt="shop_view" />
                  </>
                }
              </div>
            }
            <Container fluid className="shop_details">
              {bpListingDetails && (
                <h1>{bpListingDetails[0]?.name}</h1>
              )}
              <Row>
                <Col md={8}>
                  <div>
                    <div className="reviews_section">
                      <ReactStars edit={false} count={5} size={20} color2={"#ffb21e"} value={parseInt(bpListingDetails[0]?.avgRating)} />
                      {bpListingDetails && (
                        <p>({`${bpListingDetails[0]?.reviews?.length} Reviews`})</p>
                      )}
                    </div>
                    <div className="shop_view_time">
                      <MdLocationOn />
                      {bpListingDetails && (
                        <p>{bpListingDetails[0]?.location?.address}</p>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="shop_view_time">
                      <BsTelephone />
                      <p>{bpListingDetails[0]?.contact?.phone_number}</p>
                    </div>

                    <div className="social_icons">
                      {
                        bpListingDetails[0]?.social_link.map((social: any) => {
                          return (
                            <>
                              {
                                social.platform === "Facebook" &&
                                <a href={social.link} target="_blank" rel="noopener noreferrer">
                                  <BsFacebook className="fb" />
                                </a>
                              }

                              {
                                social.platform === "Instagram" &&
                                <a href={social.link} target="_blank" rel="noopener noreferrer">
                                  <BsInstagram className="insta" />
                                </a>
                              }

                              {
                                social.platform === "Twitter" &&
                                <a href={social.link} target="_blank" rel="noopener noreferrer">
                                  <BsTwitter className="twitter" />
                                </a>
                              }
                            </>
                          )
                        })
                      }
                    </div>
                  </div>

                  {
                    bpListingDetails[0]?.contact?.website.length > 0 &&
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="shop_view_time">
                        <ImEarth />
                        <a href={bpListingDetails[0]?.contact?.website} target="_blank" rel="noreferrer">
                          <p>{bpListingDetails[0]?.contact?.website}</p></a>
                      </div>
                    </div>
                  }
                  {
                    bpListingDetails[0]?.amenities?.length > 0 &&
                    <div className="details">
                      <h5>Details</h5>
                      {
                        bpListingDetails[0]?.amenities?.map((amenity: any) => {
                          return (
                            <div className="serve">
                              <img src={`${cloudURL}${MediaPath.amenity}${amenity.picture}`} alt="dish" />
                              <p>{amenity.name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  }

                  <div className="detail_text">
                    <p>
                      {bpListingDetails[0]?.description}
                    </p>
                  </div>

                  {
                    bpListingDetails[0]?.gallery.length > 0 &&
                    <div className="gallery">
                      <h5>Gallery</h5>
                      <Row>
                        {
                          bpListingDetails[0]?.gallery?.map((data: any, index: number) => {
                            return (
                              <Col md={4} className={"mb-3"} key={index}>
                                <img src={`${cloudURL}${MediaPath?.gallery}${data}`} alt="gallery-images" />
                              </Col>
                            )
                          })
                        }
                      </Row>
                    </div>
                  }

                  {
                    bpListingDetails[0]?.businessTimings.length > 0 &&
                    <div className="timings">
                      <h5>Business Hours</h5>
                      <div>
                        {
                          bpListingDetails[0]?.businessTimings?.map((data: any, index: number) => {
                            return (
                              <Row>
                                <Col md={3}>
                                  <p> <span> {data?.day}: </span> </p>
                                </Col>
                                <Col md={8}>
                                  {showTimings(data)}
                                </Col>
                              </Row>
                            )
                          })
                        }
                      </div>
                    </div>
                  }

                  {
                    placeDiscount?.length > 0 && <div className="gallery">
                      <h5>Discounted Coupons</h5>
                      {showDiscounts}
                    </div>
                  }

                  {
                    bpListingDetails[0]?.menu_image &&
                    <div className="menu_main">
                      <h5>Menu</h5>

                      <Row>
                        {
                          findExtention() === "pdf" ?
                            <Col md={12}>
                              <a
                                href={`${cloudURL}${MediaPath?.menu_image}${bpListingDetails[0]?.menu_image}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <Document
                                  file={`${cloudURL}${MediaPath?.menu_image}${bpListingDetails[0]?.menu_image}`}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  externalLinkRel="_blank"
                                  externalLinkTarget="_blank"
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                              </a>

                              <p> Page {pageNumber} of {numPages} </p>

                              <div className="pre_forward">
                                <button onClick={previousIcon}>
                                  <img src="/images/previousIcon.png" alt="" />
                                </button>
                                <button onClick={forwardIcon}>
                                  <img src="/images/forwardIcon.png" alt="" />
                                </button>
                              </div>
                            </Col>
                            :
                            <Col md={12}>
                              <img src={`${cloudURL}${MediaPath?.menu_image}${bpListingDetails[0]?.menu_image}`} alt="gallery-images" />
                            </Col>
                        }

                      </Row>
                    </div>
                  }

                  <div className="frequently_question">
                    <h5>Frequently Asked Questions</h5>
                    {bpListingDetails[0]?.faqs?.length > 0 ? (
                      <Accordion>
                        {bpListingDetails[0]?.faqs?.map((data: any, index: number) => {
                          return (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                              <Accordion.Header>{data?.question}</Accordion.Header>
                              <Accordion.Body>
                                {data?.answer}
                              </Accordion.Body>
                            </Accordion.Item>
                          )
                        })}
                      </Accordion>
                    ) : (
                      <p
                        className="text-center"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        No Frequently Questions Found
                      </p>
                    )}
                  </div>

                  {
                    bpListingDetails[0]?.video &&
                    <div className="gallery">
                      <h5>Video</h5>
                      <div>
                        <Row className="mt-2">
                          <Col md={12} className="thumb_image_container">
                            <ReactPlayer
                              // playing={true}
                              loop={true}
                              stopOnUnmount={false}
                              controls={true}
                              url={`${cloudURL}${MediaPath?.video}${bpListingDetails[0]?.video}`}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }

                  <Reviews slug={bpListingDetails[0]?.slug} id={bpListingDetails[0]?._id} listingsData={bpListingDetails} />


                </Col>
                <Col md={4}>
                  {showEnquirySection()}

                  <div className="map_section">
                    <Map
                      map={true}
                      search={false}
                      setAddress={null}
                      selectedCoordinate={{
                        lat: bpListingDetails[0]?.location?.location?.coordinates[0],
                        lng: bpListingDetails[0]?.location?.location?.coordinates[1]
                      }}
                      setSelectedCoordinate={null}
                    />
                    <div>
                      <div className="shop_view_btns">
                        <a style={{ width: "100%" }} href={`tel:${bpListingDetails[0]?.contact?.phone_number}`} target="_blank" rel="noreferrer"> <button className="call_us">call us</button> </a>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  {pathname[1] === "admin" && <ViewListings status={bpListingDetails[0]} />}
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        }
      </Container>
      {
        pathname[1] !== "admin" ? pathname[1] !== "operator" : <AddBusiness />
      }

    </div>
  );
};
export default ShopView;
