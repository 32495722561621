import React, { useEffect, useState } from "react";
import "./Location.css";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import Map from "../Map/Map";
import { errorNotify } from "../../Utils/toast";
import { ICoordinates } from "../../Utils/Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/Store";
import { getBPListings, getCurrentCity } from "../../Redux/Action/BusinessPlaces";
import Loader from "../../Utils/Loader";
import HomeMap from "../HomeMap/HomeMap";

const Location = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch()
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState<ICoordinates | null>(null);
  const [address, setAddress] = useState(null);
  const [placeType, setPlaceType] = useState('')
  const [shopName, setShopName] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [getCountryName, setGetCountryName] = useState<any>('')

  const { loading: listLoading, shopTypes } = useSelector((state: any) => state.getShop);
  const { bpListings } = useSelector((state: any) => state.BPListing);

  useEffect(() => {
    dispatch(getBPListings('', '', '', '', '', '', '', '', ''))
  }, [])

  const shopNames = bpListings?.map((bp: any) => {
    return { id: bp._id.place_id, name: bp._id.name }
  })

  const formChangeHandler = (e: any) => {
    if (e.value === "63a14cbe3683d00187f64031") {
      setPlaceType(e.value)
      setShowAdvanced(true);
    }
    else {
      setPlaceType(e.value)
      setShowAdvanced(false);
    }
  };

  let arr: any = []
  const [amenityArr, setAmenityArr] = useState<any>([])
  const amenitesHandler = (e: any) => {

    if (e.target.value && e.target.checked) {
      setAmenityArr([...amenityArr, e.target.value])
    }
    else if (e.target.value && !e.target.checked) {

      arr = amenityArr.filter((entity: any) => entity !== e.target.value);
      setAmenityArr((previous: any) => {
        return previous.filter((entity: any) => entity !== e.target.value)
      })
    }
  }

  const formHandler = (e: any) => {
    e.preventDefault();
    if (placeType || selectedCoordinates || shopName || country || state || city) {

      localStorage.setItem('getCity', JSON.stringify(getCountryName));
      dispatch(getCurrentCity(getCountryName))
      // localStorage.setItem('locationSet', JSON.stringify(selectedCoordinates));

      if (selectedCoordinates || country.length > 0 || city.length > 0 || state.length > 0) {

        if (country.length > 0) {
          if (placeType === '63a14cbe3683d00187f64031') {
            if (arr) {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&country=${country}&lat=&lng=`);
            }
            else {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&country=${country}&lat=&lng=`);
            }
          }
          else {
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&country=${country}&lat=&lng=`);
          }
        }
        else if (state.length > 0) {
          if (placeType === '63a14cbe3683d00187f64031') {
            if (amenityArr) {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&state=${state}&lat=&lng=`);
            }
            else {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&state=${state}&lat=&lng=`);
            }
          }
          else {
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&state=${state}&lat=&lng=`);
          }
        }
        else if (city.length > 0) {
          if (placeType === '63a14cbe3683d00187f64031') {
            if (amenityArr) {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&city=${city}&lat=&lng=`);
            }
            else {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&city=${city}&lat=&lng=`);
            }
          }
          else {
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&city=${city}&lat=&lng=`);
          }
        }
        else if (selectedCoordinates) {
          if (placeType === '63a14cbe3683d00187f64031') {
            if (amenityArr) {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&lat=${selectedCoordinates?.lat}&lng=${selectedCoordinates?.lng}`);
            }
            else {
              navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&lat=${selectedCoordinates?.lat}&lng=${selectedCoordinates?.lng}`);
            }
          }
          else {
            console.log('d')
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&lat=${selectedCoordinates?.lat}&lng=${selectedCoordinates?.lng}`);
          }
        }
      }
      else {
        if (placeType === '63a14cbe3683d00187f64031') {
          if (amenityArr) {
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&lat=&lng=`);
          }
          else {
            navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=${amenityArr}&lat=&lng=`);
          }
        }
        else {
          navigate(`/find-shop?shop_type=${placeType}&shopName=${shopName}&amenities=&lat=&lng=`);
        }
      }

    }
    else {
      errorNotify("Please select any one field");
    }
  };

  const options = [
    { value: "63a14cbe3683d00187f64031", label: "Hookah Bar" },
    { value: "63a14cbe3683d00187f64033", label: "Smoke Shop" },
  ];


  const handleOnSelect = (v: any) => {
    setShopName(v.name)
  }

  console.log(getCountryName, "GET COUNTRY")

  return (
    <div className="main_location">
      <div className="location_bg">
        <h3>Find Locations</h3>
        <p>Hookah Bars, Hookah Lounges, and Smoke Shops</p>

        <Form onSubmit={formHandler}>
          <div className="location_form">
            <div className="form_input_bar">
              <Select
                options={options}
                onChange={formChangeHandler}
                placeholder="Please Select..."
              />
            </div>
            <div className="name_input" style={{ width: '250px', marginRight: "10px", paddingBottom: "5px" }}>
              <ReactSearchAutocomplete
                placeholder={'Search By Name'}
                items={shopNames}
                fuseOptions={{ keys: ['name'] }}
                autoFocus
                onSelect={handleOnSelect}
                maxResults={10}
                resultStringKeyName='name'
              />
              {/* <input placeholder="Enter By Shop Name" type='text' name="shopName" onChange={(e) => setShopName(e.target.value)} /> */}
            </div>
            <div className="form_input">
              {/* <Map
                map={false}
                search={true}
                setAddress={setAddress}
                selectedCoordinate={selectedCoordinates}
                setSelectedCoordinate={setSelectedCoordinates}
              /> */}

              <HomeMap
                map={false}
                search={true}
                setAddress={setAddress}
                selectedCoordinate={selectedCoordinates}
                setSelectedCoordinate={setSelectedCoordinates}
                setGetCountryName={setGetCountryName}
                setCountry={setCountry}
                setState={setState}
                setCity={setCity}
              />
            </div>
            <div className="location_btn_div">
              <button className="find_hookah" type="submit">
                find hookah now
              </button>
            </div>
          </div>
          {showAdvanced && (
            <div className="advance_search">
              <p>Advance Search</p>

              <div className="d-flex flex-wrap">
                {
                  listLoading ?
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                    :
                    <>
                      {
                        shopTypes?.amenities.map((amenity: any) => {
                          return (
                            <div className="d-flex">
                              <input type="checkbox" value={amenity?._id} name={amenity?.name} className='form-check'
                                onClick={amenitesHandler}
                              />
                              <label style={{ marginRight: "10px" }}>{amenity?.name}</label>
                            </div>
                          )
                        })
                      }
                    </>
                }
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Location;
