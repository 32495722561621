import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Form, Spinner } from 'react-bootstrap';
import Modal from "../../../../Components/Modal/Modal";
import { errorNotify, successNotify } from '../../../../Utils/toast';
import api from "../../../../Utils/Interceptors";
import OpeartorList from './OpeartorList';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../../Redux/Store";
import { adminCreateOperator } from '../../../../Redux/Action/Admin';

const Operator = () => {
    const dispatch: AppDispatch = useDispatch();
    const { adminCreate, loading } = useSelector((state: any) => state.adminOperatorCreate)
    const [showModal, setShowModal] = useState(false);
    const [operatorRegister, setOpeartorRegister] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        phone: ""
    })

    useEffect(() => {
        if (adminCreate?.status === 1) {
            successNotify("Created Successfully!")
            dispatch({ type: 'ADMIN_CREATE_OPERATOR_RESET' })
            setShowModal(false)
        }
    }, [adminCreate])

    const operatorHandler = (e: any) => {
        e.preventDefault();

        if (operatorRegister.email.length === 0 || operatorRegister.password.length === 0) {
            errorNotify("Fill Up Both Fields")
        }

        const data = {
            first_name: operatorRegister.first_name,
            last_name: operatorRegister.last_name,
            phone: operatorRegister.phone,
            email: operatorRegister.email,
            password: operatorRegister.password,
            conf_password: operatorRegister.password,
            roles: "operator"
        }

        dispatch(adminCreateOperator(data))

        // api.post('/auth/register/', data).then((res: any) => {
        //     console.log(res)
        //     setLoading(false)
        //     successNotify("Successfully Registered!")
        //     setShowModal(false)
        // }).catch((err: any) => {
        //     console.log(err)
        //     setLoading(false)
        // })
    };

    return (
        <div className='page_responsive'>
            <Modal
                title="Create Events"
                show={showModal}
                size="lg"
                onCloseModal={() => setShowModal(!showModal)}
                showIcon={true}
            >
                <Form onSubmit={operatorHandler}>
                    <Row className="justify-content-center">
                        <Col md={11}>
                            <div className="profile_info">
                                <Row className="profile_fields">
                                    <Col md={12} className="py-2 text-start">
                                        <label>First Name</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter First Name"
                                                onChange={(e: any) => setOpeartorRegister({
                                                    ...operatorRegister,
                                                    first_name: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={12} className="py-2 text-start">
                                        <label>Last Name</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Last Name"
                                                onChange={(e: any) => setOpeartorRegister({
                                                    ...operatorRegister,
                                                    last_name: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={12} className="py-2 text-start">
                                        <label>Phone</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Phone"
                                                onChange={(e: any) => setOpeartorRegister({
                                                    ...operatorRegister,
                                                    phone: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={12} className="py-2 text-start">
                                        <label>Email</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Email"
                                                onChange={(e: any) => setOpeartorRegister({
                                                    ...operatorRegister,
                                                    email: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={12} className="py-2 text-start">
                                        <label>Password</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Password"
                                                // type='password'
                                                onChange={(e: any) => setOpeartorRegister({
                                                    ...operatorRegister,
                                                    password: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">
                                                {loading ? <Spinner animation="border" size="sm" /> : "CREATE"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Container>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="main_heading">Operator</h1>
                            <button
                                className="back_btn"
                                onClick={() => setShowModal(!showModal)}>
                                Add Operator
                            </button>
                        </div>

                        <OpeartorList />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Operator