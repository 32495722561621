import React from 'react'
import { Container } from 'react-bootstrap';
import './TermService.css';

const TermService = () => {
    return (
        <div className='terms_main'>
            <div className="shop_details container"><h1>Terms of Service</h1></div>

            <Container>
                <h5 style={{ fontWeight: '400' }}>Hookahbars.com (HOOKAHBARS) and its affiliates provide their services to you subject to the following notices, terms, and conditions.
                    In addition, when you use any HOOKAHBARS service, you will be subject to the rules, guidelines, policies, terms, and conditions applicable
                    to that service, and you will be subject to all governing state and federal laws.</h5>

                <div className='terms_head'>
                    <h4>Copyright</h4>
                    <p>This Web site, in its entirety, is the sole property of HOOKAHBARS or its content suppliers and protected by U.S.
                        and international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all content
                        on this site is the exclusive property of HOOKAHBARS and is protected by U.S. and international copyright laws.
                        All software used on this site is the property of HOOKAHBARS or its affiliates and is protected by U.S. and international copyright laws.
                        The content and software on this Web site may be used as a personal browsing, personal shopping, and e-commerce resource.
                        Any other use, including the reproduction, modification, distribution, transmission, republication, display,
                        or performance of the content on this Web site is strictly prohibited.
                        The application of this Web site, to any other Web site, is strictly prohibited unless pre-approved by HOOKAHBARS.</p>
                </div>

                <div className='terms_head'>
                    <h4>Use of Site</h4>
                    <p>By accessing this Website, you are agreeing to all of the terms, conditions, and statements included in this legal statement.
                        The entirety of this site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, or
                        otherwise exploited for any commercial purpose that is not expressly permitted by HOOKAHBARS. HOOKAHBARS and its
                        affiliates reserve the right to refuse service or terminate accounts at its discretion, including, without limitation,
                        if HOOKAHBARS believes that customer conduct violates applicable law,
                        disrupts our normal business activities, or is harmful to the interests of HOOKAHBARS and its affiliates.</p>
                </div>

                <div className='terms_head'>
                    <h4>Email</h4>
                    <p>By registering with us, you are consenting to receive e-mails from us. You can opt out of this if you choose.</p>
                </div>

                <div className='terms_head'>
                    <h4>Reviews and Comments</h4>
                    <p>
                        If at any time, HOOKAHBARS enables site visitors to post reviews of and comments on products featured on the site,
                        the party posting that comment or review grants HOOKAHBARS and its affiliates a non-exclusive, royalty-free, perpetual,
                        irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works
                        from, distribute, and display such reviews and comments throughout the world in any media. You also grant HOOKAHBARS and
                        its affiliates the right to use the name that you submit with any review or comment, if any,
                        in connection with such review or comment.
                    </p>
                </div>

                <div className='terms_head'>
                    <h4>Indemnity</h4>
                    <p>
                        You agree to indemnify and hold HOOKAHBARS, its subsidiaries, affiliates, officers, agents,
                        and other partners and employees harmless from any loss, liability, damage, claim, or demand, including reasonable attorneys' fees,
                        made by any third party due to or arising out of your use of this Web site,
                        including your use of this Web site to provide a link to another Web site or to upload content or other information to the Web site.
                    </p>
                </div>

                <div className='terms_head'>
                    <h4>Disclaimer</h4>
                    <p>
                        THIS WEB SITE IS PROVIDED BY HOOKAHBARS ON AN "AS IS" BASIS. HOOKAHBARS MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED IN THIS SITE.
                        TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, HOOKAHBARS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                        BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                        HOOKAHBARS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, OR INFORMATION ACQUIRED
                        THROUGH THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
                    </p>
                    <p>
                        HOOKAHBARS disclaims any and all liability for the acts, omissions, conduct of any third-party users, HOOKAHBARS users, advertisers,
                        and/or sponsors on the Site, in connection with the Site, or otherwise related to your use of the Site.
                        HOOKAHBARS is not responsible for the products, services, actions, or failure to act of any third party in connection with
                        or referenced on the Site. Without limiting the foregoing, you may report the misconduct of users and/or third-party
                        advertisers or service and/or product providers referenced on or included in the Site to HOOKAHBARS at Info@Hookahbars.com.
                        HOOKAHBARS may investigate the claim and take appropriate action, at its sole discretion.
                    </p>
                </div>

                <div className='terms_head mb-5'>
                    <h4>Applicable Law</h4>
                    <p>
                        HOOKAHBARS is incorporated in the State of New Jersey, USA. As such, the laws of the State of New Jersey
                        will govern these disclaimers, terms, and conditions, without giving effect to any principles of conflicts of laws.
                        We reserve the right to make changes to our site and these disclaimers, terms, and conditions at any time.
                    </p>
                </div>
            </Container>
        </div>
    )
}

export default TermService