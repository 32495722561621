import React from 'react'
import ReactStars from 'react-stars'
import { reactStars } from '../../Utils/Interfaces'

const ReactStar = (props: reactStars) =>  {
    return (
        <ReactStars
            edit={props.edit}
            count={props.count}
            size={props.size}
            color2={props.color2}
            value={props.value}
        />
    )
}
export default ReactStar