import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { operatorGetPackagesList } from "../../../../Redux/Action/Owner";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Utils/Loader";

const ViewSubscription = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const { loading, packagesList } = useSelector((state: any) => state.OperatorPackageList);

    useEffect(() => {
        dispatch(operatorGetPackagesList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const freeMethod = (plan: string) => {
        navigate("/operator/create-listings", {
            state: { plan: plan },
        });
    }

    return (
        <div className="pt-5">
            <Container>
                {
                    loading ? <Loader /> :
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <div className="table_div">
                                    <h4>Comparison of free vs Premium listings</h4>

                                    <Table responsive>
                                        <thead>
                                            <tr style={{ backgroundColor: "#8080800f" }}>
                                                <th>FEATURE</th>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <th className="text-center">{p.name}</th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Basic Info</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.basic_info ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Review</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.review ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Photos</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.photos ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Local Search</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.search ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Ability to upload Video</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowAddVideo ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Top of Search</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.searchPriority ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Edit your listing</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowEdit ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Ability to upload Banner</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowAddBanner ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Ability to upload Restaurant/lounge PDF Menu</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowAddMenu ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>List multiple locations</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowMultiplePlaces ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Advertisement Banner</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                {
                                                                    p.features.allowAdvertisementBanner ? <AiFillCheckCircle className={"check_icon"} /> :
                                                                        <AiFillCloseCircle className="cross_icon" />
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td>Price</td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center price_tag">${p.price}</td>
                                                        )
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <td></td>
                                                {
                                                    packagesList?.map((p: any) => {
                                                        return (
                                                            <td className="text-center">
                                                                <button
                                                                    onClick={() => freeMethod(p)}
                                                                    className="purchase_btn"
                                                                >
                                                                    Get Now
                                                                </button>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
        </div>
    );
};

export default ViewSubscription