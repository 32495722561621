import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { adminCreatePackage, adminGetPackagesDetail, adminUpdatePackages } from "../../../../../Redux/Action/Admin";
import { PackageInput } from "../../../../../Utils/Interfaces";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { productValidation } from "../../../../../Utils/Validations";
import Loader from "../../../../../Utils/Loader";

const CreatePackage = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<PackageInput>();

    const dispatch: AppDispatch = useDispatch();
    const { loading, packageDetail } = useSelector((state: any) => state.adminPackageListDetail);
    const { loading: createLoading, packageData } = useSelector((state: any) => state.adminCreatePackage);
    const { loading: updateLoading, updatePackageData } = useSelector((state: any) => state.adminUpdatePackage);

    useEffect(() => {
        if (packageData?.status === 1) {
            navigate("/admin/view-packages")
            successNotify("Package Created Successfully")
            dispatch({ type: "ADMIN_CREATE_PACKAGE_RESET" })
        }
    }, [packageData?.status, dispatch, navigate])

    useEffect(() => {
        if (updatePackageData?.status === 1) {
            navigate("/admin/view-packages")
            successNotify("Package Updated Successfully")
            dispatch({ type: "ADMIN_UPDATE_PACKAGES_RESET" })
        }
    }, [dispatch, navigate, updatePackageData?.status])

    useEffect(() => {
        if (packageDetail?.status === 1) {
            console.log(packageDetail)

            setValue("package_name", packageDetail?.data?.name)
            setValue("package_price", packageDetail?.data?.price)
            setValue("package_duration", packageDetail?.data?.duration)
            setValue("duration_type", packageDetail?.data?.duration_type)

            setValue("basic_info", packageDetail?.data?.features?.basic_info)
            setValue("review", packageDetail?.data?.features?.review)
            setValue("photos", packageDetail?.data?.features?.photos)
            setValue("search", packageDetail?.data?.features?.search)
            setValue("searchPriority", packageDetail?.data?.features?.searchPriority)
            setValue("allowMultiplePlaces", packageDetail?.data?.features?.allowMultiplePlaces)
            setValue("allowEdit", packageDetail?.data?.features?.allowEdit)
            setValue("allowAddMenu", packageDetail?.data?.features?.allowAddMenu)
            setValue("allowAddVideo", packageDetail?.data?.features?.allowAddVideo)
            setValue("allowAddBanner", packageDetail?.data?.features?.allowAddBanner)
            setValue("allowAdvertisementBanner", packageDetail?.data?.features?.allowAdvertisementBanner)
        }
    }, [packageDetail?.status])

    useEffect(() => {
        if (id) {
            dispatch(adminGetPackagesDetail(id))
        }
    }, [id, dispatch])

    useEffect(() => {
        return () => {
            dispatch({ type: 'ADMIN_GET_PACKAGES_DETAIL_RESET' })
        }
    }, [])

    const productHandler: SubmitHandler<PackageInput> = (data) => {

        if (id) {

            let d = {
                ...data
            }

            console.log(d)

            dispatch(adminUpdatePackages(id, d))

        }
        else {

            let d = {
                ...data
            }

            dispatch(adminCreatePackage(d))
        }

    };

    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id ? 'Edit Package' : 'Create Package'}</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate(-1)}
                >
                    Back
                </button>
            </div>

            {
                loading ?
                    <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                    <Form onSubmit={handleSubmit(productHandler)}>
                        <Row className="login_right_section align-items-center">
                            <Col md={6} className="py-2">
                                <label>Package Name*</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Package Name"
                                        {...register("package_name", productValidation.productName)}
                                    />
                                </div>
                                {errors.package_name && (
                                    <span className="error_msg">{errors?.package_name?.message}</span>
                                )}
                            </Col>
                            <Col md={6} className="py-2">
                                <label>Package Price*</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Package Price"
                                        {...register("package_price", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.package_price && (
                                    <span className="error_msg">{errors?.package_price?.message}</span>
                                )}
                            </Col>

                            <Col md={6} className="py-2">
                                <label>Package Duration*</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Package Duration"
                                        {...register("package_duration", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.package_duration && (
                                    <span className="error_msg">{errors?.package_duration?.message}</span>
                                )}
                            </Col>

                            {/* <Col md={6} className="py-2">
                                <label>Package Duration Type</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Package Duration"
                                        {...register("duration_type", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.duration_type && (
                                    <span className="error_msg">{errors?.duration_type?.message}</span>
                                )}
                            </Col> */}

                            <Col md={6} className={'py-2'}>
                                <label>Package Duration Type*</label>
                                <select
                                    {...register("duration_type", productValidation.productPrice)}
                                    style={{ marginBottom: "0" }}
                                >
                                    <option value="">Select duration type</option>
                                    <option value="month"> Month </option>
                                    <option value="year"> Year </option>
                                </select>

                                {errors.duration_type && (
                                    <span className="error_msg">{errors?.duration_type?.message}</span>
                                )}
                            </Col>

                            <Col md={12} className="py-2">
                                <h3>Features In Packages</h3>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("basic_info", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Basic info</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("review", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Review</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("photos", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Photos</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("search", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Search</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("searchPriority", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Search Priority</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowMultiplePlaces", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Multiple Places</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowEdit", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Edit</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowAddMenu", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Add Menu</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowAddVideo", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Add Video</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowAddBanner", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Add Banner</label>
                                </div>
                            </Col>

                            <Col md={4} className='py-2'>
                                <div className="d-flex align-items-center mt-2">
                                    <input type="checkbox" {...register("allowAdvertisementBanner", {})} className='active_checkbox'
                                        style={{ width: "10%", height: "20px" }}
                                    />
                                    <label>Allow Advertise Banner</label>
                                </div>
                            </Col>


                            <Col md={12} className="pb-5">
                                <div className="text-end">
                                    {
                                        id ?
                                            <button type="submit" className="back_btn">
                                                {updateLoading ? <Spinner animation="border" size="sm" /> : "Edit"}
                                            </button>
                                            :
                                            <button type="submit" className="back_btn">
                                                {createLoading ? <Spinner animation="border" size="sm" /> : "Create"}
                                            </button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    );
};

export default CreatePackage