import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AddBusiness from "../../../../Components/AddBusiness/AddBusiness";
import ImageGallery from "react-image-gallery";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { addToCart, GetProductDetail } from "../../../../Redux/Action/Cart";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { cloudURL, CurrentUserInfo, MediaPath } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const ProductDetail = () => {

  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate()
  const [productQuantity, setProductQuantity] = useState<any>(1)
  const { Carts } = useSelector((state: any) => state.cart);
  const { loading, productDetail } = useSelector((state: any) => state.getShopProduct);

  useEffect(() => {
    if (id) {
      dispatch(GetProductDetail(id))
    }
  }, [id, dispatch])


  const images = productDetail?.images?.map((getImage: any) => {
    return {
      original: `${cloudURL}${MediaPath.product_image}${getImage}`,
      thumbnail: `${cloudURL}${MediaPath.product_image}${getImage}`
    }
  })

  const { round } = require('lodash');

  const calculatePrice = (item: any) => {
    let price = item?.price;

    if (item?.hasDiscount == "1") {
      if (item?.discountType == "0") {
        price = item.price - item.discountValue;

        return <div>
          <b className="actual mx-2"> $ {item.price} </b>
          <b className="cut_price"> $ {price} </b>
        </div>
      }

      else {
        const perc = (100 - parseFloat(item.discountValue)) / 100;
        price = item.price * perc;

        return <div>
          <b className="actual mx-2"> $ {parseFloat(round(item.price, 2))} </b>
          <b className="cut_price"> ${parseFloat(round(price, 2))} </b>
        </div>
      }
    }
    else {
      return <div className="actual_price"> ${parseFloat(round(price, 2))} </div>
    }
  }

  const checkoutHandler = () => {

    let data = {
      ...productDetail,
    }

    let found = Carts.filter((cart: any) => { return cart?._id === data?._id })
    if (found.length === 0) {
      successNotify("Product Add Successfully")
      if (data.hasDiscount === '1') {

        const p = calculatePrice(data)
        const d = {
          ...data,
          price: p.props.children[1].props.children[1].toString(),
          quantity: productQuantity
        }
        dispatch(addToCart(d));
        navigate('/checkout')
      }

      else {
        const d = {
          ...data,
          quantity: productQuantity
        }
        dispatch(addToCart(d));
        navigate('/checkout')
      }

    }

    else if (found.length > 0) {
      successNotify("Product Add Successfully")
      if (data.hasDiscount === '1') {

        const p = calculatePrice(data)
        const d = {
          ...data,
          price: p.props.children[1].props.children[1].toString(),
          quantity: productQuantity
        }
        dispatch(addToCart(d));
        navigate('/checkout')
      }

      else {
        const d = {
          ...data,
          quantity: productQuantity
        }

        dispatch(addToCart(d));
        navigate('/checkout')
      }
    }

  };

  const increment = () => {
    setProductQuantity(productQuantity + 1)
  }

  const decrement = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1)
    }
  }


  return (
    <div className="products_main">
      {
        loading ? <Loader />
          :
          <Container className="shop_details">
            <h1>Product Detail</h1>
            <Row>
              <Col md={12}>
                <div className="products_detail">
                  <Row className="mb-3">
                    <Col md={6}>
                      {
                        images && <ImageGallery
                          items={images}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          showBullets={false}
                        />
                      }
                    </Col>
                    <Col md={6} className={"product_detail_text"}>
                      <h2>{productDetail?.title}</h2>

                      <h6>Description</h6>
                      <p>
                        {productDetail?.description}
                      </p>
                      <div className="product_price">
                        <h6>{calculatePrice(productDetail)}</h6>
                        <h5>
                          <AiOutlineCheck /> In Stock & Ready to Ship
                        </h5>
                      </div>

                      <div className="d-flex align-items-center mt-3">
                        <div>
                          <div className="product_quantity" style={{ padding: '4px 10px', fontSize: "17px" }}>
                            <AiOutlinePlus
                              onClick={increment}
                            />
                            <span>{productQuantity}</span>
                            <AiOutlineMinus
                              onClick={decrement}
                            />
                          </div>
                        </div>

                        <div>
                          <button
                            disabled={CurrentUserInfo()?.roles[0] === 'OWNER' ? true : false} className={CurrentUserInfo()?.roles[0] === 'OWNER' ? "btn_untouch" : "shop_now_btn"}
                            onClick={checkoutHandler}
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>

                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
      }

      <AddBusiness />
    </div>
  );
};

export default ProductDetail;
