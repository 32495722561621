/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Row, Container, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { RegistrationInput, changePasswordHandler } from "../../../../Utils/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { authValidation } from "../../../../Utils/Validations";
import { AppDispatch } from "../../../../Redux/Store";
import { updateUserDetails, updateUserPassword } from "../../../../Redux/Action/Auth";
import Loader from "../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { cloudURL, MediaPath } from "../../../../Utils/helper";
import './Profile.css'

const Profile = () => {
  const [previewImg, setPreviewImg] = useState<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<RegistrationInput>();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm<changePasswordHandler>();
  const { loading, userDetail } = useSelector((state: any) => state.userDetail);
  const { loading: loading2, updateUserDetail } = useSelector((state: any) => state.updateUserDetail);
  const { loading: loading3, UserPassword } = useSelector((state: any) => state.updateUserPassword);

  useEffect(() => {
    if (userDetail) {
      setValue("first_name", userDetail?.first_name);
      setValue("last_name", userDetail?.last_name);
      setValue("email", userDetail?.email);
      setValue("phone", userDetail?.phone);
    }
  }, [userDetail])

  useEffect(() => {
    if (UserPassword?.status === 1) {
      successNotify("Password Update Successfully")
      dispatch({ type: "UPDATE_USER_PASSWORD_RESET" })
    }
  }, [])

  useEffect(() => {
    if (updateUserDetail?.status === 1) {
      successNotify("Profile Updated Successfully")
      dispatch({ type: "UPDATE_USER_DETAILS_RESET" })
    }
  }, [updateUserDetail, setValue])

  const imageHandler = (e: any) => {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
    setPreviewImg(URL.createObjectURL(e.target.files[0]))
  }

  const updateProfileHandler: SubmitHandler<RegistrationInput> = (data) => {

    const fileData: any = new FormData();
    fileData.append("avatar", fileSelected);
    fileData.append("first_name", data.first_name);
    fileData.append("last_name", data.last_name);
    fileData.append("phone", data.phone);

    dispatch(updateUserDetails(fileData))

  };

  const updatePasswordHandler: SubmitHandler<changePasswordHandler> = (data) => {
    if (data?.new_password !== data?.conf_password) {
      errorNotify("Password must be same!")
    }
    else {
      dispatch(updateUserPassword(data))
    }
  }

  return (
    <Container>
      {
        loading ? <div
          style={{ height: "50vh" }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Loader />
        </div> :
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="profile_settings">
                <h4>Profile Settings</h4>

                <Form onSubmit={handleSubmit(updateProfileHandler)}>
                  <div className="profile_info">
                    <Row className="profile_fields">
                      <Col md={12}>
                        <p>Avatar</p>
                        {
                          userDetail?.avatar ?
                            <img
                              src={previewImg ? previewImg : `${cloudURL}${MediaPath?.avatar}${userDetail?.avatar}`}
                              alt=""
                              className="profile_picture"
                            />
                            : <img
                              src={previewImg ? previewImg : "/images/dummy_img.png"}
                              alt=""
                              className="profile_picture"
                            />
                        }
                        <div>
                          <input
                            type="file"
                            className="profile_pic_input"
                            id="profile"
                            name="profile"
                            onChange={imageHandler}
                          />
                        </div>
                      </Col>

                      <Col md={6} className="py-2">
                        <label>First Name</label>
                        <div className="signup_input">
                          <img src="/images/AccountIcon.png" alt="" />
                          <input
                            placeholder="Enter your First name"
                            {...register("first_name", authValidation.firstName)}
                          />
                        </div>
                        {errors.first_name && (
                          <span className="error_msg">
                            {errors?.first_name?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={6} className="py-2">
                        <label>Last Name</label>
                        <div className="signup_input">
                          <img src="/images/AccountIcon.png" alt="" />
                          <input
                            placeholder="Enter your Last name"
                            {...register("last_name", authValidation.lastName)}
                          />
                        </div>
                        {errors.last_name && (
                          <span className="error_msg">
                            {errors?.last_name?.message}
                          </span>
                        )}
                      </Col>

                      <Col md={6} className="py-2">
                        <label>Email</label>
                        <div className="signup_input">
                          <img src="/images/MailIcon.png" alt="" />
                          <input
                            placeholder="Enter email address"
                            disabled
                            className="disabled"
                            {...register("email")}
                          />
                        </div>
                      </Col>
                      <Col md={6} className="py-2">
                        <label>Phone</label>
                        <div className="signup_input">
                          <img src="/images/PhoneIcon.png" alt="" />
                          <input
                            placeholder="Enter phone number"
                            {...register("phone", authValidation.phoneNumber)}
                          />
                        </div>
                        {errors.phone && (
                          <span className="error_msg">
                            {errors?.phone?.message}
                          </span>
                        )}
                      </Col>

                      <Col md={12}>
                        <div className="text-end">
                          <button type="submit" className="update_btn">
                            {loading2 ? <Spinner animation="border" size="sm" /> : 'Update Profile'}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>

                <div className="profile_info">
                  <h6>CHANGE PASSWORD</h6>

                  <Form onSubmit={handleSubmit2(updatePasswordHandler)}>
                    <Row className="profile_fields">
                      <Col md={4} className="py-2">
                        <label>Old Password</label>
                        <div className="signup_input">
                          <img src="/images/PasswordIcon.png" alt="" />
                          <input type="password" placeholder="Enter Old Password"
                            {...register2("old_password", authValidation.oldPassword)}
                          />
                        </div>
                        {errors2.old_password && (
                          <span className="error_msg">
                            {errors2?.old_password?.message}
                          </span>
                        )}
                      </Col>

                      <Col md={4} className="py-2">
                        <label>New Password</label>
                        <div className="signup_input">
                          <img src="/images/PasswordIcon.png" alt="" />
                          <input type="password" placeholder="Enter New Password"
                            {...register2("new_password", authValidation.newPassword)}
                          />
                        </div>
                        {errors2.new_password && (
                          <span className="error_msg">
                            {errors2?.new_password?.message}
                          </span>
                        )}
                      </Col>

                      <Col md={4} className="py-2">
                        <label>Confirm Password</label>
                        <div className="signup_input">
                          <img src="/images/PasswordIcon.png" alt="" />
                          <input type="password" placeholder="Enter Confirm Password"
                            {...register2("conf_password", authValidation.confirmPassword)}
                          />
                        </div>
                        {errors2.conf_password && (
                          <span className="error_msg">
                            {errors2?.conf_password?.message}
                          </span>
                        )}
                      </Col>

                      <Col md={12}>
                        <div className="text-end">
                          <button type="submit" className="update_btn">{loading3 ? <Spinner animation="border" size="sm" /> : 'Update Password'} </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>

      }
    </Container>
  );
};

export default Profile;
