import React from 'react';
import { useNavigate } from 'react-router-dom';

const Verification = () => {

    const navigate = useNavigate();

    return (
        <div className='order_placed'>
            <div className='text-center'>
                <img src='/images/tick.gif' alt='' />

                <h1>USER REGISTERED SUCCESSFULLY!!</h1>
                <h2>Please Verify your Email</h2>

                <button className='back_btn' onClick={() => navigate('/')}>Home</button>
            </div>

        </div>
    )
}

export default Verification