import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const CustomerLayout = (props: any) => {
    let userAuth: any = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

    return userAuth?.roles && userAuth?.roles[0] === "owner" ? (
        <React.Fragment>
            <Header setLocationShow={props.setLocationShow} />
            <Outlet />
            <Footer />
        </React.Fragment>
    ) : (
        <NotFound />
    );
};
export default CustomerLayout;