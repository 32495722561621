import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminPlaceStatusUpdateList } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import { successNotify } from "../../../../../Utils/toast";

const ViewListings = ({ status }: any) => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, businessPlaceListStatus } = useSelector((state: any) => state.adminBusinessPlaceStatusList);

  useEffect(() => {
    if (businessPlaceListStatus?.status === 1) {
      successNotify("Place Status Update Successfully")
      dispatch({ type: "ADMIN_PLACE_STATUS_UPDATE_LIST_RESET" })
      navigate("/admin/all-listings")
    }
  }, [businessPlaceListStatus, dispatch, navigate])

  const statusHandler = (s: any) => {
    let data = { status: s }
    dispatch(adminPlaceStatusUpdateList(status?._id, data))
  }

  let showBtn;
  if (status?.active === "0") {
    showBtn = <>
      <button type="button" className="create_btn mx-2" onClick={() => statusHandler('1')}>
        {loading ? <Spinner animation="border" size="sm" /> : "Approved"}
      </button>
      <button type="button" className="create_btn" onClick={() => statusHandler('3')}>
        {loading ? <Spinner animation="border" size="sm" /> : "Rejected"}
      </button>
    </>

  }
  else if (status?.active === "1") {
    showBtn = <button type="button" className="create_btn" onClick={() => statusHandler('2')}>
      {loading ? <Spinner animation="border" size="sm" /> : "De-Activate"}
    </button>
  }

  else if (status?.active === "2") {
    showBtn = <button type="button" className="create_btn" onClick={() => statusHandler('1')}>
      {loading ? <Spinner animation="border" size="sm" /> : "Activate"}
    </button>
  }

  return (
    <div className="d-flex justify-content-end my-5">
      {showBtn}
    </div>
  );
};
export default ViewListings;