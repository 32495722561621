import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { BsCalendar2Date } from "react-icons/bs";
import { useForm, SubmitHandler } from "react-hook-form";
import Modal from "../../../../Components/Modal/Modal";
import { CouponsInput } from "../../../../Utils/Interfaces";
import { couponsValidation } from "../../../../Utils/Validations";
import CouponsList from "./CouponsList/CouponsList";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import Select from 'react-select';
import { ownerCouponsCreate, ownerGetAllCoupons } from '../../../../Redux/Action/Owner';

const Coupons = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [previewImg, setPreviewImg] = useState<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const [profileError, setProfileError] = useState<String | null>(null);
  const [selectedPlaces, setSelectedPlaces] = useState<any>([]);
  const { loading, couponsCreate, error } = useSelector((state: any) => state.ownerCouponsSave);
  const { ownerPlacesListings } = useSelector((state: any) => state.ownerPlaceListing);

  const { register, handleSubmit, formState: { errors }, reset } = useForm<CouponsInput>();

  const handleChangeImage = (e: any) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
    setPreviewImg(URL.createObjectURL(e.target.files[0]))
    setProfileError(null)
  }

  useEffect(() => {
    if (couponsCreate?.status === 1) {
      successNotify("Coupon created successfully!");
      reset();
      setFileSelected('')
      setPreviewImg('')
      setShowModal(false)
      dispatch({ type: "OWNER_COUPONS_CREATE_RESET" });
      dispatch(ownerGetAllCoupons())
    }
    if (error) {
      errorNotify(error)
      dispatch({ type: "CLEAR_ERRORS" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponsCreate, error])

  const handlePlaces = (selectedOption: any) => {
    setSelectedPlaces(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : []);
  }

  const couponHandler: SubmitHandler<CouponsInput> = (data) => {

    if (fileSelected && data.couponEndDate > data.couponStartDate) {
      const couponsData: any = new FormData();
      couponsData.append("code", data.couponCode);
      couponsData.append("start_date", data.couponStartDate);
      couponsData.append("end_date", data.couponEndDate);
      couponsData.append("description", data.couponDescription);
      couponsData.append("places", JSON.stringify(selectedPlaces));
      couponsData.append("image", fileSelected);

      dispatch(ownerCouponsCreate(couponsData))

      setSelectedPlaces([])

    } else if (!fileSelected) {
      setProfileError("Please select Image");
    }
    else {
      errorNotify("End date is not valid!")
    }
  };

  const options = ownerPlacesListings?.data?.map((d: any) => {
    return { value: d._id, label: d.name }
  })

  return (
    <div className="my-3">
      <Modal
        title="Create Coupon"
        show={showModal}
        size="lg"
        onCloseModal={() => setShowModal(!showModal)}
        showIcon={true}
      >
        <Form onSubmit={handleSubmit(couponHandler)}>
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="profile_info">
                <Row className="profile_fields">
                  <Col md={12} className={"text-start"}>
                    <p style={{ padding: "0" }}>Coupon Image</p>
                    <img
                      src={previewImg ? previewImg : '/images/dummy_img.png'}
                      alt=""
                      className="profile_picture"
                    />
                    <div>
                      <input
                        type="file"
                        className="profile_pic_input"
                        id="profile"
                        name="profile"
                        onChange={handleChangeImage}
                      />
                    </div>
                    {profileError && <span className="error_msg">{profileError}</span>}
                  </Col>
                  <Col md={12} className="py-2 text-start">
                    <label>Coupon Code</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input
                        placeholder="Enter Coupon Code"
                        {...register(
                          "couponCode",
                          couponsValidation.couponCode
                        )}
                      />
                    </div>
                    {errors.couponCode && (
                      <span className="error_msg">
                        {errors?.couponCode?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={6} className="py-2 text-start">
                    <label>Coupon Start Date</label>
                    <div className="signup_input">
                      <BsCalendar2Date />
                      <input
                        type="date"
                        {...register(
                          "couponStartDate",
                          couponsValidation.couponStartDate
                        )}
                      />
                    </div>

                    {errors.couponStartDate && (
                      <span className="error_msg">
                        {errors?.couponStartDate?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={6} className="py-2 text-start">
                    <label>Coupon End Date</label>
                    <div className="signup_input">
                      <BsCalendar2Date />
                      <input
                        type="date"
                        {...register(
                          "couponEndDate",
                          couponsValidation.couponEndDate
                        )}
                      />
                    </div>
                    {errors.couponEndDate && (
                      <span className="error_msg">
                        {errors?.couponEndDate?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={12} className="py-2 text-start">
                    <label>Coupon Description</label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Enter Coupon Description"
                      {...register(
                        "couponDescription",
                        couponsValidation.couponDescription
                      )}
                    />
                    {errors.couponDescription && (
                      <span className="error_msg">
                        {errors?.couponDescription?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={12} className="py-2 text-start">
                    <label>Places</label>
                    <Select
                      isMulti
                      isClearable
                      name="places"
                      placeholder="Select Places"
                      options={options}
                      value={options?.filter((obj: any) => selectedPlaces.includes(obj.value))}
                      onChange={handlePlaces}
                      className="basic-multi-select form-control"
                      classNamePrefix="select"
                    />
                  </Col>
                  <Col md={12}>
                    <div className="text-end">
                      <button type="submit" className="update_btn">
                        {loading ? <Spinner animation="border" size="sm" /> : "CREATE"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="text-end">
              <button
                className="back_btn"
                onClick={() => setShowModal(!showModal)}
              >
                Create Coupon
              </button>
            </div>
            <CouponsList />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Coupons;
