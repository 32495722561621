import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { authValidation } from "../../../../Utils/Validations";
import { LoginInput } from "../../../../Utils/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { login } from "../../../../Redux/Action/Auth";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();

  let userAuth = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

  const { register, handleSubmit, formState: { errors }, reset } = useForm<LoginInput>();
  const { error, loading, user } = useSelector((state: any) => state.login);

  useEffect(() => {
    if (user?.message) {
      successNotify(`Welcome ${user?.data?.first_name}!`);
      reset();
      dispatch({ type: "LOGIN_RESET" });
    }

    if (error) {
      errorNotify(error)
      dispatch({ type: "CLEAR_ERRORS" })
    }

    else if (userAuth?.roles && userAuth?.roles[0] === "owner") {
      localStorage.removeItem("tab");

      if (userAuth?.hasPlace) {
        navigate("/owner/dashboard")
      }
      else {
        navigate("/owner/payment-plans");
      }
    }

    else if (userAuth?.roles && userAuth?.roles[0] === "customer") {
      localStorage.removeItem("customerTab");

      // let checkout = localStorage.getItem("checkout") ? JSON.parse(localStorage.getItem("checkout") || "") : null;
      let checkout = localStorage.getItem("checkout")

      console.log(checkout)

      if (checkout === "yes") {
        navigate("/checkout", { replace: true });
      }
      else {
        navigate("/customer/dashboard", { replace: true });
      }
    }

    else if (userAuth?.roles && userAuth?.roles[0] === "admin") {
      navigate("/admin/dashboard", { replace: true });
    }

    else if (userAuth?.roles && userAuth?.roles[0] === "operator") {
      navigate("/operator/all-listings", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const loginHandler: SubmitHandler<LoginInput> = data => dispatch(login(data));

  function onChange(value: any) {
    console.log("Captcha value:", value);
  }

  return (
    <div className="main_login">
      <Container>
        <Row className="justify-content-center">
          <Col md={11} className="login_container">
            <Row className="py-5 px-3">
              <Col md={6}>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img
                    src="/images/SigninImg.png"
                    className="login_img px-2"
                    alt="aa"
                  />
                </div>
              </Col>
              <Col md={6} className="px-1 pt-4 d=flex justify-content-center align-items-center">
                <h3>
                  <span style={{ fontWeight: "800", color: "#ba2271" }}>
                    Sign In
                  </span>
                </h3>
                <Form onSubmit={handleSubmit(loginHandler)}>
                  <Row className="login_right_section">
                    <Col md={12} className="py-2">
                      <label>Email</label>
                      <div className="signup_input">
                        <img src="/images/MailIcon.png" alt="" />
                        <input
                          placeholder="Enter email address"
                          {...register("username", authValidation.email)}
                        />
                      </div>
                      {errors.username && (
                        <span className="error_msg">
                          {errors?.username?.message}
                        </span>
                      )}
                    </Col>

                    <Col md={12} className="py-2">
                      <label>Password</label>
                      <div className="signup_input">
                        <img src="/images/PasswordIcon.png" alt="" />
                        <input
                          type="password"
                          placeholder="Enter Password"
                          {...register("password", authValidation.password)}
                        />
                      </div>
                      {errors.password && (
                        <span className="error_msg">
                          {errors?.password?.message}
                        </span>
                      )}
                    </Col>

                    <Col md={12}>
                      <h6 className="text-end">
                        <Link
                          to={"/forget-password"}
                          style={{ fontWeight: "800", color: "#ba2271" }}
                        >
                          Forgot your password?
                        </Link>
                      </h6>
                    </Col>

                    <Col>
                      <ReCAPTCHA
                        sitekey="AIzaSyAFsEP9H8HabaWXDeZzpZZ7-7JuVnXv2yk"
                        onChange={onChange}
                      />
                    </Col>

                    <Col md={12} className="py-2 mt-4">
                      <Button
                        style={{ width: "100%", height: "40px" }}
                        type="submit"
                      >
                        {loading ? <Spinner animation="border" size="sm" /> : 'SIGN IN'}
                      </Button>
                    </Col>

                    <Col md={12} className="d-flex justify-content-center align-items-center my-2">
                      <div
                        style={{
                          width: "20px",
                          borderBottom: "1px solid black",
                          margin: "0 10px 5px 10px",
                        }}
                      ></div>
                      <h5>Or</h5>
                      <div
                        style={{
                          width: "20px",
                          borderBottom: "1px solid black",
                          margin: "0 10px 5px 10px",
                        }}
                      ></div>
                    </Col>

                    <Col md={12}>
                      <h6 className="text-center">
                        Already have an account?{" "}
                        <Link
                          to={
                            pathname === "/login"
                              ? "/register"
                              : "/owner/register"
                          }
                          style={{ fontWeight: "800", color: "#ba2271" }}
                        >
                          Sign up
                        </Link>
                      </h6>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
