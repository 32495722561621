import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "../../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { changePasswordHandler } from "../../../../../Utils/Interfaces";
import { authValidation } from "../../../../../Utils/Validations";
import api from '../../../../../Utils/Interceptors';
import { getToken } from "../../../../../Utils/helper";

const OpeartorDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [customerProfile, setCustomerProfile] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [passwordLoading, setPasswordLoading] = useState(false)

    const { register, handleSubmit, setValue } = useForm();
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset } = useForm<changePasswordHandler>();


    useEffect(() => {
        setLoading(true)
        api.get(`/admin/user/operator/${id}/profile`).then((res: any) => {
            setLoading(false)
            setCustomerProfile(res?.data?.data)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [id])

    useEffect(() => {
        setValue("first_name", customerProfile?.first_name);
        setValue("last_name", customerProfile?.last_name);
        setValue("email", customerProfile?.email);
        setValue("phone", customerProfile?.phone);
    }, [customerProfile])

    const updateCustomerHandler: SubmitHandler<any> = (data) => {

        const fileData: any = new FormData();
        fileData.append("first_name", data.first_name);
        fileData.append("last_name", data.last_name);
        fileData.append("phone", data.phone);

        setEditLoading(true)
        api.put(`/admin/user/operator/${id}/profile`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        }).then((res: any) => {
            setEditLoading(false)
            successNotify("Update Successfully!")
        }).catch((err: any) => {
            console.log(err)
            setEditLoading(false)
        })

    };

    const updatePasswordHandler: SubmitHandler<any> = (data) => {

        if (data.password !== data.conf_password) {
            errorNotify("Passowrd not same")
            return;
        }
        setPasswordLoading(true)
        api.put(`/admin/user/${id}/changePassword`, data).then((res: any) => {
            setPasswordLoading(false)
            successNotify("Password Updated Successfully!")
        }).catch((err: any) => {
            setPasswordLoading(false)
        })
    }

    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">Opeartor Details</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/operator")}
                >
                    Back
                </button>
            </div>
            {
                loading ? <div
                    style={{ height: '50vh' }}
                    className={"d-flex justify-content-center align-items-center"}
                >
                    <Loader />
                </div> :
                    <div className="profile_settings">
                        <h4>User Details</h4>
                        <Form onSubmit={handleSubmit(updateCustomerHandler)}>
                            <div className="profile_info">
                                <Row className="profile_fields">
                                    <Col md={6} className="py-2">
                                        <label>First Name</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter your First name"
                                                {...register("first_name")}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="py-2">
                                        <label>Last Name</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter your Last name"
                                                {...register("last_name")}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="py-2">
                                        <label>Email</label>
                                        <div className="signup_input">
                                            <img src="/images/MailIcon.png" alt="" />

                                            <input
                                                placeholder="Enter email address"
                                                disabled
                                                value={customerProfile?.email}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="py-2">
                                        <label>Phone</label>
                                        <div className="signup_input">
                                            <img src="/images/PhoneIcon.png" alt="" />

                                            <input
                                                placeholder="Enter phone number"
                                                {...register("phone")}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">
                                                {editLoading ? <Spinner animation="border" size="sm" /> : 'Update Opeartor'}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                        <div className="profile_info">
                            <h6>CHANGE PASSWORD</h6>

                            <Form onSubmit={handleSubmit2(updatePasswordHandler)}>
                                <Row className="profile_fields">
                                    <Col md={6} className="py-2">
                                        <label>New Password</label>
                                        <div className="signup_input">
                                            <img src="/images/PasswordIcon.png" alt="" />
                                            <input type="password" placeholder="Enter New Password"
                                                {...register2("password", authValidation.newPassword)}
                                            />
                                        </div>
                                        {errors2.password && (
                                            <span className="error_msg">
                                                {errors2?.password?.message}
                                            </span>
                                        )}
                                    </Col>

                                    <Col md={6} className="py-2">
                                        <label>Confirm Password</label>
                                        <div className="signup_input">
                                            <img src="/images/PasswordIcon.png" alt="" />
                                            <input type="password" placeholder="Enter Confirm Password"
                                                {...register2("conf_password", authValidation.confirmPassword)}
                                            />
                                        </div>
                                        {errors2.conf_password && (
                                            <span className="error_msg">
                                                {errors2?.conf_password?.message}
                                            </span>
                                        )}
                                    </Col>

                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">{passwordLoading ? <Spinner animation="border" size="sm" /> : 'Update Password'} </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
            }
        </div>
    );
};
export default OpeartorDetails;