import api from "../../Utils/Interceptors";

export const customerDashboard = () => async (dispatch: any) => {
    try {
      dispatch({
        type: "CUSTOMER_DASHBOARD_REQUEST",
      });
  
      const { data } = await api.get("/customer/dashboard");
  
      dispatch({
        type: "CUSTOMER_DASHBOARD_SUCCESS",
        payload: data,
        success: true,
      });
  
    } 
    catch (e: any) {
      dispatch({
        type: "CUSTOMER_DASHBOARD_FAILED",
        payload: e.response.data.message,
        success: false,
      });
    }
};

export const customerOrder = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "CUSTOMER_ORDERS_REQUEST",
    });

    const { data } = await api.get("/customer/orders/list");

    dispatch({
      type: "CUSTOMER_ORDERS_SUCCESS",
      payload: data,
      success: true,
    });

  } 
  catch (e: any) {
    dispatch({
      type: "CUSTOMER_ORDERS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const customerOrderDetail = (order_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "CUSTOMER_ORDER_DETAILS_REQUEST",
    });

    const { data } = await api.get(`/customer/order/${order_id}`);

    dispatch({
      type: "CUSTOMER_ORDER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  } 
  catch (e: any) {
    dispatch({
      type: "CUSTOMER_ORDER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};