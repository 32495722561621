import React, { useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { getAdminSuggestedPlaces } from "../../../../Redux/Action/Admin";
import { FaEye } from "react-icons/fa";
import Loader from "../../../../Utils/Loader";

const SuggestedPlaces = () => {

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { loading, adminSuggestPlace } = useSelector((state: any) => state.adminGetSuggestedPlaces);

  useEffect(() => {
    dispatch(getAdminSuggestedPlaces())
  }, [])

  const tabs = ["SNo.", "Business Name", "Email", "Phone", "Action"];

  return (
    <div className="page_responsive">
      <Row className="justify-content-center">
        <h1 className="main_heading">Suggested Places</h1>

        {
          loading ?
            <Loader /> :
            <Col md={12}>
              <div className="table_div">
                <h4>Places</h4>

                <Table responsive>
                  <thead>
                    <tr style={{ backgroundColor: "#8080800f" }}>
                      {tabs.map((tab) => (
                        <th className="">{tab}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {adminSuggestPlace?.map((user: any, index: number) => {
                      return (
                        <tr>
                          <td className="">{index + 1}</td>
                          <td className="">{user.name}</td>
                          <td className="">{user.email}</td>
                          <td className="">{user.phone}</td>
                          <td className="">
                            <div className="table_icons">
                              <FaEye
                                onClick={() =>
                                  navigate(
                                    `/admin/suggested-places-detail/${user._id}`
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
        }
      </Row>
    </div>
  );
};
export default SuggestedPlaces;
