import CustomerDashboard from "../Pages/Dashboard/CustomerDashboard";
import OrderDetails from "../Pages/Orders/OrderDetails/OrderDetails";

export const customerRoutes: any[] = [
  {
    path: "/customer/dashboard",
    component: <CustomerDashboard />,
  },
  {
    path: "/customer/order-details/:id",
    component: <OrderDetails />,
  },
];


