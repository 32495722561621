import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderPlaced.css';

const OrderPlaced = () => {

    const navigate = useNavigate();

    const dashboardHandler = () => {
        localStorage.setItem("customerTab", JSON.stringify("3"));
        navigate('/customer/dashboard')
    }
    return (
        <div className='order_placed'>
            <div className='text-center'>
                <img src='/images/tick.gif' alt='' />

                <h1>Your Order has been Placed!!</h1>

                <button className='back_btn' onClick={dashboardHandler}>Go to Dashboard</button>
            </div>

        </div>
    )
}

export default OrderPlaced