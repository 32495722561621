import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { customerDashboard } from "../../../../Redux/Action/Customer";
import { AppDispatch } from "../../../../Redux/Store";
import { CurrentUserInfo } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";

const CustomerMain = () => {

  const dispatch: AppDispatch = useDispatch();
  const { loading, customer } = useSelector((state: any) => state.customerDashboard);

  useEffect(() => {
    dispatch(customerDashboard())
  }, [])

  return (
    <React.Fragment>
      {
        loading ? <div
          style={{ height: "100vh" }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Loader />
        </div>
          :
          <Row className="main_boxes">
            <div> <h2>Welcome {CurrentUserInfo()?.first_name}!</h2></div>

            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>all orders</li>
                </ul>
                <h5>{customer?.all}</h5>
              </div>
            </Col>

            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>pending orders</li>
                </ul>
                <h5>{customer?.pending}</h5>
              </div>
            </Col>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>completed orders</li>
                </ul>
                <h5>{customer?.completed}</h5>
              </div>
            </Col>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>rejected orders</li>
                </ul>
                <h5>{customer?.rejected}</h5>
              </div>
            </Col>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>approved orders</li>
                </ul>
                <h5>{customer?.approve}</h5>
              </div>
            </Col>
          </Row>
      }
    </React.Fragment>
  );
};

export default CustomerMain;
