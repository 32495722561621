import React from 'react'
import { Container } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className='about_main'>
            <div className="shop_details container"><h1>What the heck is HookahBars.com?</h1></div>
            <Container>
                <p>Hookahbars.com is a website specifically designed to make finding hookah around you simple. </p>

                <p>Users have the ability to filter searches further based on whether establishments serve food, alcohol, provide live entertainment, outdoor seating or coupons.</p>

                <p>Hookah bars can list their services for FREE. Users can use the site for FREE.</p>

                <h4>Restrictions on who can use this website.</h4>

                <p>By visiting any page on Hookahbars.com you understand that "Adult" content may be encountered that is not suitable for children. You must be at least 18 years of age.</p>

                <h4>Why am I getting emails from Hookahbars.com?</h4>

                <p>You signed up to receive daily deals on our site! Click on the 'Unsubscribe button' at the top of the email and you'll never hear from us again.</p>

                <h4>I'm an Advertiser and some reviews disappeared from my listing page. What happened?</h4>

                <p>A review may be deleted if...</p>

                <ul>
                    <li>It is blatantly negative and bashing.</li>
                    <li>Mention another advertiser by name or otherwise.</li>
                    <li>Addresses other reviewers or reviews.</li>
                    <li>...Is not really a review.</li>
                </ul>

                <p>We also check to make sure your reviews are coming from real reviews and not fake accounts (which are often on the same IP).</p>

                <h4>Can I post reviews under multiple accounts?</h4>

                <p>No, it's not ok to write multiple reviews from multiple accounts for the same hookah bar. We reserve the right to delete multiple accounts when we see them.</p>

                <h4>Can advertisers message me after I review their business?</h4>

                <p>Advertisers are real people too and sometimes they make like to reach out to the people who have reviewed their establishment. We've created a feature that allows business owners to privately message those who have reviewed their business.</p>

                <h4>Business Review Policy Disclosure</h4>

                <p>Reviews are an important part of Hookahbars.com and we take them seriously.  Reviews can only be removed if the review is in clear violation of Hookahbars.com review policy. If there is no evidence of a violation and/or if the decision of the Hookahbars.com moderation staff is that the review is in compliance, then the review will not be removed. Review of advertisers and non-advertisers, regardless of status, are treated identically and objectively using the same guidelines and terms.</p>

                <h4>In what circumstance will Hookahbars.com ever remove reviews?</h4>

                <p>In some cases a member of the Hookah Bars Moderation staff will remove a review (and will typically email the writer to let them know) if it doesn't follow these guidelines:</p>

                <ul>
                    <li><span>Conflicts of interest.</span> Your reviews should be unbiased and objective. If there's any hint of a conflict, please don't post the review. For example, owners shouldn't sing the praises of their own businesses or bash their competitors or other business listings and users shouldn't comment on their current or former employers.</li>
                    <li><span>Second-hand experiences,</span> hearsay or you've never been. For example, you can't give a hookah bar a bad review because your friend complained. Your reviews must be about your personal experiences, not someone else's. If you haven't been inside the establishment, you can't accurately rate it.</li>
                    <li><span>Personal attacks.</span> We can handle strong language, but there's no need for lewdness, hate speech and other displays of bigotry. Your friends probably know when you're being funny, but the thousands of people who read reviews on Hookahbars.com may not.</li>
                    <li>Relevance. Reviews aren't the place for rants about a business's employment practices, political ideologies, or other matters that don't address the core of the normal customer experience.</li>
                    <li>Plagiarism. Use your own words to tell us about your experience with a local business, but don't lift something from another website.</li>
                    <li>Blank reviews. A review without text is like a fish without water. Don't be shy, use that smart noggin and write!</li>
                    <li>User Review Verification: If a review is reported to our moderation staff as potentially fraudulent (i.e., a malicious review from a competitor or a planted review from an ex-employee) and the Author's authenticity is in question due to limited activity and/or inconsistent activity as determined by the moderation department, then the moderation department can moderate/un-publish any review in question. We are under no obligation to enforce the Terms of Service on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms of Service, we reserve the right to investigate and take appropriate action at our sole discretion.</li>
                </ul>

                <h4>What makes for a great review?</h4>

                <p>
                    Reviews should be truthful, funny and cool because people like you take the time to share thoughtful insights on local hookah bars.
                    The best reviews are personal and experiential, and tend to offer helpful suggestions, perhaps even an insider tip or two.
                    The most useful reviews sometimes make mention of unique qualities that
                    make the hookah bar special or the type of person who might also like this business.
                </p>
            </Container>

            <div className="shop_details container"><h1>What the heck is HookahBars.com?</h1></div>

            <Container>
                <h4>Hookahbars.com Maps and Main Website Features</h4>

                <ul>
                    <li>Automatically Find Your Local Hookah Bars! <br />
                        <p>By now you have likely already realized that we show you all of the local Hookah Bars in your area on an easy to use interactive map!</p>
                    </li>

                    <li>See ALL Listings In Your Area! <br />
                        <p>Since we offer FREE listings to advertisers, you can be assured that unlike any other Hookah Bar Listing website, you are seeing all of the hookah places in your area in a very easy to use format!</p>
                    </li>

                    <li>Easy To Use Maps! <br />
                        <p>The map areas are intuitively laid out so that finding any particular hookah bar is very simple. Just enter a name,
                            city or zip code to see hookah bars in your area.</p>
                    </li>

                    <li>Get Door To Door Directions! <br />
                        <p>Find an advertiser on the map and click their marker or navigate to an advertiser's listing page and check their map
                            marker and you will see that you can put your
                            address right into the "Directions from: box" marker cloud.</p>
                    </li>

                    <li>Review Hookah Bars You've Visited! <br />
                        <p>Have a great experience at a place? Have a bad experience at a place? Share your experiences as well as your thoughts on the hookah establishment.</p>
                    </li>
                </ul>

                <div className='mb-5'>
                    <h4>We are always working on new and exciting features here at Hookahbars.com!</h4>
                </div>
            </Container>
        </div>
    )
}

export default AboutUs