import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import ReactStar from "../../../Components/ReactStar/ReactStar";
import AddBusiness from "../../../Components/AddBusiness/AddBusiness";
import Loader from "../../../Utils/Loader";
import { AppDispatch } from "../../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { getBPListings, getCurrentBPListings } from "../../../Redux/Action/BusinessPlaces";
import { cloudURL, MediaPath } from "../../../Utils/helper";
import ShopMapLocation from "./ShopMapLocation/ShopMapLocation";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FindShop = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [bannerImg, setBannerImg] = useState<any>([])

  const dispatch: AppDispatch = useDispatch()
  const { bpListings, loading } = useSelector((state: any) => state.BPListing);
  const { loading: listLoading, shopTypes } = useSelector((state: any) => state.getShop);

  let query = useQuery();

  let getShopType: any = query.get("shop_type")
  let getLat: any = query.get("lat")
  let getLng: any = query.get("lng")
  let getShopName: any = query.get("shopName")
  let getAmenities: any = query.get("amenities")
  let getCountry: any = query.get("country")
  let getCity: any = query.get("city")
  let getState: any = query.get("state")

  const markers = bpListings?.map((bp: any) => {
    return {
      id: bp?._id?.place_id,
      name: bp?._id?.name,
      position: {
        lat: bp?._id?.location?.location?.coordinates[0],
        lng: bp?._id?.location?.location?.coordinates[1]
      }
    }
  })

  useEffect(() => {
    if (getAmenities) {
      dispatch(getBPListings(getShopType, getLat, getLng, '', getAmenities, getShopName, getCountry, getCity, getState))
      dispatch(getCurrentBPListings(getLat, getLng))
    }
    else {
      dispatch(getBPListings(getShopType, getLat, getLng, '', '', getShopName, getCountry, getCity, getState))
      dispatch(getCurrentBPListings(getLat, getLng))
    }
  }, [getShopType])

  const groupedAmenities: any = {};
  shopTypes?.amenities?.forEach((amenity: any) => {
    if (!groupedAmenities[amenity.category.name]) {
      groupedAmenities[amenity.category.name] = [];
    }
    groupedAmenities[amenity.category.name].push(amenity);
  });

  // const groupedAmenities = a.reduce((acc: any, curr: any) => {
  //   const categoryName = curr.category.name;
  //   if (!acc[categoryName]) {
  //     acc[categoryName] = [];
  //   }
  //   acc[categoryName].push(curr);
  //   return acc;
  // }, {});
  // console.log(groupedAmenities);

  // let amenities: any = [{ ...groupedAmenities }]

  // console.log(amenities)

  const [priceRange, setPriceRange] = useState('')

  const checkboxHandler = (e: any) => {
    setPriceRange(e.target.value)

    dispatch(getBPListings(getShopType, getLat, getLng, e.target.value, getAmenities, getShopName, getCountry, getCity, getState))
  }


  const [amenityArr, setAmenityArr] = useState<any>([])
  const amenitesHandler = (e: any) => {
    if (e.target.value && e.target.checked) {
      setAmenityArr([...amenityArr, e.target.value])
      dispatch(getBPListings(getShopType, getLat, getLng, priceRange, [...amenityArr, e.target.value], getShopName, getCountry, getCity, getState))
    }
    else if (e.target.value && !e.target.checked) {
      let arr = amenityArr.filter((entity: any) => entity !== e.target.value);
      setAmenityArr((previous: any) => {
        return previous.filter((entity: any) => entity !== e.target.value)
      })
      console.log(arr)

      dispatch(getBPListings(getShopType, getLat, getLng, priceRange, arr, getShopName, getCountry, getCity, getState))
    }

  }

  useEffect(() => {
    bpListings && bpListings?.map((data: any, index: number) => {
      if (data._id.advertisment_banner && data._id.advertisment_banner.length > 0) {
        setBannerImg((prev: any) => [...prev, data._id.advertisment_banner])
      }
    })
  }, [bpListings])

  console.log(bannerImg)

  return (
    <React.Fragment>

      <Container className="shop_details">
        <h1>
          {
            getShopType === '63a14cbe3683d00187f64033' ? "Smoke Shop" : "Hookah bars"
          }
        </h1>
      </Container>
      <Container fluid className="shop_details mb-5">
        <Row className="find_shop_row justify-content-around">
          {
            getShopType !== '63a14cbe3683d00187f64033' &&
            <Col md={2}>
              {
                listLoading ? <Loader /> :
                  <div className="search_filter_div">
                    <h6>Search Filter</h6>

                    <div className="filter">
                      - Price Range
                      <div className="range_div">
                        <label className="labl">
                          <input
                            type="radio"
                            name="price"
                            value="$"
                            onClick={checkboxHandler}
                          />
                          <div>$</div>
                        </label>
                        <label className="labl">
                          <input
                            type="radio"
                            name="price"
                            value="$$"
                            onClick={checkboxHandler}
                          />
                          <div>$$</div>
                        </label>
                        <label className="labl">
                          <input
                            type="radio"
                            name="price"
                            value="$$$"
                            onClick={checkboxHandler}
                          />
                          <div>$$$</div>
                        </label>
                        <label className="labl">
                          <input
                            type="radio"
                            name="price"
                            value="$$$$"
                            onClick={checkboxHandler}
                          />
                          <div>$$$$</div>
                        </label>
                      </div>
                    </div>

                    {
                      Object.keys(groupedAmenities)?.map((ame: any, index: any) => {
                        return (
                          <div className="filter" key={index}>
                            - {ame}
                            <div>
                              {groupedAmenities[ame].map((item: any, index: number) => (
                                <label className="checkbox_container" key={index}>
                                  {item.name}
                                  <input type="checkbox" value={item._id} name={item.name} onClick={amenitesHandler} />
                                  <span className="checkmark"></span>

                                </label>
                              ))}
                            </div>

                          </div>
                        )
                      })
                    }
                  </div>
              }
            </Col>
          }
          {
            loading ? <Col md={10}>
              <Loader />
            </Col>
              :
              <React.Fragment>
                <Col md={7}>
                  <Row>
                    {
                      bpListings?.length > 0 ? bpListings && bpListings?.map((data: any, index: number) => {
                        return (
                          <Col md={6} className={"mb-3"} key={index}>
                            <a style={{ textDecoration: "none" }} target={'_blank'} rel="noreferrer" href={`/shop-view/${data?._id?.slug}`}>
                              <div className="shops">
                                {
                                  data.is_premium === 1 ? <img src={'/images/featuredIcon.png'} alt="" className="featured_icon" /> : null
                                }
                                {
                                  data?._id?.image ?
                                    <img src={`${cloudURL}${MediaPath?.image}${data?._id?.image}`} alt="service1" className="main_service_img" /> :
                                    <img src="/images/service2.png" className="shop_Image" alt="" />
                                }

                                <div className="d-flex align-items-center justify-content-between mt-2">
                                  <p>{data?._id?.name}</p>
                                  <ReactStar
                                    edit={false}
                                    count={5}
                                    size={20}
                                    color2={"#ffb21e"}
                                    value={data?.avgRating === null ? 0 : data?.avgRating}
                                  />
                                </div>
                                <p className="location_text">
                                  <MdLocationOn /> {data?._id?.location?.address}
                                </p>
                                <div className="show_now_container">
                                  <a className="back_btn" target={'_blank'} rel="noreferrer" href={`/shop-view/${data?._id?.slug}`}>View</a>
                                </div>
                                {/* <p className="location_text">
                                <BsTelephone /> {data?._id?.contact?.phone_number}
                              </p> */}
                              </div>
                            </a>
                          </Col>
                        )
                      }) :
                        <h5 style={{ textAlign: "center", fontSize: "35px", fontWeight: '700' }}>No Place Found</h5>
                    }
                  </Row>
                </Col>
                <Col md={3} className={"mb-3 main_map"}>
                  <ShopMapLocation markers={markers} />

                  <div style={{ marginTop: "25px" }}>
                    <img src={`${cloudURL}${MediaPath?.advertisment_banner}${bannerImg[0]}`} alt="" />
                  </div>
                </Col>
              </React.Fragment>
          }
        </Row>
      </Container>
      <AddBusiness />
    </React.Fragment>
  );
};

export default FindShop;
