import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminOwnerReport } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from '../../../../../Utils/Loader';
import { Table } from 'react-bootstrap';
import moment from 'moment';

const OwnersReport = () => {

    // adminGetSalesData
    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch: AppDispatch = useDispatch();
    const { loading, adminGetOwner } = useSelector((state: any) => state.adminGetOwnerData);

    console.log(adminGetOwner)

    useEffect(() => {
        dispatch(adminOwnerReport(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='page_responsive'>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id === 'free' ? 'free Owners' : "Premium Owners"}</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/dashboard")}
                >
                    Back
                </button>
            </div>

            <div className="table_div">
                <h4>Owners</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            {
                                adminGetOwner?.data?.length > 0 ?
                                    <>
                                        <thead>
                                            <tr style={{ backgroundColor: "#8080800f" }}>
                                                <th className="">S NO.</th>
                                                {/* <th className="">SUBS-EXPIRE DATE</th> */}
                                                <th className="">OWNER EMAIL</th>
                                                <th className="">OWNER NAME</th>
                                                <th className="">OWNER PHONE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminGetOwner?.data?.map((list: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className="">{index + 1}</td>
                                                        {/* {
                                                            list?.is_premium === 'yes' && <td className="">{moment(list?.subscription_expire).format('ll')}</td>
                                                        } */}
                                                        <td className="">{list?.email}</td>
                                                        <td className="">{list?.first_name}</td>
                                                        <td className="">{list?.phone}</td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </>
                                    : <p className='text-center mb-0 mt-2'>No Data Found</p>
                            }
                        </Table>
                }
            </div>
        </div>
    )
}

export default OwnersReport