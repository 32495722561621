import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { AppDispatch } from "../../../../Redux/Store";
import { adminGetProductList, adminDeleteProduct } from "../../../../Redux/Action/Admin";
import Loader from "../../../../Utils/Loader";
import { cloudURL, MediaPath } from "../../../../Utils/helper";
import DeleteModal from "../../../../Components/Modal/Modal"
import "cropperjs/dist/cropper.css";
import { errorNotify, successNotify } from "../../../../Utils/toast";

const Products = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false)
  const [storeId, setStoreId] = useState('')
  const dispatch: AppDispatch = useDispatch();

  const { loading, productList } = useSelector((state: any) => state.adminProductList);
  const { loading: deleteLoading, deleteProductData, error } = useSelector((state: any) => state.adminProductDelete)
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<any>();

  console.log(productList)

  useEffect(() => {
    if (productList) {
      setLoaderShow(false)
    }
  }, [productList])

  useEffect(() => {
    dispatch(adminGetProductList('', ''))

    if (deleteProductData?.status === 1) {
      successNotify("Product Deleted Successfully")
      setShow(!show)
      dispatch({ type: "ADMIN_DELETE_PRODUCT_RESET" })
    }

    if (error) {
      errorNotify('already deleted, need to set proper message for deleted or invalid product')
      setShow(!show)
      dispatch({ type: "ADMIN_DELETE_PRODUCT_RESET" })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductData, dispatch, error]);

  const productDeleteHandler = (id: string) => {
    setShow(!show)
    setStoreId(id)
  };

  const deleteProduct = () => {
    dispatch(adminDeleteProduct(storeId))
  }

  const ResetHandler = () => {
    dispatch(adminGetProductList('', ''))
  }

  const productSearchHandler: SubmitHandler<any> = (data) => {
    setLoaderShow(true)
    dispatch(adminGetProductList(data.keyword, data.sku))
    reset();
  }

  const { round } = require('lodash');

  const calculatePrice = (item: any) => {
    let price = item.price;

    if (item.hasDiscount == "1") {
      if (item.discountType == "0") {
        price = item.price - item.discountValue;
        return <div>
          <b className="actual mx-2"> $ {item.price} </b>
          <b className="cut_price"> $ {price} </b>
        </div>
      }

      else {
        const perc = (100 - parseFloat(item.discountValue)) / 100;
        price = item.price * perc;

        return <div>
          <b className="actual mx-2"> $ {parseFloat(round(item.price, 2))} </b>
          <b className="cut_price"> ${parseFloat(round(price, 2))} </b>
        </div>
      }
    }

    else {
      return <div className="actual_price"> ${parseFloat(round(price, 2))} </div>
    }
  }

  return (
    <div className="page_responsive">
      <DeleteModal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Delete"
      >

        <div>
          <h5>Are you sure you want to delete this Product??</h5>
          <div>
            <button className="mx-3 back_btn" onClick={deleteProduct}>{deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
            <button className="back_btn" onClick={() => setShow(!show)}>No</button>
          </div>
        </div>
      </DeleteModal>

      <div className="d-flex justify-content-between align-items-center">
        <h1 className="main_heading">All Products {productList && `(${productList?.length})`} </h1>
        <button className="back_btn" onClick={() => navigate("/admin/create-product")}>
          Create Product
        </button>
      </div>

      <Form onSubmit={handleSubmit(productSearchHandler)}>
        <Row>
          <Col md={3} className="py-2">
            <div className="signup_input">
              <img src="/images/AccountIcon.png" alt="" />
              <input
                placeholder="Search By Name or Desc."
                {...register("keyword")}
              />
            </div>
          </Col>
          <Col md={3} className="py-2">
            <div className="signup_input">
              <img src="/images/AccountIcon.png" alt="" />
              <input
                placeholder="Search By SKU#"
                {...register("sku")}
              />
            </div>
          </Col>
          <Col md={3} className="py-2">
            <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
            <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
          </Col>
        </Row>
      </Form>

      <div className="products_main">
        {
          loading ?
            <div
              style={{ height: '50vh' }}
              className={"d-flex justify-content-center align-items-center"}
            >
              <Loader />

            </div>
            :
            <Row>
              {productList?.map((data: any) => {
                return (
                  <Col key={data?._id} md={4} className={"product_div"}>
                    <AiOutlineClose onClick={() => productDeleteHandler(data?._id)} />
                    <img src={`${cloudURL}${MediaPath.product_image}${data?.feature_image}`} alt={"menu1"} />
                    {
                      data?.hasDiscount === '1' ? <img
                        src="/images/onSale.png"
                        className="sale_icon"
                        alt=""
                      /> : null
                    }

                    <div className="card_content">
                      <h6>{data?.title}</h6>
                      <p>{data?.description}</p>
                      {calculatePrice(data)}

                      <div className="show_now_container">
                        <button className="shop_now_btn me-3" onClick={() => navigate(`/admin/view-product/${data?._id}`)}>
                          View
                        </button>
                        <button className="shop_now_btn" onClick={() => navigate(`/admin/edit-product/${data?._id}`)}>Edit</button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
        }

      </div>
    </div>
  );
};

export default Products;
