const initialState = {
    owner: {}
}

// Owner Dashboard Reducer

export const OwnerDashboardReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "OWNER_DASHBOARD_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_DASHBOARD_SUCCESS":
            return {
                ...state,
                loading: false,
                owner: action.payload.data,
                error: false
            }
        case "OWNER_DASHBOARD_FAILED":
            return {
                ...state,
                loading: false,
                owner: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// Owner Place Create Reducer

export const ownerCreatePlaceReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_PLACE_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_PLACE_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerPlaces: action.payload,
                error: false
            }

        case "OWNER_PLACE_CREATE_RESET":
            return {
                ...state,
                loading: false,
                ownerPlaces: null
            }

        case "OWNER_PLACE_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// Owner Place Edit Reducer

export const ownerEditPlaceReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_PLACE_EDIT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_PLACE_EDIT_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerEditPlaces: action.payload,
                error: false
            }

        case "OWNER_PLACE_EDIT_RESET":
            return {
                ...state,
                loading: false,
                ownerEditPlaces: null
            }

        case "OWNER_PLACE_EDIT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// Owner Place Edit Reducer

export const ownerDeletePlaceReducer: any = (state = {}, action: any) => {

    switch (action.type) {
        case "OWNER_PLACE_DELETE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_PLACE_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerDeletePlaces: action.payload,
                error: false
            }

        case "OWNER_PLACE_DELETE_RESET":
            return {
                ...state,
                loading: false,
                ownerDeletePlaces: null
            }

        case "OWNER_PLACE_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// Owner Place Listing Reducer

export const ownerPlaceListingReducer = (state = { ownerPlacesListings: [] }, action: any) => {
    switch (action.type) {
        case "GET_OWNER_PLACE_LISTING_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_OWNER_PLACE_LISTING_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerPlacesListings: action.payload,
            };
        case "GET_OWNER_PLACE_LISTING_FAILED":
            return {
                ...state,
                ownerPlacesListings: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


// Owner Coupons Create 

export const ownerCouponsCreateReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_COUPONS_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_COUPONS_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                couponsCreate: action.payload,
                error: false
            }

        case "OWNER_COUPONS_CREATE_RESET":
            return {
                ...state,
                loading: false,
                couponsCreate: null
            }

        case "OWNER_COUPONS_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// Owner Get All Coupons

export const ownerGetAllCouponsReducer = (state = { getAllCoupons: [] }, action: any) => {
    switch (action.type) {
        case "GET_ALL_COUPONS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_ALL_COUPONS_SUCCESS":
            return {
                ...state,
                loading: false,
                getAllCoupons: action.payload?.data,
            };
        case "GET_ALL_COUPONS_FAILED":
            return {
                ...state,
                getAllCoupons: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


// Owner Update Coupons

export const ownerUpdateCouponsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_UPDATE_COUPONS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OWNER_UPDATE_COUPONS_SUCCESS":
            return {
                ...state,
                loading: false,
                updateCoupons: action.payload
            };
        case "OWNER_UPDATE_COUPONS_RESET":
            return {
                ...state,
                loading: false,
                updateCoupons: null
            };
        case "OWNER_UPDATE_COUPONS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Owner Delete Coupons

export const ownerDeleteCouponsReducer = (state = [], action: any) => {
    switch (action.type) {
        case "OWNER_COUPONS_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OWNER_COUPONS_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteCoupons: action.payload,
            };
        case "OWNER_COUPONS_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "OWNER_COUPONS_DELETE_RESET":
            return {
                ...state,
                deleteCoupons: {}
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Owner Event 

export const ownerEventsCreateReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_EVENT_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_EVENT_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                eventsCreate: action.payload,
                error: false
            }

        case "OWNER_EVENT_CREATE_RESET":
            return {
                ...state,
                loading: false,
                eventsCreate: null
            }

        case "OWNER_EVENT_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const ownerGetAllEventsReducer = (state = { getAllEvents: [] }, action: any) => {
    switch (action.type) {
        case "GET_ALL_EVENTS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_ALL_EVENTS_SUCCESS":
            return {
                ...state,
                loading: false,
                getAllEvents: action.payload?.data,
            };
        case "GET_ALL_EVENTS_FAILED":
            return {
                ...state,
                getAllEvents: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Owner Update Coupons

export const ownerUpdateEventReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "OWNER_UPDATE_EVENT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OWNER_UPDATE_EVENT_SUCCESS":
            return {
                ...state,
                loading: false,
                updateEvent: action.payload
            };
        case "OWNER_UPDATE_EVENT_RESET":
            return {
                ...state,
                loading: false,
                updateEvent: null
            };
        case "OWNER_UPDATE_EVENT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Owner Delete Coupons

export const ownerDeleteEventReducer = (state = [], action: any) => {
    switch (action.type) {
        case "OWNER_EVENTS_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OWNER_EVENTS_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteEvent: action.payload,
            };
        case "OWNER_EVENTS_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "OWNER_EVENTS_DELETE_RESET":
            return {
                ...state,
                deleteEvent: {}
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Owner Enquiries Reducer

export const OwnerEnquiryReducer = (state = [], action: any) => {
    switch (action.type) {
        case "GET_OWNER_ENQUIRY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_OWNER_ENQUIRY_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerEnquiry: action?.payload?.data,
            };
        case "GET_OWNER_ENQUIRY_FAILED":
            return {
                ...state,
                ownerEnquiry: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// HOME ALL EVENTS

export const getAllEventsReducer = (state = { getAllHomeEvents: [] }, action: any) => {

    switch (action.type) {
        case "GET_ALL_HOME_EVENTS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_ALL_HOME_EVENTS_SUCCESS":
            return {
                ...state,
                loading: false,
                getAllHomeEvents: action.payload?.data,
            };
        case "GET_ALL_HOME_EVENTS_FAILED":
            return {
                ...state,
                getAllHomeEvents: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// ============= OWNER PACKAGE =============

export const OwnerGetPackagesReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "OWNER_GET_PACKAGES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_GET_PACKAGES_SUCCESS":
            return {
                ...state,
                loading: false,
                packagesList: action.payload.data,
                error: false
            }

        case "OWNER_GET_PACKAGES_FAILED":
            return {
                ...state,
                loading: false,
                packagesList: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const OwnerGetSubscriptionReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "OWNER_GET_SUBSCRIPTION_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OWNER_GET_SUBSCRIPTION_SUCCESS":
            return {
                ...state,
                loading: false,
                subscriptionGet: action.payload.data,
                error: false
            }

        case "OWNER_GET_SUBSCRIPTION_FAILED":
            return {
                ...state,
                loading: false,
                subscriptionGet: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Admin PACKAGES GET

export const OperatorGetPackagesReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "OPERATOR_GET_PACKAGES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OPERATOR_GET_PACKAGES_SUCCESS":
            return {
                ...state,
                loading: false,
                packagesList: action.payload.data,
                error: false
            }

        case "OPERATOR_GET_PACKAGES_FAILED":
            return {
                ...state,
                loading: false,
                packagesList: null,
                error: action.payload
            }
        default:
            return state
    }
}