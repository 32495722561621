import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddBusiness from "../../../Components/AddBusiness/AddBusiness";
import "./OurProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { addToCart, getAllProducts } from "../../../Redux/Action/Cart";
import { successNotify } from "../../../Utils/toast";
import { cloudURL, CurrentUserInfo, MediaPath } from "../../../Utils/helper";
import Loader from "../../../Utils/Loader";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const OurProducts = () => {
  const navigate = useNavigate();
  const [loaderShow, setLoaderShow] = useState(false)
  const [discountPrice, setDiscountPrice] = useState()
  const dispatch: AppDispatch = useDispatch();

  const { products, loading, Carts } = useSelector((state: any) => state.cart);
  const { register, handleSubmit, reset } = useForm<any>();

  console.log(products)

  useEffect(() => {
    if (products) {
      setLoaderShow(false)
    }
  }, [products])

  useEffect(() => {

    dispatch(getAllProducts('', ''))

  }, [dispatch]);


  const addCartHandler = (data: any) => {

    let found = Carts.filter((cart: any) => { return cart._id === data._id })
    if (found.length === 0) {
      successNotify("Product Add Successfully")

      if (data.hasDiscount === '1') {
        const p = calculatePrice(data)
        const d = {
          ...data,
          price: p.props.children[1].props.children[1].toString(),
          quantity: 1
        }
        dispatch(addToCart(d));
      }

      else {
        const d = {
          ...data,
          quantity: 1
        }
        dispatch(addToCart(d));
      }
    }

  };

  const productDetailHandler = (id: number) => {
    navigate(`/product/details/${id}`)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const ResetHandler = () => {
    dispatch(getAllProducts('', ''))
  }

  const productSearchHandler: SubmitHandler<any> = (data) => {
    setLoaderShow(true)
    dispatch(getAllProducts(data.keyword, data.sku))
    reset();
  }


  const { round } = require('lodash');

  const calculatePrice = (item: any) => {
    let price = item.price;

    if (item.hasDiscount == "1") {
      if (item.discountType == "0") {
        price = item.price - item.discountValue;

        return <div>
          <b className="actual mx-2"> $ {item.price} </b>
          <b className="cut_price"> $ {price} </b>
        </div>
      }

      else {
        const perc = (100 - parseFloat(item.discountValue)) / 100;
        price = item.price * perc;

        return <div>
          <b className="actual mx-2"> $ {parseFloat(round(item.price, 2))} </b>
          <b className="cut_price"> ${parseFloat(round(price, 2))} </b>
        </div>
      }
    }
    else {
      return <div className="actual_price"> ${parseFloat(round(price, 2))} </div>
    }
  }

  return (
    <div className="products_main">
      <Container>
        <Row>
          <Col md={12} className="product_cover_img_container">
            <img src="/images/product-cover.png" alt="shop_view" />
          </Col>
        </Row>
      </Container>

      {
        loading ? <Loader /> :
          <Container className="shop_details">
            <h1>Product Shop</h1>

            <Form onSubmit={handleSubmit(productSearchHandler)}>
              <Row>
                <Col md={3} className="py-2">
                  <div className="signup_input">
                    <img src="/images/AccountIcon.png" alt="" />
                    <input
                      placeholder="Search By Name or Desc."
                      {...register("keyword")}
                    />
                  </div>
                </Col>
                <Col md={3} className="py-2">
                  <div className="signup_input">
                    <img src="/images/AccountIcon.png" alt="" />
                    <input
                      placeholder="Search By SKU#"
                      {...register("sku")}
                    />
                  </div>
                </Col>
                <Col md={3} className="py-2">
                  <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
                  <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
                </Col>
              </Row>
            </Form>

            <Row>
              <Col md={12}>
                <div className="products_main">
                  <Row>
                    {products?.map((data: any, index: number) => {
                      return (
                        <Col key={index} md={3} className={"product_div"}>
                          <div onClick={() => productDetailHandler(data?._id)} style={{ cursor: "pointer" }}>
                            <img src={`${cloudURL}${MediaPath.product_image}${data?.images[0]}`} alt={"menu1"} />
                            {
                              data?.hasDiscount === '1' ? <img
                                src="/images/onSale.png"
                                className="sale_icon"
                                alt=""
                              /> : null
                            }
                            <div className="card_content">
                              <h6>{data?.title}</h6>
                              <p>{data?.description}</p>

                              {calculatePrice(data)}
                            </div>
                          </div>

                          <div className="show_now_container">
                            <button type="button" disabled={CurrentUserInfo()?.roles[0] === 'OWNER' ? true : false} className={CurrentUserInfo()?.roles[0] === 'OWNER' ? "btn_untouch" : "shop_now_btn"} onClick={() => addCartHandler(data)}>Add to Cart</button>
                            <button className="shop_now_btn mx-4" onClick={() => productDetailHandler(data?._id)}> Detail </button>
                          </div>

                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
      }

      <AddBusiness />
    </div>
  );
};

export default OurProducts;
