
// import EditListings from "../../Admin/Pages/AllListings/EditListings/EditListings";
import ShopView from "../../Pages/FindShop/ShopView/ShopView";

import { BsListCheck } from "react-icons/bs";
import AllListings from "../Pages/AllListings/AllListings";
import CreateListings from "../Pages/CreateListings/CreateListing";
import ViewSubscription from "../Pages/ViewSubsciption/ViewSubscription";
// import AllListings from "../../Admin/Pages/AllListings/AllListings";

export const operatorRoutes: any[] = [
    {
        path: "/operator/all-listings",
        component: <AllListings />,
    },
    {
        path: "/operator/create-listings",
        component: <div className="page_responsive"><CreateListings /></div>,
    },
    {
        path: "/operator/view-packages",
        component: <div className="page_responsive"><ViewSubscription /></div>,
    },
    {
        path: "/operator/view-listings/:id",
        component: <div className="page_responsive"><ShopView /></div>,
    },
    // {
    //     path: "/admin/edit-listings/:id",
    //     component: <EditListings />,
    // },
];

export const operatorSideBarItems: any[] = [
    {
        path: "/operator/all-listings",
        icon: <BsListCheck />,
        title: "All Listings",
        isSubNav: false,
    },
    {
        path: "/operator/view-packages",
        icon: <BsListCheck />,
        title: "Create Place",
        isSubNav: false,
    },
];
