import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Spinner } from "react-bootstrap";
import { errorNotify, successNotify } from '../../../../../../Utils/toast';
import api from "../../../../../../Utils/Interceptors";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/Store";

import { useNavigate } from 'react-router-dom';

const OrderPayment: any = (props: any) => {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch()
    const [loader, setLoader] = useState(false)

    const onSubmit = async (e: React.FormEvent) => {
        dispatch({ type: "GET_ALL_PRODUCTS_RESET" })
        e.preventDefault();
        setLoader(true)

        if (!stripe || !elements) {
            setLoader(false)
            return;
        }

        const cardElement = elements.getElement(CardElement)!
        const cardDetail = await stripe.createToken(cardElement)

        const cardObj: any = {
            ...cardDetail.token
        }

        const subsData: any = new FormData();
        subsData.append("order_id", props.orderId);
        subsData.append("token", JSON.stringify(cardObj));

        if (cardDetail.error) {
            setLoader(false)
            errorNotify(cardDetail.error.message!)
        }

        else {

            api.put("/shop/payment", subsData).then((res: any) => {

                if (res.data.status === 1) {
                    successNotify("Payment done Successfully")
                    setLoader(false)

                    navigate("/order-placed")
                }

            }).catch((e: any) => {
                setLoader(false)
                console.log(e)
                errorNotify(e?.response?.data?.message)
            })
        }
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group>
                <Form.Label style={{ textAlign: 'left', display: "flex", fontSize: "20px", fontWeight: "600", marginBottom: "30px" }}>Please Enter Card Details for Payment</Form.Label>
                <CardElement
                    className={"mb-3"}
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '23px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <div className='text-end'>
                    <button type={'submit'} className="create_btn mt-3">
                        {loader ? <Spinner animation={"border"} size="sm" /> : 'Pay NOW'}
                    </button>
                </div>
            </Form.Group>
        </Form>
    );
};

export default OrderPayment;
