import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { adminGetProductDetail } from "../../../../../Redux/Action/Admin";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";
import Loader from "../../../../../Utils/Loader";

const ViewProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const { loading, productDetail } = useSelector((state: any) => state.adminProductDetail);

  useEffect(() => {
    if (id) {
      dispatch(adminGetProductDetail(id))
    }
  }, [id, dispatch])


  const images = productDetail?.images.map((getImage: any) => {
    return {
      original: `${cloudURL}${MediaPath.product_image}${getImage}`,
      thumbnail: `${cloudURL}${MediaPath.product_image}${getImage}`
    }
  })

  return (
    <div className="page_responsive">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="main_heading">Product Details</h1>
        <button
          className="back_btn"
          onClick={() => navigate("/admin/products")}
        >
          Back
        </button>
      </div>
      {
        loading ?
          <div
            style={{ height: '50vh' }}
            className={"d-flex justify-content-center align-items-center"}
          >
            <Loader />

          </div>
          :
          <Container className="shop_details">
            <div className="products_detail">
              <Row className="mb-3">
                <Col md={6}>
                  {
                    images &&
                    <ImageGallery
                      items={images}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showBullets={false}
                    />
                  }
                </Col>
                <Col md={6} className={"product_detail_text"}>
                  <h2>{productDetail?.title}</h2>
                  <p>
                    {productDetail?.description}
                  </p>

                  <div className="product_price">
                    <h6>${productDetail?.price}</h6>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

      }
    </div>
  );
};

export default ViewProduct;
