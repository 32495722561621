import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form, Spinner } from "react-bootstrap";
import { enquiryValidation } from "../../../../../Utils/Validations";
import { EnquiryInput } from "../../../../../Utils/Interfaces";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { EnquiryCreate } from '../../../../../Redux/Action/BusinessPlaces';
import { successNotify } from '../../../../../Utils/toast';
import { LocalizationProvider, TimePicker, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from 'moment';

const EnquiryPage = (props: any) => {
    const [time, setTime] = useState('')
    const [date, setDate] = useState<any>('')
    const { register, handleSubmit, formState: { errors }, reset } = useForm<EnquiryInput>();
    const dispatch: AppDispatch = useDispatch();

    const enquiryHandler: SubmitHandler<EnquiryInput> = (d) => {

        let data = {
            ...d,
            reserve_date: `${moment(date).format("YYYY-MM-DD")} ${new Date(time).toString().split(" ")[4].slice(0, 5)}`,
        }

        console.log(data)

        dispatch(EnquiryCreate(props.id, data))
        reset()
    };

    const { enquiryCreate, loading } = useSelector((state: any) => state.CreateEnquiry);

    useEffect(() => {
        if (enquiryCreate?.status === 1) {
            successNotify("Enquiry Send Successfully")
            dispatch({ type: 'ENQUIRY_CREATE_RESET' })
        }
    }, [enquiryCreate])

    const handleInputChange = (getDate: any) => {
        console.log(getDate)
        // let properDate = new Date(getDate).toString().split(" ")[4].slice(0, 5)
        // console.log(properDate);
        setTime(getDate.toString())
    };


    return (
        <div className="enquiry_section">
            <h3>
                <span>Book a</span> Appointment
            </h3>

            <Form onSubmit={handleSubmit(enquiryHandler)}>
                <Row>
                    <Col md={12} className="py-2">
                        <label>Name</label>
                        <div className="signup_input">
                            <img src="/images/AccountIcon.png" alt="" />
                            <input
                                placeholder="Enter your full name"
                                {...register("name", enquiryValidation.name)}
                            />
                        </div>
                        {errors.name && (
                            <span className="error_msg">{errors?.name?.message}</span>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="py-2">
                        <label>Email</label>
                        <div className="signup_input">
                            <img src="/images/MailIcon.png" alt="" />

                            <input
                                placeholder="Enter email address"
                                {...register("email", enquiryValidation.email)}
                            />
                        </div>
                        {errors.email && (
                            <span className="error_msg">
                                {errors?.email?.message}
                            </span>
                        )}
                    </Col>
                    <Col md={12} className="py-2">
                        <label>Phone</label>
                        <div className="signup_input">
                            <img src="/images/PhoneIcon.png" alt="" />

                            <input
                                placeholder="Enter phone number"
                                {...register(
                                    "phone",
                                    enquiryValidation.phoneNumber
                                )}
                            />
                        </div>
                        {errors.phone && (
                            <span className="error_msg">
                                {errors?.phone?.message}
                            </span>
                        )}
                    </Col>
                    <Col md={12} className="py-2">
                        <label>Person</label>
                        <div className="signup_input">
                            <img src="/images/AccountIcon.png" alt="" />
                            <input
                                placeholder="How many Persons"
                                type='number'
                                {...register("persons", enquiryValidation.name)}
                            />
                        </div>
                        {errors.persons && (
                            <span className="error_msg">{errors?.persons?.message}</span>
                        )}
                    </Col>
                    <Col md={12} className="py-2">
                        <label>Time</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                value={time}
                                onChange={(e) => handleInputChange(e)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={'time_picker'}
                                        fullWidth
                                        placeholder="Enter Time"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Col>

                    <Col md={12}>
                        <label>Date</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={date}
                                onChange={(value) => setDate(value)}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Col>

                    <Col md={12} className="py-2 mt-2">
                        <Button
                            style={{ width: "100%", height: "40px" }}
                            type="submit"
                            className='enquiry_btn'
                        >
                            {loading ? <Spinner animation='border' size='sm' /> : 'SUBMIT'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div >
    )
}

export default EnquiryPage
