import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './Faqs.css';

const Faqs = () => {
    return (
        <div className='faq_main'>
            <Container>
                <div className="shop_details container"><h1>Frequently Asked Questions</h1></div>

                <div className='faq_accordion'>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is legal age to Smoke Hookah</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The legal age for smoking a hookah, also known as a water pipe or shisha, varies depending on the location. In many countries,
                                    the legal age for smoking a hookah is the same as the legal age for smoking cigarettes, which is typically 18 or 21 years old.
                                    However, in some areas, the legal age for smoking a hookah may be different or may not be regulated at all.
                                </p>
                                <p>
                                    It's important to note that hookah smoking is not a safe or healthy activity, regardless of the legal age.
                                    Inhaling the smoke from a hookah exposes you to many of the same toxic chemicals and substances as smoking cigarettes,
                                    including nicotine, carbon monoxide, and tar. These substances can have negative effects on your health,
                                    including addiction, heart disease, cancer, and respiratory problems.
                                </p>
                                <p>
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.
                                    It is also important to follow all local laws and regulations regarding
                                    smoking and tobacco use.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Shisha vs Hookah</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Shisha and hookah are two terms that are often used interchangeably to refer to the same thing: a type
                                    of water pipe that is used to smoke tobacco. Shisha is the term that is commonly used in the Middle East,
                                    while hookah is more commonly used in other parts of the world.
                                </p>
                                <p>
                                    Both shisha and hookah refer to a type of water pipe that consists of a bowl for holding the tobacco,
                                    a stem that connects the bowl to the base, and a hose through which the smoke is inhaled.
                                    The tobacco is typically mixed with fruit flavors or other sweeteners to make the smoke more pleasant to inhale.
                                    It's important to note that shisha and hookah smoking are not safe or healthy activities.
                                </p>
                                <p>
                                    Inhaling the smoke from a shisha or hookah exposes you to many of the same toxic chemicals and
                                    substances as smoking cigarettes, and it can lead to a range of negative health effects.
                                    If you are considering smoking a shisha or hookah, it is important to be aware of the risks and to use caution.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Flavors of hookah tobacco</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Hookah tobacco, also known as shisha, is typically flavored with a variety of fruit and other
                                    sweet flavors in order to make the smoke more pleasant to inhale. Some common flavors of hookah
                                    tobacco include:
                                </p>
                                <p>
                                    Fruit flavors: These can include flavors like apple, cherry, watermelon, blueberry, and many others.
                                </p>
                                <p>Mint and menthol: These flavors are popular for their refreshing and cooling taste.</p>
                                <p>Chocolate and coffee: These flavors are popular for their rich and indulgent taste.</p>
                                <p>Spices: Some hookah tobacco is flavored with spices like clove, cinnamon, and cardamom for a more complex flavor.</p>
                                <p>Alcoholic beverages: Some hookah tobacco is flavored with the taste of popular alcoholic drinks, such as mojitos, margaritas, and pina coladas.</p>
                                <p>It's important to note that hookah tobacco is still a form of tobacco, www.hookahbars.com does not and will
                                    not recommend use of tobacco and it can be harmful to your health. Smoking a hookah exposes you to many of
                                    the same toxic chemicals and substances as smoking cigarettes, and it can lead to a range of negative health effects.
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Food and Hookah Smoking</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Some people enjoy eating food while smoking a hookah, as the sweet flavors of tobacco can be a nice
                                    complement to certain types of food. However,
                                    it's important to note that hookah smoking is not a safe or healthy activity, and it is not advisable to combine it with eating.
                                </p>

                                <p>
                                    Inhaling the smoke from a hookah exposes you to many of the same toxic chemicals and
                                    substances as smoking cigarettes, including nicotine, carbon monoxide, and tar.
                                    These substances can have negative effects on your health, including addiction, heart disease,
                                    cancer, and respiratory problems.
                                </p>

                                <p>
                                    Additionally, smoking a hookah can affect your sense of taste and smell, which can affect your enjoyment of food.
                                    It can also cause bad breath and other oral health problems, which can be unappealing to others.
                                </p>

                                <p>
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.
                                    It is also important to follow all of the instructions carefully and to use the
                                    hookah in a well-ventilated area to reduce your exposure to toxic smoke.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>History of hookah</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The hookah, also known as a water pipe or shisha, has a long and storied history.
                                    The exact origins of the hookah are uncertain, but it is thought to have originated in ancient India or the Middle East.
                                </p>
                                <p>
                                    According to some accounts, the hookah was invented by a physician in ancient India as a way to cool and filter the
                                    smoke from burning tobacco. It is also believed that the hookah was used as a way to socialize and relax,
                                    and it quickly became a popular pastime in many cultures around the world.
                                </p>
                                <p>
                                    Over time, the hookah spread to other parts of Asia and the Middle East, and it became a popular form of tobacco
                                    consumption in many countries. In the 19th and 20th centuries, hookahs gained popularity in the West as well,
                                    and they are now a common sight in many parts of the world.
                                </p>
                                <p>
                                    Despite its long history, hookah smoking is not a safe or healthy activity. It exposes users to many of
                                    the same toxic chemicals
                                    and substances as smoking cigarettes, and it can lead to a range of negative health effects.
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>How popular is hookah smoking</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The popularity of hookah smoking, also known as water pipe smoking or shisha smoking,
                                    varies depending on the location. In some parts of the world,
                                    hookah smoking is a very popular activity, while in other areas it is less common.
                                </p>
                                <p>
                                    Hookah smoking is particularly popular in many countries in the Middle East, where it has a long tradition
                                    and is often a social activity enjoyed in cafes and other public spaces.
                                    It is also popular in many parts of Asia, particularly India, Pakistan, and China.
                                </p>
                                <p>
                                    In the United States, hookah smoking is less common than cigarette smoking, but it is still a popular activity
                                    among some young people and college students. According to the Centers for Disease Control and Prevention (CDC),
                                    in 2019, 9.4% of high school students in the United States had smoked a hookah in the past 30 days.
                                </p>
                                <p>
                                    It's important to note that hookah smoking is not a safe or healthy activity.
                                    Inhaling the smoke from a hookah exposes you to many of the same toxic chemicals and substances as smoking cigarettes,
                                    and it can lead to a range of negative health effects.
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How to smoke a Hookah</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    A hookah is a type of water pipe that is used to smoke tobacco, which is typically mixed with fruit flavors or other sweeteners.
                                    Here are the steps for smoking a hookah:
                                </p>
                                <p>
                                    Fill the base of the hookah with water: The water helps to cool and filter the smoke as it passes through the pipe.
                                    Make sure the water level is high enough to reach the bottom of the Down stem,
                                    but not so high that it touches the bottom of the bowl.
                                </p>
                                <p>
                                    Prepare the tobacco: Hookah tobacco, also known as shisha, is usually sold in small, flavored blocks. You will need to break off a small piece and place it in the bowl at the
                                    top of the hookah. It's important to pack the tobacco evenly and not too tightly, so that it will burn evenly
                                </p>
                                <p>
                                    Inhale the smoke: Once the charcoal is heated, you can start inhaling the smoke through the hose.
                                    You may need to use your mouth to create a seal around the hose in order to draw the smoke through.
                                </p>
                                <p>
                                    Exhale the smoke: Once you have inhaled the smoke, exhale it through your nose or mouth.
                                    You can take multiple puffs in a row or pass the hose to a friend.
                                </p>
                                <p>
                                    It's important to note that smoking hookah is not a safe alternative to smoking cigarettes,
                                    as it still exposes you to many of the same harmful substances.
                                    If you do decide to smoke a hookah, it's important to use caution and follow all the instructions carefully.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Is hookah smoking addictive</Accordion.Header>
                            <Accordion.Body>
                                <p>Hookah smoking, also known as water pipe smoking or shisha smoking, can be addictive.
                                    This is because hookah tobacco, also known as shisha, contains nicotine, which is a highly addictive substance. </p>
                                <p>Inhaling the smoke from a hookah can deliver a high dose of nicotine to the body, which can lead to physical dependence and addiction.
                                    As with other forms of tobacco, people who smoke a hookah regularly may develop a tolerance to nicotine,
                                    which can lead them to smoke more in order to achieve the same effects. </p>
                                <p>It's important to note that hookah smoking is not a safe or healthy activity, regardless of its potential for addiction.
                                    Inhaling the smoke from a hookah exposes you to many of the same toxic chemicals and substances as smoking cigarettes,
                                    and it can lead to a range of negative health effects.
                                    If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Is Smoking hookah relaxing?</Accordion.Header>
                            <Accordion.Body>
                                <p>Some people may find hookah smoking to be a relaxing activity,
                                    as it can be a social activity that allows people to relax and unwind with friends.
                                    However, it's important to note that hookah smoking is not a safe or healthy activity. </p>

                                <p>Inhaling the smoke from a hookah exposes you to many of the same toxic chemicals and substances as smoking cigarettes,
                                    including nicotine, carbon monoxide, and tar.
                                    These substances can have negative effects on your health, including addiction, heart disease, cancer, and respiratory problems.. </p>

                                <p>Furthermore, the relaxation that some people may experience from hookah smoking is
                                    likely due to the effects of nicotine, which is a highly addictive substance that can have
                                    sedative and calming effects. However, the negative health effects of nicotine far outweigh any temporary relaxation it may provide.
                                </p>

                                <p>If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.
                                    It is also important to follow all of the
                                    instructions carefully and to use the hookah in a well-ventilated area to reduce your exposure to toxic smoke</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Tobacco in hookah</Accordion.Header>
                            <Accordion.Body>
                                <p>Hookah tobacco, also known as shisha, is a type of tobacco that is specifically made for use in water pipes.
                                    It is usually sold in small, flavored blocks,
                                    and is typically mixed with fruit flavors or other sweeteners to make the smoke more pleasant to inhale. </p>

                                <p>While hookah tobacco is often marketed as a safer or healthier alternative to cigarettes,
                                    it is still a form of tobacco and can be harmful to your health. Smoking a hookah exposes you to many of the
                                    same toxic chemicals and substances as smoking cigarettes, including nicotine, carbon monoxide, and tar.
                                    These substances can cause a range of negative health effects, including addiction, heart disease, cancer, and respiratory problems. </p>

                                <p>If you are considering smoking a hookah, it is important to be aware of the risks and to use caution.
                                    It is also important to follow all of the instructions carefully and to use the hookah in a well-ventilated area to
                                    reduce your exposure to toxic smoke.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10">
                            <Accordion.Header>What is a Smoke Shop</Accordion.Header>
                            <Accordion.Body>
                                <p>A smoke shop is a retail store that specializes in selling products related to tobacco and
                                    smoking. These products can include cigarettes, cigars, pipe tobacco, rolling papers,
                                    lighters, ashtrays, and other smoking accessories. Some smoke shops may also sell other
                                    items, such as vaporizers and e-cigarettes.
                                </p>

                                <p>
                                    Smoke shops are typically found in areas where smoking is legal and are often frequented
                                    by people who enjoy smoking or are looking for specific tobacco products. They may also
                                    sell a variety of novelty items, such as t-shirts and other items with smoking-related themes.
                                </p>

                                <p>It's important to note that smoking is a harmful activity that can have serious negative
                                    effects on your health. If you are considering smoking or using tobacco products, it is
                                    important to be aware of the risks and to use caution. It is also important to follow all local
                                    laws and regulations regarding smoking and tobacco use

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </div>
    )
}

export default Faqs