import axios from "axios";
import { CurrentUserInfo, getToken } from "../../Utils/helper";
import api from "../../Utils/Interceptors";

export const ownerDashboard = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_DASHBOARD_REQUEST",
    });

    const { data } = await api.get("/owner/dashboard");

    dispatch({
      type: "OWNER_DASHBOARD_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "OWNER_DASHBOARD_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Owner Place Profile Create

export const ownerPlaceCreate = (placeData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_PLACE_CREATE_REQUEST",
    });

    const { data } = await api.post("/owner/profile", placeData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    console.log(data)

    dispatch({
      type: "OWNER_PLACE_CREATE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OWNER_PLACE_CREATE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Owner Place Profile Create

export const ownerPlaceEdit = (placeData: any, place_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_PLACE_EDIT_REQUEST",
    });

    const { data } = await api.put(`/owner/place/${place_id}`, placeData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "OWNER_PLACE_EDIT_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OWNER_PLACE_EDIT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Owner Place Profile Create

export const ownerPlaceDelete = (place_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_PLACE_DELETE_REQUEST",
    });

    const { data } = await api.delete(`/owner/place/${place_id}`);

    dispatch({
      type: "OWNER_PLACE_DELETE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OWNER_PLACE_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// GET Owner Place Listing

export const getOwnerPlaceListings = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_OWNER_PLACE_LISTING_REQUEST"
    })

    const { data } = await api.get('/owner/places')

    dispatch({
      type: "GET_OWNER_PLACE_LISTING_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_OWNER_PLACE_LISTING_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Owner Discount/Coupons Save


export const ownerCouponsCreate = (couponsData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_COUPONS_CREATE_REQUEST",
    });

    const { data } = await api.post("/owner/discount", couponsData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "OWNER_COUPONS_CREATE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OWNER_COUPONS_CREATE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Owner Get All Coupons

export const ownerGetAllCoupons = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_ALL_COUPONS_REQUEST"
    })

    const { data } = await api.get('/owner/discount/list')

    dispatch({
      type: "GET_ALL_COUPONS_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_ALL_COUPONS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Owner Update Coupons

export const ownerUpdateCoupons = (coupons_id: any, couponsData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_UPDATE_COUPONS_REQUEST"
    })

    const { data } = await api.put(`/owner/discount/${coupons_id}`, couponsData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "OWNER_UPDATE_COUPONS_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "OWNER_UPDATE_COUPONS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Owner Coupons Deleted

export const ownerCouponsDeleted = (coupons_id: string) => async (dispatch: any) => {

  try {
    dispatch({
      type: "OWNER_COUPONS_DELETE_REQUEST",
    });

    const { data } = await api.delete(`/owner/discount/${coupons_id}`);

    dispatch({
      type: "OWNER_COUPONS_DELETE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "OWNER_COUPONS_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// --------- EVENTS ------------- 

export const ownerEventCreate = (eventsData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_EVENT_CREATE_REQUEST",
    });

    console.log(CurrentUserInfo().roles[0])

    let d;
    if (CurrentUserInfo().roles[0] === 'admin') {
      d = await api.post("/admin/events", eventsData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getToken()
        }
      });
    }
    else {
      d = await api.post("/owner/events", eventsData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getToken()
        }
      });
    }

    dispatch({
      type: "OWNER_EVENT_CREATE_SUCCESS",
      payload: d.data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OWNER_EVENT_CREATE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ------- event get -------- 

export const ownerGetAllEvents = (placeQuery: any, objData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_ALL_EVENTS_REQUEST"
    })

    let d;
    if (CurrentUserInfo().roles[0] === 'admin') {
      console.log(objData)
      if(objData){
        d = await api.get(`/admin/events/list?place_id=${placeQuery}&${objData.queryName}=${objData.queryValue}`)
      }
      else {
        d = await api.get(`/admin/events/list?place_id=${placeQuery}`)
      }
    }
    else {
      d = await api.get('/owner/events/list')
    }

    dispatch({
      type: "GET_ALL_EVENTS_SUCCESS",
      payload: d.data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_ALL_EVENTS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// -------- event update --------

export const ownerUpdateEvent = (event_id: any, eventData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_UPDATE_EVENT_REQUEST"
    })

    let d;
    if (CurrentUserInfo().roles[0] === 'admin') {
      d = await api.put(`/admin/event/${event_id}`, eventData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getToken()
        }
      });
    }
    else {
      d = await api.put(`/owner/event/${event_id}`, eventData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getToken()
        }
      });
    }

    dispatch({
      type: "OWNER_UPDATE_EVENT_SUCCESS",
      payload: d.data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "OWNER_UPDATE_EVENT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Owner Events Deleted

export const ownerEventDeleted = (coupons_id: string) => async (dispatch: any) => {

  try {
    dispatch({
      type: "OWNER_EVENTS_DELETE_REQUEST",
    });

    let d;
    if (CurrentUserInfo().roles[0] === 'admin') {
      d = await api.delete(`/admin/event/${coupons_id}`)
    }
    else {
      d = await api.delete(`/owner/event/${coupons_id}`)
    }

    dispatch({
      type: "OWNER_EVENTS_DELETE_SUCCESS",
      payload: d.data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "OWNER_EVENTS_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Owner Enquiries

export const OwnerEnquiries = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_OWNER_ENQUIRY_REQUEST"
    })

    const { data } = await api.get('/owner/enquiry/list')

    dispatch({
      type: "GET_OWNER_ENQUIRY_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_OWNER_ENQUIRY_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}


// ------- get Home Event -------- 

export const ownerGetAllHomeEvents = (search: any, start_date: any, coordinate: any, place: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_ALL_HOME_EVENTS_REQUEST"
    })

    let d;
    if (!coordinate) {
      d = await axios.get(`/events/list?search=${search}&start_date=${start_date}&showPlace=${place}`)
    }
    else {
      d = await axios.get(`/events/list?search=${search}&start_date=${start_date}&location=${coordinate.lat},${coordinate.lng}&showPlace=${place}`)
    }

    dispatch({
      type: "GET_ALL_HOME_EVENTS_SUCCESS",
      payload: d.data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_ALL_HOME_EVENTS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}


// ============== OWNER PACKAGE =====================

export const ownerGetPackagesList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_GET_PACKAGES_REQUEST",
    });

    const { data } = await api.get(`/owner/packages`);

    dispatch({
      type: "OWNER_GET_PACKAGES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "OWNER_GET_PACKAGES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const ownerSubscription = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "OWNER_GET_SUBSCRIPTION_REQUEST",
    });

    const { data } = await api.get(`/owner/subscription/package`);

    dispatch({
      type: "OWNER_GET_SUBSCRIPTION_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "OWNER_GET_SUBSCRIPTION_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// OPERATOR GET PACKAGES LIST 

export const operatorGetPackagesList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "OPERATOR_GET_PACKAGES_REQUEST",
    });

    const { data } = await api.get(`/operator/packages/list`);

    dispatch({
      type: "OPERATOR_GET_PACKAGES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "OPERATOR_GET_PACKAGES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};