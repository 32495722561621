import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Spinner } from "react-bootstrap";
import { errorNotify, successNotify } from '../../../../Utils/toast';
import api from "../../../../Utils/Interceptors";
import { getRefreshToken, updateLocalAccessToken } from '../../../../Utils/helper';
import instance from '../../../../Utils/Interceptors';
import { useNavigate } from 'react-router-dom';

const PaymentModal: any = ({ packageId }: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoader(true)

        if (!stripe || !elements) {
            setLoader(false)
            return;
        }
        const cardElement = elements.getElement(CardElement)!
        const cardDetail = await stripe.createToken(cardElement)

        const cardObj: any = {
            card: cardDetail?.token?.card,
            id: cardDetail?.token?.id
        }
        // let priceId = '63b411dc812f248a45b80064';

        const subsData: any = new FormData();
        subsData.append("package_id", packageId);
        subsData.append("token", JSON.stringify(cardObj));

        for (let value of subsData) {
            console.log(value[1])
        }

        if (cardDetail.error) {
            setLoader(false)
            errorNotify(cardDetail.error.message!)
        }

        else {
            api.post("/subscription", subsData).then(async (res: any) => {

                if (res.data.status === 1) {

                    const rs = await instance.post('/auth/refreshtoken', {
                        refreshToken: getRefreshToken(),
                    });

                    const { accessToken } = rs?.data?.data;

                    if (rs?.data?.status === 1) {
                        successNotify("Payment done Successfully we are redirecting you to the dashboard")
                        setLoader(false)
                        updateLocalAccessToken(accessToken)
                        navigate("/owner/dashboard")
                    }
                }

            }).catch((e: any) => {
                setLoader(false)
                console.log(e)
            })
        }
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group>
                <Form.Label style={{ textAlign: 'left', display: "flex", fontSize: "20px", fontWeight: "600", marginBottom: "30px" }}>Please Enter Card Details for Payment</Form.Label>
                <CardElement
                    className={"mb-3"}
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '23px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <div className='text-end'>
                    <button type={'submit'} className="create_btn mt-3">
                        {loader ? <Spinner animation={"border"} size="sm" /> : 'Pay NOW'}
                    </button>
                </div>
            </Form.Group>
        </Form>
    );
};

export default PaymentModal;
