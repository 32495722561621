import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Container, Form, Spinner } from "react-bootstrap";
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./Checkout.css";
import { DecreaseQuantity, IncreaseQuantity, DeleteCart, createCheckout } from "../../../../../Redux/Action/Cart";
import { useForm, SubmitHandler } from "react-hook-form";
import Modal from "../../../../../Components/Modal/Modal";
import { checkoutValidation } from "../../../../../Utils/Validations";
import { errorNotify } from "../../../../../Utils/toast";
import { CheckoutInterface } from "../../../../../Utils/Interfaces";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";
import { AppDispatch } from "../../../../../Redux/Store";
import axios from "axios";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  let userAuth = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

  console.log(userAuth)

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [allCountries, setAllCountries] = useState<any>([])
  const [getCheckoutData, setGetCheckoutData] = useState({})
  const [getCountry, setGetCountry] = useState<any>({
    id: "",
    name: ""
  })
  const [getShippingCountry, setGetShippingCountry] = useState<any>({
    id: "",
    name: ""
  })

  const [allStates, setAllStates] = useState<any>([])
  const [allShippingStates, setAllShippingStates] = useState<any>([])
  const [getState, setGetState] = useState<any>({
    id: "",
    name: ""
  })
  const [getShippingState, setGetShippingState] = useState<any>({
    id: "",
    name: ""
  })

  const [allCities, setAllCities] = useState([])
  const [allShippingCities, setAllShippingCities] = useState([])
  const [getCity, setGetCity] = useState("")
  const [getShippingCity, setGetShippingCity] = useState("")

  const { register, handleSubmit, formState: { errors } } = useForm<CheckoutInterface>();

  const { Carts } = useSelector((state: any) => state.cart);
  const { loading, checkoutData } = useSelector((state: any) => state.createCheckout);

  // console.log(checkoutData)

  useEffect(() => {

    if (checkoutData?.status === 1) {
      navigate("/order-confirmation", { state: checkoutData?.data?.order_id })

      dispatch({ type: "CREATE_CHECKOUT_RESET" })

    }

  }, [checkoutData?.status === 1])


  const Increase = (data: number) => {
    dispatch(IncreaseQuantity(data));
  };

  const Decrease = (data: number) => {
    dispatch(DecreaseQuantity(data));
  };

  const deleteCartHandler = (data: number) => {
    dispatch(DeleteCart(data));
  };

  let ListCart: any = [];
  let TotalCart = 0;

  Object.keys(Carts).forEach(function (item) {
    TotalCart += Carts[item].quantity * Carts[item].price;
    ListCart.push(Carts[item]);
  });

  const checkoutHandler: SubmitHandler<CheckoutInterface> = (data) => {

    if (Carts.length >= 1) {
      if (checked) {

        if (getCountry.name.length === 0 || getState.name.length === 0 || getCity.length === 0) {
          errorNotify("Please Select All Field")
        }
        else {

          const fileData: any = new FormData();
          fileData.append("billingName", data.name);
          fileData.append("billingEmail", data.email);
          fileData.append("billingPhone", data.phoneNumber);
          fileData.append("billingCountry", getCountry.name);
          fileData.append("billingState", getState.name);
          fileData.append("billingCity", getCity);
          fileData.append("billingAddress", data.address);
          fileData.append("billingZipCode", data.zip);

          fileData.append("shippingName", data.name);
          fileData.append("shippingEmail", data.email);
          fileData.append("shippingPhone", data.phoneNumber);
          fileData.append("shippingCountry", getCountry.name);
          fileData.append("shippingState", getState.name);
          fileData.append("shippingCity", getCity);
          fileData.append("shippingAddress", data.address);
          fileData.append("shippingZipCode", data.zip);

          fileData.append("TotalPrice", TotalCart)

          fileData.append("cartData", JSON.stringify(ListCart));

          const userFound = localStorage.getItem("User");

          if (userFound) {
            if (userAuth.roles[0] === 'customer') {
              setGetCheckoutData(fileData)
              dispatch(createCheckout(fileData))
            }
            else {
              errorNotify("Only Customer can Order")
            }
            // api 
          }
          else {
            setShow(!show);
          }

        }
      }
      else {

        if (getCountry.name.length === 0 || getState.name.length === 0 || getCity.length === 0 ||
          getShippingCountry.name.length === 0 || getShippingState.name.length === 0 || getShippingCity.length === 0
        ) {
          errorNotify("Please Select All Fields")
        }
        else {
          const fileData: any = new FormData();

          fileData.append("billingName", data.name);
          fileData.append("billingEmail", data.email);
          fileData.append("billingPhone", data.phoneNumber);
          fileData.append("billingCountry", getCountry.name);
          fileData.append("billingState", getState.name);
          fileData.append("billingCity", getCity);
          fileData.append("billingAddress", data.address);
          fileData.append("billingZipCode", data.zip);

          fileData.append("shippingName", data.shippingName);
          fileData.append("shippingEmail", data.shippingEmail);
          fileData.append("shippingPhone", data.shippingPhone);
          fileData.append("shippingCountry", getShippingCountry.name);
          fileData.append("shippingState", getShippingState.name);
          fileData.append("shippingCity", getShippingCity);
          fileData.append("shippingAddress", data.shippingAddress);
          fileData.append("shippingZipCode", data.shippingZip);
          fileData.append("TotalPrice", TotalCart)

          fileData.append("cartData", JSON.stringify(ListCart));

          const userFound = localStorage.getItem("User");

          if (userFound) {
            // api 
            if (userAuth.roles[0] === 'customer') {
              setGetCheckoutData(fileData)
              dispatch(createCheckout(fileData))
            }
            else {
              errorNotify("Only Customer can Order")
            }
          }

          else {
            setShow(!show);
          }
        }
      }
    }
    else {
      errorNotify("Your Cart is empty");
    }

  };

  const checkoutLogin = () => {
    localStorage.setItem("checkout", "yes");
    navigate("/login");
  };

  useEffect(() => {
    if (ListCart.length === 0) {
      navigate("/");
    }
  }, [ListCart]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get('/get_countries');

      if (data.status === 1) {
        setAllCountries(data?.data)
      }
    }

    fetchData()
      .catch(console.error);
  }, [])

  const countryHandler = async (e: any) => {

    if (e.target.name === "shipping") {
      setGetShippingCountry({
        id: allCountries[e.target.value]._id,
        name: allCountries[e.target.value].name
      })

      const { data } = await axios.get(`/get_states/${allCountries[e.target.value]._id}`);

      if (data.status === 1) {
        setAllShippingStates(data?.data)
      }

    }
    else {

      setGetCountry({
        id: allCountries[e.target.value]._id,
        name: allCountries[e.target.value].name
      })

      const { data } = await axios.get(`/get_states/${allCountries[e.target.value]._id}`);

      if (data.status === 1) {
        setAllStates(data?.data)
      }
    }

  }

  const stateHandler = async (e: any) => {

    // shippingState

    if (e.target.name === "shippingState") {
      setGetShippingState({
        id: allShippingStates[e.target.value]._id,
        name: allShippingStates[e.target.value].name
      })

      const { data } = await axios.get(`/get_cities/${getShippingCountry.id}/${allShippingStates[e.target.value]._id}`);

      if (data.status === 1) {
        setAllShippingCities(data?.data)
      }
    }

    else {

      setGetState({
        id: allStates[e.target.value]._id,
        name: allStates[e.target.value].name
      })

      const { data } = await axios.get(`/get_cities/${getCountry.id}/${allStates[e.target.value]._id}`);

      if (data.status === 1) {
        setAllCities(data?.data)
      }
    }

  }

  const cityHandler = (e: any) => {

    if (e.target.name === "shippingCity") {
      setGetShippingCity(e.target.value)
    }
    else {
      setGetCity(e.target.value)
    }
  }

  return (
    <div className="checkout_section">
      <Modal
        title="Login"
        show={show}
        size={"lg"}
        onCloseModal={() => setShow(!show)}
        showIcon={true}
      >
        <div>
          <h3>You Need to Login First</h3>
          <button className="sign_btn" onClick={checkoutLogin}>
            Login
          </button>
        </div>
      </Modal>

      <Container>
        <Row>
          <Col md={12}>
            <div className="main_checkout">
              <h5>Checkout</h5>

              <Form onSubmit={handleSubmit(checkoutHandler)}>
                <div className="checkout_text_section">
                  <Row>
                    <Col md={7}>
                      <div className="billing_details">
                        <h2>Billing Address</h2>

                        <Row className="mt-2 form_inputs">
                          <Col md={12} className={"mb-3"}>
                            <input
                              placeholder=" Full Name"
                              {...register(
                                "name",
                                checkoutValidation.firstName
                              )}
                            />
                            {errors.name && (
                              <span className="error_msg">
                                {errors?.name?.message}
                              </span>
                            )}
                          </Col>
                          <Col md={6} className={"mb-3"}>
                            <input
                              placeholder=" Email Address"
                              {...register("email", checkoutValidation.email)}
                            />
                            {errors.email && (
                              <span className="error_msg">
                                {errors?.email?.message}
                              </span>
                            )}
                          </Col>
                          <Col md={6} className={"mb-3"}>
                            <input
                              placeholder=" phone number"
                              {...register(
                                "phoneNumber",
                                checkoutValidation.phoneNumber
                              )}
                            />
                            {errors.phoneNumber && (
                              <span className="error_msg">
                                {errors?.phoneNumber?.message}
                              </span>
                            )}
                          </Col>
                          <Col md={6} className={"mb-3"}>
                            <select
                              name="country"
                              onChange={countryHandler}
                              className={'select_div'}
                            >
                              <option value="">Please Select Country</option>
                              {
                                allCountries?.map((c: any, index: number) => {
                                  return <option value={index}>{c.name}</option>
                                })
                              }
                            </select>
                          </Col>
                          <Col md={6} className={"mb-3"}>

                            <select
                              name="state"
                              onChange={stateHandler}
                              className={'select_div'}
                            >
                              <option value="">Please Select State</option>
                              {
                                allStates?.map((c: any, index: number) => {
                                  return <option value={index}>{c.name}</option>
                                })
                              }
                            </select>
                          </Col>
                          <Col md={6} className={"mb-3"}>

                            <select
                              name="city"
                              onChange={(e) => setGetCity(e.target.value)}
                              className={'select_div'}
                            >
                              <option value="">Please Select City</option>
                              {
                                allCities?.map((c: any) => {
                                  return <option value={c.name}>{c.name}</option>
                                })
                              }
                            </select>
                          </Col>
                          <Col md={6} className={"mb-3"}>
                            <input
                              placeholder="Zip"
                              {...register("zip", checkoutValidation.city)}
                            />
                            {errors.zip && (
                              <span className="error_msg">
                                {errors?.zip?.message}
                              </span>
                            )}
                          </Col>
                          <Col md={12} className={"mb-3"}>
                            <input
                              placeholder="Address"
                              {...register(
                                "address",
                                checkoutValidation.address
                              )}
                            />
                            {errors.address && (
                              <span className="error_msg">
                                {errors?.address?.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div className="billing_details">
                        <div className="d-flex">
                          <h2>Shipping Address</h2>
                          <div>
                            <label className="checkbox_container mt-0 mx-3">
                              Same as Billing?
                              <input
                                type="checkbox"
                                onChange={() => setChecked(!checked)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        {!checked && (
                          <Row className="mt-2 form_inputs">
                            <Col md={12} className={"mb-3"}>
                              <input
                                placeholder=" Full Name"
                                {...register(
                                  "shippingName",
                                  checkoutValidation.firstName
                                )}
                              />

                              {errors.shippingName && (
                                <span className="error_msg">
                                  {errors?.shippingName?.message}
                                </span>
                              )}
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <input
                                placeholder=" Email Address"
                                {...register("shippingEmail", checkoutValidation.email)}
                              />
                              {errors.shippingEmail && (
                                <span className="error_msg">
                                  {errors?.shippingEmail?.message}
                                </span>
                              )}
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <input
                                placeholder=" phone number"
                                {...register(
                                  "shippingPhone",
                                  checkoutValidation.phoneNumber
                                )}
                              />
                              {errors.shippingPhone && (
                                <span className="error_msg">
                                  {errors?.shippingPhone?.message}
                                </span>
                              )}
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <select
                                name="shipping"
                                onChange={countryHandler}
                                className={'select_div'}
                              >
                                <option value="">Please Select Country</option>
                                {
                                  allCountries?.map((c: any, index: number) => {
                                    return <option value={index}>{c.name}</option>
                                  })
                                }
                              </select>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <select
                                name="shippingState"
                                onChange={stateHandler}
                                className={'select_div'}
                              >
                                <option value="">Please Select State</option>
                                {
                                  allShippingStates?.map((c: any, index: number) => {
                                    return <option value={index}>{c.name}</option>
                                  })
                                }
                              </select>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <select
                                name="shippingCity"
                                onChange={cityHandler}
                                className={'select_div'}
                              >
                                <option value="">Please Select City</option>
                                {
                                  allShippingCities?.map((c: any) => {
                                    return <option value={c.name}>{c.name}</option>
                                  })
                                }
                              </select>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                              <input
                                placeholder="Zip"
                                {...register("shippingZip", checkoutValidation.city)}
                              />
                              {errors.shippingZip && (
                                <span className="error_msg">
                                  {errors?.shippingZip?.message}
                                </span>
                              )}
                            </Col>
                            <Col md={12} className={"mb-3"}>
                              <input
                                placeholder="Address"
                                {...register(
                                  "shippingAddress",
                                  checkoutValidation.address
                                )}
                              />
                              {errors.shippingAddress && (
                                <span className="error_msg">
                                  {errors?.shippingAddress?.message}
                                </span>
                              )}
                            </Col>

                            <Col md={12} className={"mb-3"}>
                              <div></div>
                            </Col>
                          </Row>
                        )}
                      </div>

                    </Col>
                    <Col md={5} className={"checkout_products"}>
                      <div>
                        <h6>order summary</h6>
                        {ListCart.length > 0 ? (
                          <div>
                            {ListCart.map((cart: any, index: any) => {
                              return (
                                <div className="product_detail" key={index}>
                                  <img src={`${cloudURL}${MediaPath.product_image}${cart?.images[0]}`} alt="" />

                                  <div>
                                    <p>{cart?.title}</p>
                                    <div className="product_quantity">
                                      <AiOutlinePlus
                                        onClick={() => Increase(index)}
                                      />
                                      <span>{cart.quantity}</span>
                                      <AiOutlineMinus
                                        onClick={() => Decrease(index)}
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <p>${(cart.price * cart.quantity).toFixed(2)}</p>
                                  </div>

                                  <div>
                                    <AiFillDelete
                                      onClick={() => deleteCartHandler(index)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="text-center my-2">No Data Found</p>
                        )}
                      </div>

                      <div className="main_total">
                        <h3>Total</h3>
                        <p>$ {TotalCart.toFixed(2)}</p>
                      </div>

                      <div className="place_order">
                        <button type="submit"> {loading ? <Spinner animation="border" size="sm" /> : "Order Confirmation"} </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Checkout;
