import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import PaymentModal from "../CreateShop/PaymentModal/PaymentModal";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import "./PaymentMethod.css";
import { useLocation } from "react-router-dom";

const PaymentMethod = () => {
  const location = useLocation();

  const stripePromise = loadStripe('pk_test_sQRyFOwBtUzkyRV3OfD7MoRy005PT3uIBO'!);

  let currentPlan = location?.state?.plan ? location?.state?.plan : null;

  console.log(currentPlan)

  return (
    <div className="payment_method_bg">
      <Container>
        <Row>
          <Col md={12} className="product_cover_img_container">
            <img src="/images/payment-cover.png" alt="shop_view" />
          </Col>
        </Row>
      </Container>

      <Container className="payment_details">
        <h1>Payment Method</h1>
        <Row>
          <Col md={8}>
            <div className="payment_card">
              <h5>PAYMENT</h5>

              <Elements stripe={stripePromise}>
                <PaymentModal packageId={currentPlan?._id} />
              </Elements>

            </div>
          </Col>

          <Col md={4}>
            <div className="selected_package">
              <h4>Selected Package</h4>

              <div className="package_text">
                <p>{currentPlan?.name}</p>

                {/* <ul>
                  <li>- 1 Month Expiration Date</li>
                  <li>- Multiple Place Listing</li>
                  <li>- Ability to upload Video</li>
                  <li>- Ability to upload Banner Image</li>
                  <li>- Ability to upload Menu</li>
                </ul> */}

                <div className="total_div">
                  <h5>Total</h5>
                  <h6>${currentPlan?.price}/-</h6>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PaymentMethod;