import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ownerDashboard } from "../../../../Redux/Action/Owner";
import { AppDispatch } from "../../../../Redux/Store";
import { CurrentUserInfo } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";

const OwnerMain = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, owner } = useSelector((state: any) => state.OwnerDashboard);

  useEffect(() => {
    dispatch(ownerDashboard())
  }, [])

  const cardHandler = () => {
    navigate("/owner/payment-method")
  }

  return (
    <>
      {
        !props.loading &&
        <>
          {props.plan === "free" && (
            <div className="upgrade">
              <h1>
                <span>Upgrade your Membership</span> <br /> then your shop  will be <span>Active</span>
              </h1>
              <button onClick={cardHandler}>
                upgrade now
              </button>
            </div>
          )}
        </>
      }


      {
        loading ? <div
          style={{ paddingBottom: "50px", height: '50vh' }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Loader />
        </div>
          :
          <Row className="main_boxes">
            <div> <h2>Welcome {CurrentUserInfo()?.first_name}!</h2></div>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>active place</li>
                </ul>
                <h5>{owner[0]?.active_places}</h5>
              </div>
            </Col>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>total reviews</li>
                </ul>
                <h5>{owner[0]?.total_reviews}</h5>
              </div>
            </Col>
            <Col md={4}>
              <div className="main_box">
                <ul>
                  <li>total enquiry</li>
                </ul>
                <h5>{owner[0]?.total_enquiries}</h5>
              </div>
            </Col>
          </Row>
      }
    </>
  );
};

export default OwnerMain;
