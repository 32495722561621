import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker } from "@react-google-maps/api";

const libraries: any = ['places'];

const ShopMapLocation = ({ markers }: any) => {

    const [activeMarker, setActiveMarker] = useState<any>(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAFsEP9H8HabaWXDeZzpZZ7-7JuVnXv2yk',
        libraries,
    });

    if (!isLoaded) {
        return <p>...Loading</p>;
    }

    const handleActiveMarker = (marker: any) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map: any) => {
        const bounds = new google.maps.LatLngBounds();
        markers?.forEach(({ position }: any) => bounds.extend(position));
        map.fitBounds(bounds);
    };


    return (
        <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            zoom={15}
            mapContainerStyle={{
                width: "100%",
                height: "500px",
                marginTop: "10px",
            }}
        >
            {markers?.map(({ id, name, position }: any) => (
                <Marker
                    key={id}
                    position={position}
                    onClick={() => handleActiveMarker(id)}
                >
                    {activeMarker === id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>{name}</div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </GoogleMap>
    )
}

export default ShopMapLocation