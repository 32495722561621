import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Enquiry from "../Enquiry/Enquiry";
import OwnerMain from "../OwnerMain/OwnerMain";
import Places from "../Places/Places";
import Profile from "../../../Customer/Pages/Profile/Profile";
import Coupons from "../Coupons/Coupons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { getOwnerPlaceListings, ownerSubscription } from '../../../../Redux/Action/Owner';
import { getUserDetails } from "../../../../Redux/Action/Auth";
import "./Dashboard.css";
import Events from "../Events/Events";

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userDetail, loading } = useSelector((state: any) => state.userDetail);
  const { subscriptionGet, loading: subLoading } = useSelector((state: any) => state.ownerSubscriptions);
  const [tab, setTab] = useState<any>("1");

  console.log(subscriptionGet)

  useEffect(() => {
    dispatch(getUserDetails())
    dispatch(getOwnerPlaceListings())
    dispatch(ownerSubscription())
  }, [dispatch])

  const switchPage = {
    main: <OwnerMain plan={subscriptionGet?.name} loading={loading} />,
    profile: <Profile />,
    places: <Places />,
    enquiry: <Enquiry />,
    coupons: <Coupons />,
    events: <Events />
  };

  useEffect(() => {
    setTab(
      localStorage.getItem("tab")
        ? JSON.parse(localStorage.getItem("tab") || "")
        : "1"
    );
  }, []);

  const changeTab = (value: string) => {
    setTab(value);
    localStorage.setItem("tab", JSON.stringify(value));
  };

  let showPage;
  if (tab === "1") {
    showPage = <div>{switchPage.main}</div>;
  } else if (tab === "2") {
    showPage = <div>{switchPage.profile}</div>;
  } else if (tab === "3") {
    showPage = <div>{switchPage.places}</div>;
  } else if (tab === "4") {
    showPage = <div>{switchPage.enquiry}</div>;
  }
  else if (tab === "5") {
    showPage = <div>{switchPage.coupons}</div>;
  }
  else if (tab === "6") {
    showPage = <div>{switchPage.events}</div>
  }
  else {
    showPage = <div>{switchPage.main}</div>;
  }

  return (
    <div style={{ backgroundColor: "#f4faff" }}>
      <div className="owners_tab">
        <Container>
          <ul>
            <li
              className={tab === "1" ? "active" : ""}
              onClick={() => changeTab("1")}
            >
              Dashboard
            </li>
            <li
              className={tab === "2" ? "active" : ""}
              onClick={() => changeTab("2")}
            >
              Profile
            </li>
            <li
              className={tab === "3" ? "active" : ""}
              onClick={() => changeTab("3")}
            >
              My Places
            </li>
            <li
              className={tab === "4" ? "active" : ""}
              onClick={() => changeTab("4")}
            >
              Enquire
            </li>
            <li
              className={tab === "5" ? "active" : ""}
              onClick={() => changeTab("5")}
            >
              Coupons
            </li>
            <li
              className={tab === "6" ? "active" : ""}
              onClick={() => changeTab("6")}
            >
              Events
            </li>
          </ul>
        </Container>
      </div>

      <Container>{showPage}</Container>
    </div>
  );
};

export default Dashboard;
