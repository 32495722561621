import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { adminCreateAmenity, adminGetAmenityDetail, adminUpdateAmenity } from "../../../../../Redux/Action/Admin";
import { AmenityInput } from "../../../../../Utils/Interfaces";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/Store";
import { productValidation } from "../../../../../Utils/Validations";
import Loader from "../../../../../Utils/Loader";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";

const CreateAmenities = () => {
    const navigate = useNavigate();

    const [images, setImages] = useState<any | null>(null);
    const [preview, setPreview] = useState<any>(null);
    const { id } = useParams();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<AmenityInput>();

    const dispatch: AppDispatch = useDispatch();
    const { loading, amenityDetail } = useSelector((state: any) => state.adminGetAmenity);
    const { loading: createLoading, amenityData } = useSelector((state: any) => state.adminCreateAmenity);
    const { loading: updateLoading, updateAmenityData } = useSelector((state: any) => state.adminUpdateAmenity);

    useEffect(() => {
        if (amenityData?.status === 1) {
            navigate("/admin/amenities")
            successNotify("Amenity Created Successfully")
            dispatch({ type: "ADMIN_CREATE_AMENITY_RESET" })
        }
    }, [amenityData?.status, dispatch, navigate])

    useEffect(() => {
        if (updateAmenityData?.status === 1) {
            navigate("/admin/amenities")
            successNotify("Amenity Updated Successfully")
            dispatch({ type: "ADMIN_UPDATE_AMENITY_RESET" })
        }
    }, [dispatch, navigate, updateAmenityData?.status])

    useEffect(() => {
        if (amenityDetail?.status === 1) {
            setValue("name", amenityDetail?.data[0]?.name)
            setValue("category", amenityDetail?.data[0]?.category.name)
            setPreview(`${cloudURL}${MediaPath.amenity}${amenityDetail?.data[0]?.picture}`)
        }
    }, [amenityDetail?.data, amenityDetail?.status, setValue])

    useEffect(() => {
        if (id) {
            dispatch(adminGetAmenityDetail(id))
        }
    }, [id, dispatch])

    const handleImagesChange = (e: any) => {
        setImages(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]))
    };

    const productHandler: SubmitHandler<AmenityInput> = (data) => {

        if (id) {
            const fileData: any = new FormData();
            fileData.append('name', data?.name);
            fileData.append('category', data?.category);
            fileData.append("amenity_image", images);

            dispatch(adminUpdateAmenity(id, fileData))

        }
        else {

            if (!images) {
                errorNotify("Please select Amenity image")
            }

            else {
                const fileData: any = new FormData();
                fileData.append('name', data?.name);
                fileData.append('category', data?.category);
                fileData.append("amenity_image", images);

                dispatch(adminCreateAmenity(fileData))
            }
        }

    };

    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id ? 'Edit Amenity' : 'Create Amenity'}</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/amenities")}
                >
                    Back
                </button>
            </div>

            {
                loading ?
                    <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                    <Form onSubmit={handleSubmit(productHandler)}>
                        <Row className="login_right_section">
                            <Col md={6} className="py-2">
                                <label>Amenity Name</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Amenity Name"
                                        {...register("name", productValidation.productName)}
                                    />
                                </div>
                                {errors.name && (
                                    <span className="error_msg">{errors?.name?.message}</span>
                                )}
                            </Col>
                            <Col md={6} className="py-2">
                                <label>Category</label>
                                <div className="signup_input">
                                    <img src="/images/AccountIcon.png" alt="" />
                                    <input
                                        placeholder="Enter Category"
                                        {...register("category", productValidation.productPrice)}
                                    />
                                </div>
                                {errors.category && (
                                    <span className="error_msg">{errors?.category?.message}</span>
                                )}
                            </Col>

                            <Col md={5} className="thumb_image_container">
                                <label>Amenity Image</label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="amenity_image"
                                    name="amenity_image"
                                    onChange={handleImagesChange}
                                />
                                <div className="thumb_image">
                                    <label htmlFor="amenity_image">
                                        <img
                                            src={
                                                preview ?
                                                    preview
                                                    : "/images/previewImg.png"
                                            }
                                            alt=""
                                        />
                                    </label>
                                </div>
                                <p className="text-muted">image size must be 20x20</p>
                            </Col>

                            <Col md={12} className="pb-5">
                                <div className="text-end">
                                    {
                                        id ?
                                            <button type="submit" className="back_btn">
                                                {updateLoading ? <Spinner animation="border" size="sm" /> : "Edit"}
                                            </button>
                                            :
                                            <button type="submit" className="back_btn">
                                                {createLoading ? <Spinner animation="border" size="sm" /> : "Create"}
                                            </button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    );
};

export default CreateAmenities;
