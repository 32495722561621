import axios from "axios";
import api from "../../Utils/Interceptors";

export const getAllProducts = (keyword: any, sku: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_ALL_PRODUCTS_REQUEST",
    });

    const { data } = await axios.get(`/shop/product/list?search=${keyword}&sku=${sku}`);

    dispatch({
      type: "GET_ALL_PRODUCTS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "GET_ALL_PRODUCTS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN GET PRODUCT DETAIL 

export const GetProductDetail = (product_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_PRODUCT_DETAIL_REQUEST",
    });

    const { data } = await axios.get(`/shop/product/${product_id}`);

    dispatch({
      type: "GET_PRODUCT_DETAIL_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "GET_PRODUCT_DETAIL_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const addToCart = (product: any) => {
  return {
    type: "ADD_TO_CART",
    payload: product,
  };
};

export function UpdateCart(product: any) {
  return {
    type: 'UPDATE_CART',
    payload: product
  }
}
export function DeleteCart(product: any) {
  return {
    type: 'DELETE_CART',
    payload: product
  }
}
export function IncreaseQuantity(product: any) {
  return {
    type: 'INCREASE_QUANTITY',
    payload: product
  }
}
export function DecreaseQuantity(product: any) {
  return {
    type: 'DECREASE_QUANTITY',
    payload: product
  }
}

export const createCheckout = (product: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "CREATE_CHECKOUT_REQUEST",
    });

    const { data } = await api.post('shop/checkout/', product);

    dispatch({
      type: "CREATE_CHECKOUT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "CREATE_CHECKOUT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};