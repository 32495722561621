import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Accordion } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Map from "../../../Components/Map/Map";
import "./createShop.css";
import { placeValidation } from "../../../Utils/Validations";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { ICoordinates } from "../../../Utils/Interfaces";
import { shopRegistration } from "../../../Utils/Interfaces";
import { ownerPlaceCreate } from '../../../Redux/Action/Owner'
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/Store";
import { errorNotify, successNotify } from "../../../Utils/toast";
import Loader from "../../../Utils/Loader";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { getUserDetails } from "../../../Redux/Action/Auth";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import Cropper from "react-cropper";
import { TextField } from "@mui/material";

const CreateShop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch()
  const { error, loading, ownerPlaces } = useSelector((state: any) => state.OwnerCreatePlace);
  const { loading: listLoading, shopTypes } = useSelector((state: any) => state.getShop);

  const [social, setSocial] = useState<any>([]);
  const [inputField, setInputField] = useState({
    platform: "",
    link: "https://",
  });

  const [frequently, setFrequently] = useState({
    question: "",
    answer: "",
  });
  const [quesAns, setQuesAns] = useState<any>([]);

  let currentPlan = location?.state?.plan ? location?.state?.plan : "premium";

  console.log(currentPlan)

  const generalRef = useRef(null);
  const amenitiesRef = useRef(null);
  const locationRef = useRef(null);
  const contactInfoRef = useRef(null);
  const socialNetworkRef = useRef(null);
  const mediaRef = useRef(null);
  const timingRef = useRef(null);
  const videoRef = useRef(null);
  const bannerRef = useRef(null);
  const menuRef = useRef(null);
  const questionRef = useRef(null);

  const [selectedImages, setSelectedImages] = useState([])

  const [active, setActive] = useState<number>(1);
  const [file, setFile] = useState<any>(null);
  const [bannerFile, setBannerFile] = useState<any>(null);
  const [videoFile, setVideoFile] = useState<any>(null)
  const [menuFile, setMenuFile] = useState<any>(null);
  const [images, setImages] = useState<any>([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState<ICoordinates | null>(null);
  const [address, setAddress] = useState(null);
  const [preview1, setPreview1] = useState<any>(null)

  const [preview2, setPreview2] = useState<any>(null)
  const [advertisement, setAdvertisement] = useState<any>(null);

  const [bannerPreview, setBannerPreview] = useState<any>(null)
  const [websiteValue, setWebsiteValue] = useState("")
  const [allCountries, setAllCountries] = useState<any>([])
  const [getCountry, setGetCountry] = useState<any>({
    id: "",
    name: ""
  })

  const [allStates, setAllStates] = useState<any>([])
  const [getState, setGetState] = useState<any>({
    id: "",
    name: ""
  })

  const [allCities, setAllCities] = useState([])
  const [getCity, setGetCity] = useState("")

  const { register, handleSubmit, formState: { errors }, reset } = useForm<shopRegistration>();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get('/get_countries');

      if (data.status === 1) {
        setAllCountries(data?.data)
      }
    }

    fetchData()
      .catch(console.error);
  }, [])

  const { userDetail } = useSelector((state: any) => state.userDetail);

  useEffect(() => {
    dispatch(getUserDetails())
  }, [])

  useEffect(() => {
    if (ownerPlaces?.status === 1) {
      successNotify('Place Created Successfully!');
      reset();
      setBannerPreview('')
      setBannerFile('')
      setImages('')
      setVideoFile('')
      // setPreview('')
      setSelectedImages([])
      setPreview1('')
      setMenuFile('')
      setQuesAns('')
      setAddress(null)
      setFrequently({ question: '', answer: '' })
      dispatch({ type: "OWNER_PLACE_CREATE_RESET" });
      localStorage.removeItem("tab");

      if (currentPlan.price > "0" || currentPlan.price > 0) {
        if (Object.keys(userDetail?.subscription).length > 0) {
          navigate('/owner/dashboard')
        }
        else {
          navigate('/owner/payment-method', {
            state: { plan: currentPlan },
          })
        }

      }
      else {
        navigate('/owner/dashboard')
      }

    }

    if (error) {
      errorNotify(error)
      dispatch({ type: "CLEAR_ERRORS" })
    }

  }, [ownerPlaces, error, dispatch, navigate, reset, currentPlan, userDetail?.subscription])

  const handleTabClick: any = (number: any, name: any) => {
    setActive(number);
    window.scrollTo({
      top: name.offsetTop - 10,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleChangeImage = (e: any) => {

    // const img = new Image();
    // const i = URL.createObjectURL(e.target.files[0])

    // img.onload = function () {
    // var height = img.height;
    // var width = img.width;
    // if (height > 400 || width > 500) {
    // errorNotify("Maximum file size 500x400")
    // return false;
    // }
    setFile(e.target.files[0]);
    setPreview1(URL.createObjectURL(e.target.files[0]))
    // return true;
    // };

  };

  const handleChangeImage2 = (e: any) => {

    setAdvertisement(e.target.files[0]);
    setPreview2(URL.createObjectURL(e.target.files[0]))

  };

  const handleVideo = (e: any) => {
    setVideoFile(e.target.files[0])
  }

  const handleBannerImage = (e: any) => {

    const img = new Image();
    img.src = URL.createObjectURL(e.target.files[0])

    img.onload = function () {

      let height = img.height;
      let width = img.width;
      let aspectRatio = width / height;

      if (aspectRatio <= 2 || width < 1000) {
        errorNotify("Banner Size is not accurate")
        return false;
      }
      setBannerFile(e.target.files[0]);
      setBannerPreview(URL.createObjectURL(e.target.files[0]))
      return true;
    };
  };

  const handleGalleryImagesChange = (e: any) => {

    if (e.target.files.length <= 10) {

      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles)
      setImages((prev: any) => prev.concat(selectedFilesArray))
      const imagesArray: any = selectedFilesArray.map((file: any) => {
        return URL.createObjectURL(file)
      })
      setSelectedImages((previousImage) => previousImage.concat(imagesArray))

    }
    else {
      errorNotify("Maximum 10 Images")
    }

  };

  const socialHandler = (e: any) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
  };

  const countryHandler = async (e: any) => {

    setGetCountry({
      id: allCountries[e.target.value]._id,
      name: allCountries[e.target.value].name
    })

    const { data } = await axios.get(`/get_states/${allCountries[e.target.value]._id}`);

    if (data.status === 1) {
      setAllStates(data?.data)
    }
  }

  const stateHandler = async (e: any) => {
    setGetState({
      id: allStates[e.target.value]._id,
      name: allStates[e.target.value].name
    })

    const { data } = await axios.get(`/get_cities/${getCountry.id}/${allStates[e.target.value]._id}`);

    if (data.status === 1) {
      setAllCities(data?.data)
    }
  }

  const questionHandler = (e: any) => {
    setFrequently({
      ...frequently,
      [e.target.name]: e.target.value,
    });
  };

  const addQuestion = (e: any) => {
    e.preventDefault();

    if (frequently.question.length > 0 && frequently.answer.length) {
      setQuesAns((q: any) => {
        return [...q, frequently];
      });

      setFrequently({
        question: "",
        answer: "",
      });
    }
  };

  const deleteQuestion = (i: number) => {
    setQuesAns(
      quesAns?.filter((data: any, index: number) => {
        return index !== i;
      })
    );
  };

  const addSocial = (e: any) => {
    e.preventDefault();

    let hasValue = inputField.link.split('//')

    if (inputField.platform.length > 0 && hasValue[1].length > 0) {
      let hasHttps = inputField.link.split('//').includes("https:")

      if (hasHttps) {
        setSocial((s: any) => {
          return [...s, inputField];
        });

        setInputField({
          platform: "",
          link: "https://",
        });
      }
      else {
        errorNotify("Social Link must includes https")
      }
    }

  };

  const deleteSocial = (i: number) => {
    setSocial(
      social.filter((data: any, index: number) => {
        return index !== i;
      })
    );
  };

  const formHandler = (data: any) => {

    let business_hours = [];
    for (let i = 0; i < timings.length; i++) {
      if (timings[i].openTime.length !== 0 && timings[i].closeTime.length !== 0) {
        business_hours.push(
          {
            [timings[i].day]: {
              open: new Date(timings[i].openTime).toString().split(" ")[4].slice(0, 5),
              close: new Date(timings[i].closeTime).toString().split(" ")[4].slice(0, 5),
            }
          }
        )
      }
    }

    if (websiteValue.length > 0) {
      let websiteHasHttps = websiteValue.split('/').includes("https:")
      let websiteHasHttp = websiteValue.split('/').includes("http:")

      if ((websiteHasHttps || websiteHasHttp) && selectedCoordinates) {

        const fileData: any = new FormData();
        fileData.append("business_name", data.business_name);
        fileData.append("price_range", data.price_range);
        fileData.append("description", data.description);
        fileData.append("shop_type", data.place_type);
        fileData.append("social_link", JSON.stringify(social));
        if (!data.amenities) {
          fileData.append("amenities", JSON.stringify([]));
        }
        else {
          fileData.append("amenities", JSON.stringify(data.amenities));
        }
        fileData.append("country", getCountry.name);
        fileData.append("state", getState.name);
        fileData.append("city", getCity);
        fileData.append("address", data.address);
        fileData.append("location", `${JSON.stringify(selectedCoordinates?.lat)},${JSON.stringify(selectedCoordinates?.lng)}`);
        fileData.append("email", data.email);
        fileData.append("phone_number", data.phone_number);
        fileData.append("website", websiteValue);
        fileData.append("timings", JSON.stringify(business_hours));
        fileData.append("faqs", JSON.stringify(quesAns));
        fileData.append("menu_image", menuFile)
        fileData.append("advertisment_banner", advertisement)
        fileData.append("image", file);
        [...images].forEach(image => {
          fileData.append("gallery", image);
        });
        fileData.append("banner_image", bannerFile);
        fileData.append("video", videoFile)

        for (let value of fileData) {
          console.log(value[1])
        }

        dispatch(ownerPlaceCreate(fileData))

      }
      else {
        if (!selectedCoordinates) {
          errorNotify("Please Select Location")
        }
        else {
          errorNotify("Website url must have http:// or https://")
        }
      }
    }
    else {

      if (selectedCoordinates) {
        const fileData: any = new FormData();
        fileData.append("business_name", data.business_name);
        fileData.append("price_range", data.price_range);
        fileData.append("description", data.description);
        fileData.append("shop_type", data.place_type);
        fileData.append("social_link", JSON.stringify(social));
        if (!data.amenities) {
          fileData.append("amenities", JSON.stringify([]));
        }
        else {
          fileData.append("amenities", JSON.stringify(data.amenities));
        }
        fileData.append("country", getCountry.name);
        fileData.append("city", getCity);
        fileData.append("state", getState.name);
        fileData.append("address", data.address);
        fileData.append("location", `${JSON.stringify(selectedCoordinates?.lat)},${JSON.stringify(selectedCoordinates?.lng)}`);
        fileData.append("email", data.email);
        fileData.append("phone_number", data.phone_number);
        fileData.append("website", websiteValue);
        fileData.append("timings", JSON.stringify(business_hours));
        fileData.append("faqs", JSON.stringify(quesAns));
        fileData.append("menu_image", menuFile)
        fileData.append("advertisment_banner", advertisement)
        fileData.append("image", file);
        [...images].forEach(image => {
          fileData.append("gallery", image);
        });
        fileData.append("banner_image", bannerFile);
        fileData.append("video", videoFile)

        for (let value of fileData) {
          console.log(value[1])
        }

        dispatch(ownerPlaceCreate(fileData))
      }
      else {
        errorNotify("Please Select Location")
      }

    }
  };

  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

  const [countDay, setCountDay] = useState(0)
  const [timings, setTimings] = useState([{ day: days[countDay], openTime: '', closeTime: '' }]);

  const handleInputChange = (e: any, index: any, name: string) => {
    // let formatedTime = new Date(e).toString().split(" ")[4].slice(0, 5)


    // console.log(new Date(formatedTime).toString())

    const list: any = [...timings];
    list[index][name] = e.toString();

    setTimings(list);
  };

  // handle click event of the Remove button
  // const handleRemoveClick = (index: any) => {
  //   const list = [...timings];
  //   list.splice(index, 1);
  //   setTimings(list);
  // };

  // handle click event of the Add button
  const handleAddClick = () => {

    setCountDay(countDay + 1)
    const lastTiming = timings[timings.length - 1];

    if (lastTiming.openTime.length !== 0 && lastTiming.closeTime.length !== 0) {
      setTimings([
        ...timings,
        { day: days[countDay + 1], openTime: lastTiming.openTime, closeTime: lastTiming.closeTime },
      ]);
    }
    else {
      errorNotify("Open and close time cannot be empty")
    }
  };

  // const [time, setTime] = useState

  const upgradePremium = () => {
    navigate("/owner/create-shop", {
      state: { plan: 'premium' },
    });
    successNotify("upgraded to premium")
  }

  function deleteFile(event: any, index: any) {

    images.splice(index, 1)
    setImages(images)
    setSelectedImages(selectedImages.filter((e: any) => e !== event))
  }


  return (
    <Container>
      {
        loading ?
          <div className="shop_loading">
            <Loader />
            <h5>Please Wait!! We are preparing your Listing...</h5>
          </div>
          :
          <Row className="my-3">
            <Col md={3} className="pt-3 create_shop_right_section">
              <div className="shops_tab_btn">
                <div style={{ backgroundColor: "#F5FAFE" }}>
                  <Button
                    className={`${active === 1 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(1, generalRef.current)}
                  >
                    General
                  </Button>
                  <Button
                    className={`${active === 5 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(5, socialNetworkRef.current)}
                  >
                    Social Network
                  </Button>
                  <Button
                    className={`${active === 2 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(2, amenitiesRef.current)}
                  >
                    Amenities
                  </Button>

                  {!currentPlan.features.allowAddMenu ? (
                    <OverlayTrigger
                      overlay={(props) => (
                        <Tooltip {...props}> For Premium users only!!  <span onClick={upgradePremium}>upgrade from Here</span> </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className={"disable w-100 my-1"}
                        style={{
                          height: "40px",
                          backgroundColor: "transparent",
                          color: "#000",
                        }}
                      >
                        Menu
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      className={`${active === 10 ? "activeTab" : ""} w-100 my-1`}
                      style={{ height: "40px" }}
                      onClick={() => handleTabClick(10, menuRef.current)}
                    >
                      Menu
                    </Button>
                  )}

                  <Button
                    className={`${active === 3 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(3, locationRef.current)}
                  >
                    Location
                  </Button>
                  <Button
                    className={`${active === 4 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(4, contactInfoRef.current)}
                  >
                    Contact Info
                  </Button>
                  <Button
                    className={`${active === 8 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(8, timingRef.current)}
                  >
                    Business Hours
                  </Button>

                  <Button
                    className={`${active === 15 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(15, questionRef.current)}
                  >
                    Frequently Questions
                  </Button>

                  <Button
                    className={`${active === 7 ? "activeTab" : ""} w-100 my-1`}
                    style={{ height: "40px" }}
                    onClick={() => handleTabClick(7, mediaRef.current)}
                  >
                    Media
                  </Button>

                  {!currentPlan.features.allowAddBanner ? (
                    <OverlayTrigger
                      overlay={(props) => (
                        <Tooltip {...props}>For Premium users only!! <span onClick={upgradePremium}>upgrade from Here</span> </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className={"disable w-100 my-1"}
                        style={{
                          height: "40px",
                          backgroundColor: "transparent",
                          color: "#000",
                        }}
                      >
                        Banner Image
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      className={`${active === 11 ? "activeTab" : ""} w-100 my-1`}
                      style={{ height: "40px" }}
                      onClick={() => handleTabClick(11, bannerRef.current)}
                    >
                      Banner Image
                    </Button>
                  )}

                  {!currentPlan.features.allowAddVideo ? (
                    <OverlayTrigger
                      overlay={(props) => (
                        <Tooltip {...props}>For Premium users only!! <span onClick={upgradePremium}>upgrade from Here</span></Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className={"disable w-100 my-1"}
                        style={{
                          height: "40px",
                          backgroundColor: "transparent",
                          color: "#000",
                        }}
                      >
                        Video
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      className={`${active === 9 ? "activeTab" : ""} w-100 my-1`}
                      style={{ height: "40px" }}
                      onClick={() => handleTabClick(9, videoRef.current)}
                    >
                      Video
                    </Button>
                  )}
                </div>

                {currentPlan.name === "Free" || currentPlan.name === "free" &&
                  <div className="text-center">
                    <button className="back_btn" style={{ color: "#fff", marginTop: "10px" }}
                      onClick={upgradePremium}
                    >Upgrade to premium</button>
                  </div>
                }
              </div>


            </Col>
            <Col md={9}>
              <div className="create_Shop_form px-4">
                <h4 className="py-3 text-center">Add new place</h4>
                <Form className="create_shop_form" onSubmit={handleSubmit(formHandler)}>

                  <div ref={generalRef} className="tab_container">
                    <h6><span> General </span></h6>
                    <Row className="mt-2 form_inputs">
                      <Col md={12}>
                        <label>Place Name*</label>
                        <input
                          placeholder="Enter place name"
                          {...register("business_name", placeValidation.business_name)}
                        />
                        {errors.business_name && (
                          <span className="error_msg">
                            {errors?.business_name?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={12} className={'mt-2'}>
                        <label>Price Range*</label>
                        <select
                          {...register("price_range", placeValidation.price_range)}
                        >
                          <option value="">Select Price Range</option>
                          <option value="$">$ - $10 per person</option>
                          <option value="$$">$$ - $11-$30 per person</option>
                          <option value="$$$">$$$ - $31-$60 per person</option>
                          <option value="$$$$">$$$$ - Over $61 per person</option>
                        </select>

                        {errors.price_range && (
                          <span className="error_msg">
                            {errors?.price_range?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={12} className="mt-2">
                        <label>Description*</label>
                        <textarea
                          rows={4}
                          className="form-control"
                          placeholder="Enter About your description"
                          {...register(
                            "description",
                            placeValidation.description
                          )}
                        />
                        {errors.description && (
                          <span className="error_msg">
                            {errors?.description?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={12} className="mt-2">
                        <label>Place Type*</label>
                        <select
                          {...register("place_type", placeValidation.place_type)}
                        >
                          <option value="">Select Place Type</option>
                          {shopTypes?.shop_types?.map(
                            (shopsData: any, index: number) => (
                              <option key={index} value={shopsData?._id}>
                                {shopsData?.name}
                              </option>
                            )
                          )}
                        </select>

                        {errors.place_type && (
                          <span className="error_msg">
                            {errors?.place_type?.message}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div ref={socialNetworkRef} className="tab_container">
                    <h6><span> SOCIAL NETWORKS </span></h6>
                    <Row className="mt-2 form_inputs align-items-center">
                      <label>Select Social Network</label>
                      <Col md={5}>
                        <select
                          value={inputField.platform}
                          name="platform"
                          onChange={socialHandler}
                        >
                          <option value="">Enter Social</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Twitter">Twitter</option>
                        </select>
                      </Col>
                      <Col md={6}>
                        <input
                          placeholder="Enter URL include https://"
                          name="link"
                          value={inputField.link}
                          onChange={socialHandler}
                        />
                      </Col>
                      <Col md={1}>
                        <button onClick={addSocial} className={"social_btn"}>
                          <AiFillPlusCircle />
                        </button>
                      </Col>

                      <Col md={12}>
                        {social?.map((s: any, i: number) => {
                          return (
                            <ul className="social_show" key={i}>
                              <li>{i + 1}.</li>
                              <li>{s.plateform}</li>
                              <li><a href={s.link} target="_blank" rel="noreferrer"> {s.link} </a> </li>
                              <li className="delete_btn">
                                <MdDelete onClick={() => deleteSocial(i)} />
                              </li>
                            </ul>
                          );
                        })}
                      </Col>
                    </Row>
                  </div>

                  <div ref={amenitiesRef} className="tab_container">
                    <h6><span> AMENITIES </span></h6>
                    <div className="mt-2 amenities">
                      {
                        listLoading ?
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                          :
                          <>
                            {
                              shopTypes?.amenities.map((amenity: any) => {
                                return (
                                  <div>
                                    <input type="checkbox" value={amenity?._id} {...register("amenities", {})} />
                                    <label>{amenity?.name}</label>
                                  </div>
                                )
                              })
                            }
                          </>
                      }
                    </div>
                  </div>

                  <div ref={locationRef} className="tab_container">
                    <h6><span> LOCATION </span></h6>

                    <Row className="mt-2 form_inputs">
                      <Col md={12} className={"mb-3"}>
                        <label>Shop Location*</label>
                        <Map
                          map={true}
                          search={true}
                          setAddress={setAddress}
                          selectedCoordinate={selectedCoordinates}
                          setSelectedCoordinate={setSelectedCoordinates}
                        />
                      </Col>
                      <Col md={12}>
                        <label>Address*</label>
                        <input placeholder="Enter Shop Address"
                          {...register("address", placeValidation.city)}
                        />
                        {errors.address && (
                          <span className="error_msg">
                            {errors?.address?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={6}>
                        <label>Country*</label>
                        <select
                          name="country"
                          onChange={countryHandler}
                        >
                          <option value="">Please Select Country</option>
                          {
                            allCountries?.map((c: any, index: number) => {
                              return <option value={index}>{c.name}</option>
                            })
                          }
                        </select>
                      </Col>
                      <Col md={6}>
                        <label>State*</label>
                        <select
                          name="state"
                          onChange={stateHandler}
                        >
                          <option value="">Please Select State</option>
                          {
                            allStates?.map((c: any, index: number) => {
                              return <option value={index}>{c.name}</option>
                            })
                          }
                        </select>
                      </Col>
                      <Col md={6}>
                        <label>City*</label>
                        <select
                          name="city"
                          onChange={(e) => setGetCity(e.target.value)}
                        >
                          <option value="">Please Select City</option>
                          {
                            allCities?.map((c: any) => {
                              return <option value={c.name}>{c.name}</option>
                            })
                          }
                        </select>
                      </Col>
                      <Col md={6}>
                        <label>Zip*</label>
                        <input placeholder="Enter Zip"
                          {...register("zip", placeValidation.city)}
                        />
                        {errors.zip && (
                          <span className="error_msg">
                            {errors?.zip?.message}
                          </span>
                        )}

                      </Col>
                    </Row>
                  </div>

                  <div ref={contactInfoRef} className="tab_container">
                    <h6><span> CONTACT INFO </span></h6>
                    <Row className="mt-2 form_inputs">
                      <Col md={6}>
                        <label>Email*</label>
                        <input
                          placeholder="Enter Email Address"
                          {...register("email", placeValidation.email)}
                        />
                        {errors.email && (
                          <span className="error_msg">
                            {errors?.email?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={6}>
                        <label>Phone Number*</label>
                        <input
                          placeholder="Enter phone number"
                          {...register(
                            "phone_number",
                            placeValidation.phone_number
                          )}
                        />
                        {errors.phone_number && (
                          <span className="error_msg">
                            {errors?.phone_number?.message}
                          </span>
                        )}
                      </Col>
                      <Col md={12}>
                        <label>Website</label>
                        <input
                          placeholder="Enter Website with http:// or https://"
                          name="website"
                          value={websiteValue}
                          onChange={(e) => setWebsiteValue(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div ref={timingRef} className="tab_container">
                    <h6><span> Business Hours </span></h6>
                    <Row className="mt-2 form_inputs">
                      <Col md={12}>
                        {timings.map((timing: any, i: any) => {
                          return (
                            <div key={i}>
                              <Row className="mt-3">
                                <Col md={4}>
                                  <input
                                    id={`day-${i}`}
                                    name="day"
                                    value={timing.day}
                                    disabled
                                    onChange={(e) => handleInputChange(e, i, "day")}
                                    style={{ marginRight: "10px" }}
                                  />
                                </Col>
                                <Col md={3}>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                      label="OpenTime"
                                      value={timing.openTime}
                                      onChange={(e) => handleInputChange(e, i, "openTime")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className={'time_picker'}
                                          fullWidth
                                          placeholder="Enter OpenTime"
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>

                                </Col>
                                <Col md={3}>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                      label="closeTime"
                                      value={timing.closeTime}
                                      onChange={(e) => handleInputChange(e, i, "closeTime")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className={'time_picker'}
                                          fullWidth
                                          placeholder="Enter OpenTime"
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>

                                  {/* <input type={"time"}
                                  placeholder="9:00 AM to 11:00 PM"
                                  onChange={(e) => handleInputChange(e, i, "closeTime")}
                                  id={`closeTime-${i}`} name="closeTime" value={timing.closeTime}
                                /> */}
                                </Col>

                                {/* <Col md={1}>
                                {timings.length !== 1 && (
                                  <button className="mr10 social_btn" style={{fontSize: "20px"}} type="button" onClick={() => handleRemoveClick(i)}>
                                    <MdDelete />
                                  </button>
                                )}
                              </Col> */}

                                <Col md={1}>
                                  {timings.length - 1 === i && (
                                    <>
                                      {
                                        timing.day === 'sunday' ?
                                          null
                                          :
                                          <button className={"social_btn"} type="button" onClick={handleAddClick}><AiFillPlusCircle /></button>
                                      }
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </div>

                  <div ref={questionRef} className="tab_container">
                    <h6><span> Frequently Asked Questions </span></h6>
                    <p style={{ fontWeight: 600, fontSize: "14px" }}>
                      *Please Enter Questions and Answers about your Shop, location, etc...
                    </p>
                    <Row className="mt-2 form_inputs">
                      <Col md={12}>
                        <label>Question</label>
                        <input
                          placeholder="Write Question"
                          name="question"
                          value={frequently.question}
                          onChange={questionHandler}
                        />
                      </Col>
                      <Col md={12}>
                        <label>Answer</label>
                        <textarea
                          rows={4}
                          className="form-control"
                          placeholder="Write Answer"
                          name="answer"
                          value={frequently.answer}
                          onChange={questionHandler}
                        />
                      </Col>
                      <Col md={12} className="mt-2 text-end">
                        <button className="create_btn" onClick={addQuestion}>
                          Create Question
                        </button>
                      </Col>
                    </Row>

                    <div className="question_div">
                      <Accordion>
                        {quesAns &&
                          quesAns?.map((q: any, i: number) => {
                            return (
                              <Row className="align-items-center" key={i}>
                                <Col md={11}>
                                  <Accordion.Item eventKey={JSON.stringify(i)}>
                                    <Accordion.Header>{q.question}</Accordion.Header>
                                    <Accordion.Body>{q.answer}</Accordion.Body>
                                  </Accordion.Item>
                                </Col>
                                <Col md={1}>
                                  <div className="delete_icon">
                                    <MdDelete onClick={() => deleteQuestion(i)} />
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                      </Accordion>
                    </div>
                  </div>

                  <div ref={mediaRef} className="tab_container">
                    <h6><span> MEDIA </span></h6>

                    <Row className="mt-2">

                      <Col md={6} className="thumb_image_container">
                        <label style={{ fontSize: "18px", fontWeight: "600", marginBottom: "4px" }}>Thumbnail Image*</label>
                        <p style={{ fontWeight: 600, fontSize: "14px" }} className="text-start">
                          *This image shows as the Main image of your Shop.
                        </p>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="image1"
                          name="image1"
                          onChange={handleChangeImage}
                        />
                        <div className="thumb_image">
                          <label htmlFor="image1">
                            <img
                              src={
                                preview1 ?
                                  preview1
                                  : '/images/add_image.png'
                              }
                              alt=""
                            />
                          </label>
                        </div>
                        <p className="text-muted">File Format must be .png or jpg or .jpeg & Max. size 500x400</p>
                      </Col>

                      {currentPlan.features.allowAddBanner && (
                        <Col md={12} className="thumb_image_container">
                          <label style={{ fontSize: "18px", fontWeight: "600", marginBottom: "4px" }}>Banner Image</label>
                          <p style={{ fontWeight: 600, fontSize: "14px" }} className="text-start">
                            *This image shows as the Cover image of your Shop.
                          </p>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="image2"
                            name="image2"
                            onChange={handleBannerImage}
                          />

                          <div className="thumb_image2">
                            <label htmlFor="image2">
                              <img
                                src={
                                  bannerPreview
                                    ? bannerPreview
                                    : '/images/add_image.png'
                                }
                                alt=""
                              />
                            </label>
                          </div>
                          <p className="text-muted">File Format must be .png or jpg or .jpeg & Minimum width Size width 1000 & height must be minimum 250</p>
                        </Col>
                      )}

                      <Col md={12} className="thumb_image_container">
                        <label style={{ fontSize: "18px", fontWeight: "600", marginBottom: "4px" }}>Gallery Image*</label>
                        <p style={{ fontWeight: 600, fontSize: "14px" }} className="text-start">
                          * Gallery is a set of images which will be showing on the shop
                        </p>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="gallery"
                          name="gallery"
                          onChange={handleGalleryImagesChange}
                          multiple
                        />
                        <div className="thumb_image_gallery">
                          <label>
                            <Row className="justify-content-center">
                              {selectedImages.length > 0 && selectedImages?.map((previewImg: string, index: number) => {
                                return (
                                  <Col md={3} key={index} className='pre_div'>
                                    <img
                                      src={previewImg}
                                      alt=""
                                      className="preview_gallery_img2"
                                    />
                                    <AiOutlineClose onClick={() => deleteFile(previewImg, index)} />
                                  </Col>
                                );
                              })}
                              <Col md={3}>
                                <label htmlFor="gallery">
                                  <img
                                    src={'/images/add_image.png'}
                                    alt=""
                                    className={selectedImages.length > 0 ? "preview_gallery_img_view" : ''}
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                              </Col>
                            </Row>
                          </label>
                        </div>
                        <p className="text-muted">File Format must be .png or jpg or .jpeg</p>
                      </Col>

                      {currentPlan.features.allowAdvertisementBanner && (
                        <Col md={6} className="thumb_image_container">
                          <label style={{ fontSize: "18px", fontWeight: "600", marginBottom: "4px" }}>Advertisement Banner</label>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="image2"
                            name="image1"
                            onChange={handleChangeImage2}
                          />
                          <div className="thumb_image">
                            <label htmlFor="image2">
                              <img
                                src={
                                  preview2 ?
                                    preview2
                                    : '/images/add_image.png'
                                }
                                alt=""
                              />
                            </label>
                          </div>
                          <p className="text-muted">File Format must be .png or jpg or .jpeg & Max. size 350x500</p>
                        </Col>
                      )}
                    </Row>
                  </div>

                  {currentPlan.features.allowAddMenu && (
                    <div ref={menuRef} className="tab_container my-5">
                      <h6><span> Restaurant/Lounge Menu </span></h6>
                      <Row className="mt-2">
                        <Col md={12} className="thumb_image_container">
                          <input type={"file"} name="menu"
                            onChange={(e: any) => setMenuFile(e.target.files[0])} />
                          <p className="text-muted text-start pt-1">File Format must be .png or jpg or .pdf</p>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {currentPlan.features.allowAddVideo && (
                    <div ref={videoRef} className="tab_container my-5">
                      <h6><span> Video </span></h6>
                      <p style={{ fontWeight: 600, fontSize: "14px" }} className="text-start">
                        * Video will be showing on the shop
                      </p>
                      <Row className="mt-2">
                        <Col md={12} className="thumb_image_container">
                          <input type={"file"} onChange={handleVideo} />
                          <p className="text-muted text-start pt-1">Video Format must be .mp4 or mpeg-4</p>
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div className="d-flex justify-content-end mt-5">
                    <button type="submit" className="create_btn">
                      CREATE
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
      }
    </Container>
  );
};

export default CreateShop;
