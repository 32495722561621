import { getToken } from "../../Utils/helper";
import api from "../../Utils/Interceptors";

export const adminDashboard = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DASHBOARD_REQUEST",
    });

    const { data } = await api.get("/admin/dashboard");

    dispatch({
      type: "ADMIN_DASHBOARD_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_DASHBOARD_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN CUSTOMER LIST 

export const adminCustomerList = (search: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CUSTOMER_LIST_REQUEST",
    });

    const { data } = await api.get(`/admin/user/customer/list?search=${search}`);

    dispatch({
      type: "ADMIN_CUSTOMER_LIST_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CUSTOMER_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN CUSTOMER PROFILE LIST

export const adminCustomerProfile = (id: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CUSTOMER_PROFILE_REQUEST",
    });

    const { data } = await api.get(`/admin/user/customer/${id}/profile`);

    dispatch({
      type: "ADMIN_CUSTOMER_PROFILE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CUSTOMER_PROFILE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN OWNERS LIST 

export const adminOwnersList = (search: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_OWNER_LIST_REQUEST",
    });

    const { data } = await api.get(`/admin/user/owner/list?search=${search}`);

    dispatch({
      type: "ADMIN_OWNER_LIST_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_OWNER_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminOwnersEdit = (owner_id: any, formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_OWNER_EDIT_REQUEST",
    });

    const { data } = await api.put(`/admin/user/owner/${owner_id}/profile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_OWNER_EDIT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_OWNER_EDIT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminOwnersDelete = (owner_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_OWNER_DELETE_REQUEST",
    });

    const { data } = await api.delete(`/admin/user/owner/${owner_id}/profile`);

    dispatch({
      type: "ADMIN_OWNER_DELETE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_OWNER_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// ADMIN CUSTOMER

export const adminCustomerEdit = (customer_id: any, formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CUSTOMER_EDIT_REQUEST",
    });

    const { data } = await api.put(`/admin/user/customer/${customer_id}/profile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_CUSTOMER_EDIT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CUSTOMER_EDIT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminEditUserPasswordChange = (id: any, formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_USER_PASSWORD_REQUEST",
    });

    const { data } = await api.put(`/admin/user/${id}/changePassword`, formData);

    dispatch({
      type: "ADMIN_USER_PASSWORD_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_USER_PASSWORD_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminCustomerDelete = (customer_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CUSTOMER_DELETE_REQUEST",
    });

    const { data } = await api.delete(`/admin/user/customer/${customer_id}/profile`);

    dispatch({
      type: "ADMIN_CUSTOMER_DELETE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CUSTOMER_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};



// ADMIN OWNERS PROFILE LIST

export const adminOwnersProfile = (id: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_OWNER_PROFILE_REQUEST",
    });

    const { data } = await api.get(`/admin/user/owner/${id}/profile`);

    dispatch({
      type: "ADMIN_OWNER_PROFILE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_OWNER_PROFILE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN BUSINESS PLACE LIST 

export const adminBusinessPlaceList = (ownerId: any, search: any, shopType: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_BUSINESS_PLACE_LIST_REQUEST",
    });

    let d;
    if (shopType.length === 0) {
      d = await api.get(`/admin/place/listing?owner=${ownerId}&shopName=${search}`);
    }
    else {
      d = await api.get(`/admin/place/listing?shopName=${search}&shop_type=${shopType}`);
    }

    dispatch({
      type: "ADMIN_BUSINESS_PLACE_LIST_SUCCESS",
      payload: d.data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_BUSINESS_PLACE_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN BUSINESS PLACE LIST 

export const operatorBusinessPlaceList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "OPERATOR_BUSINESS_PLACE_LIST_REQUEST",
    });

    const { data } = await api.get(`/operator/place/listing`);

    console.log(data, "DATA")

    dispatch({
      type: "OPERATOR_BUSINESS_PLACE_LIST_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "OPERATOR_BUSINESS_PLACE_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN BUSINESS PLACE LIST 

export const adminDeleteBusinessPlaceList = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DELETE_BUSINESS_PLACE_LIST_REQUEST",
    });

    const { data } = await api.delete(`admin/place/${id}`);

    dispatch({
      type: "ADMIN_DELETE_BUSINESS_PLACE_LIST_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_DELETE_BUSINESS_PLACE_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN PLACE STATUS UPDATE 

export const adminPlaceStatusUpdateList = (place_id: any, status: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_PLACE_STATUS_UPDATE_LIST_REQUEST",
    });

    console.log(place_id, status)

    const { data } = await api.put(`/admin/place/${place_id}/status`, status);

    dispatch({
      type: "ADMIN_PLACE_STATUS_UPDATE_LIST_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_PLACE_STATUS_UPDATE_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};



// ADMIN GET AMENITIES LIST 

export const adminGetAmenitesList = (search: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_AMENITIES_REQUEST",
    });

    const { data } = await api.get(`/admin/amenity/list?search=${search}`);

    dispatch({
      type: "ADMIN_GET_AMENITIES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_AMENITIES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN GET AMENITY DETAIL 

export const adminGetAmenityDetail = (amenity_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_AMENITY_DETAIL_REQUEST",
    });

    const { data } = await api.get(`/admin/amenity/${amenity_id}`);

    dispatch({
      type: "ADMIN_GET_AMENITY_DETAIL_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_AMENITY_DETAIL_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN CREATE AMENITY 

export const adminCreateAmenity = (amenityData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CREATE_AMENITY_REQUEST",
    });

    console.log(amenityData)

    const { data } = await api.post('/admin/amenity/', amenityData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_CREATE_AMENITY_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CREATE_AMENITY_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN UPDATE AMENITY 

export const adminUpdateAmenity = (amenity_id: string, amenityData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_UPDATE_AMENITY_REQUEST",
    });

    const { data } = await api.put(`/admin/amenity/${amenity_id}`, amenityData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_UPDATE_AMENITY_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_UPDATE_AMENITY_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN DELETE AMENITY 

export const adminDeleteAmenity = (amenity_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DELETE_AMENITY_REQUEST",
    });

    const { data } = await api.delete(`/admin/amenity/${amenity_id}`);

    dispatch({
      type: "ADMIN_DELETE_AMENITY_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_DELETE_AMENITY_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ================================== PACKAGES ===================================

// ADMIN GET PACKAGES LIST 

export const adminGetPackagesList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_PACKAGES_REQUEST",
    });

    const { data } = await api.get(`/admin/packages/list`);

    dispatch({
      type: "ADMIN_GET_PACKAGES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_PACKAGES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN GET AMENITY DETAIL 

export const adminGetPackagesDetail = (package_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_PACKAGES_DETAIL_REQUEST",
    });

    const { data } = await api.get(`/admin/package/${package_id}`);

    dispatch({
      type: "ADMIN_GET_PACKAGES_DETAIL_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_PACKAGES_DETAIL_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN CREATE AMENITY 

export const adminCreatePackage = (packageData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CREATE_PACKAGE_REQUEST",
    });

    const { data } = await api.post('/admin/packages/', packageData);

    dispatch({
      type: "ADMIN_CREATE_PACKAGE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CREATE_PACKAGE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN UPDATE AMENITY 

export const adminUpdatePackages = (package_id: string, packageData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_UPDATE_PACKAGES_REQUEST",
    });

    const { data } = await api.put(`/admin/package/${package_id}`, packageData);

    dispatch({
      type: "ADMIN_UPDATE_PACKAGES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_UPDATE_PACKAGES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN DELETE AMENITY 

export const adminDeletePackage = (package_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DELETE_PACKAGE_REQUEST",
    });

    const { data } = await api.delete(`/admin/packages/${package_id}`);

    dispatch({
      type: "ADMIN_DELETE_PACKAGE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_DELETE_PACKAGE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};












// PRODUCT CREATE

export const adminCreateProduct = (productData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CREATE_PRODUCT_REQUEST",
    });

    const { data } = await api.post('/admin/product', productData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_CREATE_PRODUCT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CREATE_PRODUCT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminGetProductList = (keyword: any, sku: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_PRODUCT_REQUEST",
    });

    const { data } = await api.get(`/admin/products?search=${keyword}&sku=${sku}`);

    dispatch({
      type: "ADMIN_GET_PRODUCT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_PRODUCT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN DELETE PRODUCT 

export const adminDeleteProduct = (product_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DELETE_PRODUCT_REQUEST",
    });

    const { data } = await api.delete(`/admin/product/${product_id}`);

    dispatch({
      type: "ADMIN_DELETE_PRODUCT_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "ADMIN_DELETE_PRODUCT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN GET PRODUCT DETAIL 

export const adminGetProductDetail = (product_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_GET_PRODUCT_DETAIL_REQUEST",
    });

    const { data } = await api.get(`/admin/product/${product_id}`);

    dispatch({
      type: "ADMIN_GET_PRODUCT_DETAIL_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_GET_PRODUCT_DETAIL_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// ADMIN UPDATE PRODUCT 

export const adminUpdateProduct = (product_id: string, productData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_UPDATE_PRODUCT_REQUEST",
    });

    const { data } = await api.put(`/admin/product/${product_id}`, productData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_UPDATE_PRODUCT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_UPDATE_PRODUCT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// CUSTOMERS

export const adminOrder = (userId: any, search: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_ORDERS_REQUEST",
    });

    const { data } = await api.get(`/admin/orders/list?userId=${userId}&search=${search}`);

    dispatch({
      type: "ADMIN_ORDERS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_ORDERS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminOrderByQuery = (status: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_ORDERS_QUERY_REQUEST",
    });

    const { data } = await api.get(`/admin/orders/list?status=${status}`);

    dispatch({
      type: "ADMIN_ORDERS_QUERY_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_ORDERS_QUERY_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminOrderDetail = (order_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_ORDER_DETAILS_REQUEST",
    });

    const { data } = await api.get(`/admin/order/${order_id}`);

    dispatch({
      type: "ADMIN_ORDER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_ORDER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminOrderStatus = (order_id: any, status: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_ORDER_STATUS_REQUEST",
    });

    const { data } = await api.put(`/admin/order/${order_id}/status/${status}`);

    dispatch({
      type: "ADMIN_ORDER_STATUS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_ORDER_STATUS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// SUGGESTED PLACES

export const getAdminSuggestedPlaces = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_SUGGESTED_PLACES_REQUEST",
    });

    const { data } = await api.get("/admin/suggestions/list");

    dispatch({
      type: "ADMIN_SUGGESTED_PLACES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_SUGGESTED_PLACES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const getAdminSuggestedPlaceDetails = (suggest_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_SUGGESTED_PLACE_DETAILS_REQUEST",
    });

    const { data } = await api.get(`/admin/suggestion/${suggest_id}`);

    dispatch({
      type: "ADMIN_SUGGESTED_PLACE_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_SUGGESTED_PLACE_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// SUGGESTED PLACES

export const getAdminNewsletter = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_NEWSLETTER_REQUEST",
    });

    const { data } = await api.get("/admin/newsletter");

    dispatch({
      type: "ADMIN_NEWSLETTER_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_NEWSLETTER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// ====== ADMIN PLACE EDIT

export const adminPlaceEdit = (placeData: any, place_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_PLACE_EDIT_REQUEST",
    });

    const { data } = await api.put(`/admin/place/${place_id}`, placeData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "ADMIN_PLACE_EDIT_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_PLACE_EDIT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};



// === REPORTING 

export const adminSales = (date: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_SALES_REQUEST",
    });

    const { data } = await api.get(`/admin/reports/sales/${date}?status=completed`);

    dispatch({
      type: "ADMIN_SALES_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_SALES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminSubscription = (date: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_SUBSCRIPTION_REQUEST",
    });

    const { data } = await api.get(`/admin/reports/subscriptions/${date}?status=active`);

    dispatch({
      type: "ADMIN_SUBSCRIPTION_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_SUBSCRIPTION_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminExpiredSubscription = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_EXPIRED_SUBSCRIPTION_REQUEST",
    });

    const { data } = await api.get(`admin/reports/subscriptions/expired/all?status=active`);

    dispatch({
      type: "ADMIN_EXPIRED_SUBSCRIPTION_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_EXPIRED_SUBSCRIPTION_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const adminOwnerReport = (date: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_OWNER_REPORT_REQUEST",
    });

    const { data } = await api.get(`/admin/reports/user/owner/${date}/all`);

    dispatch({
      type: "ADMIN_OWNER_REPORT_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_OWNER_REPORT_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// Owner Place Profile Create

export const adminPlaceCreate = (placeData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_PLACE_CREATE_REQUEST",
    });

    const { data } = await api.post("/admin/place", placeData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    console.log(data)

    dispatch({
      type: "ADMIN_PLACE_CREATE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_PLACE_CREATE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const operatorPlaceCreate = (placeData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "OPERATOR_PLACE_CREATE_REQUEST",
    });

    const { data } = await api.post("/operator/place/create", placeData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    console.log(data)

    dispatch({
      type: "OPERATOR_PLACE_CREATE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "OPERATOR_PLACE_CREATE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Send Reminder

export const adminSendNotice = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_SEND_NOTICE_REQUEST",
    });

    const { data } = await api.get(`/admin/owner/${id}/subscription/sendNotice`);

    dispatch({
      type: "ADMIN_SEND_NOTICE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_SEND_NOTICE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminCreateOperator = (d: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_CREATE_OPERATOR_REQUEST",
    });

    const { data } = await api.post('/auth/register/', d);

    dispatch({
      type: "ADMIN_CREATE_OPERATOR_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_CREATE_OPERATOR_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const adminDeleteOperator = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_DELETE_OPERATOR_REQUEST",
    });

    const { data } = await api.delete(`/admin/user/operator/${id}/profile`)

    dispatch({
      type: "ADMIN_DELETE_OPERATOR_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ADMIN_DELETE_OPERATOR_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};