const initialState: any = {
  cartCount: 0,
  Carts: [],
  products: [],
};

export const CartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_ALL_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
        error: false
      }

    case "GET_ALL_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.payload.data,
        error: false
      }

    case "GET_ALL_PRODUCTS_FAILED":
      return {
        ...state,
        loading: false,
        products: null,
        error: action.payload
      }

    case "GET_ALL_PRODUCTS_RESET":
      return {
        ...state,
        Carts: {},
        cartCount: 0,
        error: false,
      }

    case "GET_ALL_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };

    case "ADD_TO_CART":
      console.log(action.payload.quantity)
      if (state.cartCount === 0) {
        let cart = {
          ...action.payload,
          quantity: action.payload.quantity,
        };
        state.cartCount++;
        state.Carts.push(cart);
      }

      else {
        let check = false;

        // eslint-disable-next-line array-callback-return
        state.Carts.map((item: any, key: any) => {
          if (item._id === action.payload._id) {
            // if (state.Carts[key].quantity < 1) {
            state.Carts[key].quantity= state.Carts[key].quantity + action.payload.quantity;
            // }
            check = true;
          }
        });

        if (!check) {
          let _cart = {
            ...action.payload,
            quantity: action.payload.quantity,
          };
          state.cartCount++;
          state.Carts.push(_cart);
        }
      }
      return {
        ...state,
        // cartCount: state.cartCount + 1,
      };

    case "INCREASE_QUANTITY":
      // state.cartCount++;
      state.Carts[action.payload].quantity++;

      return {
        ...state,
      };

    case "DECREASE_QUANTITY":
      let quantity = state.Carts[action.payload].quantity;
      if (quantity > 1) {
        // state.cartCount--;
        state.Carts[action.payload].quantity--;
      }

      return {
        ...state,
      };

    case "DELETE_CART":
      // let quantity_ = state.Carts[action.payload].quantity;

      return {
        ...state,
        cartCount: state.cartCount - 1,

        Carts: state.Carts.filter((item: any) => {
          return item._id != state.Carts[action.payload]._id;
        }),
      };

    default:
      return state;
  }
};


export const GetProductDetailReducer: any = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_PRODUCT_DETAIL_REQUEST":
      return {
        loading: true,
        error: false
      }

    case "GET_PRODUCT_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        productDetail: action.payload.data,
        error: false
      }

    case "GET_PRODUCT_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        productDetail: null,
        error: action.payload
      }
    default:
      return state
  }
}


export const GetCheckoutReducer: any = (state = { checkoutData: {} }, action: any) => {
  switch (action.type) {
    case "CREATE_CHECKOUT_REQUEST":
      return {
        loading: true,
        error: false
      }

    case "CREATE_CHECKOUT_SUCCESS":
      return {
        ...state,
        loading: false,
        checkoutData: action.payload,
        error: false
      }

    case "CREATE_CHECKOUT_FAILED":
      return {
        ...state,
        loading: false,
        checkoutData: null,
        error: action.payload
      }

    case "CREATE_CHECKOUT_RESET":
      return {
        ...state,
        checkoutData: {},
        error: false,
      }
    default:
      return state
  }
}