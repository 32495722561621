const initialState = {
    customer: {}
}

export const CustomerDashboardReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "CUSTOMER_DASHBOARD_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "CUSTOMER_DASHBOARD_SUCCESS":
            return {
                ...state,
                loading: false,
                customer: action.payload.data,
                error: false
            }

        case "CUSTOMER_DASHBOARD_FAILED":
            return {
                ...state,
                loading: false,
                customer: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const CustomerOrdersReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "CUSTOMER_ORDERS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "CUSTOMER_ORDERS_SUCCESS":
            return {
                ...state,
                loading: false,
                customerOrders: action.payload.data,
                error: false
            }

        case "CUSTOMER_ORDERS_FAILED":
            return {
                ...state,
                loading: false,
                customerOrders: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const CustomerOrderDetailsReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "CUSTOMER_ORDER_DETAILS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "CUSTOMER_ORDER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                customerOrderDetails: action.payload.data,
                error: false
            }

        case "CUSTOMER_ORDER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                customerOrderDetails: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}