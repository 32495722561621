import api from "../../Utils/Interceptors";
import axios from "axios";
import { getToken } from "../../Utils/helper";

export const login = (inputData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    const { data } = await axios.post("/auth/login", inputData);

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
      success: true,
    });

    localStorage.setItem("User", JSON.stringify(data?.data));

  }
  catch (e: any) {
    dispatch({
      type: "LOGIN_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const registerUser = (registerData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "REGISTER_REQUEST",
    });

    const { data } = await axios.post("/auth/register", registerData);

    dispatch({
      type: "REGISTER_SUCCESS",
      payload: data,
      success: true,
    });

    localStorage.setItem("User", JSON.stringify(data?.data));
  }
  catch (e: any) {
    dispatch({
      type: "REGISTER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// USER

export const getUserDetails = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_USER_DETAILS_REQUEST",
    });

    const { data } = await api.get("/account/profile");

    dispatch({
      type: "GET_USER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "GET_USER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const updateUserDetails = (userDetail: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "UPDATE_USER_DETAILS_REQUEST",
    });

    const { data } = await api.put("/account/profile", userDetail, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "UPDATE_USER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "UPDATE_USER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const updateUserPassword = (userPassword: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "UPDATE_USER_PASSWORD_REQUEST",
    });

    const { data } = await api.put("/account/change_password", userPassword);

    dispatch({
      type: "UPDATE_USER_PASSWORD_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "UPDATE_USER_PASSWORD_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// Website Settings Shop types

export const getShopTypes = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_SHOP_TYPE_REQUEST",
    });

    const { data } = await axios.get("/get_lists");

    dispatch({
      type: "GET_SHOP_TYPE_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "GET_SHOP_TYPE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const suggestedPlaces = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "CREATE_SUGGESTED_PLACES_REQUEST",
    });

    const { data } = await axios.post("/suggestion", formData);

    dispatch({
      type: "CREATE_SUGGESTED_PLACES_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "CREATE_SUGGESTED_PLACES_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};