import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./EnquiryDetails.css";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';

const EnquiryDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let enquiryDetail = location?.state?.enquiry_detail

  const goToBack = () => {
    navigate("/owner/dashboard");
  };

  return (
    <div className="py-3" style={{ backgroundColor: "rgb(244, 250, 255)" }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="table_div">
              <h4>User Enquiry</h4>

              <div className="enquiry_details">
                <div className="text-end">
                  <button className="back_btn" onClick={goToBack}>
                    back
                  </button>
                </div>

                <Row>
                  <Col md={12} className="py-2">
                    <label>Full Name</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input placeholder="Enter your First name" value={enquiryDetail.name} disabled />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Email</label>
                    <div className="signup_input">
                      <img src="/images/MailIcon.png" alt="" />
                      <input placeholder="Enter email address" value={enquiryDetail.email} disabled />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Phone</label>
                    <div className="signup_input">
                      <img src="/images/PhoneIcon.png" alt="" />
                      <input placeholder="Enter phone number" disabled value={enquiryDetail.phone} />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Persons</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input placeholder="Enter your First name" value={enquiryDetail.reservation_persons} disabled />
                    </div>
                  </Col>
                  <Col md={6} className="py-2">
                    <label>Date & Time</label>
                    <div className="signup_input">
                      <img src="/images/AccountIcon.png" alt="" />
                      <input placeholder="Enter your First name" value={moment(enquiryDetail.reservation_date).format('MMMM Do YYYY, h:mm a')} disabled />
                    </div>
                  </Col>
                  <Col md={12} className="py-2">
                    <label>Place</label>
                    <div className="signup_input">
                      <img src="/images/PhoneIcon.png" alt="" />
                      <input placeholder="Enter Place" disabled value={enquiryDetail.place ? enquiryDetail.place.name : '-'} />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EnquiryDetails;
