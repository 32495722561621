import { combineReducers } from "redux";
import {
    LoginReducer, RegisterReducer, GetUserDetailsReducer, UpdateUserDetailsReducer,
    UpdateUserPasswordReducer, getShopTypesReducer, createSuggestedReducer
} from "./Auth";
import { CustomerDashboardReducer, CustomerOrdersReducer, CustomerOrderDetailsReducer } from './Customer';
import {
    AdminDashboardReducer, AdminCustomerListReducer, AdminCustomerProfileReducer,
    AdminOwnerListReducer, AdminOwnerProfileReducer, AdminBusinessPlaceListReducer,
    AdminGetAmenitesReducer, AdminGetAmenityDetailReducer, AdminCreateAmenityReducer,
    AdminUpdateAmenityReducer, AdminDeleteAmenityReducer, AdminCreateProductReducer, AdminGetProductsReducer,
    AdminDeleteProductReducer, AdminGetProductDetailReducer, AdminUpdateProductReducer, AdminPlaceStatusUpdateListReducer,
    AdminDeleteBusinessPlaceReducer, AdminOrdersReducer, AdminOrderDetailsReducer,
    AdminOrderStatusReducer, AdminGetSuggestedReducer, AdminGetSuggestedDetailsReducer, AdminOwnerEditReducer,
    AdminOwnerDeleteReducer, AdminCustomerEditReducer, AdminCustomerDeleteReducer, adminEditPlaceReducer,
    AdminGetSalesReducer, AdminGetSubscriptionReducer, AdminGetOwnerReducer, adminCreatePlaceReducer, AdminGetExpiredSubscriptionReducer, OperatorBusinessPlaceListReducer, operatorCreatePlaceReducer, AdminUserPasswordReducer, AdminSendNoticeReducer, AdminGetPackagesReducer, AdminGetPackagesDetailReducer, AdminCreatePackagesReducer, AdminUpdatePackageReducer, AdminDeletePackageReducer, AdminOrdersQueryReducer, AdminGetNewsletterReducer, AdminCreateOpeartorReducer, AdminDeleteOpeartorReducer
} from './Admin';
import {
    OwnerDashboardReducer, ownerCreatePlaceReducer, ownerEditPlaceReducer, ownerPlaceListingReducer, ownerCouponsCreateReducer, ownerGetAllCouponsReducer,
    ownerUpdateCouponsReducer, ownerDeleteCouponsReducer, OwnerEnquiryReducer,
    ownerDeletePlaceReducer, ownerEventsCreateReducer, ownerGetAllEventsReducer, ownerUpdateEventReducer,
    ownerDeleteEventReducer, getAllEventsReducer, OwnerGetPackagesReducer, OwnerGetSubscriptionReducer, OperatorGetPackagesReducer
} from "./Owner";
import { CartReducer, GetProductDetailReducer, GetCheckoutReducer } from "./CartReducer";
import {
    BPListingReducer, BPListingDetailsReducer, bpReviewSubmitReducer, CreateEnquiryReducer,
    BPGetPlaceDiscountReducer, BPCurrentListingReducer, CityListingReducer
} from "./BusinessPlaces";

const rootReducer = combineReducers({
    cart: CartReducer,
    getShopProduct: GetProductDetailReducer,
    login: LoginReducer,
    register: RegisterReducer,
    userDetail: GetUserDetailsReducer,
    updateUserDetail: UpdateUserDetailsReducer,
    updateUserPassword: UpdateUserPasswordReducer,
    getShop: getShopTypesReducer,
    createSuggestedPlaces: createSuggestedReducer,

    // Customer
    customerDashboard: CustomerDashboardReducer,
    customerGetOrders: CustomerOrdersReducer,
    customerOrderDetails: CustomerOrderDetailsReducer,

    // Admin
    adminDashboard: AdminDashboardReducer,
    adminCustomerList: AdminCustomerListReducer,
    adminCustomerProfile: AdminCustomerProfileReducer,
    adminOwnerList: AdminOwnerListReducer,
    adminOwnerProfile: AdminOwnerProfileReducer,

    adminUserPassword: AdminUserPasswordReducer,

    adminOwnerEdit: AdminOwnerEditReducer,
    adminOwnerDelete: AdminOwnerDeleteReducer,
    adminCustomerEdit: AdminCustomerEditReducer,
    adminCustomerDelete: AdminCustomerDeleteReducer,

    adminCreatePlace: adminCreatePlaceReducer,
    operatorCreatePlace: operatorCreatePlaceReducer,
    adminBusinessPlaceList: AdminBusinessPlaceListReducer,
    operatorBusniessPlaceList: OperatorBusinessPlaceListReducer,
    adminDeleteBusinessPlace: AdminDeleteBusinessPlaceReducer,
    adminEditBusinessPlace: adminEditPlaceReducer,

    adminBusinessPlaceStatusList: AdminPlaceStatusUpdateListReducer,

    adminAmenitesList: AdminGetAmenitesReducer,
    adminGetAmenity: AdminGetAmenityDetailReducer,
    adminCreateAmenity: AdminCreateAmenityReducer,
    adminUpdateAmenity: AdminUpdateAmenityReducer,
    adminDeleteAmenity: AdminDeleteAmenityReducer,



    adminPackageList: AdminGetPackagesReducer,
    adminPackageListDetail: AdminGetPackagesDetailReducer,
    adminCreatePackage: AdminCreatePackagesReducer,
    adminUpdatePackage: AdminUpdatePackageReducer,
    adminDeletePackages: AdminDeletePackageReducer,

    OperatorPackageList: OperatorGetPackagesReducer,



    adminCreateProduct: AdminCreateProductReducer,
    adminProductList: AdminGetProductsReducer,
    adminProductDelete: AdminDeleteProductReducer,
    adminProductDetail: AdminGetProductDetailReducer,
    adminUpdateProduct: AdminUpdateProductReducer,
    adminOrderList: AdminOrdersReducer,
    adminOrderListDetail: AdminOrderDetailsReducer,
    adminOrderListStatus: AdminOrderStatusReducer,
    adminGetSuggestedPlaces: AdminGetSuggestedReducer,
    adminGetSuggestPlaceDetails: AdminGetSuggestedDetailsReducer,

    adminGetNewsletter: AdminGetNewsletterReducer,

    adminGetSalesData: AdminGetSalesReducer,
    adminGetSubsData: AdminGetSubscriptionReducer,
    adminGetExpiredData: AdminGetExpiredSubscriptionReducer,
    adminGetOwnerData: AdminGetOwnerReducer,

    adminGetOrderQuery: AdminOrdersQueryReducer,

    adminOperatorCreate: AdminCreateOpeartorReducer,
    adminOperatorDelete: AdminDeleteOpeartorReducer,

    // Owner
    OwnerDashboard: OwnerDashboardReducer,
    OwnerCreatePlace: ownerCreatePlaceReducer,
    OwnerEditPlace: ownerEditPlaceReducer,
    OwnerDeletePlace: ownerDeletePlaceReducer,
    ownerPlaceListing: ownerPlaceListingReducer,
    ownerCouponsSave: ownerCouponsCreateReducer,
    ownerEventsSave: ownerEventsCreateReducer,
    ownerGetCoupons: ownerGetAllCouponsReducer,
    ownerGetEvents: ownerGetAllEventsReducer,
    ownerUpdateCoupons: ownerUpdateCouponsReducer,
    ownerUpdateEvents: ownerUpdateEventReducer,
    ownerDeleteCoupons: ownerDeleteCouponsReducer,
    ownerDeleteEvents: ownerDeleteEventReducer,
    ownerGetEnquiry: OwnerEnquiryReducer,
    ownerGetPackageList: OwnerGetPackagesReducer,

    ownerSubscriptions: OwnerGetSubscriptionReducer,

    // Business Place Listings
    BPListing: BPListingReducer,
    BPCurrentListing: BPCurrentListingReducer,
    getCurrentCities: CityListingReducer,

    BPListingDetails: BPListingDetailsReducer,
    BpReview: bpReviewSubmitReducer,
    CreateEnquiry: CreateEnquiryReducer,
    BPDiscount: BPGetPlaceDiscountReducer,

    // checkout

    createCheckout: GetCheckoutReducer,


    homeEvents: getAllEventsReducer,

    sendReminder: AdminSendNoticeReducer

})

export default rootReducer;