const initialState = {
    user: {}
}

export const LoginReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                loading: true,
                isAuthenticated: false,
                error: false
            }

        case "LOGIN_SUCCESS":
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload,
                error: false
            }

        case "LOGIN_RESET":
            return {
                ...state,
                user: {}
            }

        case "LOGIN_FAILED":
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const RegisterReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "REGISTER_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "REGISTER_SUCCESS":
            return {
                ...state,
                loading: false,
                isRegistered: action.payload,
            };
        case "REGISTER_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "REGISTER_RESET":
            return {
                ...state,
                isRegistered: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const GetUserDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "GET_USER_DETAILS_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "GET_USER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                userDetail: action.payload.data,
            };
        case "GET_USER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}

export const UpdateUserDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "UPDATE_USER_DETAILS_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "UPDATE_USER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                updateUserDetail: action.payload,
            };
        case "UPDATE_USER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "UPDATE_USER_DETAILS_RESET":
            return {
                ...state,
                updateUserDetail: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const UpdateUserPasswordReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "UPDATE_USER_PASSWORD_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "UPDATE_USER_PASSWORD_SUCCESS":
            return {
                ...state,
                loading: false,
                UserPassword: action.payload,
            };
        case "UPDATE_USER_PASSWORD_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "UPDATE_USER_PASSWORD_RESET":
            return {
                ...state,
                UserPassword: null,
            };
        default:
            return state;
    }
}


// Website Settings Shop types

export const getShopTypesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "GET_SHOP_TYPE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_SHOP_TYPE_SUCCESS":
            return {
                ...state,
                loading: false,
                shopTypes: action.payload.data,
                error: false
            }

        case "GET_SHOP_TYPE_FAILED":
            return {
                ...state,
                loading: false,
                shopTypes: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


export const createSuggestedReducer = (state = {}, action: any) => {
    switch (action.type) {

        case "CREATE_SUGGESTED_PLACES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "CREATE_SUGGESTED_PLACES_SUCCESS":
            return {
                ...state,
                loading: false,
                suggestPlaces: action.payload,
                error: false
            }

        case "CREATE_SUGGESTED_PLACES_FAILED":
            return {
                ...state,
                loading: false,
                suggestPlaces: null,
                error: action.payload
            }

        case "CREATE_SUGGESTED_PLACES_RESET":
            return {
                ...state,
                loading: false,
                suggestPlaces: {},
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}