import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Table, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { adminOrder, adminOrderByQuery } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import moment from 'moment';
import Loader from "../../../../Utils/Loader";
import { useForm, SubmitHandler } from "react-hook-form";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OrdersQuery = () => {
  const navigate = useNavigate();

  const [loaderShow, setLoaderShow] = useState(false)

  const dispatch: AppDispatch = useDispatch();
  const { loading, adminOrdersByQuery } = useSelector((state: any) => state.adminGetOrderQuery);

  let query = useQuery();
  let userStatus: any = query.get("status")

  useEffect(() => {
    if (adminOrdersByQuery) {
      setLoaderShow(false)
    }
  }, [adminOrdersByQuery])

  useEffect(() => {
    dispatch(adminOrderByQuery(userStatus))
  }, [dispatch])

  const orderStatus = (status: string) => {
    if (status === "0") {
      return <td className="text-center"> <span className="pending"> Pending </span></td>
    }
    else if (status === "1") {
      return <td className="text-center"> <span className="completed"> Completed </span></td>
    }
    else if (status === "2") {
      return <td className="text-center "> <span className="approved"> Approved </span> </td>
    }
    else if (status === "3") {
      return <td className="text-center"> <span className="rejected"> Failed </span> </td>
    }
    else if (status === "4") {
      return <td className="text-center "> <span className="rejected"> Rejected </span></td>
    }
    else if (status === "5") {
      return <td className="text-center"> <span className="rejected"> Expired </span></td>
    }
  }

  return (
    <div className="page_responsive">
      <h1 className="main_heading">Orders</h1>

      <div className="text-end">
        <button className="back_btn"
          onClick={() => navigate(-1)}
        >Back</button>
      </div>

      {
        loading ?
          <Loader />
          :
          <div className="table_div">
            <h4>All Orders</h4>
            {
              adminOrdersByQuery ?
                <Table responsive>
                  <thead>
                    <tr style={{ backgroundColor: "#8080800f" }}>
                      <th className="">ORDER ID</th>
                      <th className="">DATE</th>
                      <th className="">ORDER EMAIL</th>
                      <th className="">TOTAL PRICE</th>
                      <th className="">STATUS</th>
                      <th className="">ACTION</th>
                    </tr>
                  </thead>
                  {adminOrdersByQuery?.map((list: any, index: number) => (
                    <tbody key={list}>
                      <tr>
                        <td className="">{index + 1}</td>
                        <td className="">{moment(list?.order_date).format('ll')}</td>
                        <td className="">{list?.user.email}</td>
                        <td className="">{list?.amount}</td>
                        {orderStatus(list?.active)}
                        <td className="">
                          <div className="table_icons">
                            <FaEye
                              onClick={() =>
                                navigate(`/admin/order-details/${list?._id}`)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
                : <p style={{ textAlign: "center", fontWeight: "600" }}>No Order Found</p>
            }
          </div>
      }
    </div>
  );
};

export default OrdersQuery