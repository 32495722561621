import { MdDashboard, MdFastfood, MdPlace } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { BsListCheck } from "react-icons/bs";
import { TbBrandProducthunt } from "react-icons/tb";
import { ImProfile } from 'react-icons/im';
import AllListings from "../Pages/AllListings/AllListings";
import AllUsers from "../Pages/AllUsers/AllUsers";
import AllOwners from "../Pages/AllOwners/AllOwners";
import UsersDetails from "../Pages/AllUsers/UsersDetails/UsersDetails";
import OwnerDetails from "../Pages/AllOwners/OwnersDetails/OwnersDetails";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Products from "../Pages/Products/Products";
import CreateProduct from "../Pages/Products/CreateProduct/CreateProduct";
import ViewProduct from "../Pages/Products/ViewProduct/ViewProduct";
import SuggestedPlaces from "../Pages/SuggestedPlaces/SuggestedPlaces";
import PlacesDetail from "../Pages/SuggestedPlaces/PlacesDetail/PlacesDetail";
import Orders from "../Pages/Orders/Orders";
import OrderDetails from "../Pages/Orders/OrderDetails/OrderDetails";
import AdminProfile from "../Pages/Profile/Profile";
import Amenities from "../Pages/Amenities/Amenities";
import CreateAmenities from "../Pages/Amenities/CreateAmenities/CreateAmenities";
import ShopView from "../../Pages/FindShop/ShopView/ShopView";
import EditListings from "../Pages/AllListings/EditListings/EditListings";
import EditProduct from "../Pages/Products/EditProduct/EditProduct";
import Sales from "../Pages/Reporting/Sales/Sales";
import Subscription from "../Pages/Reporting/Subscription/Subscription";
import OwnersReport from "../Pages/Reporting/Owners/Owners";
import CreateListings from "../Pages/AllListings/CreateListings/CreateListings";
import Events from "../Pages/Events/Events";
import Expired from '../Pages/Reporting/Subscription/Expired';
import Packages from "../Pages/Packages/Packages";
import CreatePackage from "../Pages/Packages/CreatePackage/CreatePackage";
import OrdersQuery from "../Pages/OrdersbyQuery/OrdersQuery";
import ViewSubscription from "../Pages/ViewSubsciption/ViewSubscription";
import NewsLetter from "../Pages/NewsLetter/NewsLetter";
import Operator from "../Pages/Operator/Operator";
import OpeartorDetails from "../Pages/Operator/OperatorDetails/OpeartorDetails";

export const adminRoutes: any[] = [
  {
    path: "/admin/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/admin/all-customers",
    component: <AllUsers />,
  },
  {
    path: "/admin/customer-details/:id",
    component: <UsersDetails />,
  },
  {
    path: "/admin/opeartor-details/:id",
    component: <OpeartorDetails />,
  },
  {
    path: "/admin/all-owners",
    component: <AllOwners />,
  },
  {
    path: "/admin/owner-details/:id",
    component: <OwnerDetails />,
  },
  {
    path: "/admin/all-listings",
    component: <AllListings />,
  },
  {
    path: "/admin/select-package",
    component: <div className="page_responsive"><ViewSubscription /></div>,
  },
  {
    path: "/admin/create-listings",
    component: <div className="page_responsive"><CreateListings /></div>,
  },
  {
    path: "/admin/view-listings/:id",
    component: <div className="page_responsive"><ShopView /></div>,
  },
  {
    path: "/admin/edit-listings/:id",
    component: <EditListings />,
  },
  {
    path: '/admin/view-packages',
    component: <Packages />
  },
  {
    path: '/admin/create-package',
    component: <CreatePackage />
  },
  {
    path: '/admin/edit-package/:id',
    component: <CreatePackage />
  },
  {
    path: "/admin/amenities",
    component: <Amenities />,
  },
  {
    path: "/admin/create-amenities",
    component: <CreateAmenities />,
  },
  {
    path: "/admin/edit-amenities/:id",
    component: <CreateAmenities />,
  },
  {
    path: "/admin/products",
    component: <Products />,
  },
  {
    path: "/admin/create-product",
    component: <CreateProduct />,
  },
  {
    path: "/admin/edit-product/:id",
    component: <EditProduct />,
  },
  {
    path: "/admin/view-product/:id",
    component: <ViewProduct />,
  },
  {
    path: "/admin/suggested-places",
    component: <SuggestedPlaces />,
  },
  {
    path: "/admin/suggested-places-detail/:id",
    component: <PlacesDetail />,
  },
  {
    path: "/admin/order-details/:id",
    component: <OrderDetails />,
  },
  {
    path: "/admin/orders",
    component: <Orders />,
  },
  {
    path: "/admin/profile",
    component: <AdminProfile />,
  },

  {
    path: "/admin/reports/sales/:id",
    component: <Sales />
  },
  {
    path: "/admin/reports/subscription/:id",
    component: <Subscription />
  },
  {
    path: "/admin/reports/subscription/expired",
    component: <Expired />
  },
  {
    path: "/admin/reports/owners/:id",
    component: <OwnersReport />
  },
  {
    path: "/admin/events",
    component: <Events />
  },
  {
    path: "/admin/order-query",
    component: <OrdersQuery />
  },
  {
    path: "/admin/newsletter",
    component: <NewsLetter />
  },
  {
    path: "/admin/operator",
    component: <Operator />
  },
];

export const adminSideBarItems: any[] = [
  {
    path: "/admin/dashboard",
    icon: <MdDashboard />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/admin/profile",
    icon: <ImProfile />,
    title: "My Profile",
    isSubNav: false,
  },
  {
    path: "",
    icon: <ImProfile />,
    title: "Subscription",
    isSubNav: true,
    subNav: [
      {
        path: "/admin/reports/subscription/all",
        component: <Subscription />,
        icon: <ImProfile />,
        title: "All"
      },
      {
        path: "/admin/reports/subscription/expired",
        component: <Subscription />,
        icon: <ImProfile />,
        title: "Expired"
      }
    ]
  },
  {
    path: "/admin/select-package",
    icon: <BsListCheck />,
    title: "Create Place",
    isSubNav: false,
  },
  {
    path: "/admin/view-packages",
    icon: <ImProfile />,
    title: "Packages",
    isSubNav: false,
  },
  {
    path: "/admin/operator",
    icon: <FiUsers />,
    title: "Operator",
    isSubNav: false,
  },
  {
    path: "/admin/all-customers",
    icon: <FiUsers />,
    title: "All End Users",
    isSubNav: false,
  },
  {
    path: "/admin/all-owners",
    icon: <FiUsers />,
    title: "All Owners",
    isSubNav: false,
  },
  {
    path: "/admin/all-listings",
    icon: <BsListCheck />,
    title: "All Listings",
    isSubNav: false,
  },
  {
    path: "/admin/amenities",
    icon: <BsListCheck />,
    title: "Amenities",
    isSubNav: false,
  },
  {
    path: "/admin/products",
    icon: <TbBrandProducthunt />,
    title: "Products",
    isSubNav: false,
  },
  {
    path: "/admin/orders",
    icon: <MdFastfood />,
    title: "Orders",
    isSubNav: false,
  },
  {
    path: "/admin/events",
    icon: <MdPlace />,
    title: "Events",
    isSubNav: false,
  },
  {
    path: "/admin/suggested-places",
    icon: <MdPlace />,
    title: "Suggested Places",
    isSubNav: false,
  },
  {
    path: "/admin/newsletter",
    icon: <MdPlace />,
    title: "NewsLetter",
    isSubNav: false,
  }
];
