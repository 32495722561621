import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

const MainLayout = (props: any) => {
  return (
    <React.Fragment>
      <Header setLocationShow={props.setLocationShow} />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
export default MainLayout;
