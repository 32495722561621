import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminGetPackagesList, adminDeletePackage } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import Loader from "../../../../Utils/Loader";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md"
import { successNotify } from "../../../../Utils/toast";

const Packages = () => {

    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false)

    const dispatch: AppDispatch = useDispatch();

    const { loading, packagesList } = useSelector((state: any) => state.adminPackageList);
    const { loading: deleteLoading, deletePackageData } = useSelector((state: any) => state.adminDeletePackages);

    console.log(packagesList)

    useEffect(() => {
        if (packagesList) {
            setLoaderShow(false)
        }
    }, [packagesList])

    useEffect(() => {

        if (deletePackageData?.status === 1) {
            successNotify("Package Deleted Successfully")
            dispatch({ type: "ADMIN_DELETE_PACKAGE_RESET" })
        }

    }, [deletePackageData?.status, dispatch])

    useEffect(() => {
        dispatch(adminGetPackagesList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deletePackageData?.status === 1])

    const deleteHandler = (id: string) => {
        dispatch(adminDeletePackage(id))
    }

    // const productSearchHandler: SubmitHandler<any> = (data: any) => {
    //     setLoaderShow(true)
    //     dispatch(adminGetAmenitesList(data.search))
    //     reset();
    // }

    // const ResetHandler = () => {
    //     dispatch(adminGetAmenitesList(''))
    // }

    return (
        <div className="page_responsive">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">Packages</h1>
                <button className="back_btn" onClick={() => navigate("/admin/create-package")}>
                    Create Package
                </button>
            </div>

            <div className="table_div">
                <h4>All Packages</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            <thead>
                                <tr style={{ backgroundColor: "#8080800f" }}>
                                    <th className="">S.No</th>
                                    <th className="">Name</th>
                                    <th className="">Price</th>
                                    <th className="">Duration</th>
                                    <th className="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packagesList?.map((packages: any, index: number) => {
                                    return (
                                        <tr key={packages._id}>
                                            <td className="">{index + 1}</td>
                                            <td className="">{packages.name}</td>
                                            <td className="">{packages.price}</td>
                                            <td className="">{packages.duration} {packages.duration_type}</td>
                                            <td className="">
                                                <div className="table_icons">
                                                    {
                                                        deleteLoading ?
                                                            <>
                                                                <FaEdit />
                                                                <MdDelete />
                                                            </>
                                                            :
                                                            <>
                                                                <FaEdit onClick={() => navigate(`/admin/edit-package/${packages._id}`)} />
                                                                <MdDelete onClick={() => deleteHandler(packages._id)} />
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                }
            </div>
        </div>
    )
}

export default Packages