import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerOrderDetail } from "../../../../../Redux/Action/Customer";
import { AppDispatch } from "../../../../../Redux/Store";
import "./OrderDetails.css";
import Loader from "../../../../../Utils/Loader";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";

const OrderDetails = () => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams()

  const { loading, customerOrderDetails } = useSelector((state: any) => state.customerOrderDetails);

  console.log(customerOrderDetails)

  useEffect(() => {
    dispatch(customerOrderDetail(id))
  }, [])

  const statusMsg = (status: any) => {
    if (status === "0") {
      return <h4 className="pending status_msg">Your Order is Under Approval</h4>
    }
    else if (status === "1") {
      return <h4 className="completed status_msg">Order Completed</h4>
    }
    else if (status === "2") {
      return <h4 className="pending status_msg">Your Order Approved & will deliver soon.</h4>
    }
    else if (status === "3") {
      return <h4 className="rejected status_msg">Your Order Failed</h4>
    }
    else if (status === "4") {
      return <h4 className="rejected status_msg">Your Order has been Rejected</h4>
    }
    else if (status === "5") {
      return <h4 className="rejected status_msg">Order Expried</h4>
    }
  }

  return (
    <div className="Order_details">
      {
        loading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}> <Loader /> </div>
          :
          <Container>
            <Row className="justify-content-center py-5">
              <Col md={10}>
                <div className="d-flex justify-content-between">
                  <h3>Order Details</h3>
                  <button className="back_btn" onClick={() => navigate('/customer/dashboard')}>Back</button>
                </div>

                <div className="order_id">
                  <h6> Order ID: <span>#{id}</span> </h6>
                  <h5> Total: <span>${customerOrderDetails?.amount}/-</span> </h5>
                </div>

                <div className="order_product_detail">
                  <h6>Product Details</h6>
                  <div className="p-4">
                    {statusMsg(customerOrderDetails?.active)}

                    <Row className="product_row">
                      {
                        customerOrderDetails?.details?.map((product: any) => {
                          return (
                            <>
                              <Col md={6} className={'product_img'}>
                                <img src={`${cloudURL}${MediaPath.product_image}${product?.product?.images[0]}`} alt="" />
                                <p>{product?.product?.title}</p>
                              </Col>
                              <Col md={2}>
                                <p>PRICE: ${product?.price}</p>
                              </Col>

                              <Col md={2}>
                                <p>QTY: {product?.quantity}</p>
                              </Col>

                              <Col md={2}>
                                <p>TOTAL: ${product?.gross_total}</p>
                              </Col>
                            </>
                          )
                        })
                      }
                    </Row>
                  </div>
                </div>

                <Row className="my-3 justify-content-end">
                  <Col md={6}>
                    <div className="order_detail_left">
                      <h6>Billing Address</h6>

                      <div className="p-3">
                        <p><span>Name:</span> {customerOrderDetails?.billing?.name}</p>
                        <p><span>Adress:</span> {customerOrderDetails?.billing?.address}</p>
                        <p><span>Phone:</span> {customerOrderDetails?.billing?.phone}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="order_detail_left">
                      <h6>Shipping Address</h6>

                      <div className="p-3">
                        <p><span>Name:</span> {customerOrderDetails?.shipping?.name}</p>
                        <p><span>Adress:</span> {customerOrderDetails?.shipping?.address}</p>
                        <p><span>Phone:</span> {customerOrderDetails?.shipping?.phone}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
      }
    </div>
  );
};

export default OrderDetails;
