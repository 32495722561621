import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { useJsApiLoader } from "@react-google-maps/api";
import Modal from "../Modal/Modal";
import { BiWorld } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { suggestedPlaces } from "../../Redux/Action/Auth";
import { AppDispatch } from "../../Redux/Store";
import "./SuggestPlace.css";
import { successNotify } from "../../Utils/toast";

const libraries: any = ["places"];

const SuggestPlaces = () => {
  const [getCountry, setGetCountry] = useState("");
  const [getCity, setGetCity] = useState("");
  const [show, setShow] = useState(false);
  const [suggestForm, setSuggestForm] = useState({
    businessName: "",
    address: "",
    email: "",
    phone: "",
    description: "",
  });

  const dispatch: AppDispatch = useDispatch();
  const { loading, suggestPlaces } = useSelector((state: any) => state.createSuggestedPlaces);

  useEffect(() => {

    if (suggestPlaces?.status === 1) {
      successNotify("Suggested Created!!")
      dispatch({ type: "CREATE_SUGGESTED_PLACES_RESET" })
      setShow(!show);

      setSuggestForm({
        businessName: "",
        email: "",
        address: "",
        phone: "",
        description: "",
      });
    }

  }, [suggestPlaces?.status === 1])


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAFsEP9H8HabaWXDeZzpZZ7-7JuVnXv2yk",
    libraries,
  });

  if (!isLoaded) {
    return <p>...Loading</p>;
  }

  const getGeocodeByCoordinate = (coordinate: google.maps.LatLngLiteral) => {
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: coordinate })
      .then((response: { results: any[] }) => {
        if (response.results[0]) {
          setSuggestForm({
            ...suggestForm,
            address: response.results[0].formatted_address,
          });

          for (var i = 0; i < response.results[0].address_components.length; i++) {
            for (var b = 0; b < response.results[0].address_components[i].types.length; b++) {
              if (response.results[0].address_components[i].types[b] === "country") {
                setGetCountry(response.results[0].address_components[i].long_name);
              }

              if (response.results[0].address_components[i].types[b] === "locality") {
                setGetCity(response.results[0].address_components[i].long_name);
              }
            }
          }
          setShow(true);
        }
      });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        const coordinate = {
          lat: latitude,
          lng: longitude,
        };
        getGeocodeByCoordinate(coordinate);
      }
    );
  };

  const suggestOnchange = (e: any) => {
    setSuggestForm({
      ...suggestForm,
      [e.target.name]: e.target.value,
    });
  };

  const suggestHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();


    const formData = {
      ...suggestForm,
      country: getCountry,
      city: getCity
    }

    console.log()
    dispatch(suggestedPlaces(formData))

  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Suggested a Hookah Places"
      >
        <Form className="suggestFormHandler" onSubmit={suggestHandler}>
          <Row>
            <Col md={12} className="py-2">
              <label>Hookah Bar Name</label>
              <div className="signup_input">
                <img src="/images/AccountIcon.png" alt="" />
                <input
                  placeholder="Enter Business Name"
                  name="businessName"
                  value={suggestForm.businessName}
                  onChange={suggestOnchange}
                />
              </div>
            </Col>
            <Col md={6} className="py-2">
              <label>Email</label>
              <div className="signup_input">
                <img src="/images/MailIcon.png" alt="" />
                <input
                  placeholder="Enter email address"
                  name="email"
                  value={suggestForm.email}
                  onChange={suggestOnchange}
                />
              </div>
            </Col>
            <Col md={6} className="py-2">
              <label>Phone</label>
              <div className="signup_input">
                <img src="/images/PhoneIcon.png" alt="" />
                <input
                  placeholder="Enter phone number"
                  name="phone"
                  value={suggestForm.phone}
                  onChange={suggestOnchange}
                />
              </div>
            </Col>
            <Col md={12} className="py-2">
              <label>Address</label>
              <div className="signup_input">
                <textarea
                  rows={4}
                  name="address"
                  value={suggestForm.address}
                  onChange={suggestOnchange}
                  className="form-control"
                  placeholder="Enter description"
                />
              </div>
            </Col>
            <Col md={6} className="py-2">
              <label>Country</label>
              <div className="signup_input">
                <BiWorld />
                <input
                  placeholder="Enter Country Name"
                  name="getCountry"
                  value={getCountry}
                  onChange={(e: any) => setGetCountry(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} className="py-2">
              <label>City</label>
              <div className="signup_input">
                <BiWorld />
                <input
                  placeholder="Enter City Name"
                  name="getCity"
                  value={getCity}
                  onChange={(e: any) => setGetCity(e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} className="py-2">
              <label>Description</label>
              <textarea
                rows={4}
                name="description"
                value={suggestForm.description}
                onChange={suggestOnchange}
                className="form-control"
                placeholder="Enter description"
              />
            </Col>
            <Col md={12} className="py-2">
              <div className="text-end">
                <button type="submit" className="back_btn">
                  {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div className="add-business-container mb-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className="text-center text-white">
              <div className="business-content">
                <h2>SUGGEST A HOOKAH PLACE</h2>
              </div>

              <div className="suggest_hookah_place">
                <input
                  placeholder="Enter Business Name and press on location symbol"
                  name="businessName"
                  value={suggestForm.businessName}
                  onChange={suggestOnchange}
                />
                <div className={"location_icon"}>
                  <img
                    src="/images/location_icon.png"
                    alt={"location-icon"}
                    onClick={getCurrentLocation}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuggestPlaces;
