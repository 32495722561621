import React, { useState, useEffect } from 'react'
import { Table, Col, Form, Row, Spinner } from "react-bootstrap";
import { BsCalendar2Date } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import Modal from "../../../../../Components/Modal/Modal";
import { EventInput } from "../../../../../Utils/Interfaces";
import { eventValidation } from "../../../../../Utils/Validations";
import { FaEdit } from "react-icons/fa";
import { useForm, SubmitHandler } from "react-hook-form";
import { ownerGetAllEvents, ownerUpdateEvent, ownerEventDeleted } from '../../../../../Redux/Action/Owner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Redux/Store';
import Loader from '../../../../../Utils/Loader';
import Select from 'react-select';
import { errorNotify, successNotify } from '../../../../../Utils/toast';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { cloudURL, MediaPath } from '../../../../../Utils/helper';

const EventList = () => {
    const dispatch: AppDispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [previewImg, setPreviewImg] = useState<any>(null);
    const [fileSelected, setFileSelected] = useState<any>(null);
    const [profileError, setProfileError] = useState<String | null>(null);
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<EventInput>();
    const { loading, getAllEvents } = useSelector((state: any) => state.ownerGetEvents);

    // console.log(getAllEvents)

    const { loading: updateLoading, updateEvent } = useSelector((state: any) => state.ownerUpdateEvents);

    const { deleteEvent } = useSelector((state: any) => state.ownerDeleteEvents);
    const { ownerPlacesListings } = useSelector((state: any) => state.ownerPlaceListing);
    const [eventData, setEventData] = useState<any>({});
    const [selectedPlaces, setSelectedPlaces] = useState<any>([]);
    const [timings, setTimings] = useState<any>('')

    const handlePlaces = (selectedOption: any) => {
        setSelectedPlaces(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : []);
    }

    useEffect(() => {
        if (eventData) {
            setTimings(eventData?.event_time)
            setValue("eventTitle", eventData?.title);
            setValue("eventStartDate", eventData?.start_date);
            setValue("eventEndDate", eventData?.end_date);
            setValue("eventDescription", eventData?.description);
        }
    }, [eventData])

    useEffect(() => {
        dispatch(ownerGetAllEvents('', ''))
    }, [])

    // Update Coupons 

    useEffect(() => {
        if (updateEvent?.status === 1) {
            successNotify("Event updated successfully")
            setShowModal(false)
            setPreviewImg(null)
            setFileSelected(null)
            setEventData(null)
            dispatch({ type: "OWNER_UPDATE_EVENT_RESET" })
            dispatch(ownerGetAllEvents('', ''))
            reset()
        }
    }, [updateEvent, setValue, dispatch, reset])

    // Delete Coupons

    useEffect(() => {
        if (deleteEvent?.status === 1) {
            successNotify("Event deleted successfully");
            dispatch({ type: "OWNER_EVENTS_DELETE_RESET" });
            dispatch(ownerGetAllEvents('', ''))
        }
    }, [deleteEvent]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDeleteHandler = (id: any) => {
        dispatch(ownerEventDeleted(id))
    }

    const handleChangeImage = (e: any) => {
        const fileList = e.target.files;
        if (!fileList) return;
        setFileSelected(fileList[0]);
        setPreviewImg(URL.createObjectURL(e.target.files[0]))
        setProfileError(null)
    };

    const modalHandler = (indivisualCoupon: any) => {
        setShowModal(!showModal)
        setEventData(indivisualCoupon)
    }

    const eventHandler: SubmitHandler<EventInput> = (data) => {

        if (data.eventEndDate) {
            if (data.eventEndDate > data.eventStartDate) {

                const eventsData: any = new FormData();
                eventsData.append("eventTitle", data.eventTitle);
                eventsData.append("eventStartDate", data.eventStartDate);
                eventsData.append("eventEndDate", data.eventEndDate);
                eventsData.append("eventTime", timings);
                eventsData.append("eventDescription", data.eventDescription);
                eventsData.append("places", JSON.stringify(selectedPlaces));
                eventsData.append("eventImage", fileSelected);

                for (let value of eventsData) {
                    console.log(value[1])
                }

                dispatch(ownerUpdateEvent(eventData?._id, eventsData))
                setSelectedPlaces([])

            }
            else {
                errorNotify("End date is not valid!")
            }
        }

        else {

            const eventsData: any = new FormData();
            eventsData.append("eventTitle", data.eventTitle);
            eventsData.append("eventStartDate", data.eventStartDate);
            eventsData.append("eventEndDate", data.eventEndDate);
            eventsData.append("eventTime", timings);
            eventsData.append("eventDescription", data.eventDescription);
            eventsData.append("places", JSON.stringify(selectedPlaces));
            eventsData.append("eventImage", fileSelected);

            for (let value of eventsData) {
                console.log(value[1])
            }

            dispatch(ownerUpdateEvent(eventData?._id, eventsData))
            setSelectedPlaces([])
        }

    };

    const options = ownerPlacesListings?.data?.map((d: any) => {
        return { value: d._id, label: d.name }
    })

    const arr: any = eventData?.places;
    for (let j = 0; j < options?.length; j++) {
        for (let i = 0; i < arr?.length; i++) {
            if (arr[i] === options[j].value) {
                arr[i] = options[j]
            }
        }
    }

    useEffect(() => {

        if (showModal) {
            // setSelectedPlaces(eventData?.places?.map((p: any) => p.value))

            setSelectedPlaces(Array.isArray(eventData?.places) ? eventData?.places?.map((x: any) => x.value) : []);
        }

    }, [showModal])

    const closeModalHandler = () => {
        setShowModal(!showModal)
        setSelectedPlaces([])
    }

    return (
        <React.Fragment>
            <Modal
                title="Edit Event"
                show={showModal}
                size="lg"
                onCloseModal={closeModalHandler}
                showIcon={true}
            >
                <Form onSubmit={handleSubmit(eventHandler)}>
                    <Row className="justify-content-center">
                        <Col md={11}>
                            <div className="profile_info">
                                <Row className="profile_fields">

                                    <Col md={6} className="thumb_image_container text-start">
                                        <label>Event Image</label>
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            id="image1"
                                            name="image1"
                                            onChange={handleChangeImage}
                                        />
                                        <div className="thumb_image">
                                            <label htmlFor="image1">
                                                <img
                                                    src={
                                                        previewImg ?
                                                            previewImg
                                                            : `${cloudURL}${MediaPath?.events}${eventData?.image}`
                                                    }
                                                    alt=""
                                                />
                                            </label>
                                        </div>
                                        {profileError && <span className="error_msg">{profileError}</span>}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Event Title</label>
                                        <div className="signup_input">
                                            <img src="/images/AccountIcon.png" alt="" />
                                            <input
                                                placeholder="Enter Event Title"
                                                {...register(
                                                    "eventTitle",
                                                    eventValidation.eventTitle
                                                )}
                                            />
                                        </div>
                                        {errors.eventTitle && (
                                            <span className="error_msg">
                                                {errors?.eventTitle?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event Start Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "eventStartDate",
                                                    eventValidation.eventStartDate
                                                )}
                                            />
                                        </div>

                                        {errors.eventStartDate && (
                                            <span className="error_msg">
                                                {errors?.eventStartDate?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event End Date</label>
                                        <div className="signup_input">
                                            <BsCalendar2Date />
                                            <input
                                                type="date"
                                                {...register(
                                                    "eventEndDate",
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="py-2 text-start">
                                        <label>Event Time</label>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={timings}
                                                    onChange={(e) => setTimings(e.toString())}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className={'time_picker'}
                                                            fullWidth
                                                            placeholder="Enter OpenTime"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Event Details</label>
                                        <textarea
                                            rows={4}
                                            className="form-control"
                                            placeholder="Enter Event Description"
                                            {...register(
                                                "eventDescription",
                                                eventValidation.eventDescription
                                            )}
                                        />
                                        {errors.eventDescription && (
                                            <span className="error_msg">
                                                {errors?.eventDescription?.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col md={12} className="py-2 text-start">
                                        <label>Places</label>
                                        <Select
                                            defaultValue={arr}
                                            isMulti
                                            isClearable
                                            name="places"
                                            placeholder="Select Places"
                                            options={options}
                                            onChange={handlePlaces}
                                            className="basic-multi-select form-control"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <div className="text-end">
                                            <button type="submit" className="update_btn">
                                                {updateLoading ? <Spinner animation="border" size="sm" /> : "EDIT"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {
                loading ? <Loader /> :
                    <div className="table_div">
                        <h4>Events</h4>
                        {
                            getAllEvents?.length > 0 ?

                                <Table responsive >
                                    <thead>
                                        <tr style={{ backgroundColor: "#8080800f" }}>
                                            {/* <th className="text-center">IMAGE</th> */}
                                            <th className="text-center">EVENT TITLE</th>
                                            {/* <th className="text-center">PLACE</th> */}
                                            <th className="text-center">START DATE</th>
                                            <th className="text-center">END DATE</th>
                                            {/* <th className="text-center">TIME</th> */}
                                            <th className="text-center">DESCRIPTION</th>
                                            <th className="text-center">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getAllEvents?.map((data: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        {/* <td className="text-center">
                                                            <img className='coupons_img' src={`${cloudURL}${MediaPath?.image}${data?.image}`} alt="service1" />
                                                        </td> */}
                                                        <td className="text-center">{data?.title}</td>
                                                        {/* <td className="text-center">{data?.Place}</td> */}
                                                        <td className="text-center">{data?.start_date}</td>
                                                        <td className="text-center">{data?.end_date}</td>
                                                        {/* <td className="text-center">{data?.eventTime}</td> */}
                                                        <td className="text-truncate text-center" style={{ maxWidth: "200px" }}>{data?.description}</td>
                                                        <td className="text-center">
                                                            <div className="table_icons">
                                                                <FaEdit onClick={() => modalHandler(data)} />
                                                                <AiFillDelete onClick={() => onDeleteHandler(data?._id)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <p
                                    className="text-center"
                                    style={{ fontSize: "20px", fontWeight: "500", padding: '15px' }}
                                >
                                    No Events Found
                                </p>
                        }
                    </div>
            }
        </React.Fragment>
    )
}
export default EventList
