import React, { useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import "./Header.css";
import { successNotify } from "../../Utils/toast";
import { AppDispatch } from "../../Redux/Store";
import { getUserDetails } from "../../Redux/Action/Auth";
import { CurrentUserInfo, cloudURL, MediaPath } from "../../Utils/helper";

const Header = (props: any) => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const { cartCount } = useSelector((state: any) => state.cart);
  const { userDetail } = useSelector((state: any) => state.userDetail);
  const { updateUserDetail } = useSelector((state: any) => state.updateUserDetail);

  const goToShop = (shopCategory: string) => {

    if (shopCategory === 'Hookah Bars') {
      navigate(`/find-shop?shop_type=63a14cbe3683d00187f64031&shopName=&lat=&lng=`)
    }

    else if (shopCategory === 'Smoke shop') {
      navigate(`/find-shop?shop_type=63a14cbe3683d00187f64033&shopName=&lat=&lng=`)
    }

  };

  const logoutHandler = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("checkout");
    successNotify("Logout Successfully!")
    navigate("/");
  };

  useEffect(() => {
    if (CurrentUserInfo()) {
      dispatch(getUserDetails())
    }
  }, [dispatch])

  useEffect(() => {
    if (updateUserDetail?.status === 1) {
      dispatch(getUserDetails())
    }
  }, [dispatch, updateUserDetail])

  const dashboardHandler = () => {
    localStorage.removeItem("tab");
    localStorage.removeItem("customerTab");
    if (userDetail?.roles[0]?.name === "customer") {
      navigate("/customer/dashboard");
    }
    else if (userDetail?.roles[0]?.name === "owner") {
      navigate("/owner/dashboard", { state: { plan: 'free' } });
    }

    else if (userDetail?.roles[0]?.name === "admin") {
      navigate("/admin/dashboard");
    }

    else if (userDetail?.roles[0]?.name === "operator") {
      navigate("/operator/all-listings");
    }
  };

  let showNav;
  if (CurrentUserInfo()?.roles) {
    showNav = (
      <div className="d-flex align-items-center login_nav">
        {
          userDetail?.avatar ?
            <img src={`${cloudURL}${MediaPath?.avatar}${userDetail?.avatar}`} alt="" />
            : <img src='/images/dummy_img.png' alt="" />
        }
        <NavDropdown title={userDetail?.first_name} id="basic-nav-dropdown">
          <NavDropdown.Item href="#" onClick={dashboardHandler}>
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={logoutHandler}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  }
  else {
    showNav = (
      <>
        <NavLink className="nav-link" to="/login">
          Login
        </NavLink>
        <NavLink className="nav-link" to="/register">
          Register
        </NavLink>

        <button
          className="sign_btn mx-2"
          onClick={() => navigate("/owner/register")}
        >
          {/* <AiOutlinePlus /> */}
          Add hookah place
        </button>
      </>
    );
  }

  return (
    <React.Fragment>
      <p className="navbar_text">
        Own a Hookah Business? List it Free..
        <span onClick={() => navigate("/owner/register")}> Get Started</span>
      </p>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>
            <img src="/images/logo.png" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <p className="nav-link" onClick={() => goToShop("Hookah Bars")}>
                Hookah Bars
              </p>
              <p className="nav-link" onClick={() => goToShop("Smoke shop")}>
                Smoke shops
              </p>
              <NavLink className="nav-link" to="/product-shop">
                Product shop
              </NavLink>

              {showNav}

              {cartCount > 0 ? (
                <div className={"cart_link"} onClick={() => navigate("/checkout")}>
                  <FiShoppingCart />
                  <sup>{cartCount}</sup>
                </div>
              ) : (
                <div className={"cart_link"}>
                  <FiShoppingCart />
                  <sup>{cartCount}</sup>
                </div>
              )}

              {/* <p className="nav-link" onClick={() => props.setLocationShow(true)}>
                Change Location
              </p> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
