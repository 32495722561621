import React, { useState, useEffect } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye, FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { adminBusinessPlaceList, adminDeleteBusinessPlaceList } from "../../../../Redux/Action/Admin";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../../../Utils/Loader";
import DeleteModal from "../../../../Components/Modal/Modal";
import { successNotify } from "../../../../Utils/toast";
import { useForm, SubmitHandler } from "react-hook-form";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AllListings = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [show, setShow] = useState(false)
  const [getPlaceId, setGetPlaceId] = useState('')
  const [listingTab, setListingTab] = useState("ALL");
  const [loaderShow, setLoaderShow] = useState(false)

  const { loading, businessPlaceList } = useSelector((state: any) => state.adminBusinessPlaceList);
  const { loading: deleteLoading, businessPlaceDelete } = useSelector((state: any) => state.adminDeleteBusinessPlace);
  const { register, handleSubmit, reset } = useForm<any>();

  let query = useQuery();
  let ownerId: any = query.get("owner")
  let shopTypeGet: any = query.get("shopType")

  useEffect(() => {
    if (businessPlaceList) {
      setLoaderShow(false)
    }
  }, [businessPlaceList])

  useEffect(() => {
    if (businessPlaceDelete?.status === 1) {
      successNotify("Place Deleted Successfully")
      dispatch({ type: "ADMIN_DELETE_BUSINESS_PLACE_LIST_RESET" })
      setShow(!show)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPlaceDelete])

  useEffect(() => {
    if (ownerId) {
      dispatch(adminBusinessPlaceList(ownerId, '', ''))
    }
    else if (shopTypeGet) {
      dispatch(adminBusinessPlaceList('', '', shopTypeGet))
    }
    else {
      dispatch(adminBusinessPlaceList('', '', ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPlaceDelete?.status === 1])

  const deleteHandler = (id: any) => {
    setShow(!show)
    setGetPlaceId(id)
  }

  const deletePlace = () => {
    dispatch(adminDeleteBusinessPlaceList(getPlaceId))
  }

  const showStatus = (status: any) => {
    if (status === '0') {
      return <td className=""> <span className="pending">Pending</span> </td>
    }
    else if (status === '1') {
      return <td className=""> <span className="approved">Approved</span> </td>
    }
    else if (status === '3') {
      return <td className=""> <span className="rejected">Rejected</span> </td>
    }
    else if (status === '2') {
      return <td className=""> <span className="banned">De-Activate</span> </td>
    }
    else if (status === '4') {
      return <td className=""> <span className="rejected">Expired</span> </td>
    }
  }

  const editHandler = (id: any) => {
    navigate(`/admin/edit-listings/${id}`)
  }

  const showListing = (Listing: any) => {
    if (businessPlaceList.filter((list: any) => list?._id?.active === Listing).length > 0) {
      return (
        <Table responsive>
          <thead>
            <tr style={{ backgroundColor: "#8080800f" }}>
              <th>S.NO</th>
              <th>PLACE NAME</th>
              <th>OWNER NAME</th>
              <th>LOCATION</th>
              <th>SHOP TYPE</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          {businessPlaceList
            .filter((list: any) => list?._id?.active === Listing)
            .map((list: any, index: number) => (
              <tbody key={index}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{list._id?.name}</td>
                  <td>{list._id?.owner?.first_name}</td>
                  <td>{`${list._id.location.city}, ${list._id.location.country}`}</td>
                  <td className="">{list?._id?.shop_type?.name}</td>
                  {showStatus(list._id.active)}
                  <td>
                    <div className="table_icons">
                      <FaEye
                        onClick={() =>
                          navigate(`/admin/view-listings/${list?._id?.slug}`)
                        }
                      />
                      <FaEdit onClick={() => editHandler(list?._id?.place_id)} />
                      <AiFillDelete onClick={() => deleteHandler(list?._id?.place_id)} />
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
        </Table>
      );
    }
    else return <p className="text-center py-3 fw-bold">No Data Found</p>;
  };

  const tabs = ["ALL", "PENDING", "APPROVED", "BANNED", "REJECTED"]

  const productSearchHandler: SubmitHandler<any> = (data: any) => {
    setLoaderShow(true)
    if (ownerId) {
      dispatch(adminBusinessPlaceList(ownerId, data.shopName, ''))
    }
    else {
      dispatch(adminBusinessPlaceList('', data.shopName, ''))
    }
    reset();
  }

  const ResetHandler = () => {
    if (ownerId) {
      dispatch(adminBusinessPlaceList(ownerId, '', ''))
    }
    else {
      dispatch(adminBusinessPlaceList('', '', ''))
    }
  }

  const filterType = (type: string) => {
    if (type === 'Hookahbars') {
      dispatch(adminBusinessPlaceList('', '', '63a14cbe3683d00187f64031'))
    }
    else {
      dispatch(adminBusinessPlaceList('', '', '63a14cbe3683d00187f64033'))
    }
  }

  return (
    <div className="page_responsive">
      <DeleteModal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Delete"
      >
        <div>
          <h5>Are you sure you want to delete this Place??</h5>
          <div>
            <button className="mx-3 back_btn" onClick={deletePlace}>{deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
            <button className="back_btn" onClick={() => setShow(!show)}>No</button>
          </div>
        </div>
      </DeleteModal>

      <h1 className="main_heading">All Listings</h1>

      {
        !ownerId &&
        <div className="text-center">
          {tabs.map((tab) => <button key={tab} className="sign_btn m-2 mb-5" onClick={() => setListingTab(tab)}> {tab} </button>)}
        </div>
      }

      {
        ownerId && <div className="text-end">
          <button className="back_btn"
            onClick={() => navigate('/admin/all-owners')}
          >Back</button>
        </div>
      }

      <Form onSubmit={handleSubmit(productSearchHandler)}>
        <Row>
          <Col md={3} className="py-2">
            <div className="signup_input">
              <img src="/images/AccountIcon.png" alt="" />
              <input
                placeholder="Search by Shop Name"
                {...register("shopName")}
              />
            </div>
          </Col>
          <Col md={3} className="py-2">
            <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
            <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
          </Col>
        </Row>
      </Form>

      {
        !ownerId &&
        <div className="text-center" style={{ margin: "25px 0" }}>
          <h5>Do you want to create a Place?? <span
            style={{ color: '#971b59', fontWeight: '700', cursor: "pointer" }}
            onClick={() => navigate('/admin/select-package')}>Create Now</span></h5>
        </div>
      }

      {
        !ownerId &&
        <div className="text-center">
          <button className="sign_btn m-2 mb-2" onClick={() => filterType('Hookahbars')}> Hookahbars </button>
          <button className="sign_btn m-2 mb-2" onClick={() => filterType('Smoke')}> Smoke Shops </button>
        </div>
      }

      <div className="table_div mb-3">
        <h4>{listingTab} BUSINESS LISTINGS</h4>
        {
          loading ?
            <div
              style={{ height: '50vh' }}
              className={"d-flex justify-content-center align-items-center"}
            >
              <Loader />
            </div>
            :
            <>
              {listingTab === "ALL" && (
                <Table responsive className="mb-0">
                  <thead>
                    <tr style={{ backgroundColor: "#8080800f" }}>
                      <th className="">S.NO</th>
                      <th className="">PLACE NAME</th>
                      <th className="">OWNER NAME</th>
                      <th className="">LOCATION</th>
                      <th className="">SHOP TYPE</th>
                      <th className="">STATUS</th>
                      <th className="">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businessPlaceList?.map((list: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="">{index + 1}</td>
                          <td className="">{list._id?.name}</td>
                          <td className="">{list._id?.owner?.first_name}</td>
                          <td className="">{`${list._id.location.city}, ${list._id.location.country}`}</td>
                          <td className="">{list?._id?.shop_type?.name}</td>
                          {showStatus(list._id.active)}
                          <td className="">
                            <div className="table_icons">
                              <FaEye
                                onClick={() =>
                                  navigate(`/admin/view-listings/${list._id.slug}`, { state: { placeDetail: list } })
                                }
                              />
                              <FaEdit onClick={() => editHandler(list?._id?.place_id)} />
                              <AiFillDelete onClick={() => deleteHandler(list?._id?.place_id)} />
                            </div>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </Table>
              )}

              {listingTab === "PENDING" && <>{showListing("0")}</>}
              {listingTab === "APPROVED" && <>{showListing("1")}</>}
              {listingTab === "BANNED" && <>{showListing("2")}</>}
              {listingTab === "REJECTED" && <>{showListing("3")}</>}
            </>
        }
      </div>
    </div>
  );
};
export default AllListings;