const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
};

export const authValidation = {
  firstName: {
    required: {
      value: true,
      message: "firstName cannot be empty",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "lastName cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },

  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  oldPassword: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  newPassword: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  confirmPassword: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  phoneNumber: {
    required: {
      value: true,
      message: "Phone number cannot be empty"
    },
    pattern: {
      value: regex.phoneNumber,
      message: "Phone number is not valid"
    }
  },
  role: {
    required: {
      value: true,
      message: "Cannot be empty",
    },
  },
};

export const checkoutValidation = {
  firstName: {
    required: {
      value: true,
      message: "firstName cannot be empty",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "lastName cannot be empty",
    },
  },
  country: {
    required: {
      value: true,
      message: "lastName cannot be empty",
    },
  },
  city: {
    required: {
      value: true,
      message: "lastName cannot be empty",
    },
  },
  address: {
    required: {
      value: true,
      message: "lastName cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: "Phone number cannot be empty"
    },
    pattern: {
      value: regex.phoneNumber,
      message: "Phone number is not valid"
    }
  },
};

export const placeValidation = {
  business_name: {
    required: {
      value: true,
      message: "placeName cannot be empty",
    },
  },
  price_range: {
    required: {
      value: true,
      message: "priceRange cannot be empty",
    },
  },
  description: {
    required: {
      value: true,
      message: "description cannot be empty",
    },
  },
  place_type: {
    required: {
      value: true,
      message: "Please select Place Type",
    },
  },
  amenities: {
    required: {
      value: true,
      message: "Please select atleast one amenities",
    },
  },
  country: {
    required: {
      value: true,
      message: "country cannot be empty",
    },
  },
  city: {
    required: {
      value: true,
      message: "cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  phone_number: {
    required: {
      value: true,
      message: "Phone number cannot be empty"
    },
    pattern: {
      value: regex.phoneNumber,
      message: "Phone number is not valid"
    }
  },
  hours: {
    required: {
      value: true,
      message: "Please must select Hours",
    },
  }
};

export const enquiryValidation = {
  name: {
    required: {
      value: true,
      message: "Name cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: "Phone number cannot be empty"
    },
    pattern: {
      value: regex.phoneNumber,
      message: "Phone number is not valid"
    }
  },
  message: {
    required: {
      value: true,
      message: "Message cannot be empty",
    },
  },
};

export const couponsValidation = {
  couponCode: {
    required: {
      value: true,
      message: "Coupon Code required",
    },
  },
  couponStartDate: {
    required: {
      value: true,
      message: "Coupon Start Date required",
    },
  },
  couponEndDate: {
    required: {
      value: true,
      message: "Coupon End Date required",
    },
  },
  couponDescription: {
    required: {
      value: true,
      message: "Coupon Description required",
    },
  },
};

export const eventValidation = {
  eventTitle: {
    required: {
      value: true,
      message: "Event Title required",
    },
  },
  eventStartDate: {
    required: {
      value: true,
      message: "event Start Date required",
    },
  },
  eventEndDate: {
    required: {
      value: true,
      message: "event End Date required",
    },
  },
  eventDescription: {
    required: {
      value: true,
      message: "event Description required",
    },
  },
};

export const productValidation = {
  productName: {
    required: {
      value: true,
      message: "Product Name cannot be empty",
    },
  },
  productPrice: {
    required: {
      value: true,
      message: "Product Price cannot be empty",
    },
  },
  productDescription: {
    required: {
      value: true,
      message: "Product Description cannot be empty",
    },
  },
  productDetail: {
    required: {
      value: true,
      message: "product Details cannot be empty",
    },
  },
};