import React from "react";
import "./Home.css";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Location from "../../../Components/Location/Location";
import HookahShops from "./HookahShops/HookahShops";
import AddBusiness from "../../../Components/AddBusiness/AddBusiness";
import SuggestPlaces from "../../../Components/SuggestPlaces/SuggestPlaces";
import { useSelector } from "react-redux";
import Loader from "../../../Utils/Loader";

const Home = () => {
  const navigate = useNavigate();

  const { getCity, loading } = useSelector((state: any) => state.getCurrentCities);

  const goToShop = (shopCategory: string) => {

    if (shopCategory === 'Hookah Bars') {
      navigate(`/find-shop?shop_type=63a14cbe3683d00187f64031&shopName=&lat=&lng=`)
    }

    else if (shopCategory === 'smoke shop') {
      navigate(`/find-shop?shop_type=63a14cbe3683d00187f64033&shopName=&lat=&lng=`)
    }
  };

  const goToShopByCity = (getCity: any) => {
    navigate(`/find-shop?&city=${getCity}&lat=&lng=`)
  }

  const cityImages = [
    '/images/city1.png',
    '/images/city2.png',
    '/images/city3.png',
    '/images/city4.png',
  ]

  return (
    <React.Fragment>
      <div className="main_home">
        <Location />
      </div>

      <HookahShops />

      <Container className="browse_by">
        <h1>Browse by </h1>

        <Row>
          <Col
            md={4}
            className={"browse_col"}
            onClick={() => goToShop("Hookah Bars")}
          >
            <img src="/images/category4.png" alt="category" />
            <h5>hookah bars</h5>
          </Col>
          <Col
            md={4}
            className={"browse_col"}
            onClick={() => goToShop("smoke shop")}
          >
            <img src="/images/category1.png" alt="category" />
            <h5>smoke shops</h5>
          </Col>
          <Col
            md={4}
            className={"browse_col"}
            onClick={() => navigate("/events")}
          >
            <img src="/images/category3.png" alt="category" />
            <h5>Events</h5>
          </Col>
        </Row>
      </Container>

      <AddBusiness />

      <div className="products-container">
        <Container>
          <Row>
            <Col md={6}>
              <div className="py-5 my-3 left-section">
                <h4>Hookah Products</h4>
                <p>
                  Featured Hookah Products, Online Retailers, Shisha Tobacco
                </p>
                <img
                  src="images/hookah.png"
                  style={{ borderRadius: "10px" }}
                  alt=""
                />
                <button onClick={() => navigate("/product-shop")}>
                  SEE HOOKAH PRODUCTS
                </button>
              </div>
            </Col>
            <Col md={6}>
              <div className="py-5 right-section">
                <h4>Welcome!</h4>
                <p>
                  Hookahbars.com was created to be your ultimate hookah bar
                  directory. We strive to present our users with accurate
                  information on all hookah bars in their area as well as serve
                  as a forum for hookah lovers to connect with each other in
                  their geographical region.
                </p>
                <p>
                  Hookah has become an ultimate social pastime that is meant to
                  be shared by friends. We intend to soon create a social
                  networking blog where hookah enthusiats can share their
                  experiences and recommend their favorite hookah bars to
                  others.
                </p>
                <hr />
                <p>
                  Hookah bars can list their services for{" "}
                  <span className="col-purple">FREE</span>. Users can use this
                  site for <span className="col-purple">FREE</span>.
                </p>
                <p>
                  If there is a hookah bar that we’ve missed or a listing is
                  incorrect, please don’t hesitate to contact us and let us
                  know.
                </p>
                <button onClick={() => navigate("/owner/register")}>
                  SIGN UP OR GET INFO
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {
        getCity?.data?.length > 0 &&
        <Container className="browse_by">
          <h1>Popular Cities</h1>

          {
            loading ? <Loader /> :
              <Row>
                {
                  getCity?.data?.map((c: any, index: number) => {
                    return (
                      <Col
                        key={c}
                        md={3}
                        className={"browse_col"}
                        onClick={() => goToShopByCity(c._id.city)}
                      >
                        <h6>{c._id.country}</h6>
                        <img
                          src={cityImages[index]}
                          alt="category"
                          style={{ height: "400px", borderRadius: "10px" }}
                        />
                        <h5 className="city_name">
                          {c._id.city} <br /> <span>{c.total} Places</span>
                        </h5>
                      </Col>
                    )
                  })
                }
              </Row>
          }
        </Container>
      }

      <SuggestPlaces />

      {/* <Faqs /> */}

    </React.Fragment>
  );
};
export default Home;