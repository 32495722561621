import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminOrderDetail, adminOrderStatus } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from "../../../../../Utils/Loader";
import { cloudURL, MediaPath } from "../../../../../Utils/helper";
import DeleteModal from "../../../../../Components/Modal/Modal";
import { successNotify } from "../../../../../Utils/toast";

const OrderDetails = () => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams()
  const [show, setShow] = useState(false)
  const [addStatus, setAddStatus] = useState('')

  const { loading, adminOrderDetails } = useSelector((state: any) => state.adminOrderListDetail);

  const { loading: updatedLoading, adminOrderListStatus } = useSelector((state: any) => state.adminOrderListStatus);

  useEffect(() => {
    if (adminOrderListStatus?.status === 1) {
      successNotify("Order Status Updated!!")
      dispatch({ type: 'ADMIN_ORDER_STATUS_RESET' })
      setShow(!show)
      navigate('/admin/orders')
    }
  }, [adminOrderListStatus?.status === 1])

  useEffect(() => {
    dispatch(adminOrderDetail(id))

    return () => {
      setAddStatus('')
    }
  }, [])

  const statusUpdate = (status: string) => {
    setShow(!show)
    setAddStatus(status)
  }

  const showOrderBtn = (status: any) => {
    if (status === "1") {
      return <div className="text-end my-4">
        <button className="back_btn mx-2" onClick={() => statusUpdate("approve")}>Approved</button>
        <button className="back_btn" onClick={() => statusUpdate("reject")}>Rejected</button>
      </div>
    }
  }

  const statusHandler = () => {
    dispatch(adminOrderStatus(id, addStatus))
  }

  return (
    <div className="page_responsive">
      <DeleteModal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Order Status"
      >

        <div>
          <h5>Are you sure {addStatus === 'approve' ? 'Approve' : 'Rejected'}  this Order??</h5>
          <div>
            <button className="mx-3 back_btn" onClick={statusHandler}>{updatedLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
            <button className="back_btn" onClick={() => setShow(!show)}>No</button>
          </div>
        </div>
      </DeleteModal>
      <Container>
        {
          loading ?
            <Loader />
            :
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="main_heading">Order Details</h1>
                  <button
                    className="back_btn"
                    onClick={() => navigate("/admin/orders")}
                  >
                    Back
                  </button>
                </div>

                <div className="order_id">
                  <h6>
                    Order ID: <span>{id}</span>
                  </h6>
                  <h5>
                    Total: <span>${adminOrderDetails?.amount}</span>
                  </h5>
                </div>

                <div className="order_product_detail">
                  <h6>Product Details</h6>
                  <div className="p-4">

                    <Row className="product_row">
                      {
                        adminOrderDetails?.details?.map((product: any) => {
                          return (
                            <>
                              <Col md={6} className={'product_img'}>
                                <img src={`${cloudURL}${MediaPath.product_image}${product?.product?.images[0]}`} alt="" />
                                <p>{product?.product?.title}</p>
                              </Col>
                              <Col md={2}>
                                <p>PRICE: ${product?.price}</p>
                              </Col>

                              <Col md={2}>
                                <p>QTY: {product?.quantity}</p>
                              </Col>

                              <Col md={2}>
                                <p>TOTAL: ${product?.gross_total}</p>
                              </Col>
                            </>
                          )
                        })
                      }
                    </Row>
                  </div>
                </div>

                <Row className="my-3 justify-content-end">
                  <Col md={6}>
                    <div className="order_detail_left">
                      <h6>Billing Address</h6>

                      <div className="p-3">
                        <p><span>Name:</span> {adminOrderDetails?.billing?.name}</p>
                        <p><span>Adress:</span> {adminOrderDetails?.billing?.address}</p>
                        <p><span>Phone:</span> {adminOrderDetails?.billing?.phone}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="order_detail_left">
                      <h6>Shipping Address</h6>

                      <div className="p-3">
                        <p><span>Name:</span> {adminOrderDetails?.shipping?.name}</p>
                        <p><span>Adress:</span> {adminOrderDetails?.shipping?.address}</p>
                        <p><span>Phone:</span> {adminOrderDetails?.shipping?.phone}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                {
                  showOrderBtn(adminOrderDetails?.active)
                }
                <div>

                </div>
              </Col>
            </Row>
        }
      </Container>
    </div>
  );
};

export default OrderDetails;
