import React from 'react';
import { Container } from 'react-bootstrap';
import './Advertise.css';

const Advertise = () => {
    return (
        <div className='advertise_main'>
            <Container>
                <div className="shop_details container"><h1>Advertise</h1></div>

                <div>
                    <h4>Hookah Bar Owners</h4>

                    <p>
                        If you are a Hookah Bar owner or hookah related smoke shop owner and wish to upgrade your free BASIC listing
                        to a PREMIUM listing, please visit www.hookahbars.com and click on register a new business.
                    </p>
                </div>

                <div>
                    <h4>Banner Advertisements</h4>
                    <h6>
                        I have an on-line retail business or a hookah product/accessory but I’m not a hookah bar owner.
                        Can I still advertise on hookahbars.com?
                    </h6>

                    <ul>
                        <li>Most Definitely!  While our listing features caters mainly to hookah bar owners we welcome on-line
                            retail businesses and vendors to advertise on our site.  Advertising on hookahbars.com
                            will increase your brand recognition and on-line visibility to this huge hookah community.
                            In addition, hookahbars.com plans to expand to other regions internationally.</li>
                    </ul>
                </div>

                <div>
                    <p style={{ fontWeight: "600" }}>See all the differences between free and premium paid listings:</p>

                    <ul className='listings_li'>
                        <li>More images</li>
                        <li>Ability to upload video</li>
                        <li>Ability to post a menu.</li>
                        <li>Ability to share your business on social media</li>
                        <li>More features will be added where you will enjoy their benefits</li>
                    </ul>
                </div>
            </Container>
        </div>
    )
}

export default Advertise