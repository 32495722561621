import CreateShop from "../../Pages/CreateShop/CreateShop";
import PaymentMethod from "../../Pages/PaymentMethod/PaymentMethod";
import PaymentPlans from "../../Pages/PaymentPlans/PaymentPlans";
import OwnerDashboard from "../Pages/Dashboard/Dashboard";
import OwnerEnquiryDetails from "../Pages/Enquiry/EnquiryDetails/EnquiryDetails";
import EditPlaces from "../Pages/Places/EditPlaces/EditPlaces";

export const OwnerRoutes: any[] = [
  {
    path: "/owner/dashboard",
    component: <OwnerDashboard />,
  },
  {
    path: "/owner/enquiry-details/:id",
    component: <OwnerEnquiryDetails />,
  },
  {
    path: "/owner/edit-place/:id",
    component: <EditPlaces />,
  },
  {
    path: "/owner/create-shop",
    component: <CreateShop />,
  },
  {
    path: "/owner/payment-plans",
    component: <PaymentPlans />,
  },
  {
    path: "/owner/payment-method",
    component: <PaymentMethod />,
  }
];
