import React from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import { settings } from "../../../../Utils/slick";
import { MdLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { cloudURL, MediaPath } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";

const HookahShops = () => {

  const { bpCurrent, loading } = useSelector((state: any) => state.BPCurrentListing);
  // const { bpListings, loading } = useSelector((state: any) => state.BPListing);

  console.log(bpCurrent, "IN HOOKAH SHOPS")

  const shopViewHandler = (slug: any) => {
    window.location.replace(`/shop-view/${slug}`);
  };

  let businessShop;

  if (bpCurrent?.data?.length > 0 && bpCurrent?.data?.length <= 4) {
    businessShop = <Row>
      {
        bpCurrent?.data?.map((data: any, index: any) => {
          return (
            <Col md={3} key={data}>
              <div className="shops" onClick={() => shopViewHandler(data?._id?.slug)}>
                <div className="shops">
                  {
                    data.is_premium === 1 ? <img src={'/images/featuredIcon.png'} alt="" className="featured_icon" /> : null
                  }

                  {
                    data?.discountText ? <h5>*offers discount</h5> : null
                  }

                  <img className="places_image" src={`${cloudURL}${MediaPath?.image}${data?._id?.image}`} alt="service1" />
                  <p>{data?._id?.name}.</p>

                  <p className="location_text">
                    <MdLocationOn /> {data?._id?.location?.address}
                  </p>

                  <div className="show_now_container">
                    <a className="back_btn" target={'_blank'} rel="noreferrer" href={`/shop-view/${data?._id?.slug}`}>View</a>
                  </div>
                </div>
              </div>
            </Col>
          )
        })
      }
    </Row>
  }
  else if (bpCurrent?.data?.length > 4) {
    businessShop = <Slider {...settings}>
      {
        bpCurrent?.data?.map((data: any, index: any) => {
          return (
            <div className="shops" key={data} onClick={() => shopViewHandler(data?._id?.slug)}>
              <div className="shops">
                {
                  data.is_premium === 1 ? <img src={'/images/featuredIcon.png'} alt="" className="featured_icon" /> : null
                }

                {
                  data?.discountText ? <h5>*offers discount</h5> : null
                }

                <img className="places_image" src={`${cloudURL}${MediaPath?.image}${data?._id?.image}`} alt="service1" />
                <p>{data?._id?.name}.</p>

                <p className="location_text">
                  <MdLocationOn /> {data?._id?.location?.address}
                </p>

                <div className="show_now_container">
                  <a className="back_btn" target={'_blank'} rel="noreferrer" href={`/shop-view/${data?._id?.slug}`}>View</a>
                </div>
              </div>
            </div>
          )
        })
      }
    </Slider>
  }
  else {
    businessShop = <p
      className="text-center"
      style={{ fontSize: "20px", fontWeight: "500" }}
    >
      No Places Found
    </p>
  }

  return (
    <React.Fragment>
      {
        bpCurrent?.data?.length > 0 &&
        <Container className="home_slider">
          <h1>Hookah Places near me</h1>
          {loading ? <Loader /> :
            <React.Fragment>
              {businessShop}
            </React.Fragment>
          }
        </Container>
      }
    </React.Fragment>
  );
};

export default HookahShops;
