import React, { useState, useEffect } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { adminBusinessPlaceList, adminDeleteBusinessPlaceList, operatorBusinessPlaceList } from "../../../../Redux/Action/Admin";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../../../Utils/Loader";
import DeleteModal from "../../../../Components/Modal/Modal";
import { successNotify } from "../../../../Utils/toast";
import { FaEdit } from "react-icons/fa";
import { useForm, SubmitHandler } from "react-hook-form";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AllListings = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [listingTab, setListingTab] = useState("ALL");
    const [loaderShow, setLoaderShow] = useState(false)

    const { loading, businessPlaceList } = useSelector((state: any) => state.operatorBusniessPlaceList);

    useEffect(() => {
        dispatch(operatorBusinessPlaceList())
    }, [])

    console.log(businessPlaceList)

    useEffect(() => {
        if (businessPlaceList) {
            setLoaderShow(false)
        }
    }, [businessPlaceList])


    const showStatus = (status: any) => {

        if (status === '0') {
            return <td className=""> <span className="pending">Pending</span> </td>
        }
        else if (status === '1') {
            return <td className=""> <span className="approved">Approved</span> </td>
        }
        else if (status === '3') {
            return <td className=""> <span className="rejected">Rejected</span> </td>
        }
        else if (status === '2') {
            return <td className=""> <span className="banned">De-Activate</span> </td>
        }
    }

    return (
        <div className="page_responsive">
            {/* <DeleteModal
                show={show}
                size="lg"
                onCloseModal={() => setShow(!show)}
                showIcon={true}
                title="Delete"
            >

                <div>
                    <h5>Are you sure you want to delete this Place??</h5>
                    <div>
                        <button className="mx-3 back_btn" onClick={deletePlace}>{deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
                        <button className="back_btn" onClick={() => setShow(!show)}>No</button>
                    </div>
                </div>
            </DeleteModal> */}

            <h1 className="main_heading">All Listing</h1>

            {/* {
                !ownerId &&
                <div className="text-center">
                    {tabs.map((tab) => <button className="sign_btn m-2 mb-5" onClick={() => setListingTab(tab)}> {tab} </button>)}
                </div>
            } */}

            {/* {
                ownerId && <div className="text-end">
                    <button className="back_btn"
                        onClick={() => navigate('/admin/all-owners')}
                    >Back</button>
                </div>
            } */}

            {/* <Form onSubmit={handleSubmit(productSearchHandler)}>
                <Row>
                    <Col md={3} className="py-2">
                        <div className="signup_input">
                            <img src="/images/AccountIcon.png" alt="" />
                            <input
                                placeholder="Search by Shop Name"
                                {...register("shopName")}
                            />
                        </div>
                    </Col>
                    <Col md={3} className="py-2">
                        <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
                        <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
                    </Col>
                </Row>
            </Form> */}

            {/* {
                !ownerId &&
                <div className="text-center" style={{ margin: "25px 0" }}>
                    <h5>Do you want to create a Place?? <span
                        style={{ color: '#971b59', fontWeight: '700', cursor: "pointer" }}
                        onClick={() => navigate('/admin/create-listings')}>Create Now</span></h5>
                </div>
            } */}

            <div className="table_div mb-3">
                <h4>{listingTab} BUSINESS LISTINGS</h4>
                {
                    loading ?
                        <div
                            style={{ height: '50vh' }}
                            className={"d-flex justify-content-center align-items-center"}
                        >
                            <Loader />
                        </div>
                        :
                        <>
                            {/* {listingTab === "ALL" && ( */}
                            <Table responsive className="mb-0">
                                <thead>
                                    <tr style={{ backgroundColor: "#8080800f" }}>
                                        <th className="">S.NO</th>
                                        <th className="">PLACE NAME</th>
                                        <th className="">OWNER NAME</th>
                                        <th className="">LOCATION</th>
                                        <th className="">STATUS</th>
                                        <th className="">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessPlaceList?.map((list: any, index: number) => (
                                        <tr key={index}>
                                            <td className="">{index + 1}</td>
                                            <td className="">{list._id.name}</td>
                                            <td className="">{list._id.owner.first_name}</td>
                                            <td className="">{`${list._id.location.city}, ${list._id.location.country}`}</td>
                                            {showStatus(list._id.active)}
                                            <td className="">
                                                <div className="table_icons">
                                                    <FaEye
                                                        onClick={() =>
                                                            navigate(`/operator/view-listings/${list._id.slug}`, { state: { placeDetail: list } })
                                                        }
                                                    />
                                                    {/* <FaEdit onClick={() => editHandler(list?._id?.place_id)} /> */}
                                                    {/* <AiFillDelete onClick={() => deleteHandler(list?._id?.place_id)} /> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* )} */}

                            {/* {listingTab === "PENDING" && <>{showListing("0")}</>} */}
                            {/* {listingTab === "APPROVED" && <>{showListing("1")}</>} */}
                            {/* {listingTab === "BANNED" && <>{showListing("2")}</>} */}
                            {/* {listingTab === "REJECTED" && <>{showListing("3")}</>} */}
                        </>
                }
            </div>
        </div>
    );
};
export default AllListings;