import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminSales } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from '../../../../../Utils/Loader';
import { Table } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
import moment from 'moment';

const Sales = () => {

    // adminGetSalesData
    const navigate = useNavigate();
    const { id } = useParams();

    console.log(id)

    const dispatch: AppDispatch = useDispatch();
    const { loading, adminGetSales } = useSelector((state: any) => state.adminGetSalesData);

    console.log(adminGetSales)

    useEffect(() => {
        dispatch(adminSales(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const orderStatus = (status: string) => {
        if (status === "0") {
            return <td className="text-center"> <span className="pending"> Pending </span></td>
        }
        else if (status === "1") {
            return <td className="text-center"> <span className="completed"> Completed </span></td>
        }
        else if (status === "2") {
            return <td className="text-center "> <span className="approved"> Approved </span> </td>
        }
        else if (status === "3") {
            return <td className="text-center"> <span className="rejected"> Failed </span> </td>
        }
        else if (status === "4") {
            return <td className="text-center "> <span className="rejected"> Rejected </span></td>
        }
        else if (status === "5") {
            return <td className="text-center"> <span className="rejected"> Expired </span></td>
        }
    }

    return (
        <div className='page_responsive'>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id === 'all' ? 'Total Sales' : "Today's Sales"}</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/dashboard")}
                >
                    Back
                </button>
            </div>

            <div className="table_div">
                <h4>Sales</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            {
                                adminGetSales ?
                                    <>
                                        <thead>
                                            <tr style={{ backgroundColor: "#8080800f" }}>
                                                <th className="">ORDER ID</th>
                                                <th className="">DATE</th>
                                                <th className="">ORDER EMAIL</th>
                                                <th className="">TOTAL PRICE</th>
                                                <th className="">STATUS</th>
                                                <th className="">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminGetSales?.data?.map((list: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className="">{index + 1}</td>
                                                        <td className="">{moment(list?.order_date).format('ll')}</td>
                                                        <td className="">{list?.user.email}</td>
                                                        <td className="">{list?.amount}</td>
                                                        {orderStatus(list?.active)}
                                                        <td className="">
                                                            <div className="table_icons">
                                                                <FaEye
                                                                    onClick={() =>
                                                                        navigate(`/admin/order-details/${list?._id}`)
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </>
                                    : <p className='text-center mb-0 mt-2'>No Data Found</p>
                            }
                        </Table>
                }
            </div>
        </div>
    )
}

export default Sales