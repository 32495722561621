import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import rootReducer from './Reducer';

type State = { a: any };
export type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)))

export default store;