const initialState = {
    admin: {}
}

export const AdminDashboardReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_DASHBOARD_REQUEST":
            return {
                loading: true,
                isAuthenticated: false,
                error: false
            }

        case "ADMIN_DASHBOARD_SUCCESS":
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                admin: action.payload.data,
                error: false
            }

        case "ADMIN_DASHBOARD_FAILED":
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                admin: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const AdminCustomerListReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CUSTOMER_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CUSTOMER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                customerList: action.payload.data,
                error: false
            }

        case "ADMIN_CUSTOMER_LIST_FAILED":
            return {
                ...state,
                loading: false,
                customerList: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminCustomerProfileReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CUSTOMER_PROFILE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CUSTOMER_PROFILE_SUCCESS":
            return {
                ...state,
                loading: false,
                customerProfile: action.payload.data,
                error: false
            }

        case "ADMIN_CUSTOMER_PROFILE_FAILED":
            return {
                ...state,
                loading: false,
                customerProfile: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminOwnerListReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_OWNER_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_OWNER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerList: action.payload.data,
                error: false
            }

        case "ADMIN_OWNER_LIST_FAILED":
            return {
                ...state,
                loading: false,
                ownerList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// --- EDIT ---

export const AdminOwnerEditReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_OWNER_EDIT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_OWNER_EDIT_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerEdit: action.payload,
                error: false
            }

        case "ADMIN_OWNER_EDIT_FAILED":
            return {
                ...state,
                loading: false,
                ownerEdit: null,
                error: action.payload
            }

        case "ADMIN_OWNER_EDIT_RESET":
            return {
                ...state,
                loading: false,
                ownerEdit: {},
            }
        default:
            return state
    }
}

// -- DELETE --

export const AdminOwnerDeleteReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_OWNER_DELETE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_OWNER_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerDelete: action.payload,
                error: false
            }

        case "ADMIN_OWNER_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                ownerDelete: null,
                error: action.payload
            }
        case "ADMIN_OWNER_DELETE_RESET":
            return {
                ...state,
                loading: false,
                ownerDelete: {},
            }
        default:
            return state
    }
}

export const AdminCustomerEditReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CUSTOMER_EDIT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CUSTOMER_EDIT_SUCCESS":
            return {
                ...state,
                loading: false,
                customerEdit: action.payload,
                error: false
            }

        case "ADMIN_CUSTOMER_EDIT_FAILED":
            return {
                ...state,
                loading: false,
                customerEdit: null,
                error: action.payload
            }

        case "ADMIN_CUSTOMER_EDIT_RESET":
            return {
                ...state,
                loading: false,
                customerEdit: {},
            }
        default:
            return state
    }
}

export const AdminUserPasswordReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_USER_PASSWORD_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_USER_PASSWORD_SUCCESS":
            return {
                ...state,
                loading: false,
                adminUserPassword: action.payload,
                error: false
            }

        case "ADMIN_USER_PASSWORD_FAILED":
            return {
                ...state,
                loading: false,
                adminUserPassword: null,
                error: action.payload
            }

        case "ADMIN_USER_PASSWORD_RESET":
            return {
                ...state,
                loading: false,
                adminUserPassword: {},
            }
        default:
            return state
    }
}

// -- DELETE --

export const AdminCustomerDeleteReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CUSTOMER_DELETE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CUSTOMER_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                customerDelete: action.payload,
                error: false
            }

        case "ADMIN_CUSTOMER_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                customerDelete: null,
                error: action.payload
            }
        case "ADMIN_CUSTOMER_DELETE_RESET":
            return {
                ...state,
                loading: false,
                customerDelete: {},
            }
        default:
            return state
    }
}



export const AdminOwnerProfileReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_OWNER_PROFILE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_OWNER_PROFILE_SUCCESS":
            return {
                ...state,
                loading: false,
                ownerProfile: action.payload.data,
                error: false
            }

        case "ADMIN_OWNER_PROFILE_FAILED":
            return {
                ...state,
                loading: false,
                ownerProfile: null,
                error: action.payload
            }
        default:
            return state
    }
}

// ADMIN BUSINESS PLACE LIST 

export const AdminBusinessPlaceListReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_BUSINESS_PLACE_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_BUSINESS_PLACE_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                businessPlaceList: action.payload.data,
                error: false
            }

        case "ADMIN_BUSINESS_PLACE_LIST_FAILED":
            return {
                ...state,
                loading: false,
                businessPlaceList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// ADMIN BUSINESS PLACE LIST 

export const OperatorBusinessPlaceListReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "OPERATOR_BUSINESS_PLACE_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OPERATOR_BUSINESS_PLACE_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                businessPlaceList: action.payload.data,
                error: false
            }

        case "OPERATOR_BUSINESS_PLACE_LIST_FAILED":
            return {
                ...state,
                loading: false,
                businessPlaceList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// ADMIN BUSINESS PLACE LIST 

export const AdminDeleteBusinessPlaceReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_DELETE_BUSINESS_PLACE_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_DELETE_BUSINESS_PLACE_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                businessPlaceDelete: action.payload,
                error: false
            }

        case "ADMIN_DELETE_BUSINESS_PLACE_LIST_FAILED":
            return {
                ...state,
                loading: false,
                businessPlaceDelete: null,
                error: action.payload
            }

        case "ADMIN_DELETE_BUSINESS_PLACE_LIST_RESET":
            return {
                ...state,
                businessPlaceDelete: {},
                error: false,
            }
        default:
            return state
    }
}


// ADMIN BUSINESS PLACE LIST 

export const AdminPlaceStatusUpdateListReducer: any = (state = initialState, action: any) => {
    switch (action.type) {

        case "ADMIN_PLACE_STATUS_UPDATE_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_PLACE_STATUS_UPDATE_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                businessPlaceListStatus: action.payload,
                error: false
            }

        case "ADMIN_PLACE_STATUS_UPDATE_LIST_FAILED":
            return {
                ...state,
                loading: false,
                businessPlaceListStatus: null,
                error: action.payload
            }

        case "ADMIN_PLACE_STATUS_UPDATE_LIST_RESET":
            return {
                ...state,
                businessPlaceListStatus: {},
                error: false,
            }
        default:
            return state
    }
}


// Admin Amenites

export const AdminGetAmenitesReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_AMENITIES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_AMENITIES_SUCCESS":
            return {
                ...state,
                loading: false,
                amenitiesList: action.payload.data,
                error: false
            }

        case "ADMIN_GET_AMENITIES_FAILED":
            return {
                ...state,
                loading: false,
                amenitiesList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Admin Get Amenity Detail

export const AdminGetAmenityDetailReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_AMENITY_DETAIL_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_AMENITY_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                amenityDetail: action.payload,
                error: false
            }

        case "ADMIN_GET_AMENITY_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                amenityDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Admin Create Amenity

export const AdminCreateAmenityReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CREATE_AMENITY_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CREATE_AMENITY_SUCCESS":
            return {
                ...state,
                loading: false,
                amenityData: action.payload,
                error: false
            }

        case "ADMIN_CREATE_AMENITY_FAILED":
            return {
                ...state,
                loading: false,
                amenityData: null,
                error: action.payload
            }

        case "ADMIN_CREATE_AMENITY_RESET":
            return {
                ...state,
                amenityData: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Update Amenity

export const AdminUpdateAmenityReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_UPDATE_AMENITY_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_UPDATE_AMENITY_SUCCESS":
            return {
                ...state,
                loading: false,
                updateAmenityData: action.payload,
                error: false
            }

        case "ADMIN_UPDATE_AMENITY_FAILED":
            return {
                ...state,
                loading: false,
                updateAmenityData: null,
                error: action.payload
            }

        case "ADMIN_UPDATE_AMENITY_RESET":
            return {
                ...state,
                updateAmenityData: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Delete Amenity

export const AdminDeleteAmenityReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_DELETE_AMENITY_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_DELETE_AMENITY_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteAmenityData: action.payload,
                error: false
            }

        case "ADMIN_DELETE_AMENITY_FAILED":
            return {
                ...state,
                loading: false,
                deleteAmenityData: null,
                error: action.payload
            }

        case "ADMIN_DELETE_AMENITY_RESET":
            return {
                ...state,
                deleteAmenityData: {},
                error: false,
            }

        default:
            return state
    }
}


// =================================== PACKAGES ===============================


// Admin PACKAGES GET

export const AdminGetPackagesReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_PACKAGES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_PACKAGES_SUCCESS":
            return {
                ...state,
                loading: false,
                packagesList: action.payload.data,
                error: false
            }

        case "ADMIN_GET_PACKAGES_FAILED":
            return {
                ...state,
                loading: false,
                packagesList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Admin Get Amenity Detail

export const AdminGetPackagesDetailReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_PACKAGES_DETAIL_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_PACKAGES_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                packageDetail: action.payload,
                error: false
            }

        case "ADMIN_GET_PACKAGES_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                packageDetail: null,
                error: action.payload
            }

        case "ADMIN_GET_PACKAGES_DETAIL_RESET":
            return {
                ...state,
                loading: false,
                packageDetail: {},
                error: action.payload
            }
        default:
            return state
    }
}

// Admin Create Amenity

export const AdminCreatePackagesReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CREATE_PACKAGE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CREATE_PACKAGE_SUCCESS":
            return {
                ...state,
                loading: false,
                packageData: action.payload,
                error: false
            }

        case "ADMIN_CREATE_PACKAGE_FAILED":
            return {
                ...state,
                loading: false,
                packageData: null,
                error: action.payload
            }

        case "ADMIN_CREATE_PACKAGE_RESET":
            return {
                ...state,
                packageData: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Update Amenity

export const AdminUpdatePackageReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_UPDATE_PACKAGES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_UPDATE_PACKAGES_SUCCESS":
            return {
                ...state,
                loading: false,
                updatePackageData: action.payload,
                error: false
            }

        case "ADMIN_UPDATE_PACKAGES_FAILED":
            return {
                ...state,
                loading: false,
                updatePackageData: null,
                error: action.payload
            }

        case "ADMIN_UPDATE_PACKAGES_RESET":
            return {
                ...state,
                updatePackageData: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Delete Amenity

export const AdminDeletePackageReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_DELETE_PACKAGE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_DELETE_PACKAGE_SUCCESS":
            return {
                ...state,
                loading: false,
                deletePackageData: action.payload,
                error: false
            }

        case "ADMIN_DELETE_PACKAGE_FAILED":
            return {
                ...state,
                loading: false,
                deletePackageData: null,
                error: action.payload
            }

        case "ADMIN_DELETE_PACKAGE_RESET":
            return {
                ...state,
                deletePackageData: {},
                error: false,
            }

        default:
            return state
    }
}
















// Admin Create product

export const AdminCreateProductReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_CREATE_PRODUCT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CREATE_PRODUCT_SUCCESS":
            return {
                ...state,
                loading: false,
                createProduct: action.payload,
                error: false
            }

        case "ADMIN_CREATE_PRODUCT_FAILED":
            return {
                ...state,
                loading: false,
                createProduct: null,
                error: action.payload
            }

        case "ADMIN_CREATE_PRODUCT_RESET":
            return {
                ...state,
                createProduct: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Amenites

export const AdminGetProductsReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_PRODUCT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_PRODUCT_SUCCESS":
            return {
                ...state,
                loading: false,
                productList: action?.payload?.data,
                error: false
            }

        case "ADMIN_GET_PRODUCT_FAILED":
            return {
                ...state,
                loading: false,
                productList: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Admin Delete Product

export const AdminDeleteProductReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_DELETE_PRODUCT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_DELETE_PRODUCT_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteProductData: action.payload,
                error: false
            }

        case "ADMIN_DELETE_PRODUCT_FAILED":
            return {
                ...state,
                loading: false,
                deleteProductData: null,
                error: action.payload
            }

        case "ADMIN_DELETE_PRODUCT_RESET":
            return {
                ...state,
                deleteProductData: {},
                error: false,
            }

        default:
            return state
    }
}

// Admin Get Amenity Detail

export const AdminGetProductDetailReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_GET_PRODUCT_DETAIL_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_GET_PRODUCT_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                productDetail: action.payload.data,
                error: false
            }

        case "ADMIN_GET_PRODUCT_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                productDetail: null,
                error: action.payload
            }

        case "ADMIN_GET_PRODUCT_DETAIL_RESET":
            return {
                ...state,
                loading: false,
                productDetail: {},
            }
        default:
            return state
    }
}

// Admin Update Amenity

export const AdminUpdateProductReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_UPDATE_PRODUCT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_UPDATE_PRODUCT_SUCCESS":
            return {
                ...state,
                loading: false,
                updateProductData: action.payload,
                error: false
            }

        case "ADMIN_UPDATE_PRODUCT_FAILED":
            return {
                ...state,
                loading: false,
                updateProductData: null,
                error: action.payload
            }

        case "ADMIN_UPDATE_PRODUCT_RESET":
            return {
                ...state,
                updateProductData: {},
                error: false,
            }

        default:
            return state
    }
}


// CUSTOMERS

export const AdminOrdersQueryReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_ORDERS_QUERY_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_ORDERS_QUERY_SUCCESS":
            return {
                ...state,
                loading: false,
                adminOrdersByQuery: action.payload.data,
                error: false
            }

        case "ADMIN_ORDERS_QUERY_FAILED":
            return {
                ...state,
                loading: false,
                adminOrdersByQuery: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


export const AdminOrdersReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_ORDERS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_ORDERS_SUCCESS":
            return {
                ...state,
                loading: false,
                adminOrders: action.payload.data,
                error: false
            }

        case "ADMIN_ORDERS_FAILED":
            return {
                ...state,
                loading: false,
                adminOrders: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const AdminOrderDetailsReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_ORDER_DETAILS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_ORDER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                adminOrderDetails: action.payload.data,
                error: false
            }

        case "ADMIN_ORDER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                adminOrderDetails: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const AdminOrderStatusReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "ADMIN_ORDER_STATUS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_ORDER_STATUS_SUCCESS":
            return {
                ...state,
                loading: false,
                adminOrderListStatus: action.payload,
                error: false
            }

        case "ADMIN_ORDER_STATUS_FAILED":
            return {
                ...state,
                loading: false,
                adminOrderListStatus: null,
                error: action.payload
            }

        case "ADMIN_ORDER_STATUS_RESET":
            return {
                ...state,
                adminOrderListStatus: {},
                error: false,
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// SUGGESTED PLACES


export const AdminGetSuggestedReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_SUGGESTED_PLACES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_SUGGESTED_PLACES_SUCCESS":
            return {
                ...state,
                loading: false,
                adminSuggestPlace: action?.payload?.data,
                error: false
            }

        case "ADMIN_SUGGESTED_PLACES_FAILED":
            return {
                ...state,
                loading: false,
                adminSuggestPlace: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminGetSuggestedDetailsReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_SUGGESTED_PLACE_DETAILS_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_SUGGESTED_PLACE_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                adminSuggestPlaceDetails: action?.payload?.data,
                error: false
            }

        case "ADMIN_SUGGESTED_PLACE_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                adminSuggestPlaceDetails: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminGetNewsletterReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_NEWSLETTER_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_NEWSLETTER_SUCCESS":
            return {
                ...state,
                loading: false,
                adminNewsletter: action?.payload?.data,
                error: false
            }

        case "ADMIN_NEWSLETTER_FAILED":
            return {
                ...state,
                loading: false,
                adminNewsletter: null,
                error: action.payload
            }
        default:
            return state
    }
}


// Admin Place Edit Reducer

export const adminEditPlaceReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "ADMIN_PLACE_EDIT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_PLACE_EDIT_SUCCESS":
            return {
                ...state,
                loading: false,
                adminEditPlaces: action.payload,
                error: false
            }

        case "ADMIN_PLACE_EDIT_RESET":
            return {
                ...state,
                loading: false,
                adminEditPlaces: null
            }

        case "ADMIN_PLACE_EDIT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// ==== REPORTING 


export const AdminGetSalesReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_SALES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_SALES_SUCCESS":
            return {
                ...state,
                loading: false,
                adminGetSales: action?.payload,
                error: false
            }

        case "ADMIN_SALES_FAILED":
            return {
                ...state,
                loading: false,
                adminGetSales: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminGetSubscriptionReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_SUBSCRIPTION_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_SUBSCRIPTION_SUCCESS":
            return {
                ...state,
                loading: false,
                adminGetSubscription: action?.payload,
                error: false
            }

        case "ADMIN_SUBSCRIPTION_FAILED":
            return {
                ...state,
                loading: false,
                adminGetSubscription: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminGetExpiredSubscriptionReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_EXPIRED_SUBSCRIPTION_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_EXPIRED_SUBSCRIPTION_SUCCESS":
            return {
                ...state,
                loading: false,
                adminGetExpiredSubscription: action?.payload,
                error: false
            }

        case "ADMIN_EXPIRED_SUBSCRIPTION_FAILED":
            return {
                ...state,
                loading: false,
                adminGetExpiredSubscription: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const AdminGetOwnerReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_OWNER_REPORT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_OWNER_REPORT_SUCCESS":
            return {
                ...state,
                loading: false,
                adminGetOwner: action?.payload,
                error: false
            }

        case "ADMIN_OWNER_REPORT_FAILED":
            return {
                ...state,
                loading: false,
                adminGetOwner: null,
                error: action.payload
            }
        default:
            return state
    }
}

// Owner Place Create Reducer

export const adminCreatePlaceReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "ADMIN_PLACE_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_PLACE_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                adminPlaces: action.payload,
                error: false
            }

        case "ADMIN_PLACE_CREATE_RESET":
            return {
                ...state,
                loading: false,
                adminPlaces: null
            }

        case "ADMIN_PLACE_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const operatorCreatePlaceReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "OPERATOR_PLACE_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "OPERATOR_PLACE_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                adminPlaces: action.payload,
                error: false
            }

        case "OPERATOR_PLACE_CREATE_RESET":
            return {
                ...state,
                loading: false,
                adminPlaces: null
            }

        case "OPERATOR_PLACE_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// send reminder

export const AdminSendNoticeReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_SEND_NOTICE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_SEND_NOTICE_SUCCESS":
            return {
                ...state,
                loading: false,
                adminSendRemind: action?.payload,
                error: false
            }

        case "ADMIN_SEND_NOTICE_FAILED":
            return {
                ...state,
                loading: false,
                adminSendRemind: null,
                error: action.payload
            }

        case "ADMIN_SEND_NOTICE_RESET":
            return {
                ...state,
                loading: false,
                adminSendRemind: null
            }

        default:
            return state
    }
}


export const AdminCreateOpeartorReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_CREATE_OPERATOR_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_CREATE_OPERATOR_SUCCESS":
            return {
                ...state,
                loading: false,
                adminCreate: action?.payload,
                error: false
            }

        case "ADMIN_CREATE_OPERATOR_FAILED":
            return {
                ...state,
                loading: false,
                adminCreate: null,
                error: action.payload
            }

        case "ADMIN_CREATE_OPERATOR_RESET":
            return {
                ...state,
                loading: false,
                adminCreate: null
            }

        default:
            return state
    }
}

export const AdminDeleteOpeartorReducer: any = (state = {}, action: any) => {
    switch (action.type) {

        case "ADMIN_DELETE_OPERATOR_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ADMIN_DELETE_OPERATOR_SUCCESS":
            return {
                ...state,
                loading: false,
                adminDelete: action?.payload,
                error: false
            }

        case "ADMIN_DELETE_OPERATOR_FAILED":
            return {
                ...state,
                loading: false,
                adminDelete: null,
                error: action.payload
            }

        case "ADMIN_DELETE_OPERATOR_RESET":
            return {
                ...state,
                loading: false,
                adminDelete: null
            }

        default:
            return state
    }
}