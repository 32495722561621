import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminDashboard } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import Loader from "../../../../Utils/Loader";
import { BsArrowRightShort } from 'react-icons/bs'
import "./Dashboard.css";

const Dashboard = () => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, admin } = useSelector((state: any) => state.adminDashboard);

  useEffect(() => {
    dispatch(adminDashboard())
  }, [dispatch])

  const goToSection = (date: string) => {
    navigate(`/admin/reports/sales/${date}`)
  }

  const goToSub = (date: string) => {
    navigate(`/admin/reports/subscription/${date}`)
  }

  const goToOwner = (date: any) => {
    navigate(`/admin/reports/owners/${date}`)
  }

  return (
    <div className="page_responsive">
      <h1 className="main_heading">Welcome Admin</h1>
      {
        loading ? <div
          style={{ height: '50vh' }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Loader />
        </div>
          :
          <Container className="my-5">
            <Row>
              <Col md={12}>
                <h3 className="dashboard_heading_h1">- Ecommerce Stats</h3>
              </Col>
              <Col md={3}>
                <div className="dashboard_div" onClick={() => goToSection("today")}>
                  <Row>
                    <Col md={12}>
                      <h5>Today's Sales</h5>
                      <h6>{admin?.orders?.today_sales}</h6>
                      <p onClick={() => goToSection("today")}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#ff4a52" }} className="dashboard_div" onClick={() => goToSection("all")}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Sales</h5>
                      <h6>{admin?.orders?.total_sales}</h6>
                      <p onClick={() => goToSection("all")}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#ffb048" }} className="dashboard_div" onClick={() => navigate('/admin/orders')}>
                  <Row>
                    <Col md={12}>
                      <h5>Today's Orders</h5>
                      <h6>{admin?.orders?.today_order}</h6>
                      <p onClick={() => navigate('/admin/orders')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#ff4a52" }} className="dashboard_div" onClick={() => navigate('/admin/orders')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Orders</h5>
                      <h6>{admin?.orders?.total_orders}</h6>
                      <p onClick={() => navigate('/admin/orders')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#ffb048" }} className="dashboard_div" onClick={() => navigate('/admin/order-query?status=pending')}>
                  <Row>
                    <Col md={12}>
                      <h5>Pending Orders</h5>
                      <h6>{admin?.orders?.completed}</h6>
                      <p onClick={() => navigate('/admin/order-query?status=pending')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#3a8af7" }} className="dashboard_div" onClick={() => navigate('/admin/order-query?status=completed')}>
                  <Row>
                    <Col md={12}>
                      <h5>Completed Orders</h5>
                      <h6>{admin?.orders?.pending}</h6>
                      <p onClick={() => navigate('/admin/order-query?status=completed')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div className="dashboard_div" onClick={() => navigate('/admin/order-query?status=rejected')}>
                  <Row>
                    <Col md={12}>
                      <h5>Rejected Orders</h5>
                      <h6>{admin?.orders?.others}</h6>
                      <p onClick={() => navigate('/admin/order-query?status=rejected')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h1 className="dashboard_heading_h1">- Subscription</h1>
              </Col>
              <Col md={3}>
                <div className="dashboard_div" onClick={() => goToSub('today')}>
                  <Row>
                    <Col md={12}>
                      <h5>Today's Subscription</h5>
                      <h6>{admin?.subscription?.today}</h6>
                      <p onClick={() => goToSub('today')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#3a8af7" }} className="dashboard_div" onClick={() => goToSub('all')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Subscription</h5>
                      <h6>{admin?.subscription?.total}</h6>
                      <p onClick={() => goToSub('all')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#ff4a52" }} className="dashboard_div" onClick={() => navigate('/admin/reports/subscription/expired')}>
                  <Row>
                    <Col md={12}>
                      <h5>Expired Subscription</h5>
                      <h6>{admin?.owners?.expired}</h6>
                      <p onClick={() => navigate('/admin/reports/subscription/expired')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h1 className="dashboard_heading_h1">- Owners</h1>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#ffb048" }} className="dashboard_div" onClick={() => navigate('/admin/all-owners')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Owners</h5>
                      <h6>{admin?.owners?.total}</h6>
                      <p onClick={() => navigate('/admin/all-owners')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#ff4a52" }} className="dashboard_div" onClick={() => goToOwner('premium')}>
                  <Row>
                    <Col md={12}>
                      <h5>Premium Owners</h5>
                      <h6>{admin?.owners?.premium}</h6>
                      <p onClick={() => goToOwner('premium')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#3a8af7" }} className="dashboard_div" onClick={() => goToOwner('free')}>
                  <Row>
                    <Col md={12}>
                      <h5>Free Owners</h5>
                      <h6>{admin?.owners?.free}</h6>
                      <p onClick={() => goToOwner('free')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div className="dashboard_div" onClick={() => navigate('/admin/reports/subscription/expired')}>
                  <Row>
                    <Col md={12}>
                      <h5>Expired Owners</h5>
                      <h6>{admin?.owners?.expired}</h6>
                      <p onClick={() => navigate('/admin/reports/subscription/expired')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h1 className="dashboard_heading_h1">- Events</h1>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#ff4a52" }} className="dashboard_div" onClick={() => navigate('/admin/events')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Events</h5>
                      <h6>{admin?.events?.total}</h6>
                      <p onClick={() => navigate('/admin/events')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div className="dashboard_div" onClick={() => navigate("/admin/events?start_date=giveDate")}>
                  <Row>
                    <Col md={12}>
                      <h5>Active Events</h5>
                      <h6>{parseInt(admin?.events?.active) + parseInt(admin?.events?.upcoming)}</h6>
                      <p onClick={() => navigate('/admin/events?start_date=giveDate')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div style={{ backgroundColor: "#3a8af7" }} className="dashboard_div" onClick={() => navigate('/admin/events?activity=inactive')}>
                  <Row>
                    <Col md={12}>
                      <h5>Expired Events</h5>
                      <h6>{parseInt(admin?.events?.total) - (parseInt(admin?.events?.upcoming) + parseInt(admin?.events?.active))}</h6>
                      <p onClick={() => navigate('/admin/events?activity=inactive')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h1 className="dashboard_heading_h1">- Totals</h1>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: "#3a8af7" }} className="dashboard_div" onClick={() => navigate('/admin/all-listings')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Places</h5>
                      <h6>{admin?.places?.total_places}</h6>
                      <p onClick={() => navigate('/admin/all-listings')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div className="dashboard_div" onClick={() => navigate('/admin/all-listings?shopType=63a14cbe3683d00187f64031')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Hookahbars</h5>
                      <h6>{admin?.places?.hookahbars}</h6>
                      <p onClick={() => navigate('/admin/all-listings?shopType=63a14cbe3683d00187f64031')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: '#ffb048' }} className="dashboard_div" onClick={() => navigate('/admin/all-listings?shopType=63a14cbe3683d00187f64033')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total Smoke Shops</h5>
                      <h6>{admin?.places?.smoke_shops}</h6>
                      <p onClick={() => navigate('/admin/all-listings?shopType=63a14cbe3683d00187f64033')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div style={{ backgroundColor: '#ff4a52' }} className="dashboard_div" onClick={() => navigate('/admin/all-customers')}>
                  <Row>
                    <Col md={12}>
                      <h5>Total End Users</h5>
                      <h6>{admin?.total_customers}</h6>
                      <p onClick={() => navigate('/admin/all-customers')}>View <BsArrowRightShort /> </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
      }
    </div>
  );
};
export default Dashboard;