import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import { useNavigate } from "react-router-dom";
import { OwnerEnquiries } from "../../../../Redux/Action/Owner";
import Loader from "../../../../Utils/Loader";

const Enquiry = () => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, ownerEnquiry } = useSelector((state: any) => state.ownerGetEnquiry);

  console.log(ownerEnquiry)

  const viewEnquiry = (id: number, enquiry: any) => {
    navigate(`/owner/enquiry-details/${id}`, { state: { enquiry_detail: enquiry } });
  };

  useEffect(() => {
    dispatch(OwnerEnquiries())
  }, [dispatch])

  return (
    <div className="py-3">
      <Container>
        <Row className="justify-content-center">

          {
            loading ? <Loader /> :
              <Col md={10}>
                <div className="table_div">
                  <h4>Enquiry</h4>
                  {
                    ownerEnquiry?.length > 0 ?
                      <Table responsive>
                        <thead>
                          <tr style={{ backgroundColor: "#8080800f" }}>
                            <th className="text-center">NAME</th>
                            <th className="text-center">PHONE NUMBER</th>
                            <th className="text-center">EMAIL</th>
                            <th className="text-center">PLACE</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            ownerEnquiry?.map((enquiry: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="text-center">{enquiry?.name}</td>
                                  <td className="text-center">{enquiry?.phone}</td>
                                  <td className="text-center">{enquiry?.email}</td>
                                  <td className="text-center">{enquiry?.place ? enquiry?.place?.name : '-'}</td>
                                  <td className="text-center">
                                    <div className="table_view_btn">
                                      <button onClick={() => viewEnquiry(enquiry._id, enquiry)}>View</button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                      :
                      <p
                        className="text-center"
                        style={{ fontSize: "20px", fontWeight: "500", padding: '15px' }}
                      >
                        No Enquiry Found
                      </p>
                  }
                </div>
              </Col>
          }
        </Row>
      </Container>
    </div>
  );
};

export default Enquiry;