import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { BsCalendar2Date } from "react-icons/bs";
import FilterMap from "../../../Components/FilterMap/FilterMap";
import { ICoordinates } from '../../../Utils/Interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../Redux/Store';
import { ownerGetAllHomeEvents } from '../../../Redux/Action/Owner';
import './Events.css';
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from '../../../Utils/Loader';
import { cloudURL, MediaPath } from '../../../Utils/helper';

const Events = () => {
    const dispatch: AppDispatch = useDispatch();

    const { register, handleSubmit, formState: { errors }, reset } = useForm<{ search: string, start_date: string }>();

    const [selectedCoordinates, setSelectedCoordinates] = useState<ICoordinates | null>(null);
    const [address, setAddress] = useState(null);
    const [place, setPlace] = useState("");
    // const [country, setCountry] = useState('')
    // const [city, setCity] = useState('')
    // const [state, setState] = useState('')
    // const [getCountryName, setGetCountryName] = useState<any>('')

    const { loading, getAllHomeEvents } = useSelector((state: any) => state.homeEvents);

    // console.log(selectedCoordinates)

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        dispatch(ownerGetAllHomeEvents('', formattedDate, '', 1))
    }, [])

    const getDateMonth = (ev: any) => {
        const start_date = new Date(ev.start_date);
        const end_date = new Date(ev.end_date);
        const date = start_date.getDate();
        const endDate = end_date.getDate();
        const month = start_date.toLocaleString('default', { month: 'short' });
        const end_month = end_date.toLocaleString('default', { month: 'short' });

        if (endDate === date) {
            return <div className='text-center'>
                {/* <h3>{month}</h3> */}
                <h2>{`${month} ${date}`}</h2>
            </div>
        }
        else {
            return <div className='text-center'>
                {/* <h3>{month}</h3> */}
                <h2>{`${month} ${date}`} </h2>
                <h2>{`${end_month} ${endDate ? endDate : null}`}</h2>
            </div>
        }

    }

    const getTime = (t: any) => {
        console.log(t)

        const event_time = new Date(t);
        const time = event_time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

        return <div className='time'>
            <h4>Time: {time}</h4>
        </div>
    }

    const searchHandler: SubmitHandler<{ search: string, start_date: string }> = (d) => {
        dispatch(ownerGetAllHomeEvents(d.search, d.start_date, selectedCoordinates, 1))
        reset()
        console.log(selectedCoordinates)
    }

    const resetHandler = () => {
        dispatch(ownerGetAllHomeEvents('', '', '', 1))
        setSelectedCoordinates(null)
        setAddress(null)
        setPlace("")
    }

    return (
        <React.Fragment>
            <Container className="shop_details">
                <h1> Upcoming Events </h1>
            </Container>

            <Container fluid className="shop_details mb-5">
                <Row className="find_shop_row">
                    <Col md={3}>
                        <div className="search_filter_div">
                            <h6>Search</h6>

                            <Form onSubmit={handleSubmit(searchHandler)}>

                                <div>
                                    <label style={{ fontWeight: "600" }}>Date</label>
                                    <div className="signup_input">
                                        <BsCalendar2Date />
                                        <input
                                            type="date"
                                            {...register("start_date")}
                                        />
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <label style={{ fontWeight: "600" }}>Location</label>
                                    <div className='form_input filter_map'>
                                        <FilterMap
                                            map={false}
                                            search={true}
                                            setAddress={setAddress}
                                            selectedCoordinate={selectedCoordinates}
                                            setSelectedCoordinate={setSelectedCoordinates}
                                            // setGetCountryName={setGetCountryName}
                                            // setCountry={setCountry}
                                            // setState={setState}
                                            // setCity={setCity}
                                            setPlace={setPlace}
                                            place={place}

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label>Title</label>
                                    <div className="signup_input">
                                        <img src="/images/AccountIcon.png" alt="" />
                                        <input
                                            placeholder="Enter Title"
                                            {...register("search")}
                                        />
                                    </div>
                                </div>

                                <div className='text-end mt-4'>
                                    <button type="button" className="back_btn" onClick={resetHandler}> Reset </button>
                                    <button type="submit" className="back_btn mx-2"> Search </button>
                                </div>
                            </Form>

                        </div>
                    </Col>

                    <Col md={9}>
                        {
                            loading ? <Loader /> :
                                <Row>
                                    {
                                        getAllHomeEvents?.map((ev: any) => {
                                            return (
                                                <Col md={4} className={"mb-4"} key={ev._id}>
                                                    <div className="shops make_shadow">

                                                        <img src={`${cloudURL}${MediaPath?.events}${ev?.image}`} className="shop_Image" alt="" />
                                                        <h5>
                                                            Shop:
                                                            {
                                                                ev?.places?.map((place: any) => {
                                                                    return <span key={place._id} style={{ paddingLeft: "6px" }}>{place.name},</span>
                                                                })
                                                            }
                                                        </h5>

                                                        <div className='events_div'>
                                                            <Row>
                                                                <Col md={3} style={{ padding: "0" }}>
                                                                    {getDateMonth(ev)}
                                                                </Col>
                                                                <Col md={9} style={{ padding: "0" }}>
                                                                    <div className='event_details'>
                                                                        <p>{ev.title}</p>

                                                                        <p className='text'>{ev.description}</p>

                                                                        {getTime(ev.event_time)}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }

                                    {
                                        getAllHomeEvents?.length === 0 && <h3 className='text-center'>No Event Found</h3>
                                    }
                                </Row>
                        }
                    </Col>

                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Events