import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { successNotify } from "../../Utils/toast";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io"
import { Collapse } from "@mui/material";

const DashboardSidebar = (props: any) => {
  const location = useLocation();
  const navigation = useNavigate();

  const [sideBar, setSideBar] = useState(false);
  const [subNav, setSubNav] = useState(false);

  const classes = (path: string) => {
    if (path === location.pathname) return "nav_active";
    return "";
  };

  const LogoutHandler = () => {
    localStorage.removeItem("User");
    navigation('/')
    successNotify("Logged out successfully!")
  }

  return (
    <div className={sideBar ? "sidebar active" : "sidebar"}>
      <div className={"logo_content"}>
        <div className={"profile"}>
          <p className={"mb-0"}>
            <img alt="logo" src='/images/logo.png' style={{ cursor: "pointer" }} />
          </p>
        </div>
        {sideBar ? (
          <ImCross className={"fa-bars"} onClick={() => setSideBar(false)} />
        ) : (
          <FaIcons.FaBars
            className={"fa-bars"}
            onClick={() => setSideBar(true)}
          />
        )}
      </div>

      <ul className="nav_list">
        {props?.sideBarItems?.map((item: any, index: number) => {
          if (item.path) {
            return (
              <li key={index} className={`${classes(item.path)}`}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          }


          else {
            return (
              <React.Fragment>
                <li key={index} className={`${classes(item.path)}`}>
                  <div className="collapse_div" onClick={() => setSubNav(!subNav)}>
                    <Link to={'#'}>
                      {item.icon}
                      <span>{item.title}</span>
                      {
                        !subNav ?
                          <IoMdArrowDropright className={'subNav'} />
                          : <IoMdArrowDropdown className={'subNav'} />
                      }
                    </Link>
                  </div>

                  {
                    item.subNav ?
                      <Collapse in={subNav} unmountOnExit>
                        <React.Fragment>
                          {
                            item.subNav.map((subItem: any, index: number) => (
                              <li className={`${classes(subItem.path)} sub_active ml-4`} key={index}>
                                <div className="sub_nav">
                                  <Link to={subItem.path} style={{
                                    fontSize: '15px',
                                    paddingLeft: "25px"
                                  }}>
                                    {subItem?.icon}
                                    <span>{subItem.title}</span>
                                  </Link>
                                </div>
                              </li>
                            ))
                          }
                        </React.Fragment>
                      </Collapse>
                      : null
                  }
                </li>
              </React.Fragment>
            )
          }
        })}
        <li className="logout_btn" onClick={LogoutHandler}>
          <Link to={"#"}>
            <FiIcons.FiLogOut />
            <span>Logout</span>
          </Link>
        </li>
      </ul>

    </div>
  );
};
export default DashboardSidebar;