import React from "react";
import { useNavigate } from "react-router-dom";
import "./notFound.css"
const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="notFound_container">
      <div className="notFound_box">
        <div style={{ textAlign: "center", color: "#495057" }}>
          <h1 className="heading_404">404</h1>
          <h2 >OPPS! PAGE NOT FOUND</h2>
          <h4>Sorry, the page you are looking for doesn't exist.</h4>
          <button className="mt-4" onClick={() => navigate('/')}>Return Back</button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
