import React from 'react'
import Modal from "../../../../../Components/Modal/Modal";
import { cloudURL, MediaPath } from '../../../../../Utils/helper';
import { successNotify } from '../../../../../Utils/toast';

const DiscountedCoupons: React.FC<any> = ({ showModal, setShowModal, data }) => {
    
    const copyToClipboard = (code: string) => {
        navigator.clipboard.writeText(code);
        successNotify("Copied Successfully!");
    };

    return (
        <React.Fragment>
            <Modal
                title="View Coupons"
                show={showModal}
                size="lg"
                onCloseModal={() => setShowModal(!showModal)}
                showIcon={true}
            >
                <div className="modal_child">
                    <div>
                        <img src={`${cloudURL}${MediaPath?.image}${data.image}`} alt="" />

                        <h6>{data.description}</h6>

                        <div className="coupon_code">
                            <h5>{data.code}</h5>
                            <button className="copy_coupons" onClick={() => copyToClipboard(data.code)}>
                                Copy
                            </button>
                        </div>
                    </div>

                    <div className="coupon_detail">
                        <div className="d-flex">
                            <p>
                                Start Date: <span>{data.start_date}</span>
                            </p>
                            <p>
                                End Date: <span>{data.end_date}</span>
                            </p>
                        </div>

                        <ul>
                            <li>This coupon is for physical use only.</li>
                        </ul>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default DiscountedCoupons
