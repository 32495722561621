import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Table, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { adminOrder } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import moment from 'moment';
import Loader from "../../../../Utils/Loader";
import { useForm, SubmitHandler } from "react-hook-form";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Orders = () => {
  const navigate = useNavigate();

  const [orderTab, setOrderTab] = useState("ALL");
  const [loaderShow, setLoaderShow] = useState(false)

  const dispatch: AppDispatch = useDispatch();
  const { loading, adminOrders } = useSelector((state: any) => state.adminOrderList);

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<any>();

  let query = useQuery();

  let userId: any = query.get("userId")

  useEffect(() => {
    if (adminOrders) {
      setLoaderShow(false)
    }
  }, [adminOrders])

  useEffect(() => {
    if (userId) {
      dispatch(adminOrder(userId, ''))
    }
    else {
      dispatch(adminOrder('', ''))
    }
  }, [dispatch])

  const orderStatus = (status: string) => {
    if (status === "0") {
      return <td className="text-center"> <span className="pending"> Pending </span></td>
    }
    else if (status === "1") {
      return <td className="text-center"> <span className="completed"> Completed </span></td>
    }
    else if (status === "2") {
      return <td className="text-center "> <span className="approved"> Approved </span> </td>
    }
    else if (status === "3") {
      return <td className="text-center"> <span className="rejected"> Failed </span> </td>
    }
    else if (status === "4") {
      return <td className="text-center "> <span className="rejected"> Rejected </span></td>
    }
    else if (status === "5") {
      return <td className="text-center"> <span className="rejected"> Expired </span></td>
    }
  }

  const showOrder = (order: any) => {
    if (adminOrders.filter((list: any) => list.active === order).length > 0) {
      return (
        <Table responsive>
          <thead>
            <tr style={{ backgroundColor: "#8080800f" }}>
              <th className="">ORDER ID</th>
              <th className="">DATE</th>
              <th className="">ORDER EMAIL</th>
              <th className="">TOTAL PRICE</th>
              <th className="">STATUS</th>
              <th className="">ACTION</th>
            </tr>
          </thead>
          {adminOrders
            .filter((list: any) => list.active === order)
            .map((list: any, index: number) => (
              <tbody>
                <tr>
                  <td className="">{index + 1}</td>
                  <td className="">{moment(list?.order_date).format('ll')}</td>
                  <td className="">{list.user.email}</td>
                  <td className="">{list.amount}</td>
                  {orderStatus(list?.active)}
                  <td className="">
                    <div className="table_icons">
                      <FaEye
                        onClick={() =>
                          navigate(`/admin/order-details/${list._id}`)
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
        </Table>
      );
    }

    else return <p className="text-center py-3 fw-bold">No Data Found</p>
  };

  const productSearchHandler: SubmitHandler<any> = (data: any) => {
    setLoaderShow(true)
    if (userId) {
      dispatch(adminOrder(userId, data.search))
    }
    else {
      dispatch(adminOrder('', data.search))
    }
    reset();
  }

  const ResetHandler = () => {
    if (userId) {
      dispatch(adminOrder(userId, ''))
    }
    else {
      dispatch(adminOrder('', ''))
    }
  }


  const tabs = ["ALL", "APPROVED", "PENDING", "COMPLETED", "REJECTED"]
  const showTab: any = tabs.map((tab) => (<button className="sign_btn m-2" onClick={() => setOrderTab(tab)}> {tab} </button>))

  return (
    <div className="page_responsive">
      <h1 className="main_heading">Orders</h1>

      {
        !userId && <div className="text-center">
          {showTab}
        </div>
      }

      {
        userId && <div className="text-end">
          <button className="back_btn"
            onClick={() => navigate('/admin/all-customers')}
          >Back</button>
        </div>
      }

      <Form onSubmit={handleSubmit(productSearchHandler)}>
        <Row>
          <Col md={3} className="py-2">
            <div className="signup_input">
              <img src="/images/AccountIcon.png" alt="" />
              <input
                placeholder="Search...."
                {...register("search")}
              />
            </div>
          </Col>
          <Col md={3} className="py-2">
            <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
            <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
          </Col>
        </Row>
      </Form>

      {
        loading ?
          <Loader />
          :
          <div className="table_div">
            <h4>All Orders</h4>
            {orderTab === "ALL" && (
              <>
                {
                  adminOrders ?
                    <Table responsive>
                      <thead>
                        <tr style={{ backgroundColor: "#8080800f" }}>
                          <th className="">ORDER ID</th>
                          <th className="">DATE</th>
                          <th className="">ORDER EMAIL</th>
                          <th className="">TOTAL PRICE</th>
                          <th className="">STATUS</th>
                          <th className="">ACTION</th>
                        </tr>
                      </thead>
                      {adminOrders?.map((list: any, index: number) => (
                        <tbody key={list}>
                          <tr>
                            <td className="">{index + 1}</td>
                            <td className="">{moment(list?.order_date).format('ll')}</td>
                            <td className="">{list?.user.email}</td>
                            <td className="">{list?.amount}</td>
                            {orderStatus(list?.active)}
                            <td className="">
                              <div className="table_icons">
                                <FaEye
                                  onClick={() =>
                                    navigate(`/admin/order-details/${list?._id}`)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                    : <p style={{ textAlign: "center", fontWeight: "600" }}>No Order Found</p>
                }
              </>
            )}

            {orderTab === "PENDING" && <>{showOrder("0")}</>}
            {orderTab === "APPROVED" && <>{showOrder("2")}</>}
            {orderTab === "COMPLETED" && <>{showOrder("1")}</>}
            {orderTab === "REJECTED" && <>{showOrder("4")}</>}
          </div>
      }
    </div>
  );
};

export default Orders;
