import React, { useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/Store";
import CsvDownloader from 'react-csv-downloader';
import { getAdminNewsletter } from "../../../../Redux/Action/Admin";
import Loader from "../../../../Utils/Loader";
import moment from "moment";

const NewsLetter = () => {

    const dispatch: AppDispatch = useDispatch();
    const { loading, adminNewsletter } = useSelector((state: any) => state.adminGetNewsletter);

    console.log(adminNewsletter)

    useEffect(() => {
        dispatch(getAdminNewsletter())
    }, [])

    const tabs = ["SNo.", "Email", "Date"];

    const asyncFnComputeDate = () => {
        // do whatever you need async
        return Promise.resolve(adminNewsletter);
    };

    const d = [
        {
            cell1: 'row 1 - cell 1',
            cell2: 'row 1 - cell 2',
            cell3: 'row 1 - cell 2',
        },
        {
            cell1: 'row 2 - cell 1',
            cell2: 'row 2 - cell 2',
            cell3: 'row 2 - cell 2',
        },
    ];

    return (
        <div className="page_responsive">
            <Row className="justify-content-center">
                <h1 className="main_heading">Footer NewsLetter</h1>

                {
                    loading ?
                        <Loader /> :
                        <Col md={12}>

                            <div className="text-end">
                                <CsvDownloader datas={adminNewsletter}
                                    filename="newsletter"
                                    extension=".csv">
                                    <button className="back_btn">Download CSV</button>
                                </CsvDownloader>
                            </div>

                            <div className="table_div">
                                <h4>NewsLetter</h4>
                                <Table responsive>
                                    <thead>
                                        <tr style={{ backgroundColor: "#8080800f" }}>
                                            {tabs.map((tab, i) => (
                                                <th className="" key={i}>{tab}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminNewsletter?.map((user: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="">{index + 1}</td>
                                                    <td className="">{user.email}</td>
                                                    <td>{moment(user.createdAt).format('MMMM Do YYYY')}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                }
            </Row>
        </div>
    );
};

export default NewsLetter