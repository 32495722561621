import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminSubscription } from "../../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../../Redux/Store";
import Loader from '../../../../../Utils/Loader';
import { Table } from 'react-bootstrap';
import moment from 'moment';

const Subscription = () => {

    // adminGetSalesData
    const navigate = useNavigate();
    const { id } = useParams()

    const dispatch: AppDispatch = useDispatch();
    const { loading, adminGetSubscription } = useSelector((state: any) => state.adminGetSubsData);

    console.log(adminGetSubscription)

    useEffect(() => {
        dispatch(adminSubscription(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isExpired = (date: any) => {
        if (Date.parse(date) > Date.now()) {
            return <td className="">{moment(date).format('ll')}</td>
        }
        else {
            return <td className=""> <span className='unsubs'> {moment(date).format('ll')} </span></td>
        }
    }

    return (
        <div className='page_responsive'>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="main_heading">{id === 'all' ? 'Total Subscription' : "Today's Subscription"}</h1>
                <button
                    className="back_btn"
                    onClick={() => navigate("/admin/dashboard")}
                >
                    Back
                </button>
            </div>

            <div className="table_div">
                <h4>Subscription</h4>

                {
                    loading ? <div
                        style={{ height: '50vh' }}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <Loader />
                    </div> :
                        <Table responsive className="mb-3">
                            {
                                adminGetSubscription?.data?.length > 0 ?
                                    <>
                                        <thead>
                                            <tr style={{ backgroundColor: "#8080800f" }}>
                                                <th className="">OWNER NAME</th>
                                                <th className="">LISTINGS</th>
                                                <th className="">SUBSCRIPTION START</th>
                                                <th className="">SUBSCRIPTION RENEWAL</th>
                                                <th className="">SUBSCRIPTION EXPIRE</th>
                                                <th className="">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminGetSubscription?.data?.map((list: any, index: number) => {
                                                console.log(list)
                                                return (
                                                    <tr key={index}>
                                                        <td className="">{list?.owner?.first_name}</td>
                                                        <td className=""> <span className="view_orders_customer" onClick={() => navigate(`/admin/all-listings?owner=${list?.owner?._id}`)}>View Listings</span> </td>
                                                        <td className="">{moment(list?.subscription_date).format('ll')}</td>
                                                        <td className="">{moment(list?.subscription_expire).add(1, 'day').format('ll')}</td>
                                                        {
                                                            isExpired(list?.subscription_expire)
                                                        }
                                                        <td className="">{list?.amount}</td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </>
                                    : <p className='text-center mb-0 mt-2'>No Data Found</p>
                            }
                        </Table>
                }
            </div>
        </div>
    )
}

export default Subscription