import React, { useEffect } from 'react'
import { Col, Row, Form, Button, Spinner } from 'react-bootstrap';
import ReactStars from "react-stars";
import { CurrentUserInfo, cloudURL, MediaPath } from '../../../../../Utils/helper';
import { errorNotify, successNotify } from '../../../../../Utils/toast';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { AppDispatch } from '../../../../../Redux/Store';
import { BpReviewSubmit, getBPListingsDetails } from '../../../../../Redux/Action/BusinessPlaces';
import Filter from 'bad-words';

const Reviews = (props: any) => {
    const dispatch: AppDispatch = useDispatch()
    const [reviewMsg, setReviewMsg] = React.useState<any>({
        rating: 0,
        comment: null,
    });
    const { error, loading, bpReviews } = useSelector((state: any) => state.BpReview);

    const ratingChanged = (newRating: number) => {
        setReviewMsg({
            ...reviewMsg,
            rating: newRating,
        });
    };

    const reviewMessageChange = (e: any) => {
        setReviewMsg({
            ...reviewMsg,
            comment: e.target.value,
        });
    };

    var filter: any = new Filter({ placeHolder: '*' })

    useEffect(() => {
        if (bpReviews?.status === 1) {
            successNotify("Your feedback has been sent!")
            setReviewMsg({
                rating: 0,
                comment: "",
            });
            dispatch({ type: "BP_PLACE_REVIEW_RESET" });
            dispatch(getBPListingsDetails(props?.slug))
        }

        if (error) {
            errorNotify(error)
            dispatch({ type: "CLEAR_ERRORS" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bpReviews, error])

    const reviewHandler = (e: any) => {
        e.preventDefault();

        if (reviewMsg?.rating <= 0 || reviewMsg?.comment === null) {
            errorNotify("Please give proper review")
        }
        else {
            const reviewData = {
                rating: reviewMsg?.rating,
                comment: filter.clean(reviewMsg?.comment)
            }
            dispatch(BpReviewSubmit(props?.id, reviewData))
        }
    };

    return (
        <div className="testimonials">
            <h5>Reviews</h5>
            {props?.listingsData[0]?.reviews?.length > 0 ? (
                <>
                    {props?.listingsData[0]?.reviews?.map((r: any, index: number) => {
                        return (
                            <Row className="review_row" key={index}>
                                <Col md={1} className={"p-0 testimonial_img"}>
                                    {
                                        r?.createdBy?.avatar ?
                                            <img src={`${cloudURL}${MediaPath?.avatar}${r?.createdBy?.avatar}`} alt="user-avatar" />
                                            : <img src="/images/testimonial_img.png" alt="avatar" />
                                    }
                                </Col>
                                <Col md={7} className={"p-0 testimonial_text"}>
                                    <h6>{`${r?.createdBy?.first_name} ${r?.createdBy?.last_name}`}</h6>
                                    <p>{r.comment}</p>
                                </Col>
                                <Col md={4} className="p-0">
                                    <div className="testimonial_stars">
                                        <p>
                                            <span>{`${moment(props?.listingsData[0]?.reviews[index]?.createdAt).fromNow(true)} ago`}</span>
                                        </p>
                                        <ReactStars
                                            count={5}
                                            edit={false}
                                            size={24}
                                            color2={"#ffb21e"}
                                            value={r.rating}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </>
            ) : (
                <p
                    className="text-center"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                >
                    No Reviews Found
                </p>
            )}

            {CurrentUserInfo()?.roles[0] === "customer" && (
                <div className="testimonials">
                    <h5>Give Review</h5>

                    <Form onSubmit={reviewHandler}>
                        <Row>
                            <Col md={12}>
                                <div className="signup_input">
                                    <label>Select Stars</label>
                                    <ReactStars
                                        count={5}
                                        size={24}
                                        onChange={ratingChanged}
                                        color2={"#ffb21e"}
                                        value={reviewMsg.rating}
                                    />
                                </div>
                            </Col>
                            <Col md={12} className="py-2">
                                <div className="signup_input">
                                    <label>Message</label>
                                    <textarea
                                        rows={5}
                                        placeholder="Write Review"
                                        value={reviewMsg.comment}
                                        name="reviewMsg"
                                        onChange={reviewMessageChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12} className="mt-2 text-end">
                                <Button type="submit">{loading ? <Spinner animation='border' size='sm' /> : 'Send'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </div>
    )
}
export default Reviews
