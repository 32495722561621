import api from "../../Utils/Interceptors";

// BP Listing

export const getBPListings = (getShopType: any, getLat: any, getLng: any, price_range: any, amenities: any, getShopName: any, getCountry: any, getCity: any, getState: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_REQUEST"
        })

        let params = []
        if (getShopType) {
            params.push({
                "shop_type": getShopType
            })
        }
        if (getCountry) {
            params.push({
                "country": getCountry
            })
        }
        if (getCity) {
            params.push({
                "city": getCity
            })
        }
        if (getState) {
            params.push({
                "city": getState
            })
        }
        if (price_range) {
            params.push({
                "price_range": price_range
            })
        }
        if (amenities) {
            params.push({
                "amenities": amenities
            })
        }
        if (getShopName) {
            params.push({
                "shopName": getShopName
            })
        }
        if (getLat && getLng) {
            params.push({
                "location": `${getLat},${getLng}`
            })
        }

        let items: any = params.map((param: any) => {
            const key = Object.keys(param)[0];
            const value = encodeURI(param[key]);
            return `${key}=${value}`
        })

        console.log(items)

        let d = await api.get(`/place/list?${items.join('&')}`);

        // let d = []

        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_SUCCESS",
            payload: d.data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const getCurrentBPListings = (getLat: any, getLng: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_CURRENT_BUSINESS_PLACE_LISTING_REQUEST"
        })

        let { data } = await api.get(`/place/list?location=${getLat},${getLng}`);

        dispatch({
            type: "GET_CURRENT_BUSINESS_PLACE_LISTING_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_CURRENT_BUSINESS_PLACE_LISTING_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

export const getCurrentCity = (country: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_CURRENT_CITY_REQUEST"
        })

        let { data } = await api.get(`/places/get_top_cities?country=${country}`);

        dispatch({
            type: "GET_CURRENT_CITY_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_CURRENT_CITY_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// BP Listing Details

export const getBPListingsDetails = (place_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_DETAILS_REQUEST",
        });

        const { data } = await api.get(
            `/place/${place_id}`
        );

        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_DETAILS_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "GET_BUSINESS_PLACE_LISTING_DETAILS_FAILED",
            payload: e.response?.data?.message,
            success: false,
        });
    }
};

// BP Review Submit

export const BpReviewSubmit = (place_id: any, reviewData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "BP_PLACE_REVIEW_REQUEST",
        });

        const { data } = await api.post(`/place/${place_id}/review`, reviewData);

        dispatch({
            type: "BP_PLACE_REVIEW_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "BP_PLACE_REVIEW_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

// CREATE ENQUIRY

export const EnquiryCreate = (place_id: any, enquiryData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "ENQUIRY_CREATE_REQUEST",
        });

        const { data } = await api.post(`/place/${place_id}/enquiry`, enquiryData);

        dispatch({
            type: "ENQUIRY_CREATE_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "ENQUIRY_CREATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

// BP Listing Details

export const getPlaceDiscount = (place_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_PLACE_DISCOUNT_REQUEST",
        });

        const { data } = await api.get(`/place/${place_id}/discounts`);

        dispatch({
            type: "GET_PLACE_DISCOUNT_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "GET_PLACE_DISCOUNT_FAILED",
            payload: e.response?.data?.message,
            success: false,
        });
    }
};