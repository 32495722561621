// BP Listings Reducer

export const BPListingReducer = (state = { bpListings: [] }, action: any) => {
    switch (action.type) {
        case "GET_BUSINESS_PLACE_LISTING_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_BUSINESS_PLACE_LISTING_SUCCESS":
            return {
                ...state,
                loading: false,
                bpListings: action.payload?.data,
            };
        case "GET_BUSINESS_PLACE_LISTING_FAILED":
            return {
                ...state,
                bpListings: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export const BPCurrentListingReducer = (state = { bpListings: [] }, action: any) => {
    switch (action.type) {
        case "GET_CURRENT_BUSINESS_PLACE_LISTING_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_CURRENT_BUSINESS_PLACE_LISTING_SUCCESS":
            return {
                ...state,
                loading: false,
                bpCurrent: action.payload,
            };
        case "GET_CURRENT_BUSINESS_PLACE_LISTING_FAILED":
            return {
                ...state,
                bpCurrent: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export const CityListingReducer = (state = { getCity: [] }, action: any) => {
    switch (action.type) {
        case "GET_CURRENT_CITY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_CURRENT_CITY_SUCCESS":
            return {
                ...state,
                loading: false,
                getCity: action.payload,
            };
        case "GET_CURRENT_CITY_FAILED":
            return {
                ...state,
                getCity: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// BP Listings Details Reducer

export const BPListingDetailsReducer = (state = { bpListingDetails: [] }, action: any) => {
    switch (action.type) {
        case "GET_BUSINESS_PLACE_LISTING_DETAILS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_BUSINESS_PLACE_LISTING_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                bpListingDetails: action.payload?.data,
            };
        case "GET_BUSINESS_PLACE_LISTING_DETAILS_FAILED":
            return {
                ...state,
                bpListingDetails: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// BP REVIEW SUBMIT REDUCER

export const bpReviewSubmitReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "BP_PLACE_REVIEW_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "BP_PLACE_REVIEW_SUCCESS":
            return {
                ...state,
                loading: false,
                bpReviews: action.payload,
                error: false
            }

        case "BP_PLACE_REVIEW_RESET":
            return {
                ...state,
                loading: false,
                bpReviews: null
            }

        case "BP_PLACE_REVIEW_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// CREATE ENQUIRY

export const CreateEnquiryReducer: any = (state = {}, action: any) => {
    switch (action.type) {
        case "ENQUIRY_CREATE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "ENQUIRY_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                enquiryCreate: action.payload,
                error: false
            }

        case "ENQUIRY_CREATE_RESET":
            return {
                ...state,
                loading: false,
                enquiryCreate: null
            }

        case "ENQUIRY_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// BP Listings Details Reducer

export const BPGetPlaceDiscountReducer = (state = { placeDiscount: [] }, action: any) => {
    switch (action.type) {
        case "GET_PLACE_DISCOUNT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_PLACE_DISCOUNT_SUCCESS":
            return {
                ...state,
                loading: false,
                placeDiscount: action.payload?.data,
            };
        case "GET_PLACE_DISCOUNT_FAILED":
            return {
                ...state,
                placeDiscount: null,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};