import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { adminCustomerList, adminCustomerDelete } from "../../../../Redux/Action/Admin";
import { AppDispatch } from "../../../../Redux/Store";
import Loader from "../../../../Utils/Loader";
import { FaEye } from "react-icons/fa";
import { successNotify } from "../../../../Utils/toast";
import DeleteModal from "../../../../Components/Modal/Modal"
import { useForm, SubmitHandler } from "react-hook-form";

const AllUsers = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState('')
  const [loaderShow, setLoaderShow] = useState(false)

  const dispatch: AppDispatch = useDispatch();
  const { loading, customerList } = useSelector((state: any) => state.adminCustomerList);
  const { loading: deleteLoading, customerDelete } = useSelector((state: any) => state.adminCustomerDelete);

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<any>();

  useEffect(() => {
    if (customerList) {
      setLoaderShow(false)
    }
  }, [customerList])

  useEffect(() => {
    if (customerDelete?.status === 1) {
      successNotify("Customer Deleted Successfully!!")
      setShow(!show)
      dispatch(adminCustomerList(''))
      dispatch({ type: "ADMIN_CUSTOMER_DELETE_RESET" })
    }
  }, [customerDelete?.status === 1])

  useEffect(() => {
    dispatch(adminCustomerList(''))
  }, [dispatch])

  const userDetail = (id: number) => {
    navigate(`/admin/customer-details/${id}`);
  };

  const deleteHandler = (id: any) => {
    setShow(!show)
    setCustomerId(id)
  }

  const deleteOwner = () => {
    dispatch(adminCustomerDelete(customerId))
  }

  const productSearchHandler: SubmitHandler<any> = (data: any) => {
    setLoaderShow(true)
    dispatch(adminCustomerList(data.search))
    reset();
  }

  const ResetHandler = () => {
    dispatch(adminCustomerList(''))
  }

  return (
    <div className="page_responsive">
      <DeleteModal
        show={show}
        size="lg"
        onCloseModal={() => setShow(!show)}
        showIcon={true}
        title="Delete"
      >

        <div>
          <h5>Are you sure you want to delete this Customer??</h5>
          <div>
            <button className="mx-3 back_btn" onClick={deleteOwner}>{deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}</button>
            <button className="back_btn" onClick={() => setShow(!show)}>No</button>
          </div>
        </div>
      </DeleteModal>

      <h1 className="main_heading">All End Users</h1>

      <Form onSubmit={handleSubmit(productSearchHandler)}>
        <Row>
          <Col md={3} className="py-2">
            <div className="signup_input">
              <img src="/images/AccountIcon.png" alt="" />
              <input
                placeholder="Search...."
                {...register("search")}
              />
            </div>
          </Col>
          <Col md={3} className="py-2">
            <button type="submit" className="back_btn mx-2"> {loaderShow ? <Spinner animation="border" size="sm" /> : 'Search'} </button>
            <button type="button" className="back_btn" onClick={ResetHandler}> Reset </button>
          </Col>
        </Row>
      </Form>

      <div className="table_div my-3">
        <h4>All End Users</h4>

        {
          loading ? <div
            style={{ height: '50vh' }}
            className={"d-flex justify-content-center align-items-center"}
          >
            <Loader />
          </div> :
            <Table responsive className="mb-0">
              <thead>
                <tr style={{ backgroundColor: "#8080800f" }}>
                  <th className="">ID</th>
                  <th className="">Name</th>
                  <th className="">Email</th>
                  <th className="">Orders</th>
                  <th className="">IsVerified</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {customerList?.map((user: any, index: number) => {
                  return (
                    <tr key={user._id}>
                      <td className="">{index + 1}</td>
                      <td className="">{user.first_name}</td>
                      <td className="">{user.email}</td>
                      <td className=""> <span className="view_orders_customer" onClick={() => navigate(`/admin/orders?userId=${user._id}`)}>View Orders</span> </td>
                      <td className="">{user.isVerified ? <span className="approved">Yes</span> : <span className="unsubs"> No </span>}</td>
                      <td className="">
                        <div className="table_icons">
                          <FaEye onClick={() => userDetail(user._id)} />
                          <AiFillDelete onClick={() => deleteHandler(user._id)} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
        }
      </div>
    </div>
  );
};

export default AllUsers;
