import React, { useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";
import axios from "axios";
import { errorNotify, successNotify } from "../../Utils/toast";

const Footer = () => {
  const [email, setEmail] = useState('')
  const [loader, setLoader] = useState(false)

  const emailHandler = () => {
    setLoader(true)
    if (email.length === 0) {
      errorNotify("Email cannot be empty");
      setLoader(false)
      return
    }

    let data = {
      email: email
    }

    axios.post('/save_newsletter', data).then((res: any) => {
      console.log(res)
      if (res?.data?.status === 1) {
        successNotify("Created Successfully!")
        setEmail('')
        setLoader(false)
      }
    }).catch((e: any) => {
      console.log(e)
      setLoader(false)
    })
  }

  return (
    <div className="footer-container">
      <Container className="pt-5">
        <Row>
          <Col md={5}>
            <div className="footer-about">
              <img src='/images/logo.png' alt="" />
              <p className="py-4 text-white fs-14">
                Hookahbars.com is your utimate Hookah directory, Hookah bars, Hoookah smoke shops and products worldwide.
              </p>
            </div>
          </Col>
          <Col md={3}>
            <h6 className="text-white">QUICK LINKS</h6>
            <div className="py-3 text-white footer-links ">
              <p> <Link to={'/advertise'}> Advertise </Link> </p>
              <p> <Link to={'/terms-service'}> Terms of Services </Link> </p>
              <p> <Link to={'/faqs'}> FAQS </Link> </p>
              <p> <Link to={'/about-us'}> About Us </Link></p>
              <p> <Link to={'/login'}> Operator Login </Link></p>
            </div>
          </Col>
          {/* <Col md={3}>
            <h6 className="text-white">TOP REGIONS</h6>
            <div className="py-3 text-white footer-links ">
              <p>New York Hookah Bars</p>
              <p>Chicago Hookah Bars</p>
              <p>Boston Hookah Bars</p>
              <p>San Diego Hookah Bars</p>
              <p>Austin Hookah Bars</p>
              <p>Washington DC Hookah Bars</p>
            </div>
          </Col> */}
          <Col md={4}>

            <h6 className="text-white">NEWSLETTER</h6>
            <div className="py-3 text-white">
              <div className="news-letter">

                <input placeholder="Enter email address" value={email} onChange={(e: any) => setEmail(e.target.value)} />

                {
                  loader ? <Spinner animation="border" size="sm" style={{ position: "absolute", top: "16px", right: "15px", color: "#000" }} /> :
                    <img src='/images/paperPlaneIcon.png' style={{ cursor: "pointer" }} alt="" onClick={emailHandler} />
                }
              </div>

              <h6 className="pt-3 pb-2">SOCIAL CONNECT</h6>
              <div className="social-connect d-flex">
                <div> <img src='/images/facebookIcon.png' alt="" /> </div>
                <div> <img src='/images/Twitter.png' alt="" /> </div>
                <div> <img src='/images/instagram.png' alt="" /> </div>
                <div> <img src='/images/tik-tok.png' alt="" /> </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr style={{ color: '#ffffff52' }} />
      <Container>
        <Row>
          <div className="d-flex justify-content-between copyrights">
            <p className="text-white">Copyright &#169; 2023, All Right Reserved</p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
