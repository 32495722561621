import React from 'react'
import { useNavigate } from 'react-router-dom'

const UnSubscribe = () => {
    const navigate = useNavigate()
    return (
        <div className="notFound_container">
            <div className="notFound_box">
                <div style={{ textAlign: "center", color: "#495057" }}>
                    <h1 style={{ color: '#ba2271', fontWeight: "800" }}> You are unsubscribe from <br /> Mailing Services</h1>
                    <button className="mt-4" onClick={() => navigate('/')}>Return Home</button>
                </div>
            </div>
        </div>
    )
}

export default UnSubscribe